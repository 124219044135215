import AttachmentsList from '@/shared/attachments-list/index.vue';
import MediaModal from '@/shared/media-modal/index.vue';

// @vue/component
export default {
  name: 'ModalPlanWaiver',

  components: {
    AttachmentsList,
    MediaModal,
  },

  props: {
    /**
     * Waiver data
     * @type {object}
     */
    waiver: {
      type: Object,
    },

    /**
     * Is accepted
     * @type {boolean}
     * @default false
     */
    accepted: {
      type: Boolean,
      default: false,
    },

    /**
     * Plan title text
     * @type {string}
     * @default ''
     */
    planTitle: {
      type: String,
      default: '',
    },

    /**
     * Clear waiver
     * @type {boolean}
     * @default true
     */
    clearWaiver: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    waiverAccept: false,
    goBack: true,
    waiverDatails: {},
  }),

  computed: {
    waiverText() {
      if (this.waiver) {
        return this.waiver?.text ? this.waiver.text : this.waiver.accepted_text;
      }

      return null;
    },
  },

  mounted() {
    this.waiverDatails = this.waiver;
  },

  methods: {
    show() {
      if (!this.waiverAccept) {
        this.$refs.planWaiverModal.show();
      }
    },

    hide() {
      this.$refs.planWaiverModal.hide();
      this.waiverAccept = !this.clearWaiver;
    },

    hiddenModal() {
      if (this.goBack && this.$route.name == 'plan_purchase_form') {
        this.$emit('hideWaiverModal');
      }
    },

    acceptWaiver() {
      if (!this.waiverAccept) {
        return false;
      }

      this.$emit('waiverAccepted');
      this.goBack = false;
      this.hide();
    },
  },
};
