const Billing = () => import('./layout/index.vue');
const BillingPayment = () => import('./payment/index.vue');
const BillingHistory = () => import('./history/index.vue');
const BillingPurchases = () => import('./purchases/index.vue');

export default [
  {
    path: 'billing',
    component: Billing,

    children: [
      {
        path: '',
        redirect: { name: 'billing_purchases' },
      },
      {
        path: 'purchases',
        component: BillingPurchases,
        name: 'billing_purchases',
        meta: { disableScrollBehavior: true },
      },
      {
        path: 'payment',
        component: BillingPayment,
        name: 'billing_payment',
        meta: { disableScrollBehavior: true },
      },
      {
        path: 'history',
        component: BillingHistory,
        name: 'billing_history',
        meta: { disableScrollBehavior: true },
      },
    ],
  },
];
