<template>
  <div class="wrap d-flex flex-column">
    <app-header></app-header>

    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/shared/app-header/index.vue';

export default {
  components: {
    AppHeader,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.content {
  padding: 56px 0 0;
}
</style>
