// const UserProfile = () => import('./layout/index.vue');
const UserProfileInfo = () => import('./info/index.vue');

export default [
  {
    path: 'profile',
    component: UserProfileInfo,
    name: 'user_profile_info',
  },
];
