import { mapActions } from 'vuex';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

import MediaModal from '@/shared/media-modal/index.vue';

import ChecklistWrapper from '@/components/onboarding-checklist/checklist-wrapper/index.vue';

// enum name: {
//   sing_up: 'sing_up',
//   watched_video_tour: 'watched_video_tour',
//   create_first_plan: 'create_first_plan',
//   published_first_plan: 'published_first_plan',
//   sign_up_first_client: 'sign_up_first_client'
// }

const steps = [
  {
    key: 'sing_up',
    title: 'Sign up',
    is_collapsible: false,
  },
  {
    key: 'watched_video_tour',
    title: 'Watch our overview video',
    is_collapsible: true,
    is_trackable: true,
    media: {
      // thumb: '@/assets/images/my_media/temp/photo_1_b.jpg',
      video: 'pfqJ-6sAVNY',
    },
    list: [
      'We cover everything you need to know to get started on FITR.',
    ],
  },
  {
    key: 'published_first_plan',
    title: 'Build your first program',
    is_collapsible: true,
    media: {
      // thumb: '@/assets/images/my_media/temp/video_2.jpg',
      video: 'EuRS4N6wR4M',
    },
    list: [
      'Programs are how you deliver content to your clients. Choose from the four types to suit your needs.',
      '<a href="/coach/profile/payouts" target="_self">Connect to Stripe</a> to sell your programs, earn money and automate your finances. Accept payments in over 135 currencies.',
      "Click <a href='/coach/programs/create/choose-plan-type' target='_self'>'Create new program'</a> and follow the step by step guide.",
    ],
    // cta: {
    //   to: {
    //     name: 'my_plans',
    //     params: {choose_program_type: true,},
    //   },
    //   label: 'Create new program',
    // },
  },
  {
    key: 'sign_up_first_client',
    title: 'Sign up your first client',
    is_collapsible: true,
    media: {
      video: '-DcgAsky1lE',
    },
    list: [
      "You'll be guided to sign up a client as you create a program.",
      'Add people you know via name and email, or share sign up URL links to the masses.',
      "Sign up clients later via the 'More' option next to the program name.",
    ],
  },
];

// @vue/component
export default {
  name: 'ChecklistPopup',

  components: {
    MediaModal,
    ChecklistWrapper,
  },

  props: {
    /**
     * Is all steps done
     * @type {boolean}
     * @default false
     */
    isAllStepsDone: {
      type: Boolean,
      default: false,
    },

    /**
     * Completed
     * @type {Array}
     * @default []
     */
    completed: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**
     * Is Mobile view
     * @type {boolean}
     * @default false
     */
    isMobile: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      steps,
      isShown: false,
      isHideBusy: false,
      expanded: null,
    };
  },


  methods: {
    /**
     * Toggle
     */
    toggle() {
      if (this.isShown) {
        this.hide();
      } else {
        this.show();
      }
    },

    /**
     * Show
     */
    show() {
      if (!this.isShown) {
        this.isShown = true;

        // automatically expand first un-marked step
        const firstUndone = this.steps.find(
          (s) => !this.completed.includes(s.key)
        );

        if (firstUndone) {
          this.expanded = firstUndone.key;
        }
      }
    },

    /**
     * Hide
     */
    hide() {
      if (this.isShown) {
        this.isShown = false;
      }
    },

    /**
     * Show step media
     * @param {string} key - step key
     */
    showStepMedia(key) {
      const step = this.steps.find((s) => s.key == key);

      if (step?.media?.video) {
        const media = {
          type: 'youtube',
          video_id: step.media.video,
        };

        this.$refs.mediaModal?.openLightbox(media);

        if (step.is_trackable) {
          this.markStepAsCompleted(key);
        }
      }
    },

    /**
     * Title for step
     * @param {object} step - step
     * @returns {string} The title of the step.
     */
    titleForStep(step) {
      return step.title;
    },

    /**
     * Toggle step
     * @param {string} key - step key
     */
    toggleStep(key) {
      if (this.expanded == key) {
        this.expanded = null;
      } else {
        this.expanded = key;
      }
    },

    /**
     * Is expanded
     * @param {string} key - step key
     * @returns {boolean} True if the step is expanded, false otherwise.
     */
    isExpanded(key) {
      return this.expanded == key;
    },

    /**
     * Is completed
     * @param {string} key - step key
     * @returns {boolean} True if the step is completed, false otherwise.
     */
    isCompleted(key) {
      if (this.completed) {
        return this.completed.includes(key);
      }

      return false;
    },

    /**
     * Mark step as completed
     * @param {string} key - step key
     */
    markStepAsCompleted(key) {
      if (!this.isCompleted(key)) {
        HTTP.post('check_list', { check_list: { name: key } });
      }
    },

    /**
     * Hide forever
     */
    hideForever() {
      this.isHideBusy = true;

      this.hideOnboardingChecklist()
        .then(() => {
          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: 'Checklist was hidden.',
          });
        })
        .catch((e) => {
          HttpErrorsHandling(e);

          this.isHideBusy = false;
        });
    },

    /**
     * Switch Shown
     * @param {boolean} isShown - isShown
     */
    switchShown(isShown) {
      this.isShown = isShown;
    },

    ...mapActions(['hideOnboardingChecklist']),
  },
};
