import { getUnreadCounterByType, ActivityTypes } from '../helpers';

const DAY_KEY_FORMAT = 'DD/MM/YYYY';

export const setActivityType = function (state, activity_type) {
  if (ActivityTypes[activity_type] != undefined) {
    state.activityType = ActivityTypes[activity_type];
  } else {
    state.activityType = '';
  }
};

export const setPagination = function (state, meta) {
  let { first_date, last_date, min_offset, total } = meta;

  state.pagination = { first_date, last_date, min_offset, total };
};

export const setUnread = (state, meta) => {
  let {
    unread_activity,
    unread_financial,
    unread_total,
    unread_warning,
    unread_invites,
    unread_leaderboards,
  } = meta;

  state.unread = {
    unread_activity,
    unread_financial,
    unread_total,
    unread_warning,
    unread_invites,
    unread_leaderboards,
  };
};

// Read/Unread
export const markRead = function (state, id) {
  this._vm.$set(state.notifications[id], 'read', true);
};

export const markUnread = function (state, id) {
  this._vm.$set(state.notifications[id], 'read', false);
};

export const increaseUnreadCounter = function (state, id) {
  let noty = state.notifications[id];
  let type = getUnreadCounterByType(noty.activity_type);

  if (type != undefined) {
    state.unread[type] = state.unread[type] + 1;
    state.unread['unread_total'] = state.unread['unread_total'] + 1;
  }
};

export const decreaseUnreadCounter = function (state, id) {
  const noty = state.notifications[id];

  if (!noty) return;

  const type = getUnreadCounterByType(noty.activity_type);

  if (type != undefined) {
    if (state.unread[type] > 0) {
      state.unread[type] = state.unread[type] - 1;
    }

    if (state.unread['unread_total'] > 0) {
      state.unread['unread_total'] = state.unread['unread_total'] - 1;
    }
  }
};

export const markActivityAsRead = function (state, activity_type) {
  for (let noty in state.notifications) {
    if (state.notifications[noty].activity_type == activity_type) {
      this._vm.$set(state.notifications[noty], 'read', true);
    }
  }
};

export const markAllAsRead = function (state) {
  for (let noty in state.notifications) {
    this._vm.$set(state.notifications[noty], 'read', true);
  }
};

// Add notifications
export const receiveNotifications = function (state, items) {
  items.forEach((item) => {
    if (state.notifications[item.id] == undefined) {
      let noty = {
        ...item,
        date_key: this._vm
          .$moment(item.created_at * 1000)
          .format(DAY_KEY_FORMAT),
      };

      this._vm.$set(state.notifications, noty.id, noty);
    }
  });
};

export const receiveNotification = function (state, item) {
  let noty = {
    ...item,
    date_key: this._vm.$moment(item.created_at * 1000).format(DAY_KEY_FORMAT),
  };

  this._vm.$set(state.notifications, noty.id, noty);
};

export const destroyNotification = function (state, id) {
  this._vm.$delete(state.notifications, id);
};
