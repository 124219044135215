import { groupBy, cloneDeep } from 'lodash-es';

import RemoveButton from '@/shared/buttons/remove.vue';
import DocumentIcon from '@/shared/document-icon/index.vue';
import SvgIcon from '@/shared/svg-icon/index.vue';
import draggable from 'vuedraggable';

const MediaMaxHeight = 192;
const DocMaxHeight = 124;

// @vue/component
export default {
  name: 'AttachmentsList',

  components: {
    DocumentIcon,
    RemoveButton,
    SvgIcon,
    draggable,
  },

  props: {
    /**
     * The mode of the attachments list.
     * @type {string}
     * @default 'view'
     */
    mode: {
      type: String,
      default: 'view', // edit
    },

    /**
     * The size of the attachments list.
     * @type {string}
     * @default 'md'
     */
    size: {
      type: String,
      default: 'md', // md(48) lg(80) sm(40)
    },

    /**
     * An array of attachments.
     * @type {Array}
     */
    attachments: {
      type: Array,
    },

    // media item can be setted as title
    /**
     * Determines whether the media item can be set as a title.
     * @type {boolean}
     * @default false
     */
    isTitled: {
      type: Boolean,
      default: false,
    },

    // ability to change list order
    /**
     * Determines whether the item is draggable.
     * @type {boolean}
     * @default false
     */
    draggable: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines whether the title should be shown in read mode.
     * @type {boolean}
     * @default false
     */
    readModeShowTitle: {
      type: Boolean,
      default: false,
    },

    /**
     * View of attachments scrollable or vertical list with btn hide All
     * @type {boolean}
     * @default true
     */
    isScrollable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      seeAll: false,
      collapsed: false,
    };
  },

  computed: {
    groupedAttachments() {
      return groupBy(this.attachments, 'type');
    },

    mediaAttachments() {
      return [
        ...(this.groupedAttachments.video || []),
        ...(this.groupedAttachments.image || []),
        ...(this.groupedAttachments.youtube || []),
      ].sort((a, b) => {
        return parseInt(a.position, 10) - parseInt(b.position, 10);
      });
    },

    hasMediaAttachments() {
      return this.mediaAttachments?.length > 0;
    },

    docsAttachments() {
      return [...(this.groupedAttachments.other || [])].sort((a, b) => {
        // if (b.position && a.position) {
        return parseInt(a.position, 10) - parseInt(b.position, 10);
        // }
      });
    },

    hasDocsAttachments() {
      return this.docsAttachments?.length > 0;
    },
  },

  watch: {
    'mediaAttachments.length': function () {
      this.calculateMediaListPositions(cloneDeep(this.mediaAttachments));
    },
  },

  mounted: function () {
    if (!this.isScrollable) {
      this.showBtn();
    }
  },

  updated: function () {
    if (!this.isScrollable) {
      this.showBtn();
    }
  },

  methods: {
    remove(file) {
      this.$emit('remove', file);
    },

    setTitleMedia(file) {
      const currentState = file.title_media;

      this.resetTitleMedia();

      return (file.title_media = !currentState);
    },

    resetTitleMedia() {
      this.attachments.map((i) => (i.title_media = false));
    },

    collapse() {
      this.collapsed = !this.collapsed;
    },

    showAllAttachments() {
      if (this.seeAll && !this.isScrollable) {
        this.collapsed = true;
      }
    },

    mediaListEl() {
      const list = this.$refs.mediaList?.$el
        ? this.$refs.mediaList.$el
        : this.$refs.mediaList;

      if (list?.scrollHeight) {
        return list.scrollHeight > MediaMaxHeight;
      }
    },
    docListEl() {
      const list = this.$refs.docsList?.$el
        ? this.$refs.docsList.$el
        : this.$refs.docsList;

      if (list?.scrollHeight) {
        return list.scrollHeight > DocMaxHeight;
      }
    },
    showBtn() {
      this.seeAll = this.mediaListEl() || this.docListEl();
    },

    updateDraggablePosition(isAttacmentMedia, el) {
      const copy = cloneDeep(
        isAttacmentMedia ? this.mediaAttachments : this.docsAttachments
      );

      const attch = copy.splice(el.oldIndex, 1);

      copy.splice(el.newIndex, 0, attch[0]);

      this.calculateMediaListPositions(copy);
    },

    calculateMediaListPositions(list) {
      const sorted = cloneDeep(this.attachments);

      list.forEach((copied, indx) => {
        const origin = sorted.find((item) => {
          return item.id == copied.id;
        });

        if (origin) {
          origin.position = indx;
        }
      });

      this.$emit('positionsUpdated', sorted);
    },
  },
};
