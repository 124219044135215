import { HTTP } from '@/utils/http-common';

/*
// Фрии
subscription: {
  active: true,
  state: null,
  free_usage: true,
  trial: null,
  trial_end_date: null
}

// Подписка
subscription: {
  active: true,
  state: 'active', // 'past_due'
  free_usage: false,
  trial: null,
  trial_end_date: 'dd.mm.yyyy'
}

// Триал активный
subscription: {
  active: true,
  state: null,
  free_usage: false,
  trial: true,
  trial_end_date: 'dd.mm.yyyy'
}

// Триал активный c подпиской
subscription: {
  active: true,
  state: 'trial',
  free_usage: false,
  trial: true,
  trial_end_date: 'dd.mm.yyyy'
}

// Инвайтед (blocked)
subscription: {
  active: false,
  state: null,
  free_usage: false,
  trial: false,
  trial_end_date: null
}

// Cancaled (blocked)
subscription: {
  active: false,
  state: 'cancaled', // 'pending'
  free_usage: false,
  trial: false,
  trial_end_date: 'dd.mm.yyyy'
}
*/

// enum state: { init: 'init', trialing: 'trialing', active: 'active', past_due: 'past_due', canceled: 'canceled', unpaid: 'unpaid', incomplete: 'incomplete', incomplete_expired: 'incomplete_expired' }

export default {
  namespaced: true,

  getters: {
    subscriptionData(state, getters, rootState, rootGetters) {
      if (rootGetters.isUserCoach) {
        // return {
        //   active: true,
        //   state: null,
        //   free_usage: false,
        //   trial: true,
        //   trial_end_date: '2020-06-30 12:05:03 +0100'
        // }

        // return {
        //   active: true,
        //   state: 'active',
        //   free_usage: false,
        //   trial: null,
        //   trial_end_date: 'dd.mm.yyyy'
        // }

        return rootState.currentUser.subscription;
      }
    },

    isWLActive(state, getters) {
      if (getters.subscriptionData) {
        return getters.subscriptionData.white_label_is_active;
      }
    },

    isWLTrial(state, getters) {
      if (getters.subscriptionData) {
        return getters.isWLActive && getters.subscriptionData.trial;
      }
    },

    hasWhiteLabel(state, getters) {
      return getters.subscriptionData.white_label_subscription?.active;
    },

    isWLPaindAndActive(state, getters) {
      if (getters.subscriptionData) {
        return (
          getters.hasWhiteLabel &&
          getters.isWLActive &&
          !getters.subscriptionData.white_label_subscription
            ?.cancel_at_period_end
        );
      }
    },

    currentPlan(state, getters) {
      return getters.subscriptionData?.plan;
    },

    scheduledPlan(state, getters) {
      return getters.subscriptionData?.scheduled_plan;
    },

    isAccountActive(state, getters) {
      return getters.subscriptionData?.active;
    },

    isAccountBlocked(state, getters) {
      return !getters.subscriptionData?.active;
    },

    isAccountFree(state, getters) {
      return getters.subscriptionData?.free_usage;
    },

    hasSubscription(state, getters) {
      return !!getters.currentPlan?.id && getters.subscriptionData?.state;
    },

    // TODO remove DEPR
    isSubscriptionMonthly(state, getters) {
      return getters.hasSubscription && getters.currentPlan.interval == 'month';
    },

    isSubscriptionAnnual(state, getters) {
      return getters.hasSubscription && getters.currentPlan.interval == 'year';
    },
    // /-->

    isSubscriptionStarter(state, getters) {
      return (
        getters.hasSubscription &&
        getters.currentPlan.product_kind == 'starter' &&
        getters.currentPlan.interval == 'month'
      );
    },

    isSubscriptionPro(state, getters) {
      return (
        getters.hasSubscription &&
        getters.currentPlan.product_kind == 'standalone' &&
        getters.currentPlan.interval == 'month'
      );
    },

    isSubscriptionUnlimited(state, getters) {
      return (
        getters.hasSubscription &&
        getters.currentPlan.product_kind == 'business'
        // && getters.currentPlan.interval == 'year'
      );
    },

    isActiveNewPricingPlan(state, getters) {
      return (
        getters.isSubscriptionStarter ||
        getters.isSubscriptionPro ||
        getters.isSubscriptionUnlimited
      );
    },

    isCanceled(state, getters) {
      return (
        !getters.isAccountFree &&
        ['canceled', 'incomplete_expired'].includes(
          getters.subscriptionData.state
        )
      );
    },

    isCancelAtPeriodEnd(state, getters) {
      return (
        !getters.isAccountFree &&
        getters.isAccountActive &&
        getters.subscriptionData?.cancel_at_period_end
      );
    },

    canSubscriptionBeCanceled(state, getters) {
      return (
        !getters.isAccountFree &&
        getters.hasSubscription &&
        !getters.isCancelAtPeriodEnd &&
        getters.subscriptionData.state != 'canceled'
      );
    },

    // neither subscription nor trial
    isAccountEmpty(state, getters) {
      return (
        !getters.isAccountFree &&
        !getters.isAccountActive &&
        !getters.subscriptionData.trial_end_date
      );
    },

    isTrialActive(state, getters) {
      return (
        !getters.isAccountFree &&
        getters.isAccountActive &&
        getters.subscriptionData?.trial &&
        !getters.hasSubscription
      );
    },

    isTrialExpired(state, getters) {
      return (
        !getters.isAccountFree &&
        !getters.hasSubscription &&
        !getters.isAccountActive &&
        !getters.isTrialActive &&
        !!getters.subscriptionData.trial_end_date
      );
    },

    // humanize names
    humanizePlanInterval(state, getters) {
      return getters.isSubscriptionUnlimited ? 'unlimited' : 'monthly';
    },

    humanizePlanKind(state, getters) {
      switch (true) {
        case getters.isSubscriptionStarter:
          return 'starter';
        case getters.isSubscriptionPro:
          return 'pro';
        case getters.isSubscriptionUnlimited:
          return 'unlimited';

        // TODO remove DEPR
        default:
          return 'Current';
      }
    },

    humanizeScheduledPlanKind(state, getters) {
      switch (true) {
        case getters.scheduledPlan.product_kind == 'starter':
          return 'starter';
        case getters.scheduledPlan.product_kind == 'standalone':
          return 'pro';
        case getters.scheduledPlan.product_kind == 'business':
          return 'unlimited';

        // TODO remove DEPR
        default:
          return 'Current';
      }
    },

    // payments
    isPurcahseFailed(state, getters) {
      return (
        !getters.isAccountFree &&
        getters.hasSubscription &&
        ['past_due', 'incomplete'].includes(getters.subscriptionData.state)
      );
    },
    isSwlPurcahseFailed(state, getters) {
      return (
        !getters.isAccountFree &&
        getters.hasSubscription &&
        getters.subscriptionData.white_label_subscription &&
        ['past_due', 'incomplete'].includes(
          getters.subscriptionData.white_label_subscription?.state
        )
      );
    },
  },

  actions: {
    startTrial(context) {
      return new Promise((resolve, reject) => {
        HTTP.post('users/me', {
          user: { trial_start: true },
        })
          .then(({ data }) => {
            context.commit(
              'setCurrentUserSubscription',
              data.current.subscription,
              { root: true }
            );

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
