export default {
  info: {
    membership: {
      coach: 'Choose your own start date and follow the programming from your calendar view. You’ll be able to always see a week in advance.',
      else: 'Make sure you keep updating the training schedule over time.'
    },
    fixed: {
      coach: 'Choose your own start date after download and follow the programming from your calendar view.',
      else: 'Client’s choose their own start date after downloading the program.'
    },
    bespoke: {
      coach: 'Follow your programming from your calendar view. Future training visibility is set by your coach.',
      else: 'Make sure you keep updating the training schedule over time.'
    },
    one_many: {
      coach: 'Follow your programming from your calendar view. Future training visibility is set by your coach.',
      else: 'Make sure you keep updating the training schedule over time.'
    }
  },
  tab: {
    coach: 'The information below is strictly a preview of what the program will look like. The preview only shows the title, description, first week of content and general attachments.',
    else: 'The information below is strictly a preview of the program. Always follow your training from your main calendar. If you can’t see it in your calendar, you may need to set a start date (membership and fixed length programs) or contact your coach (bespoke programs).'
  }
}