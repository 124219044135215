// import Store from 'store/store';
const CoachMyProfileInfo = () => import('./profile_info/index.vue');

export default [
  {
    path: 'my_profile',
    component: CoachMyProfileInfo,

    children: [
      {
        path: '',
        redirect: { name: 'coach_my_profile_info' },
      },

      {
        path: 'info',
        component: CoachMyProfileInfo,
        name: 'coach_my_profile_info',
        meta: {
          coachOwnProfile: true,
          disableScrollBehavior: true,
          availableForBlocked: true,
        },
      },
    ],
  },
];
