import Vue from 'vue';
import VueRouter from 'vue-router';

import NotFound from '@/components/pages/NotFound.vue';
import Main from '@/components/pages/Main.vue';
import AuthRoutes from '@/components/auth/routes.js';
import PagesRoutes from '@/components/pages/routes.js';
import PlanRoutes from '@/components/plan/routes.js';

import ProfileRoutes from '@/components/profile/routes.js';

import LandingRoutes from '@/components/landing/routes.js';
import ChatRoutes from '@/components/chat/routes.js';
import FeedRoutes from '@/components/feed/routes.js';
import LeaderboardsRoutes from '@/components/leaderboards/routes.js';
import ReleaseNotesRoutes from '@/components/release-notes/routes.js';

import Store from '@/store/store.js';

Vue.use(VueRouter);

let mainRoutes = [
  {
    path: '',
    component: Main,
    name: 'main',

    meta: {
      availableForBlocked: true,
    },

    beforeEnter: (to, from, next) => {
      if (Store.state.authenticated) {
        if (Store.state.currentUser.role === 'user') {
          next({ name: 'user_my_plans', replace: true });
        } else {
          next({ name: 'my_plans', replace: true });
        }
      } else {
        next({ name: 'sign_up', replace: true });
      }
    },
  },
  {
    path: '/fitr-style-guide',
    component: () => import('@/components/pages/styleGuide.vue'),
  },

  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      availableForBlocked: true,
    },
  },

  {
    path: '*',
    redirect: '/404',
  },
];

let routes = [
  ...mainRoutes,
  ...AuthRoutes,
  ...PagesRoutes,
  ...PlanRoutes,
  ...ProfileRoutes,
  ...ChatRoutes,
  ...FeedRoutes,
  ...LandingRoutes,
  ...LeaderboardsRoutes,
  ...ReleaseNotesRoutes,
];

export const ROUTER = new VueRouter({
  mode: 'history',
  base: '/',
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (!(to.meta.disableScrollBehavior && from.meta.disableScrollBehavior)) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  },
});

ROUTER.afterEach(() => {
  document.activeElement && document.activeElement.blur();

  window.Intercom && window.Intercom('update');
});

ROUTER.beforeEach((to, from, next) => {
  let shouldRedirectBack = [
    'public_plan',
    'public-page-view',
    'public-page-plans',
    'public-page-coaches',
    'public-page-short-link',
    'public-page-short-link-plans',
    'public-page-short-link-coaches',
  ].includes(from.name);

  if (
    to.name == 'my_plans_active_with_welcome_popup' &&
    !Store.state.authenticated
  ) {
    window.localStorage.setItem('utm_query', JSON.stringify(to.query));

    // next({ name: 'onboarding-coaching-options' });
    next({ name: 'onboarding-coaching-signup' });
  } else {
    if (
      [
        'sign_up',
        'sign_in',
        'onboarding-coaching-signup',
        'onboarding-role-selection',
        'onboarding-type-selection',
        'onboarding-signin',
      ].includes(to.name)
    ) {
      if (Store.state.authenticated) {
        // next({ path: '/profile' });
        next({ name: 'coach_my_profile_info' });
      } else {
        if (
          shouldRedirectBack &&
          (!window.localStorage.getItem('fitr_return_path') ||
            window.localStorage.getItem('fitr_return_path') !== from.fullPath)
        ) {
          window.localStorage.setItem('fitr_return_path', from.fullPath);
        }
        next();
      }
    } else {
      // https://jira.themindstudios.com/browse/FITR-3655 //
      // let isCoachProgramsPages = [
      //   'coach_program_view',
      //   'coach_program_view_details',
      //   'coach_program_view_clients',
      //   'coach_program_view_invites',
      //   'coach_program_view_settings'
      // ].includes(to.name);

      let isCoachProgramsPages = to.name?.indexOf('coach_program_');

      if (
        isCoachProgramsPages >= 0 &&
        (!Store.state.authenticated || !Store.getters.isUserCoach)
      ) {
        next({ name: 'public_plan', params: { plan_id: to.params.plan_id } });
      } else if (to.meta.publicPage) {
        next();
      } else if (Store.state.authenticated) {
        let isCoachProgramsPagesPath = to.path?.indexOf('coach/programs') >= 0;

        // Blocked coach
        // if (
        //   Store.getters.isUserCoach &&
        //   Store.getters['subscription/isAccountBlocked'] &&
        //   !to.meta.availableForBlocked
        // ) {
        //   next({ name: 'coach_blocked' });
        // } else
        if (
          Store.getters.isUserCoach &&
          isCoachProgramsPagesPath &&
          Store.getters['subscription/isAccountEmpty']
        ) {
          next({ name: 'coach_blocked' });
        } else {
          next();
        }
      } else {
        if (shouldRedirectBack) {
          window.localStorage.setItem('fitr_return_path', to.fullPath);

          if (from.name == 'public_plan') {
            next({
              name: 'onboarding-type-selection',
            });
          } else {
            next({
              name: 'onboarding-coaching-signup',
              params: { role: 'client' },
            });
          }
        } else {
          if (to.name == 'user_my_plans') {
            window.localStorage.setItem('fitr_return_path', to.fullPath);
          }
          next({ name: 'onboarding-signin' });
        }
      }
    }
  }
});
