export default {
  sidebar: {
    title: 'Notes',
    close_btn: 'Close',
    add_note: 'Add note',
    add_new_note: 'Add new note',
    hotkey: 'or <b>‘Ctrl+Shift+N’</b> to quick add a new note',
    tooltip:
      'Adding notes is restricted. To unlock unlimited notes, please upgrade to the Pro or Unlimited plan.',
  },

  notes_warning: {
    text: 'You are on the Starter plan. Only the most recent note is visible. To view them all, please upgrade to the Pro or Unlimited plan.',
    btn: 'Upgrade',
    note_restricted: 'Note restricted!',
    notes_restricted: 'Notes restricted!',
  },

  notes_alert:
    'Limited features are available on Starter. Find out more by viewing our',
  notes_alert_plan: 'plans.',

  new_feature_modal: {
    title: 'You can now add Notes!',
    text_1: 'You can now add Notes to any program schedule.',
    text_2:
      'Get started by selecting the new Notes icon towards the top left of the screen.',
    checkbox_label: "Don't show me this again",
    close_btn: 'Close',
    action_btn: 'Learn more',
    list: {
      item_1: 'Add Resources to your Notes.',
      item_2: 'View Notes you’ve saved whilst programming.',
      item_3:
        'For Custom program clients, keep a Note of their injuries, equipment and goals.',
      item_4: 'Save a Note for ideas you have for your group programs.',
      item_5: 'Leave Notes for additional coaches on your account.',
      item_6:
        'Create as many Notes as you like (Starter plan limited to 1 Note).',
    },
  },

  note_item: {
    title_placeholder: 'Note title',
    title_required: 'Title is required.',
    title_min: 'Min 2 Characters',
    title_max: 'Max 64 Characters',
    save_btn: 'Save',
    text_placeholder: 'Add your note and attach any Resources.',
    body_max: 'Max {max} Characters',
  },
};
