import Plan from '@/components/plan/index.vue';

export default [
  {
    path: '/p/:plan_id',
    component: Plan,
    name: 'public_plan',
    props: true,
    meta: {
      publicPage: true,
    },
  },

  // OLD
  {
    path: '/plan/:plan_id',
    redirect: (to) => {
      return { name: 'public_plan', params: to.params };
    },
  },
];
