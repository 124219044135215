const FONT_SIZE_SCALE = 0.45;

// @vue/component
export default {
  name: 'UserAvatar',

  props: {
    /**
     * User data
     * @type {object}
     */
    user: Object,

    /**
     * Is square
     * @type {boolean}
     * @default false
     */
    square: {
      type: Boolean,
      default: false,
    },

    /**
     * Size of img
     * @type {number}
     * @default 48
     */
    size: {
      type: Number,
      default: 48, // 56 48 40 36 32 24
    },

    /**
     * Is image hiden
     * @type {boolean}
     * @default false
     */
    hide_image: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isAccountsPage() {
      return !!this.$route?.meta.coachOwnProfile;
    },

    userName() {
      if (this.isAccountsPage) {
        return this.user.full_name;
      } else {
        return this.user?.title ? this.user.title : this.user.full_name;
      }
    },

    circle() {
      return !this.square;
    },

    isEmpty() {
      return this.hide_image ? true : !this.user.avatar_url;
    },

    avatarStyle() {
      const style = {
        width: this.size + 'px',
        height: this.size + 'px',
      };

      if (this.isEmpty && this.userName) {
        style.fontSize = this.size * FONT_SIZE_SCALE + 'px';
      }

      return style;
    },

    defaultAvatar() {
      if (this.userName) {
        if (window?.Intl?.Segmenter) {
          const segmenter = new Intl.Segmenter('en', {
            granularity: 'grapheme',
          });
          const segments = segmenter.segment(this.userName.trim());
          const chars = Array.from(segments, (s) => s.segment);
          return chars[0];
        } else {
          return this.userName.trim().charAt(0);
        }
      }

      return null;
    },
  },
};
