// @vue/component
export default {
  name: 'StatusWithIcon',

  props: {
    /**
     * Color can be primary, danger, gray, orange
     * @type {string}
     */
    color: {
      type: String,
      validator: function (value) {
        return ['primary', 'danger', 'gray', 'orange'].includes(value);
      },
    },

    /**
     * Name of icon
     * @type {string}
     */
    iconName: {
      type: String,
      default: '',
    },

    /**
     * Tooltip text
     * @type {string}
     */
    tooltip: {
      type: String,
    },

    /**
     * Link URL
     * @type {string}
     * @default 'xs'
     */
    link: {
      type: String,
    },

    /**
     * Text font-size can be base, sm, xs
     * @type {string}
     * @default 'xs'
     */
    fontSize: {
      type: String,
      default: 'xs',
      validator: function (value) {
        return ['base', 'sm', 'xs'].includes(value);
      },
    },
  },

  computed: {
    hasLink() {
      return this.link?.length > 0;
    },

    fontSizeClass() {
      return this.fontSize ? `font-size-${this.fontSize}` : '';
    },
  },

  methods: {
    goToLink(event) {
      if (this.hasLink) {
        event.stopPropagation();
        window.open(this.link, '_blank');
      }
    },
  },
};
