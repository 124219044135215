import { subscribe, unsubscribe } from '@/utils/actioncable.js';
import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'ChatIndicator',

  computed: {
    hasNew: {
      get: function () {
        return this.$store.state.currentUser.has_unread_chat_messages;
      },
      set: function (v) {
        this.$store.commit('changeHasNnreadChatMessages', v);
      },
    },

    isDisabled() {
      return this.isUserCoach && this.isAccountBlocked;
    },

    ...mapGetters(['isUserCoach']),
    ...mapGetters('subscription', ['isAccountBlocked']),
  },

  created: function () {
    this.channel = subscribe('ChatNotificationChannel', ({ data, type }) => {
      if (type == 'message_created' || type == 'chat_room_created') {
        if (
          (data.chat_room.last_message.author == undefined ||
            data.chat_room.last_message.author.id !=
              this.$store.state.currentUser.id) &&
          !data.chat_room.mute
        ) {
          this.hasNew = true;
        }
      } else if (type == 'no_unread_messages') {
        this.hasNew = false;
      } else if (type == 'has_unread_messages') {
        this.hasNew = true;
      }
    });
  },

  beforeDestroy: function () {
    unsubscribe(this.channel);
  },
};
