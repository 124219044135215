<template>
  <b-dropdown
    v-if="!!$slots['default']"
    :variant="dropdownVariant"
    no-caret
    lazy
    :right="right"
    :boundary="boundary"
    :popper-opts="popperOpts"
    :disabled="disabled"
    :class="`dropdown-${variant}`"
    @show="$emit('show', $event)"
    @shown="$emit('shown', $event)"
    @hide="$emit('hide', $event)"
    @hidden="$emit('hidden', $event)"
  >
    <template slot="button-content">
      <template v-if="variant === 'icon'">
        <SvgIcon name="moreIcon"></SvgIcon>
      </template>

      <template v-else-if="variant === 'button'">
        {{ $t('en.tables.common.more') }}
      </template>
    </template>

    <slot />
  </b-dropdown>
</template>

<script>
export default {
  props: {
    /**
     * @property {string} boundary - The boundary of the component. Defaults to 'window'.
     */
    boundary: {
      type: String,
      // default: 'scrollParent',
      default: 'window',
    },
    /**
     * @property {boolean} right - Whether the component is aligned to the right. Defaults to true.
     */
    right: {
      type: Boolean,
      default: true,
    },
    /**
     * @property {boolean} preventOverflow - Whether to prevent overflow. Defaults to false.
     */
    preventOverflow: {
      type: Boolean,
      default: false,
    },
    /**
     * @property {boolean} disabled - Whether the component is disabled. Defaults to false.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * @property {string} variant - The variant of the component. Defaults to 'icon'.
     */
    variant: {
      type: String,
      default: 'icon',
      validator: (val) => ['icon', 'button'].includes(val),
    },
  },

  computed: {
    popperOpts() {
      const opts = {
        // modifiers: { flip: { padding: 72 } },
        modifiers: {
          flip: { boundariesElement: 'window' },
          preventOverflow: { enabled: this.preventOverflow },
        },
        escapeWithReference: false,
        positionFixed: true,
      };

      return opts;
    },

    dropdownVariant() {
      switch (this.variant) {
        case 'button':
          return 'outline-dark btn-sm';
        default:
          return this.variant;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  &.dropdown-icon {
    &::v-deep {
      .dropdown-toggle {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        border: none;
        color: $gray-700;

        &:focus:not(:focus-visible) {
          box-shadow: none;
        }

        &:not(:disabled):hover {
          color: var(--primary-color, #{$primary});
        }
      }
    }
  }

  &.dropdown-button {
    &::v-deep {
      .dropdown-toggle {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-size: $font-size-xs;
      }
    }
  }
}
</style>
