import DatePicker from 'vue2-datepicker';
import { mapState, mapGetters } from 'vuex';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

import AppHeader from '@/shared/app-header/index.vue';
import PlanDetail from '@/shared/plan-detail-v2/index.vue';
import AddToCalendarModal from '@/shared/modal-add-to-calendar/index.vue';
import WaiverPlanModal from '@/shared/modal-plan-waiver/index.vue';
import OverlayLoader from '@/shared/overlay-loader/index.vue';

const AskQuestion = () =>
  import('@/components/landing/components/ask-question/index.vue');

import WhatHappensModal from '@/components/plan/modal-what-happens/index.vue';
import RescheduleModal from '@/components/plan/modal-reschedule/index.vue';
import UnsubscribeModal from '@/components/plan/modal-unsubscribe/index.vue';
import ResumeModal from '@/components/plan/modal-resume/index.vue';

import SignInModal from '@/components/auth/sign-in/sign-in-modal/index.vue';
import ProgramEditTopBar from '@/shared/program-edit-top-bar/index.vue';

import ModalAddPaymentMethod from '@/shared/modal-add-payment-method/index.vue';

const coverMoveSpeed = 0.45;

// @vue/component
export default {
  name: 'PublicPlan',

  components: {
    AppHeader,
    PlanDetail,
    AddToCalendarModal,
    WhatHappensModal,
    DatePicker,
    WaiverPlanModal,
    OverlayLoader,
    AskQuestion,
    RescheduleModal,
    UnsubscribeModal,
    ResumeModal,
    SignInModal,
    ProgramEditTopBar,
    ModalAddPaymentMethod,
  },

  props: {
    /**
     * The ID of the plan.
     * @type {string|number}
     */

    plan_id: {
      type: [String, Number],
    },

    /**
     * Indicates whether the plan has just been bought.
     * @type {boolean}
     */
    planJustBought: {
      type: Boolean,
      default: false,
    },

    /**
     * The route to navigate back to after completing a certain action.
     * @type {string}
     * @default null
     */
    backRoute: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      plan: {},
      isLoading: true,
      isSubmited: false,
      isMobileLayout: false,
      isRestartSubmited: false,
      isContinueSubmited: false,
      isFreeSubmited: false,
      isTrySubmited: false,
      retryInvalidFeedback: null,

      scrollPosition: 0,
      loadingRescheduleInfo: false,
      isResumeSubmited: false,
      agreement: false,
      startFreeMembershipFrom: '',
      isTryPaymentAgain: false,
      isTryingPaymentAgaing: false,
    };
  },

  computed: {
    isOwner() {
      return (
        this.currentUser.role == 'coach' &&
        this.currentUser.id == this.plan.user.id
      );
    },

    isOwnerBlocked() {
      return !this.plan.user.subscription_state;
    },

    isArchivedPlan() {
      return this.plan.archive;
    },

    isPrivatePlan() {
      return !this.plan.accessibility;
    },

    canSubscribe() {
      return !this.isArchivedPlan && this.currentUser.role != 'coach';
    },

    canSale() {
      return this.plan.can_sale;
    },

    isAllowedToBuy() {
      if (this.authenticated) {
        return this.plan.is_bought ? true : this.plan.allowed_to_buy;
      } else {
        return this.plan.allowed_to_buy;
      }
    },

    isUserDisallowedInPrivatePlan() {
      return this.canSubscribe && this.isPrivatePlan && !this.isAllowedToBuy;
    },

    isFreePlan() {
      return this.plan.plan_pricing == 'free';
    },

    isFixedPlan() {
      return this.plan.plan_type == 'fixed';
    },
    isMembershipPlan() {
      return this.plan.plan_type == 'membership';
    },
    isBespokePlan() {
      return this.plan.plan_type == 'individual';
    },
    isOneManyPlan() {
      return this.plan.plan_type == 'one_many';
    },

    displayedPrice() {
      if (this.currentUser.role == 'user') {
        return this.plan.local_price;
      } else {
        return this.plan.original_price;
      }
    },

    cannotBeBought() {
      return (
        !this.isFreePlan &&
        !this.isOwner &&
        !this.plan.user.stripe_connect_charges_enabled
      );
    },

    cssVars() {
      return {
        '--position': this.scrollPosition + 'px',
      };
    },

    isWaiverAvailable() {
      return !!this.plan.plan_waiver && !!this.plan.plan_waiver?.show;
    },

    isZapierAccount() {
      return this.plan.user?.zapier_account;
    },

    isCancelSubscribtionAvailable() {
      return this.plan.user?.zaps_connected || !this.isZapierAccount;
    },

    isScheduled() {
      return this.plan.membership && this.plan.membership.state == 'scheduled';
    },

    isShowTextInUnsubscribeModal() {
      if (this.isFreePlan) {
        return false;
      } else if (
        this.plan.membership &&
        ['incomplete', 'scheduled'].includes(this.plan.membership.state)
      ) {
        return false;
      } else {
        return true;
      }
    },

    // Check if launch date in the past
    planLaunchDate() {
      if (this.plan.launch_date) {
        const isPast = this.$moment(
          this.plan.launch_date,
          'YYYY-MM-DD'
        ).isSameOrBefore(this.$moment(), 'day');

        if (!isPast) {
          return this.plan.launch_date;
        }
      }
      return null;
    },

    isStartTrialAvailable() {
      if (this.planLaunchDate) {
        return false;
      }

      return !!this.plan.trial_days;
    },

    skipSalesPage() {
      return (
        this.currentUser.role != 'coach' &&
        this.plan.skip_sales_page &&
        !this.plan.is_bought &&
        !this.isScheduled
      );
    },

    ...mapState(['currentUser', 'authenticated']),
    ...mapGetters(['isUserCoach']),
  },

  watch: {
    '$route.params.plan_id': 'fetchData',
  },

  mounted() {
    this.fetchData();
  },

  created() {
    this.mq = window.matchMedia('(max-width: 797.98px)');
    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);

      this.mq = undefined;
    }
  },

  methods: {
    /**
     * Get data for reschedule and open modal
     */
    showRescheduleModal() {
      this.loadingRescheduleInfo = true;
      HTTP.get(`plans/${this.plan.id}/re_add_info`)
        .then(({ data }) => {
          this.$refs.rescheduleModal.showModal(data);

          this.loadingRescheduleInfo = false;
        })
        .catch((e) => {
          HttpErrorsHandling(e);
          this.loadingRescheduleInfo = false;
        });
    },

    /**
     * Cover animation
     */
    updateMatches() {
      if (this.mq.matches) {
        this.initScrollWatch();
      } else {
        this.destroyScrollWatch();
      }

      this.isMobileLayout = this.mq.matches;
    },

    /**
     * Init scroll watch
     */
    initScrollWatch() {
      this.requestId = window.requestAnimationFrame(this.updateScrollPosition);
    },

    /**
     * Destroy scroll watch
     */
    destroyScrollWatch() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);

        this.requestId = undefined;
      }
    },

    /**
     * Update scroll position
     */
    updateScrollPosition() {
      if (this.scrolledCover) {
        let box = this.scrolledCover.getBoundingClientRect();

        if (box.top <= 0 && box.bottom + this.scrollPosition >= 0) {
          this.scrollPosition = -box.top * coverMoveSpeed;
        } else if (box.top > 0) {
          this.scrollPosition = 0;
        }
      }

      this.initScrollWatch();
    },

    /**
     * Fetch data
     */
    fetchData() {
      this.isLoading = true;

      HTTP.get(`plans/${this.plan_id}/public`)
        .then((response) => {
          this.plan = response.data;

          if (this.skipSalesPage) {
            this.$router
              .replace({
                name: 'plan_purchase_form',
              })
              .catch(() => {});
          } else {
            if (!this.authenticated && this.isUserDisallowedInPrivatePlan) {
              this.$refs.SignInModal.showModal();
            } else if (
              this.planJustBought ||
              (this.plan.is_bought &&
                !this.plan.is_applied &&
                (this.plan.plan_type == 'fixed' ||
                  this.plan.plan_type == 'membership') &&
                !this.plan.is_blocked)
            ) {
              this.$refs.whatHappensModal.showModal();

              if (window.localStorage.getItem('get_free_program_id')) {
                window.localStorage.removeItem('get_free_program_id');
              }
            } else if (
              window.localStorage.getItem('get_free_program_id') &&
              window.localStorage.getItem('get_free_program_id') ==
                this.plan.id &&
              !this.plan.is_bought &&
              this.authenticated &&
              !this.isUserDisallowedInPrivatePlan
            ) {
              this.checkPlanWaiver();
            }

            this.isLoading = false;

            this.$nextTick().then(() => {
              this.scrolledCover = document.querySelector(
                '.plan-header > .cover'
              );
            });
          }
        })
        .catch((e) => {
          HttpErrorsHandling(e);

          this.isLoading = false;
        });
    },

    /**
     * Update plan data after calcel
     * @param {object} updatedPlan - updatedPlan
     */
    updateDataAfterCancel(updatedPlan) {
      this.plan = updatedPlan;
    },

    /**
     * Hanle add to calendar
     */
    hanleAddToCalendar() {
      if (
        this.plan.membership != undefined &&
        this.plan.membership.state == 'resume' &&
        !this.isBespokePlan &&
        !this.isOneManyPlan &&
        this.plan.is_applied
      ) {
        this.$refs.reAddToCalendarModal.show();
      } else {
        if (this.isMembershipPlan && this.planLaunchDate) {
          this.$router.push({
            name: 'plan_purchase_form',
            params: { plan_id: this.plan.id.toString() },
            query: {
              waiverAgree: this.agreement,
            },
          });
        } else {
          this.$refs.addToCalendarModal.show();
        }
      }

      this.$refs.whatHappensModal.hideModal();
    },

    /**
     * ReAdd to calendar
     * @param {string} resume_type - resume_type
     */
    reAddToCalendar(resume_type) {
      this.isSubmited = true;

      if (resume_type == 'continue') {
        this.isContinueSubmited = true;
      } else {
        this.isRestartSubmited = true;
      }

      HTTP.request({
        url: `plans/${this.plan.id}/resume`,
        method: 'POST',
        data: { resume_type },
      })
        .then((response) => {
          this.plan = response.data;
          this.$refs.reAddToCalendarModal.hide();
          if (resume_type == 'continue') {
            this.$notify({
              group: 'alerts',
              type: 'congrats',
              title: 'Your program has been resumed successfully!',
            });
          } else {
            this.$refs.addToCalendarModal.show();
          }
        })
        .catch(HttpErrorsHandling)
        .finally(() => {
          this.isSubmited = false;
          this.isContinueSubmited = false;
          this.isRestartSubmited = false;
        });
    },

    /**
     * After add to calendar
     * @param {object} date - date
     */
    afterAddToCalendar(date) {
      if (this.isFreePlan) {
        this.getForFree(date);
      } else {
        if (this.plan.is_bought) {
          this.applyPlan(date);
        } else {
          this.$router.push({
            name: 'plan_purchase_form',
            params: { plan_id: this.plan.id.toString() },
            query: { startDate: date, waiverAgree: this.agreement },
          });
        }
      }
    },

    /**
     * Check plan waiver
     */
    checkPlanWaiver() {
      if (this.isWaiverAvailable && this.authenticated) {
        this.$refs.waiverPlanModal.show();
      } else {
        if (this.authenticated) {
          this.choseAddCalendarOrBuy();
        } else {
          if (this.isFreePlan) {
            window.localStorage.setItem('get_free_program_id', this.plan.id);
          }

          this.$router.push({ name: 'onboarding-type-selection' });
        }
      }
    },

    /**
     * Resume waiver accepted
     */
    resumeWaiverAccepted() {
      this.agreement = true;
      if (this.isTryPaymentAgain) {
        this.isTryingPaymentAgaing = true;
        this.tryPaymentAgain();
      } else {
        this.choseAddCalendarOrBuy();
      }
    },

    /**
     * Chose add calendar or buy
     */
    choseAddCalendarOrBuy() {
      if (this.isFixedPlan) {
        this.hanleAddToCalendar();

        // if plan s1d1
      } else if (this.isMembershipPlan) {
        // if s1d1 free
        if (this.isFreePlan) {
          // if will resome
          if (this.plan.will_resume) {
            // open modal to choose resume type
            this.$refs.resumeSubscribe.showModal();
          } else {
            // buy free plan at first time
            if (this.plan.membership == undefined) {
              this.hanleAddToCalendar();
            } else {
              // buy free plan
              this.getForFree();
            }
          }
        } else {
          // if s1d1 not free, buy at first
          if (this.plan.membership == undefined) {
            this.hanleAddToCalendar();
          } else {
            // if s1d1 not free, go to puchase page
            this.$router.push({
              name: 'plan_purchase_form',
              params: {
                plan_id: this.plan.id,
              },
            });
          }
        }
      } else {
        this.getForFree();
      }
    },

    /**
     * Start free membership plan from
     * @param {string} resumeType - resumeType
     */
    startFreeMembershipPlanFrom(resumeType) {
      this.startFreeMembershipFrom = resumeType;
      this.getForFree();
    },

    /**
     * Get for free
     * @param {string} date - date
     */
    getForFree(date) {
      if (this.authenticated) {
        this.isSubmited = true;
        this.isFreeSubmited = true;

        let data;

        if (date && typeof date == 'string') {
          data = {
            start_date: date,
          };
        }

        if (this.startFreeMembershipFrom) {
          data = {
            resume_type: this.startFreeMembershipFrom,
          };
        }

        HTTP.request({
          url: `stripe/plan/${this.plan.id}/buy`,
          method: 'POST',
          data: data,
        })
          .then((response) => {
            this.$refs.addToCalendarModal.hide();

            this.plan = response.data;

            let text =
              this.plan.plan_type == 'fixed'
                ? 'Program has been successfully purchased.'
                : 'You have been successfully subscribed to the Program.';

            this.$notify({
              group: 'alerts',
              type: 'congrats',
              title: text,
            });

            this.$refs.whatHappensModal.showModal();

            if (window.localStorage.getItem('get_free_program_id')) {
              window.localStorage.removeItem('get_free_program_id');
            }
          })
          .catch(HttpErrorsHandling)
          .finally(() => {
            if (this.$refs.addToCalendarModal) {
              this.$refs.addToCalendarModal.stopLoading();
            }

            this.isSubmited = false;
            this.isFreeSubmited = false;

            if (this.startFreeMembershipFrom) {
              this.startFreeMembershipFrom = '';
            }
          });
      } else {
        if (window.localStorage.getItem('get_free_program_id')) {
          window.localStorage.removeItem('get_free_program_id');
        } else {
          window.localStorage.setItem('get_free_program_id', this.plan.id);
          this.$router.push({ name: 'onboarding-type-selection' });
        }
      }
    },

    /**
     * Check waiver before try payment again
     */
    checkWaiverBeforeTryPaymentAgain() {
      if (this.isWaiverAvailable) {
        this.$refs.pastDueSubscriptionModal.hide();
        this.isTryPaymentAgain = true;
        this.$refs.waiverPlanModal.show();
      } else {
        this.tryPaymentAgain();
      }
    },

    /**
     * Try payment again
     */
    tryPaymentAgain() {
      if (this.authenticated) {
        this.retryInvalidFeedback = null;
        this.isTrySubmited = true;
        HTTP.request({
          url: `stripe/plan/${this.plan.id}/retry`,
          method: 'POST',
        })
          .then(({ data }) => {
            this.handleRetryServerResponse(data);
          })
          .catch((error) => {
            this.handleRetrytError(error);
          })
          .finally(() => {
            this.isTryPaymentAgain = false;
            this.isTryingPaymentAgaing = false;
          });
      }
    },

    /**
     * Handle retry server response
     * @param {object} response - response
     */
    handleRetryServerResponse(response) {
      if (response.error) {
        this.handleRetrytError(response.error);
      } else if (response.requires_action) {
        // additional action required
        if (this.plan.plan_type != 'fixed') {
          if (window.Stripe === undefined) {
            throw new Error('Stripe V3 library not loaded!');
          } else {
            let stripeAccount = window.Stripe(import.meta.env.VITE_STRIPE_KEY, {
              stripeAccount: this.plan.user.stripe_account,
            });
            // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
            stripeAccount
              .handleCardPayment(response.payment_intent_client_secret)
              .then((result) => {
                this.handleRetryStripeResponse(result);
              });
          }
        }
      } else {
        this.handleRetrytSuccess();
      }
    },

    /**
     * Handle retry stripe response
     * @param {object} response - response
     */
    handleRetryStripeResponse(response) {
      if (response.error) {
        this.handleRetrytError(response.error);
      } else {
        HTTP.request({
          url: `stripe/plan/${this.plan.id}/retry/check`,
          method: 'POST',
          data: {
            intent: response.paymentIntent.id,
          },
        })
          .then(({ data }) => {
            this.handleRetryServerResponse(data);
          })
          .catch((error) => {
            this.handleRetrytError(error);
          });
      }
    },

    /**
     * Handle retry error
     * @param {object} error - error
     */
    handleRetrytError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.base &&
        error.response.data.base.invalid
      ) {
        this.retryInvalidFeedback = error.response.data.base.invalid;
      } else if (error.message) {
        this.retryInvalidFeedback = error.message;
      }

      HttpErrorsHandling(error);
      this.isTrySubmited = false;
    },

    /**
     * Handle retry Success
     */
    handleRetrytSuccess() {
      let text =
        this.plan.plan_type == 'fixed'
          ? 'Program has been successfully purchased.'
          : 'You have been successfully subscribed to the Program.';

      HTTP.get(`plans/${this.plan.id}/public`)
        .then((response) => {
          this.plan = response.data;
        })
        .finally(() => {
          this.$refs.pastDueSubscriptionModal.hide();
          this.isTrySubmited = false;

          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: text,
          });
        });
    },

    /**
     * Ask question
     */
    askQuestion() {
      if (!this.isOwner) {
        this.$refs.askQuestion.showModal();
      }
    },

    /**
     * Resume program if it canceled before the end of billing
     */
    resumeEOB() {
      this.isResumeSubmited = true;

      HTTP.request({
        url: `athlete/plans/${this.plan.id}/resume_subscribe`,
        method: 'POST',
      })
        .then(() => {
          this.plan.membership.cancel_at_period_end = false;

          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: 'Subscription successfully resumed.',
          });
        })
        .catch((error) => {
          // case - no cards
          if (error.response.status == '422') {
            this.$refs.modalPaymentMethod.show();
            // case - card invalid
          } else if (error.response.status == '426') {
            this.getCard()
              .then((response) => {
                let card = response.data;
                this.$refs.modalPaymentMethod.show('edit', card);
              })
              .catch(HttpErrorsHandling);
          } else {
            HttpErrorsHandling(error);
          }
        })
        .finally(() => {
          this.isResumeSubmited = false;
        });
    },

    /**
     * Apply aleady bought plan
     * @param {string} start_date - start_date
     * @returns {boolean|undefined} Returns false if the plan is not bought or already applied
     */
    applyPlan(start_date) {
      if (!this.plan.is_bought || this.plan.is_applied) {
        return false;
      }

      HTTP.request({
        url: 'schedule/apply_plan',
        method: 'POST',
        data: {
          plan_id: this.plan.id,
          start_date,
        },
      })
        .then(({ data }) => {
          this.plan = data;

          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: 'Program successfully added',
          });
        })
        .catch(HttpErrorsHandling)
        .finally(() => {
          this.$refs.addToCalendarModal.hide();
        });
    },

    /**
     * Card handler
     */
    cardHandler() {
      this.resumeEOB();
    },
  },

  /**
   * Meta info
   * @returns {Array} Returns array with tags
   */
  metaInfo() {
    if (this.plan?.id) {
      const plan_id = this.plan.custom_url || this.plan.id;
      let route = this.$router.resolve({
        name: 'public_plan',
        params: { plan_id },
      });

      let tags = {
        meta: [
          {
            property: 'og:url',
            content: new URL(route.href, import.meta.env.VITE_APP_BASE).href,
            vmid: 'og_url',
          },
        ],
      };

      if (this.plan.title) {
        tags.title = `${this.plan.title} | ${this.plan.user.full_name} | FITR`;

        tags.meta.push({
          property: 'og:title',
          content: `${this.plan.title} | ${this.plan.user.full_name} | FITR`,
          vmid: 'og_title',
        });
      }

      if (this.plan.description && this.plan.description.length > 0) {
        tags.meta.push({
          name: 'description',
          content: this.plan.description.slice(0, 256),
          vmid: 'description',
        });
        tags.meta.push({
          property: 'og:description',
          content: this.plan.description.slice(0, 256),
          vmid: 'og_description',
        });
      } else {
        tags.meta.push({
          name: 'description',
          content: `View the ${this.plan.title} published by ${this.plan.user.full_name} on FITR and find your next remote health and fitness coach.`,
          vmid: 'description',
        });

        tags.meta.push({
          property: 'og:description',
          content: `View the ${this.plan.title} published by ${this.plan.user.full_name} on FITR and find your next remote health and fitness coach.`,
          vmid: 'og_description',
        });
      }

      if (this.plan.covers.length > 0) {
        let cover = this.plan.covers.find((c) => c.cover_type == 'main');

        if (cover) {
          tags.meta.push({
            property: 'og:image',
            content: cover.large,
            vmid: 'og_image',
          });
        }

        tags.meta.push({
          vmid: 'twitter_card',
          name: 'twitter:card',
          content: 'summary_large_image',
        });

        tags.meta.push({
          property: 'og:image:alt',
          content: `${this.plan.user.full_name} - ${this.plan.title}. FITR, Remote Health and Fitness Software.`,
          vmid: 'og_image_alt',
        });
      }

      return tags;
    }

    return null;
  },
};
