export default {
  hint: 'Customise the web address for your training programs and Storefront.',
  button: {
    reset: 'Reset to FITR default',
    update: 'Update',
  },

  warning_text_1:
    ' Please configure a custom web address so that when clients access their web account or program links via this address, the site will update to show your branding, logos and colours.',

  warning_text_2:
    'Any links using app.fitr that you may have shared or used previously will still work, just without your colour scheme.',
};
