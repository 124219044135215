import SignInForm from '@/components/auth/sign-in/sign-in-form/index.vue';

// @vue/component
export default {
  name: 'SignInModal',

  components: {
    SignInForm,
  },

  methods: {
    showModal() {
      this.$refs.SignInModal.show();
    },
    hideModal() {
      this.$refs.SignInModal.hide();
    },
    loginDone() {
      this.$emit('loginDone');
      this.hideModal();
    },
  },
};
