import Store from '@/store/store.js';

/*******************************************************************************
 ** USER
 *******************************************************************************/
import UserLayout from './user/Layout.vue';

// Modules
import UserRoutes from './user/profile/routes.js';
import UserMyPlansRoutes from './user/my_plans/routes.js';
import UserPurchasesRoutes from './user/purchases/routes.js';
import UserCalendarRoutes from './user/calendar/routes.js';
// import UserBenchmarkRoutes from './user/benchmarks/page/routes.js';
import UserSettings from './user/settings/routes.js';
import UserBilling from './user/billing/routes.js';

/*******************************************************************************
 ** COACH
 *******************************************************************************/
import CoachLayout from './coach/Layout.vue';

// Modules
import ProfileRoutes from './coach/profile/routes.js';
import CoachMediaRoutes from './coach/media/routes.js';
import ClientsRoutes from './coach/clients/routes.js';
import CoachCalendarRoutes from './coach/calendar/routes.js';
import CoachAthleteCalendarRoutes from './coach/athlete_calendar/routes.js';
import CoachProgramsRoutes from './coach/programs/routes.js';
import CoachTemplatesRoutes from './coach/templates/routes.js';
import CoachDashboardRoutes from './coach/dashboard/routes.js';
import CoachBenchmarksRoutes from './coach/benchmarks/routes.js';
// import CoachPurchaseRoutes from './coach/purchase/routes.js'; DEPR
import MyProfileRoutes from './coach/my_profile/routes.js';
import ChoosseYourPlanRoutes from './coach/choose_your_plan/routes.js';

import CoachChallengesRoutes from './coach/challenges/routes.js';

import CoachMyAccountsRoutes from './coach/my_accounts/routes.js';

import CoachSwlCustomisationRoutes from './coach/swl-customisation/routes.js';

const CoachCouponsPages = () => import('./coach/coupons/index.vue');

import CoachZapierIntegrationRoutes from './coach/zapier-integration/routes.js';

/*******************************************************************************
 ** PUBLIC
 *******************************************************************************/
import PublicProfileRoutes from './coach/public/routes.js';

export default [
  {
    path: '/profile',
    name: 'profile_main',

    beforeEnter: (to, from, next) => {
      if (Store.state.currentUser.role) {
        if (Store.state.currentUser.role === 'user') {
          next({ path: '/user', replace: true });
        } else {
          next({ path: '/coach', replace: true });
        }
      } else {
        next({ name: 'onboarding-signin', replace: true });
      }
    },
  },

  {
    path: '/user',
    component: UserLayout,

    beforeEnter: (to, from, next) => {
      if (Store.state.currentUser.role === 'coach') {
        next({ path: '/coach', replace: true });
      } else {
        next();
      }
    },

    children: [
      {
        path: '',
        redirect: { path: 'profile' },
      },
      ...UserRoutes,
      ...UserMyPlansRoutes,
      ...UserPurchasesRoutes,
      // ...UserBenchmarkRoutes,
      ...UserCalendarRoutes,
      ...UserSettings,
      ...UserBilling,
    ],
  },

  // COACH
  {
    path: '/coach',
    component: CoachLayout,

    beforeEnter: (to, from, next) => {
      if (!to.meta.publicPage && Store.state.currentUser.role === 'user') {
        next({ path: '/user', replace: true });
      } else {
        //   // TODO: if coach doesnt have subbscription
        //   if (
        //     Store.state.authenticated &&
        //     Store.state.currentUser.role === 'coach' &&
        //     Store.getters["subscription/isAccountBlocked"] &&
        //     to.name != 'coach_billing'
        //   ) {
        //     next({ name: 'coach_billing' });
        //   } else {
        next();
        //   }
      }
    },

    children: [
      {
        path: '',
        redirect: { path: 'profile' },
      },

      {
        path: 'dashboard',
      },

      {
        path: 'coupons',
        name: 'coach_profile_coupons',
        component: CoachCouponsPages,
        meta: {
          footerCssClass: 'footer-with-fixed-btn',
          knowledge_type: 'coupon_note',
        },
        beforeEnter: function (to, from, next) {
          if (Store.getters.isFinancialAviable) {
            next();
          } else {
            next({ name: '404', replace: true });
          }
        },
      },

      {
        path: 'purchase',
        redirect: { name: 'coach_profile_subscription' },
      },

      ...CoachProgramsRoutes,
      ...CoachMediaRoutes,
      ...ProfileRoutes,
      ...MyProfileRoutes,
      ...PublicProfileRoutes,
      ...ClientsRoutes,
      ...CoachCalendarRoutes,
      ...CoachAthleteCalendarRoutes,
      ...CoachTemplatesRoutes,
      ...CoachBenchmarksRoutes,
      ...CoachDashboardRoutes,
      // ...CoachPurchaseRoutes, DEPR
      ...CoachChallengesRoutes,
      ...CoachMyAccountsRoutes,
      ...ChoosseYourPlanRoutes,
      ...CoachSwlCustomisationRoutes,
      ...CoachZapierIntegrationRoutes,
    ],
  },
];
