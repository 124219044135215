<template>
  <div class="container static-page">
    <h1>TERMS AND CONDITIONS</h1>

    <p>
      FITR is a consumer marketplace for health and fitness coaches, brands and
      individuals.
    </p>
    <p>
      Libertatum Applications Limited is a privately owned company registered in
      England &amp; Wales, Company No: 09759426
    </p>
    <p>
      You may only use FITR.training under these Terms and Conditions and by
      signing up for a FITR account; you are deemed to accept these terms and
      conditions as amended from time to time.
    </p>
    <p>
      While every effort has been made to cover all elements of the FITR service
      within these Terms and Conditions it is also expected that standard
      e-commerce practices must be followed and each individual/ company is
      expected to act in accordance with UK and/or EU law.
    </p>
    <p>
      If you have any questions about this User Agreement, please contact
      Customer Support.
    </p>
    <p>
      You may make enquiries about these Terms and Conditions by e-mailing us on
      support@fitr.training.
    </p>
    <p>
      Unless the context requires otherwise, the following terms shall have the
      following meanings in these Terms &amp; Conditions:
    </p>

    <ul>
      <li>
        <strong>"Account/Account holder/User"</strong> means any person, persons
        or company who have signed up to use, access or explore FITR.
      </li>
      <li>
        <strong>"Categories/Departments"</strong> means sections of the Site
        separated to sort Services by structure.
      </li>
      <li><strong>"Content"</strong> means information on the Site or FITR.</li>
      <li>
        <strong>"FITR"</strong> means including our parent, subsidiaries,
        affiliates, trading names, officers, directors, agents and employees and
        any sub-service for example: SKU Cloud and all domains.
      </li>
      <li>
        <strong>"FITR Buyer"</strong> means the Account holder who aims to
        purchase products by using the Service.
      </li>
      <li>
        <strong>"FITR Offer/Offer"</strong> means the purchasing process by
        which the Service occurs on FITR.
      </li>
      <li>
        <strong>"Identity"</strong> means information relating to an individual
        given to prove identity.
      </li>
      <li>
        <strong>"Product"</strong> means the advertised for sale item on FITR.
      </li>
      <li>
        <strong>"Seller/Retailer"</strong> means the account holder who aims to
        sell training plans by using the Service.
      </li>
      <li>
        <strong>"Service"</strong> means the advertised and intended operations
        of FITR.
      </li>
      <li>
        <strong>"Site"</strong> means the website and online presence of FITR.
      </li>
      <li><strong>"Spam"</strong> means unwanted/unsolicited e-mail.</li>
      <li>
        <strong>"Staff member"</strong> means an employee or contractor for
        FITR.
      </li>
      <li><strong>"We/Us"</strong> means FITR.</li>
      <li>
        <strong>"You"</strong> means the individual or person acting on behalf
        of a company.
      </li>
    </ul>

    <p>
      Clause, schedule and paragraph headings are for ease of reference only and
      do not affect the interpretation or construction of this Agreement.
    </p>
    <p>
      A person includes a natural person, corporate or unincorporated body
      (whether or not having separate legal personality).
    </p>
    <p>
      The Schedules form part of this Agreement and shall have effect as if set
      out in full in the body of this Agreement and any reference to this
      Agreement includes its Schedules.
    </p>
    <p>Words in the singular shall include the plural and vice versa.</p>
    <p>
      A reference to a statute or statutory provision is a reference to it as it
      is in force for the time being, taking account of any amendment,
      extension, or re-enactment and includes any subordinate legislation for
      the time being in force made under it.
    </p>
    <p>
      A reference to writing or written does not include faxes or e-mail, unless
      specified herein.
    </p>
    <p>
      Where the words include(s), including or in particular are used in this
      Agreement, they are deemed to have the words without limitation following
      them.
    </p>
    <p>
      Where the context permits, the words other and otherwise are illustrative
      and shall not limit the sense of the words preceding them.
    </p>
    <p>
      Any obligation in this Agreement on a person not to do something includes
      an obligation not to agree, allow, permit or acquiesce in that thing being
      done.
    </p>
    <p>
      References to Clauses, Sub-clauses and Schedules are to the Clauses and
      Schedules of this Agreement.
    </p>

    <h3>ELIGIBILITY</h3>
    <p>In using the Service you must at all times:</p>
    <ul>
      <li>Be able to enter into a legal contract.</li>
      <li>Be at least thirteen (13) years of age.</li>
      <li>Only hold one FITR account at any time.</li>
      <li>
        Only use a valid e-mail address, which does not have a set expiry
        time/window.
      </li>
      <li>
        Ensure that all information provided is true, accurate and not
        misleading at the time of writing.
      </li>
    </ul>
    <p>In using the Service you must not at any time:</p>
    <ul>
      <li>Use someone else’s FITR account.</li>
      <li>Gain access to another FITR account.</li>
      <li>Buy or sell any FITR account or account information.</li>
      <li>Impersonate another FITR user or FITR account holder</li>
      <li>Try to mislead or trick FITR in order to gain any advantage.</li>
      <li>
        Post inaccurate, false, misleading, defamatory or libellous Content.
      </li>
      <li>
        Post information and/or Content onto the Site that may harm FITR or any
        of its account holders, employees, consultants, officers, directors,
        shareholders, agents or any member of the public or any other person
        whomsoever or whatsoever.
      </li>
      <li>
        Manipulate or gain advantages over other FITR users and/or account
        holders in a manner not intended by the services guidelines and/or help
        tools.
      </li>
      <li>Resell items bought on FITR for business purposes.</li>
      <li>Lie or send misinformation regarding the delivery of any item.</li>
    </ul>

    <h3>FITR's RIGHTS</h3>

    <p>
      All information and Content on the Site remains the copyright and
      trademark (registered or unregistered) of FITR. You may not copy, modify
      or distribute this content unless offered as a service.
    </p>
    <p>
      The information and/or content on the Site is the property of FITR and,
      unless offered as a Service, users and/or account holders are not
      permitted to distribute, download, store, spam or abuse any data obtained
      or gleaned from the Site.
    </p>
    <p>FITR may at any time without notice to you:</p>

    <ul>
      <li>
        Terminate, delay, suspend, alter or completely remove the Service or any
        part thereof.
      </li>
      <li>
        Remove offensive, derogatory and/or abusive Content and close the
        account of any account holder reasonably suspected of producing,
        uploading such Content.
      </li>
      <li>
        Interrupt or delay the Service or any part thereof for such time as is
        necessary and for any reason related to the business operations of FITR.
      </li>
      <li>
        Take technical and/or legal steps to remove any user and/or account
        holder entirely from the Site if, in its reasonable belief, the
        user/account holder is or has engaged in activities contrary to any of
        these Terms and Conditions.
      </li>
    </ul>

    <p>
      FITR is not responsible for any loss any user or account holder may incur
      as a result of using the Service.
    </p>
    <p>
      FITR has the right to use any Content and/or information submitted to the
      Site for any purpose including but not limited to, publicity, marketing,
      service improvements, including use on 3rd party applications.
    </p>
    <h3>SPAM AND UNSOLICITED MESSAGES</h3>
    <p>
      FITR will only send out account information e-mails to users and/or
      account holders who have agreed to receive such e-mails.
    </p>
    <p>
      Promotions, Updates, Changes, Advertising and Service updates, are not
      regarded as Spam.
    </p>
    <p>
      No account holder may use data obtained or gleaned from the Site to send
      out e-mails that do not directly relate to the Service.
    </p>
    <p>
      Any services on FITR that allow users to communicate are offered in good
      faith with an understanding that any communications will be good natured
      and sent with good reason. We will not tolerate communication that has
      seemingly no reason and/or is an advertisement for a third party
      service/product.
    </p>
    <p>
      Any communication or data transferred over the Site may be scanned,
      filtered and monitored. You are responsible for any communication or
      actions from your FITR account.
    </p>
    <p>
      FITR will cooperate with any regulatory and/or law enforcement agency,
      from any country where necessary to provide or hand over information that
      is lawfully requested.
    </p>
    <h3>ABUSE OF FITR SERVICE AND INTERFACE</h3>
    <p>
      While we will make every effort to remove out of date information from the
      Site, we can accept no responsibility for any information on any page
      (including ‘content’ pages) that is not accurate or is out of date.
    </p>
    <p>
      You agree (as a user or account holder) that you will not, in a group or
      by yourself, authorise, employ, lease, knowingly allow or solicit:
    </p>

    <ul>
      <li>Any robot, spider or other automated means to access FITR.</li>
      <li>
        Any service (free or paid for) that automates interactions with the FITR
        services that are intended for genuine human involvement.
      </li>
      <li>
        Any action that imposes unreasonable or disproportionate loads on our
        technical infrastructure; we hold the authority to determine and define
        this action.
      </li>
      <li>
        Any action that removes, modifies or deletes any data from the FITR
        servers outside intended means to do so by the service options.
      </li>
      <li>
        Any actions that copy, reproduce, modify, create derivative works from,
        distribute or publicly display any content from FITR without express
        permission.
      </li>
      <li>
        Any unauthorised 3rd party tool that interferes (actual or attempted)
        with the service, and reduces or hinders use to yourself or any other
        users.
      </li>
      <li>
        Any activity that can or tries to bypass any security measures we may
        use to prevent or restrict access to the Site.
      </li>
    </ul>

    <h3>GENERAL ACCOUNT HOLDER INFORMATION</h3>
    <p>
      The Site allows users to share personal information with us and with other
      users.
    </p>
    <p>
      FITR will use its reasonable endeavours to keep your personal data private
      and secure.
    </p>
    <p>
      FITR accepts no liability for any form of obtrusive, illegal, unintended,
      hacked activity that ultimately leads to the release of personal data.
    </p>
    <p>
      You may at times be shown the information of other users who FITR believe
      share similar interests, have been involved in the same actions as
      yourself, or may have reason to be grouped together. While every effort is
      made to keep this sharing as targeted as possible, some users may abuse
      the system or FITR may interpret inputted information incorrectly. For
      this reason FITR can take no liability for how this information is shared
      correctly or incorrectly.
    </p>
    <p>
      FITR is unable to fully safeguard or control how other account holders may
      use your information and for this reason we encourage you to evaluate the
      information you provide to the Site and understand, before submitting, how
      your data will be used. In return we expect you to respect other users
      data and treat it how you would treat your own.
    </p>
    <h3>CHARGES FROM FITR</h3>
    <p>
      For the UK market, FITR operates in Great British Pounds. Any charges from
      FITR will be made visible on the Site before any payment is taken (this
      may not include agreed recurring payments). There are various services
      where a fee may apply and each service clearly lists how this fee is
      applied. You will be responsible for any additional taxes or any other
      charges that occur from using the Service.
    </p>
    <p>
      Please see the appropriate section within these Terms & Conditions
      relating to payments and/or fee charges for your desired activity with
      FITR.
    </p>
    <h3>USE OF ALL ACCOUNT CONTENT</h3>
    <p>
      By uploading, adding or submitting content to the Site, you grant us a
      non-exclusive, worldwide, perpetual, irrevocable, royalty-free, right to
      exercise any and all copyright, publicity, trade marks, database rights
      and intellectual property rights you have in the Content, in any media
      known now or in the future. In addition, you waive all moral rights you
      have in the Content to the fullest extent permitted by law.
    </p>
    <p>
      FITR will not be held responsible for any inaccuracies in our search
      results or listed data. You will also remain responsible for any data
      submitted to FITR and must ensure that you alter, amend or delete any
      information that is no longer relevant.
    </p>

    <p>
      You agree not to post, upload or supply in any way any Content (or links
      to content) that:
    </p>
    <ul>
      <li>
        Is obscene, fraudulent, indecent, discourteous, racially offensive or
        abusive;
      </li>
      <li>defames, abuses, harasses or threatens others;</li>
      <li>
        contains any viruses, Trojan horses, worms, time bombs, cancelbots, or
        other disabling devices or other harmful component intended to damage,
        detrimentally interfere with, surreptitiously intercept or expropriate
        any system, data or personal information;
      </li>
      <li>advocates or encourages any illegal activity;</li>
      <li>
        violates the privacy of individuals, including other users of the Site;
        or
      </li>
      <li>
        violates any applicable local, state, national, or international law
      </li>
    </ul>

    <h3>GENERAL LIABILITY/INDEMNITY UNDERSTANDING</h3>
    <p>
      In addition, and not replacing any other release from and/or limitation of
      liability under these Terms & Conditions, FITR will not be liable for:
    </p>

    <ul>
      <li>
        Any actions by users or account holders (including information posted,
        uploaded, items listed, sold or bought) that do not represent the
        Service
      </li>
      <li>
        Any actions by users or account holders which are in breach of any code
        of conduct or guidelines issued by us at any time.
      </li>
      <li>Any fraudulent items sold via the Service.</li>
      <li>
        Any review, statistic or calculation that is not factually
        representative.
      </li>
      <li>Any 3rd party service (e.g. payment) where FITR has no control.</li>
      <li>
        Any sale and purchase conducted through the Site where the purchaser is
        another user or 3rd party.
      </li>
      <li>Any falsity in user-provided account information.</li>
      <li>
        Any business losses, such as loss of data, revenue, opportunity,
        reputation, goodwill, profits, or business interruption or for any
        losses which are not reasonably foreseeable by us arising, directly or
        indirectly, out of your use of or your inability to use Services.
      </li>
    </ul>

    <p>
      Any failure on FITR’s behalf to act immediately or in reasonable time to a
      breach by you or others does not waive our right to act with respect to
      subsequent or similar breaches.
    </p>
    <p>
      Regardless of the above list, if we are found to be liable, our liability
      to you or to any third party is limited to the lesser of (a) Fees and
      Services you paid to us in the 12 months prior to the action, giving rise
      to the liability, and (b) £100.
    </p>
    <p>
      Some jurisdictions do not allow the disclaimer of warranties or exclusion
      of damages, so such disclaimers and exclusions may not apply to you.
    </p>
    <p>
      Nothing in these Terms &amp; Conditions shall limit or exclude our
      liability for fraudulent misrepresentation, for death or personal injury
      resulting from our negligence or the negligence of our agents or employees
      or for any other liability that cannot be limited or excluded by law.
    </p>
    <h3>DATA PROTECTION</h3>
    <p>
      Your personal data and privacy is important to FITR. Please see our
      Privacy Policy that forms part of these Terms &amp; Conditions.
    </p>
    <h3>ADVERTISEMENTS</h3>
    <p>
      While many services and results on FITR are formed ‘organically’, we may
      at any time and at our discretion alter elements of the Site to benefit
      companies or individuals who have purchased preferential rights to gain
      additional/favoured exposure.
    </p>
    <p>FITR may place any form of advertisements at any place on the Site.</p>
    <p>
      To the extent reasonably practicable, we may indicate on the Site which
      goods and services are advertisements for any promotion that falls outside
      the Service. You are free at any time to select or click on these
      advertisements at your free will.
    </p>
    <h3>LINKS TO AND FROM OTHER WEBSITES</h3>
    <p>
      Some services on FITR have a high proportion of external links to third
      party websites over which we have no affiliation or control regarding
      their content.
    </p>
    <p>
      Third party websites are linked to the Site to provide information and are
      intended to be for your convenience. While every effort has been made to
      guide content posters (account holders) as to the correct information to
      link to, we have no control over and do not accept any responsibility
      and/or liability for any content, data or other elements you may encounter
      on any third party websites.
    </p>
    <p>
      FITR may become integrated closely with various social networking websites
      (such as Facebook) and we similarly do not accept any responsibility
      and/or liability for any loss or damage that may arise from your use of
      any social networking website whether such use is through or in
      conjunction with the Site or not.
    </p>
    <p>
      All users and/or account holders are deemed to accept and understand that
      access to any third party websites is entirely at their own risk.
    </p>
    <h3>RELEASE</h3>
    <p>
      We understand that due to the social nature of the Service your
      involvement with other users and/or account holders may extend beyond the
      Service and may extend outside the platform FITR provides. For this reason
      you release FITR from any claims, demands and damages of every kind
      associated with or arising out of or in connection with such extended use
      and/or involvement.
    </p>
    <p>
      You hereby agree and undertake to compensate and/or indemnify FITR for any
      misuse or abuse that breaches these Terms &amp; Conditions or your
      use/misuse of the Site which results in any losses, damages, fines,
      compensation or costs (including reasonable legal fees).
    </p>
    <p>
      Use of the Site does not at any time render you the partner, agent, joint
      venture, employee-employer, franchise-franchisee of FITR.
    </p>

    <h4>Notices</h4>
    <p>
      Any legal notices can be validly served on FITR only in original written
      form and sent by registered mail:
    </p>

    <p>
      LEGAL NOTICE - The Chief Executive Officer <br />
      Libertatum Applications Limited (Trading as FITR) <br />
      20 Angle Lane Shepreth <br />
      SG8 6QJ
    </p>

    <p>
      Any response may be made by return post if suitable details are given for
      us to process in this way.
    </p>
    <p>
      Alternatively, we may serve you any notice by registered mail to the
      address you provided during the registration process or a card billing
      address registered in your name.
    </p>
    <p>
      Notices sent to either party by Royal Mail registered/tracked mail shall
      be deemed to have been received by that party three days after the date of
      mailing OR after the tracking notification shows it has been received.
    </p>

    <h3>THIRD PARTY RIGHTS</h3>
    <p>
      A person who is not a party to these Terms &amp; Conditions has no right
      under the Contracts (Rights of Third Parties) Act 1999 (“Act”) to enforce
      any term of them but this does not affect any right or remedy of a third
      party specified in them or which exists or is available apart from the
      Act.
    </p>
    <h3>GOVERNING LAW</h3>
    <p>
      These Terms &amp; Conditions shall be construed in accordance with the
      laws of England and Wales including any dispute arising out of in
      connection with them.
    </p>
    <p>
      Any dispute arising out of these Terms &amp; Conditions shall be subject
      to the exclusive jurisdiction of the courts of England.
    </p>

    <h2>Terms of use</h2>
    <h3>USE AS A “FITR USERS”:</h3>

    <p>
      <strong>Valid E-mail Address:</strong> Every account registered on the
      Site must have a valid and genuine e-mail address and/or 3rd Party account
      that links to a valid e-mail address of your own. Any accounts that have
      been registered without a valid e-mail will be terminated without notice.
      It is usual practice for FITR to require verification by way of e-mail for
      any new e-mail account and/or change of e-mail.
    </p>
    <p>
      <strong>Closing Accounts:</strong> We have the right at all times to close
      an account at any time, with no notice and with no obligation to fulfil
      any account order or recover any financial loss on your behalf howsoever
      suffered.
    </p>
    <p>
      <strong>Multiple logins:</strong> While we accept people may use multiple
      logins by way of division between personal/business and other uses on the
      Site, we reserve the right to take action across all your accounts if
      required.
    </p>

    <h3>FITR USERS PROTECTION &amp; RESOLUTION GUIDELINES</h3>
    <p>
      While FITR will make every effort to create a fair, peaceful community, we
      rely on our user and/or account holders to make us aware of misconduct or
      additional issues related to any of the Service from any users and/or
      account holders.
    </p>
    <p>
      In order to achieve the safest environment for our FITR Users we have
      created a set of FITR Users “Resolution Guidelines” which will help
      resolve disputes with Sellers and other account holders:
    </p>
    <h3>RESOLUTION GUIDELINES:</h3>
    <p>
      You should adopt normal internet-advised security measures before buying
      online.
    </p>
    <p>
      You should always keep in mind that despite efforts, FITR may not be able
      to fully vet every Seller and you should therefore exercise due care when
      buying through the Site.
    </p>
    <p>
      Your point of contact at all times regarding a Offer, before and after any
      sale is the Seller. The Seller will provide sufficient contact information
      to ask questions or seek further advice. Where possible make sure you
      receive any advice in writing.
    </p>
    <p>
      Any request for a refund, exchange or dispute should first be placed
      through the Seller. If this is not resolved to your satisfaction it should
      then be placed via FITR customer services who have the final say on the
      matter with regards to the Service we offer. If you are not happy with the
      results you always have the option to discuss the matter with your payment
      provider (e.g. credit card etc.).
    </p>

    <h3>PAYMENT</h3>
    <p>
      We use Stripe for all our transaction processing as it is a highly
      recognised, reputable and reliable system. The payment provider may change
      at any time without notice.
    </p>
    <p>
      Once you choose to purchase a FITR offer and click pay, you will be taken
      through the payment process managed by Stripe, where you should follow the
      online instructions.
    </p>

    <h3>AUTHORISE AND CAPTURE</h3>
    <p>
      Authorise and Capture of payment may occur in different ways. By standard
      Authorise and Capture happens instantly on a purchase on FITR platform,
      however there are times when we may separate this process. When we
      authorise your payment we request clearance from your provider for the
      money to be made available to us. During this time of authorisation
      although no payment will be taken from your account the money may not be
      available for you to spend (the assigned amount) outside FITR environment.
      If we do not receive authorisation from you, or authorisation is rejected
      at any stage we have the unqualified right to cancel your participation in
      the relevant Offer and you will have no further participation from the
      point of cancel. Once authorisation is confirmed you have fully committed
      to your purchase and cannot reverse this action.
    </p>
    <p>
      Capturing payment is the process when we take money from your account.
      Generally we will do this when the when you purchase the Offer. However we
      may capture payment at a later date if you are purchasing a service or
      product from FITR that requires a delayed payment.
    </p>
    <p>
      We understand that our FITR Users are the key to our business; therefore
      security is of paramount importance and with Stripe’s strict controls we
      can be sure our high standards are enforced. Despite this, FITR accepts no
      responsibility and/or liability for any problems, fees, security issues,
      losses, damages arising in connection with or out of any 3rd Party API,
      Service or application.
    </p>

    <h3>REFUND POLICY FOR BUYERS</h3>
    <p>
      At all times your first point of contact is the Seller. The Seller will
      have the ability to offer and process refunds at its discretion, in
      accordance with the agreement you have signed up to by accepting the
      Seller’s terms &amp; conditions. Whilst FITR will endeavour to help with
      any situation, FITR does not have any obligation in regards to the manner
      in which it decides to become involved with decisions between FITR Users
      and Sellers.
    </p>
    <p>
      At all times if there is an inconsistency between these Terms & Conditions
      and the Seller’s terms & conditions which affects the use of the Site or
      your activities on the Site, these Terms & Conditions are agree to take
      precedence and you agree that these Terms & Conditions shall prevail in
      relevant dispute between you and us.
    </p>

    <h3>PURCHASING ON FITR</h3>
    <p>
      Send you E-mails: FITR will e-mail with regards to your Purchases on FITR.
    </p>
    <p>
      Lost/ Stolen Account Information: It is your duty at all times to make us
      aware if your account information is lost or stolen.
    </p>
    <h3>OBLIGATIONS</h3>
    <p>
      Accurate Information: At all times FITR tries to make sure that all
      information on the Site is accurate. We also require all account holders
      only submit accurate information. We cannot be liable for any user
      submitted information on the Site, including purchases.
    </p>
    <p>
      Content of Information: It is the aim of FITR to make the Content suitable
      for a family audience at all times. For this reason you are refrained from
      posting any Content which may be classed as unsuitable according to this
      standard and as adjudged by us at our sole discretion.
    </p>
    <h3>NOTIFICATIONS</h3>
    <p>
      We will aim to notify you by e-mail at all important instances throughout
      FITR process. Whilst we will do our best to make sure these notifications
      are regular and up to date we cannot be responsible for any impact they
      may have on your decision making process or usability of the Service. We
      are not responsible for how these e-mails are delivered.
    </p>
    <h3>ACCOUNT INFORMATION</h3>
    <p>
      The information stored in your FITR account is deemed as private and we
      will offer security measures to the best of our ability in order to keep
      this information secure. We do not hold your payment information on file
      (See Payment).
    </p>
    <h3>COMPLAINTS/PROBLEMS</h3>
    <p>
      We understand that the Service, despite our best intentions, may at times
      have issues such as bugs and/or other conditions that will ultimately
      hinder the Service from performing as intended. We also are aware that
      with 3rd parties utilising the Site many aspects of our Service are beyond
      our control and we can accept no responsibility and/or liability arising
      out of in connection with this.
    </p>
    <p>
      For any complaints or problems please contact the customer service options
      available from the Site.
    </p>
    <h3>NAME &amp; E-MAIL</h3>
    <p>
      You are required to register with a valid e-mail address. You may be asked
      to verify your e-mail on sign up. If you are unable to verify your e-mail
      then you will not be able to use the Service. Please check all spam/junk
      filters if you do not receive an e-mail confirmation from us.
    </p>
    <h3>FITR USERS VS SELLER AGREEMENT</h3>
    <p>
      It should be understood at all times whilst using Service that the
      consequent contract for purchase is between FITR Users and the Seller.
      FITR acts as facilitator of the sale only.
    </p>
    <h3>AS A SELLER</h3>
    <p>
      By signing up as a Seller you agree you have read, understood and agree to
      the Terms &amp; Conditions associated with being a FITR Users (buyer).
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style src="@/assets/styles/_static-pages.scss" lang="scss" scoped></style>
