// @vue/component
export default {
  name: 'ModalWhatHappens',

  props: {
    /**
     * Plan type
     * @type {string}
     */
    plan_type: {
      type: String,
    },

    /**
     * Start date
     * @type {string | number | Date}
     */
    start_date: {
      type: [String, Number, Date],
    },

    /**
     * Launch date
     * @type {string | number | Date}
     */
    launch_date: {
      type: [String, Number, Date],
    },
  },

  methods: {
    showModal() {
      this.$refs.whatHappens.show();
    },
    hideModal() {
      this.$refs.whatHappens.hide();
    },
  },
};
