import videojs from 'video.js';

import 'videojs-contrib-quality-levels';

// @vue/component
export default {
  name: 'PlanCover',

  props: {
    /**
     * Covers list
     * @type {Array}
     * @default []
     */
    covers: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data: function () {
    return {
      isMobileLayout: false,
    };
  },

  computed: {
    isStorefrontPage() {
      return [
        'public-page-view',
        'public-page-short-link',
        'own-public-page-edit',
      ].includes(this.$route.name);
    },

    cover() {
      let coverType =
        this.isMobileLayout && !this.isStorefrontPage ? 'mobile_view' : 'main';
      return this.covers.length > 0
        ? this.covers.find((c) => c.cover_type == coverType)
        : null;
    },

    youtubeSource() {
      if (this.cover?.media?.video_id) {
        return `https://www.youtube.com/embed/${this.cover.media.video_id}?autoplay=1&controls=0&disablekb=1&mute=1&loop=1&playsinline=1&fs=0&iv_load_policy=3&rel=0&modestbranding=1&showinfo=0&playlist=${this.cover.media.video_id}`;
      }

      return null;
    },

    isVideo() {
      return this.cover && this.cover?.media?.type == 'video';
    },

    isVideoPublished() {
      return this.isVideo && this.cover?.media?.state == 'published';
    },

    isShowPLaceholder() {
      if (this.cover == null) {
        return true;
      } else {
        if (this.cover && this.cover?.media?.id == undefined) {
          return true;
        } else {
          return false;
        }
      }
    },

    isPlaceholderClickable() {
      return !!this.$listeners.change;
    },
  },

  watch: {
    cover: {
      handler: function (cover) {
        if (cover && cover.media?.id && this.isVideoPublished) {
          this.$nextTick(function () {
            this.initPlayer();
          });
        } else {
          this.destroyPlayer();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.initPlayer();
  },

  created() {
    this.mq = window.matchMedia('(max-width: 797.98px)');

    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    this.destroyPlayer();
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    initPlayer() {
      if (this.isVideoPublished) {
        this.player = videojs(this.$el.querySelector('#videoPlayer'), {
          controlBar: {
            pictureInPictureToggle: false,
            textTrackSettings: false,
          },
          html5: {
            vhs: {
              // overrideNative: true,
              bandwidth: 10000000,
              enableLowInitialPlaylist: false,
              limitRenditionByPlayerDimensions: false,
              useDevicePixelRatio: true,
              // smoothQualityChange: true
            },
            nativeTextTracks: false,
          },
        });

        if (this.cover.media.cover_sm) {
          this.player.poster(this.cover.media.cover_sm);
        }

        this.player.src({
          type: 'application/x-mpegURL',
          src: this.cover.media.stream_src,
        });
      }
    },

    destroyPlayer() {
      if (this.player) {
        this.player.dispose();

        this.player = null;
      }
    },

    change() {
      if (this.isPlaceholderClickable) {
        this.$emit('change');
      }
    },
  },
};
