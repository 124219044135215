import { mapGetters } from 'vuex';
import StarRate from '@/shared/star-rate/View.vue';
import TrainingStatus from '@/components/profile/coach/clients/all-clients/training-status/index.vue';

// @vue/component
export default {
  name: 'PlanBar',

  props: {
    /**
     * @property {object} plan - The plan details.
     */
    plan: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  computed: {
    isThepogrmCoach() {
      return this.plan.user.thepogrm_account;
    },

    displayedPrice() {
      if (this.isUserCoach) {
        return this.plan.original_price;
      } else {
        return this.plan.local_price;
      }
    },

    hasExtraInfo() {
      return (
        !this.plan.skip_sales_page &&
        (this.plan.level ||
          this.plan.average_duration ||
          this.plan.averageDuration ||
          this.plan.weeks_duration ||
          this.plan.sections_duration)
      );
    },

    launchDate() {
      if (this.plan.launch_date) {
        return this.$moment(this.plan.launch_date, 'YYYY-MM-DD').format(
          'D MMM, YYYY'
        );
      }

      return null;
    },

    isZapierAccount() {
      return this.plan.user.zapier_account;
    },

    trainingScheduledTo() {
      if (this.plan.training_scheduled_to) {
        if (this.plan.training_scheduled_to.includes('Week')) {
          return this.plan.training_scheduled_to;
        } else {
          return this.$moment(
            this.plan.training_scheduled_to,
            'YYYY-MM-DD'
          ).format('D MMM, YYYY');
        }
      }
      return null;
    },

    planType() {
      return this.plan.planType ? this.plan.planType : this.plan.plan_type;
    },

    maxBufferValueCssClass() {
      const maxBuffer =
        this.plan.plan_max_buffer > 3 ? 3 : this.plan.plan_max_buffer;
      return `max-buffer-${maxBuffer}`;
    },

    ...mapGetters(['isUserCoach']),
  },

  components: {
    StarRate,
    TrainingStatus,
  },
};
