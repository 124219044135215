import { mapGetters, mapState } from 'vuex';

import PlanInfo from '@/shared/plan-detail-v2/components/info/index.vue';
import PlanDetails from '@/shared/plan-detail-v2/components/details/index.vue';
import PlanCover from '@/shared/plan-detail-v2/components/cover/index.vue';

import MediaModal from '@/shared/media-modal/index.vue';
import UploadButton from '@/shared/buttons/upload.vue';

import PlanTypes from '@/helpers/plan-types.js';
import UserAvatar from '@/shared/user-avatar/index.vue';

// @vue/component
export default {
  name: 'PlanDetail',

  props: {
    /**
     * The plan object.
     * @type {object}
     */
    plan: Object,

    /**
     * The validation object.
     * @type {object}
     */
    validation: Object,

    /**
     * Description of the isEdit property.
     * @type {object}
     * @property {boolean} type - The type of the isEdit property.
     * @property {boolean} default - The default value of the isEdit property.
     */
    isEdit: {
      type: Boolean,
      default: false,
    },

    /**
     * Description of the backRoute property.
     * @type {string}
     * @default null
     */
    backRoute: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    planTypes: PlanTypes,
    copied: false,
    timer: null,
  }),

  computed: {
    programsRoute() {
      if (this.backRoute) {
        return { path: this.backRoute };
      } else if (
        (this.currentUser.role == 'coach' &&
          this.currentUser.id == this.plan.user.id) ||
        this.plan.user.id == undefined
      ) {
        return { name: 'my_plans' };
      } else if (this.currentUser.role == 'user' && this.plan.is_bought) {
        return { name: 'user_my_plans' };
      } else if (this.plan.user?.type_account) {
        const user_id = this.plan.user.custom_url || this.plan.user.id;
        const user_type = this.plan.user.type_account.charAt(0).toLowerCase();

        return {
          name: 'public-page-short-link-plans',
          params: { user_type, user_id },
        };
      } else {
        return {
          name: 'public-page-plans',
          params: { user_id: this.plan.user.id },
        };
      }
    },

    planType() {
      let type = this.plan.plan_type || this.plan.planType;

      return this.planTypes[type];
    },

    planState() {
      return this.plan.state || this.plan.planState;
    },

    // displayedPrice() {
    //   if (this.currentUser.role == 'user') {
    //     return this.plan.local_price;
    //   } else {
    //     return this.plan.original_price;
    //   }
    // },

    isOwner: function () {
      return this.currentUser && this.plan.user.id == this.currentUser.id;
    },

    link() {
      let plan_id = '';

      if (this.plan.custom_url) {
        plan_id = this.plan.custom_url;
      } else {
        if (this.plan.id) {
          plan_id = this.plan.id.toString();
        } else {
          return null;
        }
      }

      let route = this.$router.resolve({
        name: 'public_plan',
        params: { plan_id },
      });

      let url = new URL(route.href, this.accountPublicRouteBase);

      return url;
    },

    linkText() {
      const link = this.link.origin.replace(/https?:\/\//i, '');
      return `${link}/p/${this.link.pathname.split('/').slice(-1)[0]}`;
    },

    ...mapState(['currentUser']),
    ...mapGetters(['accountPublicRouteBase']),
  },

  methods: {
    copyPublicLink() {
      this.$copyText(this.link.href, this.$refs.copyContainer).then(() => {
        this.copied = true;

        this.timer = window.setTimeout(() => {
          this.copied = false;
        }, 3000);
      });
    },
  },

  components: {
    PlanCover,
    PlanInfo,
    PlanDetails,
    MediaModal,
    UserAvatar,
    UploadButton,
  },
};
