import { HTTP } from '@/utils/http-common';
import { mapGetters, mapState } from 'vuex';

// @vue/component
export default {
  name: 'SubscriptionWarning',

  data() {
    return {
      coachSubscriptionTrialLeft: null,
      isHideFromLocalStorage: false,
      isClossing: false,
    };
  },

  computed: {
    isSubscriptionInvalid() {
      return (
        this.isTrialExpired ||
        this.isTrialActive ||
        this.isCancelAtPeriodEnd ||
        (this.isCanceled && !this.subscriptionData?.trial) ||
        this.isPurcahseFailed ||
        this.isSwlPurcahseFailed ||
        (this.isSubscriptionStarter &&
          this.subscriptionData.active_sub_clients == 5) ||
        (this.isSubscriptionPro && this.subscriptionData.active_sub_clients >= 40)
      );
    },

    isAvailableForCoach() {
      if (this.isFinancialAviable) {
        return true;
      } else {
        return this.isAccountBlocked;
      }
    },

    coachSubscriptionWarningText() {
      if (this.isSubscriptionInvalid) {
        if (this.isFinancialAviable) {
          if (this.isCanceled) {
            return this.$t('en.pricing.warnings.canceled');
          } else if (this.isCancelAtPeriodEnd) {
            let date = this.$moment(
              this.subscriptionData.next_payment_date,
              'YYYY-MM-DD HH:mm:ss Z'
            ).format('DD MMM, YYYY');

            if (this.subscriptionData.active_clients > 0) {
              return this.$t('en.pricing.warnings.renew_with_clients', {
                date,
              });
            } else {
              return this.$t('en.pricing.warnings.renew_without_clients', {
                date,
              });
            }
          } else if (this.isPurcahseFailed || this.isSwlPurcahseFailed) {
            return this.$t('en.pricing.warnings.payment_failed');
          } else {
            if (this.coachSubscriptionTrialLeft) {
              let pluralizeFilter = this.$options.filters.pluralize;

              let days = parseInt(this.coachSubscriptionTrialLeft.asDays(), 10);

              if (days < 0) {
                days = 0;
              }

              let left = `${days} ${pluralizeFilter(days, 'day')}`;

              if (days == 0) {
                let hours = this.coachSubscriptionTrialLeft.hours();

                if (hours > 0) {
                  left = `${hours} ${pluralizeFilter(hours, 'hour')}`;
                } else {
                  let minutes = this.coachSubscriptionTrialLeft.minutes();

                  if (minutes > 0) {
                    left = `${minutes} ${pluralizeFilter(minutes, 'minute')}`;
                  } else {
                    let seconds = this.coachSubscriptionTrialLeft.seconds();

                    if (seconds >= 0) {
                      left = `${seconds} ${pluralizeFilter(seconds, 'second')}`;
                    }
                  }
                }
              }

              if (this.subscriptionData.active_clients > 0) {
                return this.$t('en.pricing.warnings.trial_with_clients', {
                  left,
                });
              } else {
                return this.$t('en.pricing.warnings.trial_without_clients', {
                  left,
                });
              }
            } else {
              if (
                (this.isSubscriptionStarter &&
                  this.subscriptionData.active_sub_clients == 5) ||
                (this.isSubscriptionPro &&
                  this.subscriptionData.active_sub_clients >= 50)
              ) {
                return this.$t('en.pricing.warnings.clients_limit');
              } else if (
                this.isSubscriptionPro &&
                this.subscriptionData.active_sub_clients >= 40 &&
                this.subscriptionData.active_sub_clients <= 49
              ) {
                return this.$t('en.pricing.warnings.clients_before_limit');
              } else {
                return this.$t('en.pricing.warnings.trial_has_expired');
              }
            }
          }
        } else {
          return this.$t('en.pricing.warnings.for_invited', {
            name: this.currentUser.title,
          });
        }
      } else {
        return null;
      }
    },

    warningCssClass() {
      if (this.isSubscriptionInvalid) {
        let css_class = {};

        css_class['alert-lg'] = true;

        if (
          this.isCanceled ||
          this.isPurcahseFailed ||
          this.isSwlPurcahseFailed
        ) {
          css_class['alert-danger'] = true;
        } else if (this.isCancelAtPeriodEnd) {
          if (this.subscriptionData.active_clients > 0) {
            css_class['alert-danger'] = true;
          } else {
            css_class['alert-green'] = true;
          }
        } else if (
          this.isTrialExpired ||
          this.coachSubscriptionTrialLeft?.asDays() <= 7
        ) {
          css_class['alert-danger'] = true;
        } else if (
          (this.isSubscriptionStarter &&
            this.subscriptionData.active_sub_clients == 5) ||
          (this.isSubscriptionPro && this.subscriptionData.active_sub_clients >= 40)
        ) {
          css_class['alert-danger'] = true;
        } else {
          css_class['alert-green'] = true;
        }

        return css_class;
      } else {
        return null;
      }
    },

    isWarningHidden() {
      // return  ['coach_blocked', 'coach_purchase', 'coach_billing', 'create-account', 'onboarding-business-setup'].includes(this.$route.name);
      return [
        'coach_blocked',
        'coach_purchase',
        'create-account',
        'onboarding-business-setup',
        'coach_profile_billing',
        'coach_profile_subscription',
        'choose_your_plan',
      ].includes(this.$route.name);
    },

    ...mapState(['currentUser']),
    ...mapGetters(['isUserCoach', 'isFinancialAviable']),
    // 'isAccountActive', 'hasSubscription'
    ...mapGetters('subscription', [
      'subscriptionData',
      'isAccountBlocked',
      'isTrialActive',
      'isTrialExpired',
      'isCancelAtPeriodEnd',
      'isPurcahseFailed',
      'isSwlPurcahseFailed',
      'isCanceled',
      'isAccountEmpty',
      'isSubscriptionStarter',
      'isSubscriptionPro',
    ]),
  },

  mounted() {
    if (
      this.coachSubscriptionWarningText ==
      this.$t('en.pricing.warnings.clients_before_limit')
    ) {
      this.isClossing = true;

      if (localStorage.getItem('isSubscriptionWarningHide')) {
        this.isHideFromLocalStorage = true;
      }
    }

    if (this.isTrialActive) {
      this.setCoachSubscriptionTrialLeft();

      document.addEventListener('visibilitychange', this.handleVisibility);
    }

    if (this.isAccountEmpty) {
      this.$watch('isTrialActive', function () {
        this.setCoachSubscriptionTrialLeft();

        document.addEventListener('visibilitychange', this.handleVisibility);
      });
    }
  },

  beforeDestroy() {
    if (this.requestAnimationId) {
      window.cancelAnimationFrame(this.requestAnimationId);
    }
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    window.removeEventListener('visibilitychange', this.handleVisibility);
  },

  methods: {
    /**
     * Close warning
     */
    closeWarning() {
      this.isHideFromLocalStorage = true;
      localStorage.setItem('isSubscriptionWarningHide', 'true');
    },

    /**
     * Set coach subscription trial left
     */
    setCoachSubscriptionTrialLeft() {
      if (this.isTrialActive) {
        let ended_at = this.$moment(
          this.subscriptionData.trial_end_date,
          'YYYY-MM-DD HH:mm:ss Z'
        );
        let current = this.$moment();

        let diff = ended_at.diff(current);
        let duration = this.$moment.duration(diff);

        this.coachSubscriptionTrialLeft = duration;

        if (duration.asSeconds() <= 0) {
          HTTP.get('subscribe').then(({ data }) => {
            this.coachSubscriptionTrialLeft = null;

            this.$store.commit('setCurrentUserSubscription', data);
          });
        } else {
          if (duration.asSeconds() < 80) {
            this.requestAnimationId = window.requestAnimationFrame(() => {
              this.setCoachSubscriptionTrialLeft();
            });
          } else if (duration.asHours() < 1) {
            this.timeoutId = window.setTimeout(() => {
              this.setCoachSubscriptionTrialLeft();
            }, 60000);
          } else {
            this.timeoutId = window.setTimeout(() => {
              this.setCoachSubscriptionTrialLeft();
            }, 1200000);
          }
        }
      } else {
        this.coachSubscriptionTrialLeft = null;
      }
    },

    /**
     * Handle visibility
     */
    handleVisibility() {
      if (document.visibilityState === 'hidden') {
        if (this.requestAnimationId) {
          window.cancelAnimationFrame(this.requestAnimationId);
        }
        if (this.timeoutId) {
          window.clearTimeout(this.timeoutId);
        }
      } else if (document.visibilityState === 'visible') {
        this.setCoachSubscriptionTrialLeft();
      }
    },
  },
};
