export default {
  header: {
    title: 'Choose your plan',
    tooltip:
      'After activating a plan below, you will still get the full benefit of your trial period.',
  },
  trial_periods_ends: 'Your trial period ends in',
  active_clients: 'Your current number of active client:',

  most_popular: 'BEST VALUE',

  clients_limit: {
    titleStarter: '5 х Active Clients',
    titlePro: '50 х Active Clients',
    title: 'Unlimited Clients',
    tooltip:
      'An active client subscription is counted when a client subscribes to a monthly ongoing program.',
  },

  max_downloads: {
    title: 'Unlimited One-off Downloads',
    tooltip:
      'Fixed length programs are one-off programs that are downloaded by clients rather than being subscribed to over time. We don’t limit how many of these you sell or give away for free.',
  },

  unlimited_programs: {
    title: 'Unlimited Programs',
    tooltip:
      'Use our program builder to create your online training programs in a variety of formats such as one-off downloads, bespoke or group based training.',
  },

  integrated_payments: {
    title: 'Integrated Payments',
    tooltip:
      'Sell your programs for free, at a fixed price, or offer them on an ongoing subscription basis. Integrate with Stripe to receive the earnings directly into your bank account.',
  },

  notes: {
    titleStarter: '1 x Coach Note Per Program',
    title: 'Unlimited Coach Notes',
    tooltip:
      'Enhance your programming by saving notes for future reference. You and your coaching team can document key information such as injuries, equipment requirements, training days, workout ideas and more.',
  },

  coaches: {
    title: 'Add Coaches',
    titleUnlimited: 'Unlimited Coaches',
    tooltip:
      'Invite other coaches to create programs and share in the management responsibilities of your remote fitness business.',
  },

  quarterly_account_optimisation: {
    title: 'Quarterly 1:1 Account Optimisation',
    tooltip:
      "Every quarter, you'll get a private 1-hour session with a FITR Expert to optimise your account, implement changes, and answer your questions.",
  },

  white_label: {
    titleStarter: 'WHITE LABEL',
    alt: 'White label',
    tooltip:
      'Customise the web and mobile app with your company branding & logo for you and your clients.',
  },

  zapier_integration: {
    title: 'integration',
    alt: 'Zapier',
    tooltip:
      'Use Zapier to easily connect FITR to 5,000+ Apps, including tools you use every day.',
  },

  subscription: {
    button: {
      resume: 'Resume subscription',
      upgrade: 'Upgrade',
      downgrade: {
        starter: 'Downgrade to Starter',
        pro: 'Downgrade to Pro',
        activate: 'Activate',
      },
    },
  },
};
