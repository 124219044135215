import { Cropper } from 'vue-advanced-cropper';

// size: {
//   id,
//   title,
//   desc,
//   width,
//   height,
//   needPreview
// }

// @vue/component
export default {
  name: 'MediaCrop',

  components: {
    Cropper,
  },

  props: {
    /**
     * Cover types
     * @type {object}
     */
    coverTypes: {
      type: Object,
    },

    /**
     * Current cover type
     * @type {string}
     * @default 'main'
     */
    currentCoverType: {
      type: String,
      default: 'main',
    },

    /**
     * Is back available
     * @type {boolean}
     * @default true
     */
    backAvailable: {
      type: Boolean,
      default: true,
    },

    /**
     * Is new file button available
     * @type {string}
     * @default 'Upload new cover'
     */
    newFileButtonLabel: { type: String, default: 'Upload new cover' },

    /**
     * the type of work 'Upload new cover' button
     * @description 'file' type use for 'upload new file' instead of open media lib
     * @default 'library'
     * @variation library, file
     */
    uploadNewMode: {
      type: String,
      default: 'library',
      validator: function (value) {
        return ['library', 'file'].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {
      currentStepIndx: 0,
      isStepBusy: false,

      results: {},

      media: null,
      file: null,

      isReady: false,
      isCropReady: false,
    };
  },

  computed: {
    // stepsSizes() {
    //   return this.sizes.length;
    // },

    // currentStep() {
    //   return this.sizes[this.currentStepIndx];
    // },

    // hasNextStep() {
    //   return this.currentStepIndx < this.stepsSizes - 1;
    // },

    stencilProps() {
      return {
        aspectRatio: this.currentType.width / this.currentType.height,
      };
    },

    currentType() {
      return this.coverTypes ? this.coverTypes[this.currentCoverType] : {};
    },
  },

  methods: {
    crop(media) {
      this.media = JSON.parse(JSON.stringify(media));
      this.showModal();
    },

    // nextStep() {
    saveCrop() {
      this.isStepBusy = true;

      new Promise((resolve) => {
        const { coordinates, canvas } = this.$refs.cropper.getResult();

        let result = { coordinates };

        canvas.toBlob(
          (blob) => {
            result.blob = URL.createObjectURL(blob);

            resolve(result);
          },
          'image/jpg',
          0.4
        );
      }).then((data) => {
        this.results = data;
        this.results.cover_type = this.currentType.cover_type;

        this.submitCrop();
      });
    },

    submitCrop() {
      // this.file

      const data = {
        media: this.media,
        crop: this.results,
        isChanged: true,
      };

      if (this.uploadNewMode == 'file') {
        data.newOriginalFile = this.file;
      }

      this.$emit('input', data);

      this.hideModal();
    },

    prevStep() {
      this.$emit('back');
      this.hideModal();
    },

    showModal() {
      this.$refs.modal.show();
    },

    hideModal() {
      this.$refs.modal.hide();
    },

    handleModalShown() {
      this.isReady = true;
    },

    handleModalHidden() {
      this.media = null;
      this.results = {};
      this.isStepBusy = false;
      this.isReady = false;
      this.isCropReady = false;
      this.file = null;
    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      this.isStepBusy = true;

      this.file = files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        this.crop({ src: e.target.result });

        this.isStepBusy = false;
      };

      reader.readAsDataURL(this.file);
    },

    handleCropReady() {
      this.isCropReady = true;
    },
  },
};
