<template>
  <div v-if="!isViewMode || coverSrc">
    <div class="row">
      <div class="col-12 col-lg-auto mb-3 mb-lg-0">
        <div :class="`cover__${coverType}`">
          <upload-button
            :image="coverSrc"
            class="w-100 h-100"
            :hide-remove-button="hideRemoveButton || isViewMode"
            :hide-change-button="isViewMode"
            @change="showPlanCovers"
          >
            {{ $t('en.media.lib.cover.title') }}
          </upload-button>
        </div>
      </div>

      <div v-if="!!$slots.actions" class="col-12 col-lg">
        <slot name="actions" />
      </div>
    </div>

    <modal-plan-covers
      v-if="!isViewMode"
      ref="planCoversModal"
      :cover-types="coverTypes"
      :current-cover-type="currentCoverType"
      :plan-covers="plan.covers"
      @editCover="handleEditCover"
      @removeCover="handleRemoveCover"
      @uploadCover="handleUploadCover"
    />

    <media-crop
      v-if="!isViewMode"
      ref="mediaCropModal"
      :cover-types="coverTypes"
      :current-cover-type="currentCoverType"
      @input="cropCover"
      @back="isMediaRequested = true"
    />

    <media-library
      v-if="isMediaRequested && !isViewMode"
      :attachments="selectedCover"
      :multiple="false"
      :forbidden-types="['other']"
      @add="mediaChanged"
      @close="isMediaRequested = false"
    />
  </div>
</template>

<script>
import MediaCrop from '@/shared/media-crop/index.vue';
import MediaLibrary from '@/shared/media-library/View.vue';
import UploadButton from '@/shared/buttons/upload.vue';
import ModalPlanCovers from '@/shared/modal-plan-covers/index.vue';

// @vue/component
export default {
  name: 'ProgramEditTopBar',

  components: {
    MediaCrop,
    MediaLibrary,
    UploadButton,
    ModalPlanCovers,
  },

  props: {
    /**
     * The plan object.
     * @type {object}
     */
    plan: {
      type: Object,
      required: true,
    },

    /**
     * Whether to hide the delete button for upload button.
     * @type {boolean}
     * @default false
     */
    hideRemoveButton: {
      type: Boolean,
      default: false,
    },

    /**
     * Function that handles updating the covers.
     * @type {Function}
     * @default () => {}
     */
    updateCovers: {
      type: Function,
      default: () => {},
    },

    /**
     * Function that handles removing the covers.
     * @type {Function}
     * @default () => {}
     */
    removeCovers: {
      type: Function,
      default: () => {},
    },

    /**
     * Flag indicating whether the layout is mobile.
     * @type {boolean}
     * @default false
     */
    isMobileLayout: {
      type: Boolean,
      default: false,
    },

    /**
     * Flag indicating whether the component is in view mode.
     * @type {boolean}
     * @default false
     */
    isViewMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMediaRequested: false,
      currentCoverType: 'table_view',
      coverTypes: {
        table_view: {
          cover_type: 'table_view',
          title: this.$t('en.cover.cover_types.table_view.title'),
          descr: this.$t('en.cover.cover_types.table_view.descr'),
          hint: this.$t('en.cover.cover_types.table_view.hint'),
          width: '130',
          height: '130',
        },
        tiled_view: {
          cover_type: 'tiled_view',
          title: this.$t('en.cover.cover_types.tiled_view.title'),
          descr: this.$t('en.cover.cover_types.tiled_view.descr'),
          hint: this.$t('en.cover.cover_types.tiled_view.hint'),
          width: '250',
          height: '130',
        },
      },
    };
  },

  computed: {
    selectedCover() {
      let selectedCover = {};

      if (this.plan.covers.length > 0) {
        let cover = this.plan.covers.find(
          (c) => c.cover_type === this.currentCoverType
        );

        selectedCover = cover ? cover.media : {};
      }

      return selectedCover;
    },

    coverType() {
      return this.isMobileLayout ? 'tiled_view' : 'table_view';
    },

    cover() {
      return this.plan.covers.length > 0
        ? this.plan.covers.find((c) => c.cover_type == this.coverType)
        : null;
    },

    coverSrc() {
      if (this.cover?.blob) {
        return this.cover.blob;
      } else if (['youtube', 'video'].includes(this.cover?.media?.type)) {
        return this.cover.media.cover_hd;
      } else if (this.cover?.large) {
        return this.cover.large;
      }
      return null;
    },
  },

  methods: {
    showPlanCovers() {
      this.$refs.planCoversModal.show();
    },

    handleEditCover(data) {
      this.currentCoverType = data ? data.type : 'table_view';

      if (
        data.media &&
        (data.media.cover || data.media.src) &&
        data.media.type != 'youtube' &&
        data.media.type != 'video'
      ) {
        this.$refs.mediaCropModal.crop(data.media);
      } else {
        this.isMediaRequested = true;
      }
    },

    handleRemoveCover(data) {
      this.removeCovers(data.type);
    },

    handleUploadCover(data) {
      this.currentCoverType = data ? data.cover_type : 'table_view';
      this.isMediaRequested = true;
    },

    addCover(media) {
      this.updateCovers(media);
    },

    cropCover({ media, crop, isChanged }) {
      let cover = { media: media, ...crop, isChanged };

      // TODO ADD current type
      if (cover.media.kind != 'image') {
        cover.cover_type = this.currentCoverType;
      }

      this.addCover(cover);

      this.showPlanCovers();
    },

    mediaChanged(media) {
      if (media.kind == 'image') {
        this.$refs.mediaCropModal.crop(media);
      } else {
        this.cropCover({ media, isChanged: true });
      }

      this.isMediaRequested = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cover {
  &__table_view {
    width: 88px;
    height: 88px;
  }
}
</style>
