import { debounce } from 'lodash-es';
import { mapGetters, mapState, mapActions } from 'vuex';

import GenderControl from '@/components/auth/controls/gender-control/index.vue';
import BirthdateControl from '@/components/auth/controls/birthdate-control/index.vue';

// @vue/component
export default {
  name: 'AdditionalProfileInfoPopup',

  components: {
    GenderControl,
    BirthdateControl,
  },

  data() {
    return {
      gender: null,
      birthdate: null,

      isBusy: false,
    };
  },

  computed: {
    isBirthdateEmpty() {
      return (
        !this.currentUser.birthdate ||
        this.currentUser.birthdate == '31/12/1900'
      );
    },

    isValid() {
      if (this.isUserCoach) {
        return !!this.birthdate;
      } else {
        if (this.isBirthdateEmpty && !this.currentUser.gender) {
          return !!this.birthdate && !!this.gender;
        } else if (this.isBirthdateEmpty) {
          return !!this.birthdate;
        } else if (!this.currentUser.gender) {
          return !!this.gender;
        }
      }

      return null;
    },

    minAge() {
      return this.isUserCoach ? 18 : 16;
    },

    ...mapState(['currentUser', 'authenticated']),
    ...mapGetters(['isUserCoach', 'isBusinessAccount']),
  },

  watch: {
    currentUser: {
      handler: function (val) {
        if (val.id && this.authenticated) {
          this.showPopup();
        }
      },
    },
  },

  mounted() {
    if (this.authenticated) {
      this.showPopup();
    }

    this.$root.$on('show-additional-profile-info-popup', () => {
      this.showPopup();
    });
  },

  methods: {
    showPopup: debounce(function () {
      if (
        this.$route.name != 'success_page' &&
        !this.isBusinessAccount &&
        (this.isBirthdateEmpty ||
          (!this.isUserCoach && !this.currentUser.gender))
      ) {
        this.gender = this.currentUser.gender;

        if (this.currentUser.birthdate != '31/12/1900') {
          this.birthdate = this.currentUser.birthdate;
        } else {
          this.birthdate = null;
        }

        this.$refs.popup.show();
      }
    }, 2000),

    save() {
      this.isBusy = true;

      const data = {
        birthdate: this.birthdate,
      };

      if (this.gender) {
        data.gender = this.gender;
      }

      if (this.isUserCoach) {
        this.updatePrivateProfile(data)
          .then(() => {
            this.$refs.popup.hide();
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        this.updateProfile(data)
          .then(() => {
            this.$refs.popup.hide();
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    ...mapActions(['updateProfile', 'updatePrivateProfile']),
  },
};
