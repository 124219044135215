import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'ChatIndicatorLink',

  computed: {
    hasNew: function () {
      return this.$store.state.currentUser.has_unread_chat_messages;
    },
    isDisabled() {
      return this.isUserCoach && this.isAccountBlocked;
    },

    ...mapGetters(['isUserCoach']),
    ...mapGetters('subscription', ['isAccountBlocked']),
  },
};
