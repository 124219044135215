import { mapGetters, mapMutations } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { subscribe, unsubscribe } from '@/utils/actioncable';
import ChecklistPopup from '@/components/onboarding-checklist/popup/index.vue';

// @vue/component
export default {
  name: 'OnboardingChecklist',

  components: {
    ChecklistPopup,
  },

  mixins: [clickaway],

  data() {
    return {
      isMobileLayout: false,
    };
  },

  computed: {
    totalCount() {
      return this.onboardingChecklistData?.total_points;
    },

    completed() {
      return this.onboardingChecklistData?.completed;
    },

    completedCount() {
      return this.completed?.length;
    },

    isAllStepsDone() {
      return this.completedCount > 0 && this.totalCount == this.completedCount;
    },

    ...mapGetters(['onboardingChecklistData']),
  },

  created() {
    this.channel = subscribe('NotificationChannel', (message) => {
      if (message.type == 'check_list' && message.completed) {
        this.setOnboardingChecklistStepCompleted(message.completed);
      }
    });

    this.$root.$on('bv::dropdown::show', this.hidePopup);

    this.mq = window.matchMedia('(max-width: 797.98px)');

    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    unsubscribe(this.channel);

    this.$root.$off('bv::dropdown::show', this.hidePopup);

    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    /**
     * Update matches
     */
    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    /**
     * Clickaway
     */
    clickaway() {
      if (!this.isHidePopupDisabled && !this.isMobileLayout) {
        this.hidePopup();
      }
    },

    /**
     * Show Popup
     */
    showPopup() {
      this.$refs.checklistPopup?.toggle();
    },

    /**
     * Hide Popup
     */
    hidePopup() {
      this.$refs.checklistPopup?.hide();
    },

    /**
     * Handle media popup
     */
    mediaModalShown() {
      this.isHidePopupDisabled = true;
    },

    /**
     * Media modal hidden
     */
    mediaModalHidden() {
      this.isHidePopupDisabled = false;
    },

    ...mapMutations(['setOnboardingChecklistStepCompleted']),
  },
};
