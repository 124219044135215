import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import CardForm from '@/shared/modal-add-payment-method/card-form/index.vue';

// @vue/component
export default {
  name: 'ModalAddPaymentMethod',

  components: { CardForm },

  data() {
    return {
      isModalShowed: false,
      busy: false,
      complete: false,
      mode: 'add', // add, edit, rewrite
      currentCard: null,
    };
  },

  computed: {
    modalTitle() {
      switch (this.mode) {
        case 'add':
          return this.$t('en.modal.payment.title.new');
        case 'edit':
          return this.$t('en.modal.payment.title.edit');
        case 'rewrite':
          return this.$t('en.modal.payment.title.rewrite');
        default:
          return this.$t('en.modal.payment.title.new');
      }
    },

    modalCTA() {
      if (this.isEditMode) {
        return this.$t('en.modal.payment.button.update');
      } else {
        return this.$t('en.modal.payment.button.new');
      }
    },
  },

  methods: {
    show(mode, card) {
      if (mode) {
        this.mode = mode;
      }

      if (card) {
        this.currentCard = card;
      }

      this.$refs.addPaymentMethod.show();
    },

    hide() {
      this.$refs.addPaymentMethod.hide();
    },

    handleModalShow() {
      this.isModalShowed = true;
    },

    handleModalShown() {},

    handleModalHide(e) {
      if (this.busy) {
        e.preventDefault();
      }
    },

    handleModalHidden() {
      this.isModalShowed = false;
      this.busy = false;
      this.complete = false;
      this.mode = 'add';
      this.currentCard = null;
    },

    handlePaymentMethod() {
      this.busy = true;

      if (this.mode == 'edit') {
        this.$refs.cardForm
          .editPaymentMethod()
          .then((result) => {
            this.$emit('editCard', result);

            this.$notify({
              group: 'alerts',
              type: 'congrats',
              title: 'Card was successfully updated.',
            });

            this.busy = false;

            this.hide();
          })
          .catch(() => {
            this.busy = false;
          });
      } else if (['add', 'rewrite'].includes(this.mode)) {
        this.$refs.cardForm
          .addPaymentMethod()
          .then((result) => {
            this.sendStripeToken(result.setupIntent.payment_method);
          })
          .catch(() => {
            this.busy = false;
          });
      }
    },

    sendStripeToken(token) {
      HTTP.request({
        url: 'stripe/card',
        method: 'POST',
        data: {
          card: {
            token,
          },
        },
      })
        .then((response) => {
          this.$emit('addedCard', response.data);

          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: 'Card was successfully added.',
          });

          this.busy = false;
          this.hide();
        })
        .catch((error) => {
          HttpErrorsHandling(error);

          this.busy = false;
        });
    },
  },
};
