<template>
  <svg
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :style="iconStyle"
    class="svg-icon"
    data-testid="svg-icon"
  >
    <component :is="iconComponent" />
  </svg>
</template>

<script>
import icons from './icons/index.js';

/**
 * Check if the color is css color
 * @param {string} color - current color
 * @returns {boolean} - returns true if css color
 */
function isCssColor(color) {
  return !!color && !!color.match(/^(#|var\(--|(rgb|hsl)a?\()/);
}

/**
 * Icon component
 * @example <SvgIcon name="imageIcon"></SvgIcon>
 * @example <SvgIcon name="imageIcon" size="20" color="danger"></SvgIcon>
 * @example <SvgIcon name="imageIcon" size="16" color="#FECA31"></SvgIcon>
 * @example <SvgIcon name="imageIcon" color="currentColor"></SvgIcon> use 'currentColor' to inherit color from button/link
 */
export default {
  name: 'SvgIcon',

  components: {
    ...icons,
  },

  props: {
    /**
     * The name of the icon
     */
    name: {
      type: String,

      required: true,

      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },

    /**
     * The color for the icon.
     * @description Can be css color or just theme from system colors
     */
    color: {
      type: String,
      // default: undefined,
      default: 'currentColor',
    },

    /**
     * The size of the icon in px
     */
    size: {
      type: Number,
      default: 24,
    },
  },

  computed: {
    iconStyle() {
      const { size, color } = this;

      // Set icon size
      const styles = {
        width: `${size}px`,
        height: `${size}px`,
      };

      // Set icon color
      if (color) {
        if (color === 'currentColor') {
          styles['--icon-color'] = color;
        } else if (isCssColor(color)) {
          styles['--icon-color'] = color;
        } else {
          styles['--icon-color'] = `var(--${color}-color)`;
        }
      }

      return styles;
    },

    iconComponent() {
      return icons[this.name];
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  &::v-deep {
    path[fill='currentColor'] {
      fill: var(--icon-color, currentColor);
    }

    circle[stroke='currentColor'] {
      stroke: var(--icon-color, currentColor);
    }
  }
}

// Use custom properties to customise icon color
// button {
//   &:hover {
//     &::v-deep {
//       svg {
//         --icon-color: blue !important;
//       }
//     }
//   }
// }
</style>
