const Layout = () => import('./Layout.vue');

export default [
  {
    path: 'calendar',
    component: Layout,
    name: 'calendar',
    props: true,
    meta: { hideFooter: true },
  },
];
