const Layout = () => import('./layout/index.vue');

const WebLayout = () => import('./web/index.vue');
const WebLogo = () => import('./web/logo/index.vue');
const WebAddress = () => import('./web/web-address/index.vue');
const WebColourTheme = () => import('./web/colour-theme/index.vue');

const AppLayout = () => import('./app/index.vue');
const AppIcon = () => import('./app/icon/index.vue');
const AppColourTheme = () => import('./app/colour-theme/index.vue');

export default [
  {
    path: 'customisation',
    component: Layout,

    children: [
      {
        path: '',
        name: 'customisation',
        redirect: { name: 'customisation-web-address' },
      },
      {
        path: 'web',
        name: 'customisation-web',
        redirect: { name: 'customisation-web-address' },
        component: WebLayout,

        children: [
          {
            path: '',
            name: 'customisation-web-address',
            component: WebAddress,
            meta: {
              bodyCssClass: 'new-page-background',
            },
          },
          {
            path: 'logo',
            name: 'customisation-web-logo',
            component: WebLogo,
            meta: {
              bodyCssClass: 'new-page-background',
            },
          },
          {
            path: 'theme',
            name: 'customisation-web-theme',
            component: WebColourTheme,
            meta: {
              bodyCssClass: 'new-page-background',
            },
          },
        ],
      },
      {
        path: 'app',
        name: 'customisation-app',
        redirect: { name: 'customisation-app-icon' },
        component: AppLayout,

        children: [
          {
            path: '',
            name: 'customisation-app-icon',
            component: AppIcon,
            meta: {
              bodyCssClass: 'new-page-background',
            },
          },
          {
            path: 'theme',
            name: 'customisation-app-theme',
            component: AppColourTheme,
            meta: {
              bodyCssClass: 'new-page-background',
            },
          },
        ],
      },
    ],
  },
];
