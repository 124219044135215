import Vue from 'vue';
import App from '@/App.vue';
import DomainBlocked from '@/components/pages/DomainBlocked.vue';

import { HTTP } from '@/utils/http-common';

import {
  ModalPlugin,
  FormSelectPlugin,
  TooltipPlugin,
  DropdownPlugin,
  CollapsePlugin,
  NavPlugin,
  FormGroupPlugin,
  FormRadioPlugin,
  FormCheckboxPlugin,
  AlertPlugin,
  PopoverPlugin,
  BadgePlugin,
  VBScrollspyPlugin,
  TabsPlugin,
  OverlayPlugin,
} from 'bootstrap-vue';

Vue.use(ModalPlugin);
Vue.use(FormSelectPlugin);
Vue.use(TooltipPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(NavPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(AlertPlugin);
Vue.use(PopoverPlugin);
Vue.use(BadgePlugin);
Vue.use(VBScrollspyPlugin);
Vue.use(TabsPlugin);
Vue.use(OverlayPlugin);

import Notifications from 'vue-notification';
import VueTextareaAutosize from 'vue-textarea-autosize';
import Vuelidate from 'vuelidate';
import VueMeta from 'vue-meta';
import VueClipboard from 'vue-clipboard2';
import Vue2Filters from 'vue2-filters';
import VueObserveVisibility from 'vue-observe-visibility';
import VueInputAutowidth from 'vue-input-autowidth';
import VueI18n from 'vue-i18n';
Vue.use(VueTextareaAutosize);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueClipboard);
Vue.use(Vue2Filters);
Vue.use(VueObserveVisibility);
Vue.use(VueInputAutowidth);
Vue.use(VueI18n);

import Moment from 'moment';
import VueMoment from 'vue-moment/dist/vue-moment.es.js';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
Vue.use(VueMoment, { moment });

// Fitr Icons
import SvgIcon from '@/shared/svg-icon/index.vue';
Vue.component('SvgIcon', SvgIcon);

// temporary solution due to frequent renaming.
// DEPRECATED
// Use i18n instead
import PlanTypes from '@/helpers/plan-types.js';
Vue.prototype.$planTypes = PlanTypes;

import Store from '@/store/store.js';

import { ROUTER } from '@/router';

// i18n
import en from '@/lang/common';

const i18n = new VueI18n({
  locale: 'en', // default locale
  messages: {
    en,
  },
});

// Sentry
import * as Sentry from '@sentry/vue';

const replaysSessionSampleRate = import.meta.env
  .VITE_SENTRY_REPLAYS_SESSION_RATE
  ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_RATE)
  : 0;
const replaysOnErrorSampleRate = import.meta.env.VITE_SENTRY_REPLAYS_ERROR_RATE
  ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ERROR_RATE)
  : 0;

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: !!import.meta.env.VITE_SENTRY_DEBUG,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: !import.meta.env.VITE_SENTRY_DISABLED,

  integrations: [
    // new Sentry.BrowserTracing({
    //   routingInstrumentation: Sentry.vueRouterInstrumentation(ROUTER),
    // }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
});

// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Allow `<zapier-zap-templates>` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
Vue.config.ignoredElements = ['zapier-zap-templates'];

document.addEventListener('DOMContentLoaded', () => {
  let params = new URL(document.location).searchParams;

  if (params.get('gclid')) {
    window.localStorage.setItem('gclid', params.get('gclid'));
  }

  if (
    window.localStorage.getItem('access_token') &&
    !Store.state.authenticated
  ) {
    HTTP.defaults.headers.common[
      'Authorization'
    ] = `${window.localStorage.getItem(
      'token_type'
    )} ${window.localStorage.getItem('access_token')}`;

    Store.dispatch({
      type: 'getCurrentUserInfo',
    })
      .then(() => initializeApp())
      .catch((error) => {
        if (error.request.status != 0) {
          HTTP.defaults.headers.common['Authorization'] = '';

          localStorage.removeItem('access_token');
          localStorage.removeItem('token_type');
        }

        Store.dispatch({
          type: 'getAppCurrentTheme',
        })
          .then(() => {
            initializeApp();
          })
          .catch(() => {
            initializeBlockedApp();
          });
      });
  } else {
    HTTP.defaults.headers.common['Authorization'] = '';

    Store.dispatch({
      type: 'getAppCurrentTheme',
    })
      .then(() => {
        initializeApp();
      })
      .catch(() => {
        initializeBlockedApp();
      });
  }

  /**
   * Init app
   */
  function initializeApp() {
    console.log(
      `initializeApp called! (${import.meta.env.VITE_SW_PRECACHE_VERSION})`
    );

    document.body.removeChild(document.getElementById('app-loading'));

    const el = document.body.appendChild(document.createElement('market'));

    window.FitrApp = new Vue({
      el,
      store: Store,
      router: ROUTER,
      i18n: i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }

  /**
   * for blocked subdomains
   */
  function initializeBlockedApp() {
    document.body.removeChild(document.getElementById('app-loading'));

    const el = document.body.appendChild(document.createElement('market'));

    window.FitrApp = new Vue({
      el,
      render: (h) => h(DomainBlocked),
    }).$mount('#app');
  }
});
