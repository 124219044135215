export default {
  view: {
    plans: {
      no_plan: {
        title: 'Whoops',
        owner:
          "You haven't created any publicly available programs yet. Once you do, they'll appear here.",
        not_owner:
          "The coach hasn't built any programs to sign up to yet. Once they do, they'll appear here.",
      },
      back: 'Back to Main Page',
    },
    coaches: {
      back: 'Back to Main Page',
      title: 'Meet The Coaches',
      no_coaches: {
        title: 'There are no coaches',
        is_owner:
          "It looks like you haven`t invited any coaches to join you team just yet. You can do that in 'Settings'",
        not_owner:
          "It seems as though the coach hasn't invited any coaches to join their team yet.",
      },
    },
  },
  edit: {
    cancel_modal:
      'Are you sure you want to cancel all changes, unsaved content will be lost?',
  },
  account_type: {
    title: 'Create a new account — and get 14 days free!',
    title_for_free: 'Create a new account',
    subtitle:
      'Create and manage multiple teams under a single registration. Create as many teams as you like and easily switch between them without logging in and out.',
    team: {
      title: 'Team',
      text: 'Create a team account and invite anyone to join forces, delivering team programming under a single identity.',
      button: 'Start 14 Day Trial',
      button_for_free: 'Create a Team',
    },
    subscription: {
      info_1: '*No credit card required, cancel anytime.',
      info_2: 'Transaction fees apply.',
    },
  },
  more: {
    text: 'The FITR process',
    button: 'Learn more',
    modal: {
      step1: 'Download/subscribe to a Program',
      step2:
        "Depending on the type of program, you'll either get training straight away, or you'll need to wait until your coach adds some training in for you.",
      step3:
        'Follow the programming as prescribed by your coach and make use of all the other FITR benefits.',
      back: 'Go Back to the previous page',
    },
  },
  testimonials: {
    empty_text: 'Showcase what your clients think about you.',
  },
  top_coaches: {
    not_empty: {
      title: 'Meet the Coaches',
      text: 'Choose up to 4 coaches to showcase on the front of your page. Clients can click to see the full list too.',
    },
    is_empty: {
      title: "There's no coaches linked yet",
      text: "Looks like you haven`t invited any coaches to join your brand just yet. You can do that in 'Settings'",
    },
    recommend: 'We recommend you to add 4 coaches.',
    library: {
      recommend: 'We recommend you add 4 coaches to showcase.',
      is_empty: "There's no coaches linked yet",
    },
  },
  top_programs: {
    not_empty: {
      title: 'Showcase your top programs',
      text: 'Choose up to 4 top programs that you want to showcase on your public page. Clients will be able to click to see more.',
    },
    is_empty: {
      title: 'Your program library is empty',
      text: "You haven't created any training programs. Once you have, you can showcase up to 4 on the front of your Storefront.",
    },
    recommend: 'We recommend you add a minimum of 2 programs.',
    library: {
      recommend: 'We recommend you add a minimum of 2 programs.',
      is_empty: 'There are no programs showcased yet',
    },
  },
};
