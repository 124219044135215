const Layout = () => import('./layout/index.vue');
const RoleSelection = () => import('./role-selection/index.vue');
const TypeSelection = () => import('./type-selection/index.vue');

// const CoachingOptions = () =>
//   import( './coaching-options');
const SignUp = () => import('./sign-up/index.vue');
const BusinessAccount = () => import('./business-account/index.vue');
const SignIn = () => import('./sign-in/index.vue');
const ForgotPassword = () => import('./forgot-password/index.vue');
const ResetPassword = () => import('./reset-password/index.vue');
const AcceptInvitation = () => import('./accept-invitation/index.vue');
const AthleteInvitation = () => import('./athlete-invitation/index.vue');
const VerifyInformation = () => import('./verify-information/index.vue');

const SuccessPage = () => import('./success/index.vue');

const StripeOnboardingSuccess = () =>
  import('./stripe-onboarding/success/index.vue');
const StripeOnboardingFail = () => import('./stripe-onboarding/fail/index.vue');

import Store from '@/store/store.js';

export default [
  {
    path: '/onboarding',
    component: Layout,
    // beforeEnter: (to, from, next) => {
    //   if (Store.state.authenticated) {
    //     next({ path: '/', replace: true });
    //   } else {
    //     next();
    //   }
    // },

    children: [
      {
        path: '',
        name: 'onboarding',
        redirect: { name: 'onboarding-signin' },
      },

      {
        path: 'sign_in',
        name: 'onboarding-signin',
        component: SignIn,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'forgot_password',
        name: 'onboarding-forgot-password',
        component: ForgotPassword,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'reset_password',
        component: ResetPassword,
        name: 'onboarding-reset-password',
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'role',
        name: 'onboarding-role-selection',
        component: RoleSelection,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'type',
        name: 'onboarding-type-selection',
        component: TypeSelection,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      // {
      //   path: 'coaching',
      //   name: 'onboarding-coaching-options',
      //   component: CoachingOptions,
      //   meta: {
      //     publicPage: true,
      //     hideFooter: true,
      //   },
      // },

      {
        path: 'sign_up/:role',
        name: 'onboarding-coaching-signup',
        component: SignUp,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (
            to.params.role &&
            ['coaching', 'standalone', 'team', 'invited', 'client'].indexOf(
              to.params.role
            ) !== -1
          ) {
            next();
          } else {
            next({ name: 'onboarding-role-selection', replace: true });
          }
        },
      },

      {
        path: 'verify-information/:role',
        name: 'onboarding-coaching-verify',
        component: VerifyInformation,
        props: true,
        meta: {
          hideFooter: true,
          availableForBlocked: true,
        },
        beforeEnter: (to, from, next) => {
          if (
            to.params.role &&
            ['standalone', 'team', 'brand', 'gym'].indexOf(to.params.role) !==
              -1
          ) {
            next();
          } else {
            next({ name: 'onboarding-role-selection', replace: true });
          }
        },
      },

      {
        path: 'create/:type',
        name: 'onboarding-business-create',
        component: BusinessAccount,
        props: true,
        meta: {
          hideFooter: true,
          availableForBlocked: true,
        },
        beforeEnter: (to, from, next) => {
          if (to.params.type && ['team'].indexOf(to.params.type) !== -1) {
            next();
          } else {
            next({
              name: 'onboarding-coaching-signup',
              params: { role: to.params.role },
              replace: true,
            });
          }
        },
      },

      {
        path: 'setup/:type',
        name: 'onboarding-business-setup',
        component: BusinessAccount,
        props: (route) => ({
          type: route.params.type,
          isExistCreationFlow: true,
        }),

        meta: {
          hideFooter: true,
          availableForBlocked: true,
        },

        beforeEnter: (to, from, next) => {
          if (to.params.type && ['team'].indexOf(to.params.type) !== -1) {
            next();
          } else {
            next({ name: 'create-account', replace: true });
          }
        },
      },

      {
        path: 'accept-invitation/:token',
        component: AcceptInvitation,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
          availableForBlocked: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ name: 'feed_invites', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: 'athlete-invitation/:token',
        component: AthleteInvitation,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ name: 'user_my_plans', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: '/success',
        name: 'success_page',
        component: SuccessPage,
        props: true,
        meta: {
          publicPage: false,
          hideFooter: true,
          availableForBlocked: true,
        },
        beforeEnter: (to, from, next) => {
          let popup_storage = Store.state.currentUser.popup_storage;

          if (to.params.role == 'invited' && !!to.params.token) {
            popup_storage = Store.state.privateUser.popup_storage;
          }

          if (popup_storage && popup_storage.includes('sign-up-welcome-page')) {
            next({
              name: 'my_plans',
              params: { choose_program_type: true },
              replace: true,
            });
          } else {
            next();
          }
        },
      },
    ],
  },

  {
    path: '/confirmation',
    meta: {
      publicPage: true,
      hideFooter: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.query.confirmation_token) {
        Store.dispatch({
          type: 'confirmationUser',
          confirmation_token: to.query.confirmation_token,
        })
          .then(() => {
            next({ name: 'onboarding-signin', replace: true });
          })
          .catch(() => {
            next({ name: 'onboarding-signin', replace: true });
          });
      }
    },
  },

  // /stripe-onboarding/?source=signup
  // /stripe-onboarding/?source=settings
  // /stripe-onboarding/error
  {
    path: '/stripe-onboarding',
    name: 'stripe-onboarding-success',
    component: StripeOnboardingSuccess,
    props: (route) => ({
      source: route.query.source,
    }),
    meta: {
      availableForBlocked: true,
    },
    beforeEnter: (to, _, next) => {
      if (
        to.query.source &&
        ['signup', 'settings'].indexOf(to.query.source) !== -1
      ) {
        next();
      } else {
        next({ name: 'create-account', replace: true });
      }
    },
  },

  {
    path: '/stripe-onboarding/error',
    name: 'stripe-onboarding-error',
    component: StripeOnboardingFail,
    props: (route) => ({
      source: route.query.source,
    }),
    meta: {
      availableForBlocked: true,
    },
    beforeEnter: (to, _, next) => {
      if (
        to.query.source &&
        ['signup', 'settings'].indexOf(to.query.source) !== -1
      ) {
        next();
      } else {
        next({ name: 'create-account', replace: true });
      }
    },
  },

  // DEPR
  {
    path: '/user_type',
    redirect: { name: 'onboarding-role-selection' },
    name: 'user_type',
  },

  {
    path: '/sign_in',
    redirect: { name: 'onboarding-signin' },
    name: 'sign_in',
  },

  {
    path: '/sign_up/:role?',
    redirect: { name: 'onboarding-coaching-signup' },
    name: 'sign_up',
  },

  {
    path: '/forgot_password',
    redirect: { name: 'onboarding-forgot-password' },
    name: 'forgot_password',
  },

  {
    path: '/reset_password',
    redirect: { name: 'onboarding-reset-password' },
    name: 'reset_password',
  },
];
