// const modules = import.meta.glob('./*.vue', { import: 'default', eager: true });
const modules = import.meta.glob('./*.vue', { import: 'default' });

const icons = {};

for (const path in modules) {
  const name = path
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '');

  icons[name] = modules[path];
}

export default icons;
