import { HTTP } from '@/utils/http-common';

/**
 * Saves the access token and its type.
 * @param {string} access_token - The access token to be saved.
 * @param {string} token_type - The type of the access token.
 * @param {Function} cb - Callback function to be executed after saving the token.
 * @returns {void}
 */
export function saveToken(access_token, token_type, cb) {
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('token_type', token_type);

  HTTP.defaults.headers.common[
    'Authorization'
  ] = `${token_type} ${access_token}`;

  // user is auth ^_^
  cb('AuthUser');
}

/**
 * Destroys (removes) the saved access token.
 * @returns {void}
 */
export function destroyToken() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }

  localStorage.removeItem('access_token');
  localStorage.removeItem('addMediaActiveTab');
  localStorage.removeItem('token_type');
  localStorage.removeItem('back_to_plan');

  HTTP.defaults.headers.common['Authorization'] = '';

  window.localStorage.removeItem('fitr_return_path');
  window.localStorage.removeItem('utm_query');
  window.location.replace('/');
}
