import Vue from 'vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';
import { ROUTER } from '@/router';

import { includes, without } from 'lodash-es';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import { destroyToken, saveToken } from '@/utils/token-settings';

import FeedStore from '@/components/feed/store';
import SettingsStore from './modules/settings';
import SubscriptionStore from './modules/subscription';
import ColorSchemeStore from './modules/colorScheme.js';
import ReleaseNotesStore from '@/components/release-notes/store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: function () {
    return {
      authenticated: false,
      authError: null,
      alerts: {
        info: null,
        type: null,
      },
      currentUser: {},
      privateUser: {},

      accounts: [],
      // isCoachHasAccounts: false

      isHeaderDropdownOpen: false,
    };
  },

  modules: {
    feed: FeedStore,
    settings: SettingsStore,
    subscription: SubscriptionStore,
    release_notes: ReleaseNotesStore,
    color_scheme: ColorSchemeStore,
  },

  mutations: {
    AuthUser(state) {
      state.authenticated = true;
      state.authError = null;
    },
    SingOutUser(state) {
      state.authenticated = false;
      state.currentUser = {};
      state.privateUser = {};

      if (Sentry?.setUser) {
        Sentry.setUser(null);
      }
    },
    AuthError(state, e) {
      state.authError = e;
    },
    Alerts(state, e) {
      state.alerts = e;
    },

    CurrentUser(state, data) {
      state.privateUser = data.private;
      state.currentUser = data.current;

      if (Sentry?.setUser) {
        Sentry.setUser({ email: state.currentUser.email });
      }
    },

    PrivateUser(state, data) {
      state.privateUser = data.user;
    },

    CurrentUserName(state, e) {
      state.currentUser.full_name = e;
    },

    CurrentUserAvatar(state, e) {
      state.currentUser.avatar_url = e;
    },

    PrivateUserAvatar(state, e) {
      state.privateUser.avatar_url = e;
    },

    StripeAccount(state, e) {
      state.currentUser.stripe_connect = e;
    },

    changeHasNewNotifications(state, status) {
      state.currentUser.has_new_notifications = status;
    },

    changeHasNnreadChatMessages(state, status) {
      state.currentUser.has_unread_chat_messages = status;
    },

    changeDefaultWeightUnit(state, unit) {
      state.privateUser.weight = unit;
    },

    changeDefaultLengthUnit(state, unit) {
      state.privateUser.length = unit;
    },

    setAccounts(state, accounts) {
      state.accounts = accounts;
    },

    deleteAccount(state, id) {
      const index = state.accounts.findIndex((a) => a.id == id);
      state.accounts.splice(index, 1);
    },

    acceptAccount(state, id) {
      const account = state.accounts.find((a) => a.id == id);
      account.invite_status = 'accepted';
    },

    setViewedPopups(state, popups) {
      state.currentUser.popup_storage = popups;
    },

    setCurrentUserCountry(state, country) {
      state.currentUser.country = country;
    },

    setCurrentUserCurrency(state, currency) {
      state.currentUser.currency = currency;
    },

    setCurrentUserPlatformCurrency(state, currency) {
      state.currentUser.platform_currency = currency;
    },

    setCurrentUserCustomURL(state, custom_url) {
      state.currentUser.custom_url = custom_url;
    },

    setCurrentUserSubscription(state, subscription) {
      state.currentUser.subscription = subscription;
    },

    setStripeCoachCustomerId(state, id) {
      state.currentUser.stripe_coach_customer_id = id;
    },

    // change state of social network link for ptivate user
    setSocialLink(state, link) {
      state.privateUser.landing_page[link.type] = link.url;
    },

    // Checklist mutations
    setOnboardingChecklistHidden(state) {
      state.currentUser.hide_checklist = true;
    },

    setOnboardingChecklistStepCompleted(state, step) {
      if (
        state.currentUser.check_list_info &&
        !state.currentUser.check_list_info?.completed?.includes('step')
      ) {
        state.currentUser.check_list_info.completed.push(step);
      }
    },

    setHeaderDropdownStatus(state, status) {
      state.isHeaderDropdownOpen = status;
    },

    setTaxState(state, taxState) {
      state.currentUser.tax_state = taxState;
    },
  },

  actions: {
    getAppCurrentTheme({ dispatch }) {
      return dispatch('color_scheme/fetchAppTheme', {}, { root: true });
    },

    getCurrentUserInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        HTTP.get('users/me')
          .then(({ data }) => {
            dispatch('getAppCurrentTheme').finally(() => {
              commit('CurrentUser', data);
              commit('AuthUser');

              resolve();
            });
          })
          .catch((error) => {
            commit('AuthError', error.data);

            reject(error);
          });
      });
    },

    signin({ commit, dispatch }, { email, password }) {
      const params = {
        email,
        password,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/sign_in', params)
          .then(({ data }) => {
            saveToken(data.access_token, data.token_type, commit);

            dispatch('getAppCurrentTheme').finally(() => {
              commit('CurrentUser', data);

              resolve();
            });
          })
          .catch((e) => {
            if (!e?.response?.data?.subdomain) {
              HttpErrorsHandling(e);
            }

            reject(e);
          });
      });
    },

    signup({ commit, dispatch }, data) {
      // { email, password, first_name, last_name, role, birthdate, agree, country, currency }
      const params = {
        ...data,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/sign_up', params)
          .then((response) => {
            // Set Token
            saveToken(
              response.data.access_token,
              response.data.token_type,
              commit
            );

            dispatch('getAppCurrentTheme').finally(() => {
              commit('CurrentUser', response.data);

              localStorage.removeItem('role');
              resolve();
            });
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    signout() {
      return new Promise((resolve, reject) => {
        HTTP.delete('users/sign_out')
          .then(() => {
            if (window.Intercom) {
              window.Intercom('shutdown');
            }

            destroyToken();

            window.localStorage.removeItem('fitr_return_path');
            window.localStorage.removeItem('isSubscriptionWarningHide');
            window.localStorage.removeItem('utm_query');

            window.location.replace('/');

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    confirmationUser({ commit }, { confirmation_token }) {
      const params = {
        confirmation_token,
      };

      return new Promise((resolve, reject) => {
        HTTP.post('users/confirm', params)
          .then((response) => {
            commit('Alerts', { info: response.data, type: 'peace' });
            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    resetPassword(_, { params }) {
      return new Promise((resolve, reject) => {
        HTTP.post('users/password', params)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    createNewPassword(
      { commit, dispatch },
      { password, password_confirmation, reset_password_token }
    ) {
      const params = {
        password,
        password_confirmation,
        reset_password_token,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
      };

      return new Promise((resolve, reject) => {
        HTTP.put('users/password', params)
          .then(({ data }) => {
            // Set Token
            saveToken(data.access_token, data.token_type, commit);

            dispatch('getAppCurrentTheme').finally(() => {
              commit('CurrentUser', {
                private: data.private,
                current: data.current,
              });

              resolve(data);
            });
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    editPassword(
      { commit },
      { password, password_confirmation, current_password }
    ) {
      const params = {
        user: {
          password,
          password_confirmation,
          current_password,
        },
      };

      return new Promise((resolve, reject) => {
        HTTP.patch('users/password', params)
          .then(() => {
            commit('Alerts', {
              info: { message: 'Your password has been changed' },
              type: 'congrats',
            });

            // Set Token
            // saveToken(response.data.access_token, response.data.token_type, commit);

            // Set Current User
            // commit('CurrentUser', response.data.user);

            resolve();
          })
          .catch(({ response }) => {
            commit('Alerts', { info: response.data, type: 'warn' });

            reject();
          });
      });
    },

    editAvatar({ commit }, { isPrivateUser, avatar }) {
      const url = isPrivateUser ? 'users/private_avatar' : 'users/avatar';
      const key = 'user[avatar]';

      const params = new FormData();
      params.append(key, avatar);

      return new Promise((resolve, reject) => {
        HTTP.post(url, params, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
          .then((response) => {
            commit('PrivateUserAvatar', response.data.private.avatar_url);
            commit('CurrentUserAvatar', response.data.current.avatar_url);

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);

            reject();
          });
      });
    },

    // updateProfile({ commit }, payload) {
    updateProfile({ commit, getters }, payload) {
      let data = this.state.currentUser;

      let params = {};

      if (getters.isUserCoach) {
        params.landing_page_attributes = {
          title: data.title,
          id: data.landing_page.id,
        };
      } else {
        params.first_name = data.first_name || '';
        params.last_name = data.last_name || '';
        params.country = data.country;
        params.currency = data.currency;

        if (payload?.birthdate) {
          params.birthdate = payload.birthdate;
        } else {
          params.birthdate = data.birthdate || '';
        }

        if (payload?.gender) {
          params.gender = payload.gender;
        } else {
          params.gender = data.gender || '';
        }
      }

      return new Promise((resolve, reject) => {
        HTTP.post('users/me', params)
          .then((response) => {
            commit('CurrentUser', response.data);

            let data = {
              message: 'Saved Changes',
            };

            if (payload?.birthdate) {
              data.message = 'You are ready to go!';
            }

            commit('Alerts', { info: data, type: 'congrats' });

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    updatePrivateProfile({ commit, state }, payload) {
      let data = state.privateUser;
      let params = {};

      params.first_name = data.first_name || '';
      params.last_name = data.last_name || '';

      params.country = data.country;

      if (payload?.platform_currency) {
        params.platform_currency = payload.platform_currency;
      } else {
        params.platform_currency = data.platform_currency || '';
      }

      if (payload?.birthdate) {
        params.birthdate = payload.birthdate;
      } else {
        params.birthdate = data.birthdate || '';
      }

      if (payload?.gender) {
        params.gender = payload.gender;
      } else {
        params.gender = data.gender || '';
      }

      return new Promise((resolve, reject) => {
        HTTP.patch('users/private', { private: params })
          .then((response) => {
            commit('PrivateUser', response.data);

            let data = {
              message: 'Saved Changes',
            };
            commit('Alerts', { info: data, type: 'congrats' });

            resolve();
          })
          .catch((e) => {
            HttpErrorsHandling(e);
            reject();
          });
      });
    },

    setDefaultWeightUnit({ commit }, { weight }) {
      return new Promise((resolve, reject) => {
        HTTP.patch('users/private', {
          private: { weight },
        })
          .then(({ data }) => {
            commit('changeDefaultWeightUnit', data.user.weight);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setDefaultLengthUnit({ commit }, { length }) {
      return new Promise((resolve, reject) => {
        HTTP.patch('users/private', {
          private: { length },
        })
          .then(({ data }) => {
            commit('changeDefaultLengthUnit', data.user.length);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // request to set/change social newtwork link to private account
    setSocialLink({ commit }, link) {
      return HTTP.patch('users/private', {
        private: {
          landing_page_attributes: {
            [link.type]: link.url,
          },
        },
      }).then(({ data }) => {
        link.url = data.user.landing_page[link.type];
        commit('setSocialLink', link);
      });
    },

    makePopupViewed({ commit }, { name }) {
      return HTTP.patch('users/popup_storage', {
        popup_name: name,
      }).then(({ data }) => {
        commit('setViewedPopups', data.popup_storage);
      });
    },

    getCoachAccounts({ commit }) {
      return HTTP.get('accounts').then(({ data }) => {
        commit('setAccounts', data);
      });
    },

    deleteCoachAccount({ commit }, id) {
      commit('deleteAccount', id);
    },

    acceptCoachAccount({ commit }, id) {
      commit('acceptAccount', id);
    },

    // Checklist actions
    hideOnboardingChecklist({ commit }) {
      return HTTP.patch('check_list/hide_checklist').then(() => {
        commit('setOnboardingChecklistHidden');
      });
    },

    getTaxStateFromMe({ commit, state }) {
      return new Promise((resolve, reject) => {
        HTTP.get('users/me')
          .then(({ data }) => {
            if (state.currentUser.tax_state !== data.current.tax_state) {
              commit('setTaxState', data.current.tax_state);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateTaxState({ commit }, isTaxOn) {
      HTTP.post(`users/tax/${isTaxOn ? 'on' : 'off'}`).then(({ data }) => {
        commit('setTaxState', data.tax_state);
      });
    },
  },

  getters: {
    currentUserId: (state) => {
      return state.currentUser.id;
    },

    privateUserId: (state) => {
      return state.privateUser.id;
    },

    accountKind: (state) => {
      return state.currentUser.account_kind;
    },

    isUserCoach: (state) => {
      return state.currentUser.role == 'coach';
    },

    isCoachOverallAccount: (state, getters) => {
      return getters.isUserCoach && !state.currentUser.show_account;
    },

    isCoachHasAccounts: (state) => {
      return state.accounts.length > 0;
    },

    filteredAccounts: (state) => {
      return state.accounts.filter((a) => !a.current_account);
    },

    coachAccounts: (state) => {
      return state.accounts;
    },

    hasCoachAnotherAccounts: (state, getters) => {
      return getters.filteredAccounts?.length > 0;
    },

    isBusinessAccount: (state) => {
      // 'private', 'coach',
      return includes(['gym', 'brand', 'team'], state.currentUser.account_kind);
    },

    isBusinessOwner: (state) => {
      return (
        // getters.isBusinessAccount && state.currentUser.account_role == 'owner'
        state.currentUser.account_role == 'owner'
      );
    },

    isAccountOwner: (state) => {
      return state.currentUser.is_account_owner;
    },

    currentUserTitle: (state) => {
      return state.currentUser.title || state.currentUser.full_name;
    },

    isFinancialAviable: (_state, getters) => {
      // return (
      //   !getters.isBusinessAccount ||
      //   (getters.isBusinessAccount && getters.isBusinessOwner)
      // );
      return getters.isBusinessOwner;
    },

    //  Zapier integration
    isZapierAccount: (state) => {
      return state.currentUser.zapier_account;
    },

    zapierCoachToken: (state) => {
      return state.currentUser.zapier_coach_token;
    },

    // Public routes
    accountPublicRouteBase: (_state, getters) => {
      const subdomain = getters['color_scheme/siteSubdomainName'];
      const url = new URL(import.meta.env.VITE_APP_BASE);

      if (subdomain) {
        const { hostname } = url;

        const parts = hostname.split('.');

        if (parts.length == 3) {
          parts[0] = subdomain;
        } else {
          parts.unshift(subdomain);
        }

        url.hostname = parts.join('.');
      }

      return url;
    },

    accountPublicRoute:
      (state, getters) =>
      (type = 'main') => {
        if (getters.isUserCoach) {
          let name = 'public-page-short-link';

          if (type == 'library') {
            name = 'public-page-short-link-plans';
          }

          let user_type =
            getters.accountKind == 'private'
              ? 'c'
              : getters.accountKind.charAt(0);

          let user_id = state.currentUser?.custom_url
            ? state.currentUser.custom_url
            : state.currentUser.id;

          let route = { name, params: { user_type, user_id } };

          return route;
        }
      },

    accountPublicUrl:
      (state, getters) =>
      (type = 'main') => {
        if (getters.isUserCoach) {
          const route = ROUTER.resolve(getters.accountPublicRoute(type));

          const url = new URL(route.href, getters.accountPublicRouteBase);

          return url.href;
        }
      },

    stripeAccount: (state) => {
      return state.currentUser.stripe_connect;
    },

    stripeExternalAccount: (_state, getters) => {
      if (getters.stripeAccount) {
        return getters.stripeAccount.external_account;
      }
    },

    stripeAccountType: (state) => {
      return state.currentUser.stripe_account_type;
    },

    isCustomStripeAccount: (_state, getters) => {
      return getters.stripeAccountType === 'custom';
    },

    isStandardStripeAccount: (_state, getters) => {
      return !getters.isCustomStripeAccount;
    },

    stripeVerificationFields: (state, getters) => {
      if (getters.stripeAccount.verification_fields) {
        return without(
          getters.stripeAccount.verification_fields,
          'external_account'
        );
      } else {
        return [];
      }
    },

    isStandardStripeAccountEnabled: (_state, getters) => {
      return getters.stripeAccount?.charges_enabled;
    },

    stripeExternalAccountVerificationError: (_state, getters) => {
      return getters.stripeAccount?.verification_fields.includes(
        'external_account'
      );
    },

    stripeCoachCustomerId: (state) => {
      return state.currentUser.stripe_coach_customer_id;
    },

    defaultWeightUnit: (state) => {
      return state.privateUser.weight;
      // if (getters.isBusinessAccount) {
      //   return state.privateUser.weight;
      // } else {
      //   return state.currentUser.weight;
      // }
    },

    defaultLengthUnit: (state) => {
      return state.privateUser.length;
      // if (getters.isBusinessAccount) {
      //   return state.privateUser.length;
      // } else {
      //   return state.currentUser.length;
      // }
    },

    isUsaBased: (state) => {
      return state.currentUser.country == 'US';
    },

    viewedPopups: (state) => {
      return state.currentUser.popup_storage;
    },

    hasAccountsNotifications: (state) => {
      return state.accounts.some(
        (f) => f.has_new_notifications || f.has_unread_chat_messages
      );
    },

    // Checklist getters
    isOnboardingChecklistHidden: (state) => {
      return state.currentUser.hide_checklist;
    },

    onboardingChecklistData: (state) => {
      return state.currentUser.check_list_info;
    },

    // Check app domain
    isAppOpenedAsWL() {
      const fitr = new URL(import.meta.env.VITE_APP_BASE);
      const current = window.location;

      return fitr.host !== current.host;
    },

    // Check access rights to Hyrox
    hasHyrox: (state) => {
      return state.currentUser.hyrox;
    },

    // Check if dropdowns from main header is open
    isHeaderDropdownShowing: (state) => {
      return state.isHeaderDropdownOpen;
    },

    taxState: (state) => {
      return state.currentUser.tax_state;
    },
  },
});
