import { mapGetters, mapActions } from 'vuex';

import NotificationView from '@/components/feed/notification/index.vue';

// @vue/component
export default {
  name: 'NotificationsPopup',

  components: {
    NotificationView,
  },

  data() {
    return {
      isShown: false,
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('feed', ['unreadNotifications']),
  },

  methods: {
    toggle() {
      if (this.isShown) {
        this.hide();
      } else {
        this.show();
      }
    },

    show() {
      if (!this.isShown) {
        this.isShown = true;

        this.get();
      }
    },

    hide() {
      if (this.isShown) {
        this.isShown = false;
      }
    },

    get() {
      this.isLoading = true;

      this.getUnread().then(() => {
        this.isLoading = false;
      });
    },
    ...mapActions('feed', ['getUnread']),
  },
};
