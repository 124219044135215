const Layout = () => import('./Layout.vue');
const conversationsIndex = () => import('./index/index.vue');
const conversationShow = () => import('./show/index.vue');

export default [
  {
    path: '/chat',
    component: Layout,
    children: [
      {
        path: '',
        redirect: { name: 'conversations' },
      },
      {
        path: 'all',
        name: 'conversations',
        component: conversationsIndex,
      },
      {
        path: 'messages',
        component: conversationsIndex,
        name: 'conversations_messages',
        meta: {
          conversationsFilter: 'individual',
        },
      },
      {
        path: 'communities',
        component: conversationsIndex,
        name: 'conversations_communities',
        meta: {
          conversationsFilter: 'community',
        },
      },
      {
        path: ':conversation_id',
        component: conversationShow,
        name: 'conversation',
        props: true,
        meta: { hideFooter: true, bodyCssClass: 'theme_white' },
      },
    ],
  },
];
