import DatePicker from 'vue2-datepicker';

import CustomRange from '@/shared/date-range/custom-range/index.vue';

const FORMAT = 'YYYY-MM-DD';

// @vue/component
export default {
  name: 'DateRange',

  components: {
    DatePicker,
    CustomRange,
  },

  props: {
    /**
     * Value of v-model
     * @type {Array}
     */
    value: {
      type: Array,

      default: function () {
        let from = this.$moment().subtract(1, 'months').toDate();

        let to = new Date();

        return [from, to];
      },
    },

    /**
     * Is all available
     * @type {boolean}
     */
    isAllAvailable: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    // 1d     1w     1m     1y     6mo     Mtd     Qtd     All
    let shortcuts = [
      {
        text: '1d',
        onClick(inst) {
          let from = inst.$moment().startOf('day').toDate();
          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: '1w',
        onClick(inst) {
          let from = inst
            .$moment()
            .subtract(1, 'weeks')
            .startOf('day')
            .toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: '1m',
        onClick(inst) {
          let from = inst
            .$moment()
            .subtract(1, 'months')
            .startOf('day')
            .toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: '1y',
        onClick(inst) {
          let from = inst
            .$moment()
            .subtract(1, 'years')
            .startOf('day')
            .toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: '6mo',
        onClick(inst) {
          let from = inst
            .$moment()
            .subtract(5, 'months')
            .startOf('day')
            .toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: 'Mtd',
        onClick(inst) {
          let from = inst.$moment().startOf('month').toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
      {
        text: 'Qtd',
        onClick(inst) {
          let from = inst.$moment().startOf('quarter').toDate();

          let to = inst.$moment().endOf('day').toDate();

          return [from, to];
        },
      },
    ];

    if (this.isAllAvailable) {
      shortcuts.push({
        text: 'All',
        onClick(inst) {
          inst.$emit('showAll');
          inst.closePopup();
        },
      });
    }

    return {
      shortcuts,
    };
  },

  computed: {
    range: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        let from = this.$moment(newValue[0]).format(FORMAT);

        let to = this.$moment(newValue[1]).format(FORMAT);

        this.$emit('input', [from, to]);
      },
    },

    readableValue() {
      if (this.value?.[0] && this.value?.[1]) {
        let frmt = 'DD MMM, YYYY';

        return `${this.$moment(this.value[0]).format(frmt)}  —  ${this.$moment(
          this.value[1]
        ).format(frmt)}`;
      } else {
        return 'Date from — Date to';
      }
    },
  },

  methods: {
    disabledDate(date) {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      return date.getTime() > today.getTime();
    },

    onSelect(value) {
      this.$refs.datePicker.currentValue = value;
    },
  },
};
