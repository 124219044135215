import videojs from 'video.js';

import 'videojs-contrib-quality-levels';
import 'videojs-max-quality-selector';

// @vue/component
export default {
  name: 'VideoPlayer',

  props: {
    /**
     * Source URL
     * @type {string}
     */
    source: {
      type: String,
    },

    /**
     * Thumb URL
     * @type {string}
     */
    thumb: {
      type: String,
    },

    /**
     * State of video
     * @type {string}
     */
    state: {
      type: String,
      default: 'created',
    },
  },

  data() {
    return {
      player: null,
    };
  },

  computed: {
    isPublished() {
      return this.state == 'published';
    },
  },

  watch: {
    isPublished: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$nextTick(function () {
          this.initPlayer();
        });
      }
    },
  },

  mounted() {
    if (this.isPublished) {
      this.$nextTick(() => {
        this.initPlayer();
      });
    }
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    initPlayer() {
      this.player = videojs(
        this.$el.querySelector('#videoPlayer'),
        {
          controlBar: {
            pictureInPictureToggle: false,
            textTrackSettings: false,
          },
          html5: {
            vhs: {
              overrideNative: true,
              bandwidth: 10000000,
              enableLowInitialPlaylist: false,
              limitRenditionByPlayerDimensions: false,
              useDevicePixelRatio: true,
              // smoothQualityChange: true
            },
            nativeTextTracks: false,
          },
        },
        function onPlayerReady() {
          this.maxQualitySelector({
            defaultQuality: 2,
            displayMode: 1,
          });
        }
      );

      if (this.thumb) {
        this.player.poster(this.thumb);
      }

      this.player.src({
        type: 'application/x-mpegURL',
        src: this.source,
      });
    },
  },
};
