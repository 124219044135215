export default {
  state: {
    init: 'Init',
    unpaid: 'Cancelled',
    resume: 'Resume',
    scheduled: 'Scheduled',
    active: 'Active', // Active subscription
    trialing: 'Trial', // Subscription in trial period
    past: 'Past', // Cancelled subscriptions or finished Fixed plan programs
    past_due: 'Missed payment', // There was an active subscription, but the last billing failed for some reason. During 1 week user can fix the problem with the payment (change credit card, charge it, etc.). If in a week there weren't a successful payment, the subscription is automatically canceled. During this week the subscription is given status 'Past due'.
    canceled: 'Cancelled', // Canceled by the user, by the coach or automatically because of missed payment
    incomplete: 'Incomplete', // the user was trying to purchase a subscription, but his payment has failed or is stuck. Stripe set status incomplete for such subscriptions. Clients will be notified via email if it is successful, or if 24 hours subscription will be automatically canceled.
    incomplete_expired: 'Expired', // Incomplete subscriptions expire in 1 day and are deleted by Stripe.
    canceledEOB: 'Cancelled (EOB)',
  },
};
