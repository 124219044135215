import { mapGetters, mapActions } from 'vuex';

// @vue/component
export default {
  name: 'CountryControl',

  props: {
    /**
     * The value bound to the component.
     * @type {string}
     */
    value: String,

    /**
     * The role
     * @type {string}
     */
    role: String,

    /**
     * The errors
     * @type {string}
     */
    errors: String,

    /**
     * Is disabled
     * @type {boolean}
     */
    disabled: Boolean,

    /**
     * The label
     * @type {string}
     * @default 'Country'
     */
    label: {
      type: String,
      default: 'Country',
    },

    /**
     * The placeholder text
     * @type {string}
     * @default 'Please Select a Country'
     */
    placeholder: {
      type: String,
      default: 'Please Select a Country',
    },

    /**
     * The tooltip text
     * @type {string}
     */
    tooltipText: String,

    /**
     * is show a required marker
     * @type {boolean}
     */
    showRequiredMarker: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    country: {
      get() {
        return this.value;
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },

    countriesForSlect() {
      return this.availableCountries(this.role).map((c) => {
        return {
          text: c.name,
          value: c.code,
        };
      });
    },

    ...mapGetters('settings', ['availableCountries']),
  },

  created() {
    this.isLoading = true;

    this.fetch().finally(() => {
      this.isLoading = false;
    });
  },

  methods: {
    ...mapActions('settings', ['fetch']),
  },
};
