import Cover from '@/shared/modal-plan-covers/cover/index.vue';

// @vue/component
export default {
  name: 'ModalPlanCovers',

  components: {
    Cover,
  },

  props: {
    /**
     * The types of covers available for the plan.
     * @type {object}
     */
    coverTypes: {
      type: Object,
    },

    /**
     * The current cover type.
     * @type {string}
     * @default 'main'
     */
    currentCoverType: {
      type: String,
      default: 'main',
    },

    /**
     * The covers for the plan.
     * @type {Array}
     * @default function () { return []; }
     */
    planCovers: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      isStepBusy: false,
    };
  },

  methods: {
    show() {
      this.$refs.planCoversModal.show();
    },

    hideModal() {
      this.$refs.planCoversModal.hide();
    },

    saveCovers() {
      this.hideModal();
    },

    handleEditCover(data) {
      this.$emit('editCover', data);
    },

    handleRemoveCover(data) {
      this.$emit('removeCover', data);
    },

    handleUploadCover(data) {
      this.$emit('uploadCover', data);
      this.hideModal();
    },

    findCoverByType(cover_type) {
      const cover =
        this.planCovers.length > 0
          ? this.planCovers.find((c) => c.cover_type == cover_type)
          : null;

      if (cover) {
        return cover.media?.cover ? cover.media.cover : cover.original;
      } else {
        return null;
      }
    },
  },
};
