export default {
  coach: {
    athlete_calendar: {
      system_noty: 'You have new client activity below.',
    },
    benchmarks: {
      form: {
        help: {
          link: 'Link Personal Record(s)',
          text: 'or type it in manually.',
        },
      },
      page: {
        head: {
          title: 'Personal Records',
          link: 'Show me how they work.',
        },
        main: {
          title: 'Welcome to using Personal Records!',
          text: 'Placeholder Personal Records text…',
        },
      },
      instructions: {
        text: 'Using Personal Records, code your sessions so clients can see individualised sessions automatically. Automatic percentages, split times and more!',
        steps: {
          s1: {
            title: 'Step 1 – Choose the Personal Record',
            text: 'Pick from the central list, or once you’re up to speed, simply type out the Personal Record code. <mark>CODE</mark>.',
          },
          s2: {
            title: 'Step 2 – Adjust as Required',
            text: 'When the session text goes blue, we know you’ve linked a Personal Record. Adjust the percentage to your needs and for split times, you can simply adjust the + or – at the end to represent second based adjustments.',
          },
          s3: {
            title: 'Step 3 – Sense-check the session',
            text: "Sense check your sessions with test values to ensure you've achieved the required training intensity.",
          },
          s4: {
            title: "Step 4 – It's over to your client",
            text: 'Clients will be shown targets based on their own Personal Record values. If they haven’t recorded a Personal Record, we’ll let them know.',
            text2:
              'Once recoded, they’ll be shown their personalised session straight away.',
          },
        },
        dont_show: 'Don’t show me this again',
      },
    },
    calendar: {
      form: {
        unsaved:
          'You have unsaved changes. Do you want to leave and lose your changes?',
      },
      program_info: {
        text: "Now you can start to build out this program's training schedule. You can also access this program's training schedule later from the main program page.",
        dont_show: 'Do not show this message again',
      },
    },
    clients: {
      add: {
        invalid_emails_length:
          'Email invite is limited to 20 users at a time. Instead, please consider using the Program’s URL.',
        current: {
          placeholder: 'Search or select an existing client from the list',
          empty:
            'There are no available current clients to add to this program.',
          add: 'Add clients',
          no_results_placeholder: 'No clients match your fillters',
          success_title_one_added: 'has been added to the Pending tab',
          success_title_few_added: 'Clients have been added to the Pending tab',
        },
        new: {
          send: "Note: Your client(s) will appear in the 'pending clients' tab after this step. In the 'pending clients' tab you can program for clients before sending them a formal invitation to subscribe, allowing you to get a head start on their programming.",
          clients_send:
            'Manually add a client into your account. Once added, you will need to formally invite them to one of your programs via the ... option menu to the right hand side of their name.',
          add_btn: '+ Add Another Client',
          check: "Please check some formatting issues we've discovered.",
          button: {
            check: 'Click here to check them all',
            remove: 'click here to remove them',
          },
        },
      },
      all: {
        placeholder: {
          active: {
            title: 'No Active Clients',
            text: "Create a training program and invite your clients to join it. Once they've joined, they'll appear here.",
          },
          in_trial: {
            title: 'No In-Trial Clients',
            text: "You don't currently have any clients who are in a trial of one of your programs.",
          },
          pending: {
            title: 'No Pending Clients',
            text: 'Pending clients allow you to send programming to them before they formally join the program. 1-2-1 type programs only.',
          },
          past: {
            title: 'No Past Clients',
            text: "Clients will appear here when they're no longer active on one of your programs.",
          },
          activity: {
            title: 'No Clients to Review',
            text: 'Clients will appear here when they have training remarks that require your attention.',
          },
        },

        filter_no_results: {
          title: 'No clients match your filters',
          text: 'Please adjust your chosen filters to refresh the page.',
        },

        row: {
          message:
            "Populate training even before a client's joined your program.",
        },
      },
      cancel_subscription: {
        form: {
          step_info:
            'Are you 100% sure you want to cancel this FREE program subscription? The program will be сancelled immediately.',
          cancel:
            "Client access to the program will cease from tomorrow. <i>Important note</i>: if you cancel the program before the end of the client's usual billing cycle they will NOT automatically receive a refund for the part of the month they haven't received training for. Please consider whether you need to offer a partial refund on the next screen (paid programs only).",
          continue:
            'Client access to the program will continue until the natural end of the current billing cycle. At that point no further client payment will be taken and access to program content will stop for the client.',
          info: {
            text1: 'Are you 100% sure you want to cancel the subscription?',
            text2:
              'Access to the training program will cease immediately, no further training sessions will flow to the client.',
            text3:
              'The client will continue to receive training sessions from this program until the end of their billing cycle.',
          },
          refund: {
            label: 'Cancel and issue the client a refund',
            text: 'Issue a refund at the same time as cancelling their subscription.',
          },
          not_refund: {
            label: 'Cancel the subscription without a refund',
            text: "The client doesn't need a refund issued at this time.",
          },
          warning: {
            full_discount:
              "A 100% coupon was applied to the last transaction therefore a refund can't be issued.",
            no_discount:
              "You have already refunded this client's last payment via the dashboard. Please access the dashboard if you need to issue further refunds to this client.",
          },
        },
      },
      layout: {
        change_highlighted_program: 'Change highlighted program',
        batch_upload: 'Batch upload clients',
        add: 'Add new client',
        import: 'Import clients from CSV',
      },
      client_profile: {
        benchmark: {
          title: 'Personal Records',
          searchPlaceholder: 'Search Personal Records...',
          noDataTitle: 'No Personal Records',
          noDataText: "This client hasn't tracked any Personal Records yet.",
          noResultsTitle: 'No Personal Records match your filters',
          noResultsText:
            'Please adjust your chosen filters to refresh the page.',
          url: 'benchmarks',
        },
        'body-metric': {
          title: 'Body Metrics',
          searchPlaceholder: 'Search body metrics...',
          noDataTitle: 'No metrics',
          noDataText: "This client hasn't tracked any metrics yet.",
          noResultsTitle: 'No metrics match your filters',
          noResultsText:
            'Please adjust your chosen filters to refresh the page.',
          url: 'body_metrics',
        },
        challenge: {
          title: 'Challenges',
          searchPlaceholder: 'Search challenges...',
          noDataTitle: 'No challenges',
          noDataText: "Your client hasn't tracked any challenges yet.",
          noResultsTitle: 'No challenges match your filters',
          noResultsText:
            'Please adjust your chosen filters to refresh the page.',
          url: 'challenges',
        },
      },
    },
    coupons: {
      title: 'Create coupon codes',
      if_strype:
        'Boost sales and reward your loyal customers with coupon codes.',
      filter_no_results: {
        title: 'No coupons match your filters',
        text: 'Please adjust your chosen filters to refresh the page.',
      },
      create: 'Create a coupon code',
      no_strype:
        'To create coupon codes for training programs, you need to update some information with our payment provider Stripe.',
      deactivate:
        'Are you sure you want to deactivate this coupon? This action can’t be undone and you will not be able to delete it or use the same coupon name.',
      create_modal: {
        setup:
          'Set up your very own discount codes to offer promotions to new and existing clients.',
        name_feedback: {
          invalid:
            'May only contain alphanumeric characters in addition to - and _',
          valid: 'Clients will be required to enter coupon codes on checkout.',
        },
        how_long: 'How long should the discount apply for?',
        duration: 'Duration in months',
        limit_duration:
          'Limit when this code can be used? E.g. Black Friday Special.',
        max_redemptions:
          'Limit how many times this code can be used (in total).',
        redemptions_input:
          'Limit how many times this code can be used (in total).',
      },
      refund_modal: {
        alert: {
          text1:
            'Stripe will attempt to take the refund from any pending balance you have awaiting bank transfer. If this balance is not sufficient to fulfil the refund, Stripe will automatically try and debit your bank account.',
          text2:
            'Please ensure you have cleared funds in your bank account as if Stripe fails to fulfil the refund you may be charged a $15 fee.',
          text3:
            'You can check what your pending balance is within the dashboard.',
          text4:
            'Refunds can take up to 10 days to process. It is not possible to refund transaction fees. It is only possible to make a full or partial refund once per transaction.',
        },
      },
    },
    challenges: {
      create: 'Create a Challenge',
      title: 'Create a Challenge',
      description:
        "Create and insert challenges into multiple programs and we'll collate all the scores into a single leaderboard. Clients can re-enter challenges and see how they progress over time too.",
    },
    dashboard: {
      payouts: {
        alert:
          'Due to Stripe API limitations, we are not able to show the transactions that make up your individual payouts.',
        placeholder: {
          title: 'Bank payouts history',
          text: "Once you'll have pending or received payouts, they'll appear here.",
        },
      },
      transactions: {
        title: 'Transaction history',
        text: "As you generate sales, they'll appear here.",
      },
      media: {
        title: 'Store all your media and documents here.',
        text: 'You can quickly assign them into any training program you build. Any media added into a program, will also appear here.',
        edit: {
          hint: "Tag your resource. As you build training sessions, if the text starts to match a resource tag, we'll offer it as a suggested attachment.",
        },
      },
    },
    media: {
      title: 'Resources',
      subheader_text:
        'Use Resources to access and manage your media, and access FITR’s library of free imagery and videos.',
      upload_resource: 'Upload resource',
      view_all: 'View All',
      hyrox: {
        text1:
          'This exclusive content is reserved for affiliated&nbsp;HYROX&nbsp;Gyms&nbsp;and&nbsp;Trainers.<br />Click below to learn more.',
        button: 'Learn about HYROX affiliation and&nbsp;its&nbsp;benefits.',
        text2: 'Already affiliated? Let us know via',
        link: 'live&nbsp;chat',
      },
      no_resources_title: 'No resources',
      no_resources_text:
        'You don’t currently have any resources added to your library. Use the Upload resource button to add content to your library.',
      no_result_title: 'No resources match your filters',
      no_result_text:
        'Please adjust your chosen filters to refresh the results.',
    },
    profile: {
      coaches: {
        title: 'Invite your coaches',
        text: 'Invite coaches to your {accountKind} to share the workload.',
        sure: "Are you sure you want to remove this coach? This can't be undone and you'll need to send another invitation.",
      },
      info: {
        accounts:
          'You can connect to Facebook for log in purposes. We do not push any content from FITR to your Facebook account.',
        logout: "We'll save all your data until you come back.",
      },
      payment: {
        identity: {
          descr1:
            "Stripe's regulations require you to store some information securely with them to receive bank payouts.",
          descr2:
            'You will be redirected to Stripe to upload your information then automatically brought back to FITR.',
        },
        account: {
          descr1:
            'To receive automatic payouts to your bank account, you need to link one here. All your banking information is held securely with our payment provider Stripe.',
          descr2: 'Payouts to your bank account are processed every Wednesday.',
        },
        form_invalid:
          'The following fields have been denied by Stripe. Please make sure you fill in everything correctly.',
        bank: {
          text1:
            'You need to link a bank account so any client purchases will transfer into your bank account automatically.',
          text2:
            "You can skip this step for now, BUT if you do so, you'll have 21 days after your first sale to add them, otherwise the money will be returned to your client.",
          text3:
            'Note: Stripe holds sales for up to 7 days, before transfering them to your bank. They hold monies incase of any client/coach disputes.',
          attention:
            'You will be able to edit the bank account associated with you account from your payouts settings at any time.',
        },
        DEPR: {
          full: {
            info: {
              text1:
                'Stripe requires us to take some further details to ensure your payouts are never limited or suspended.',
              subtitle: 'Why?',
              text2:
                'Stripe has to ensure you are who you say you are. To do this fully, they need some personal details, the additional ones below and then your bank account.',
              text3:
                'You can receive some payments from clients with limited information but Stripe reserves the right to limit the volume of or suspend payouts at any time.',
            },
            attention:
              'We do not store any details on our servers, they are held securely with Stripe',
          },
          personal: {
            title: 'Register Personal Details',
            text: 'Stripe requires us to take some basic personal information to set up automatic payments  between youself and your clients. This is for bank verification and online safety reasons.',
            attention:
              'We do not store any details on our servers, they are held securely with Stripe',
          },
        },
        retry: {
          danger1:
            "There is a problem with your recent payment to FITR. Please check your bank account's funds and try again.",
          danger2:
            "If you don't, FITR reserves the right to suspend your account and if non payment continues, delete your account. But we really don't want to do that.",
        },
      },
      reviews: {
        title: 'Program reviews will be available soon',
        text: 'We’re hard at work. You’ll be able to gather reviews on your programs to stand out from the crowd',
      },
      settings: {
        info: "We'll always keep your feed totally up to date, but you can decide what type of emails you'll be sent below.",
        is_coach: {
          text1:
            'Receive a daily email summarising any new client performances, thread mentions, inbox messages and programs that require your attention.',
          text2:
            "Receive emails when you've got new clients, lost clients, we've blocked clients due to failed payments and more.",
          text3:
            "Receive tailored marketing communication relevant to your account. FITR will only send marketing emails when we feel it's really needed. We will not spam your account with unnecessary emails. However, you can turn off all marketing emails by unchecking this box.",
        },
        not_coach: {
          text1:
            'Receive a daily email summarising any outstanding comments from your coach(es), mentions and inbox messages.',
          text2:
            'Receive emails about any program & payment related activities.',
        },
      },
      socials: {
        link_instagram: 'Instagram',
        link_facebook: 'Facebook',
        link_twitter: 'Twitter',
      },
    },
    programs: {
      price: {
        alert: "After transaction fees you'll get: ",
        warning: 'Your Stripe account configuration requires your attention.',
        hint: "*Your program's price includes all taxes that may be applicable to you. It is the total price the client will pay.",
        danger:
          'Please contact your brand/gym/team owner and ask him to fill in the missing Stripe payout information. Your program will be saved as a draft at this point.',
      },
      review: {
        step: 'Preview, publish & share your program',
        alert:
          "This is the client view - make sure you're happy with how it looks",
      },
      list: {
        title: 'Create your first program',
        text: 'Programs are how you deliver your remote coaching services to the world. Mix and match from the available styles.',
      },
      needs_review: {
        title: 'Programs are up to date',
        text: 'It looks like your programs are all up to date. Good work.',
      },
      filter_no_results: {
        title: 'No programs match your filters',
        text: 'Please adjust your chosen filters to refresh the page.',
      },

      create_main: {
        title: 'Create your program',
      },

      actions: {
        modal_step_closer: {
          title: 'You’re one step closer to getting your program ready!',
          text: "Your program is set as free. Please be aware that once published this can't be changed.",
          button_ok: 'Yes, continue',
          button_cancel: 'Back to edit',
        },
      },

      sign_up_clients_page: {
        title: 'How would you like to sign up clients?',
        text_one:
          'Select one, or more, of the options to sign up clients and start programming or skip and do this later.',
        text_two:
          'You can have multiple 1-2-1 clients, with individualised schedules, subscribed to a single 1-2-1 program.',
        text_two_subtitle: 'Important note:',
        sign_up_options: {
          learn_more: 'Learn more.',
          option_one: {
            label: 'Current clients',
            text: 'Invite one or more of your current clients to the program.',
            description:
              "Choose one or more of your current clients to invite to the program. Once added they'll move into a {pending} status. This allows you to create programming for them before actually joining the program. Once you're ready you can send an invitation to sign up and the programming you created will be waiting for them in the App. {link}",
          },
          option_two: {
            label: 'Manually add',
            text: 'Add someone new to the program, with their name and email.',
            description:
              "Add someone new to the program with their name and email. Once added they'll move into a {pending} status. This allows you to create programming for them before actually joining the program. Once you're ready you can send an invitation to sign up and the programming you created will be waiting for them in the App. {link}",
          },
          option_three: {
            label: 'Email',
            text: "Add one or more emails into the box below and we'll send them a link to get the program.",
            description_1:
              'Simply send a link to the program via an email. Clients wil receive their email invite immediately. Most coaches find it best to add programming and then invite clients.',
            description_2:
              'Note: If you invite clients this way, you WILL have to wait for them to join the program before you can send them training.',
          },
          option_four: {
            label: 'Program URL',
            text: 'Every program has a unique, shareable link. Use it anywhere you want.',
            description_1:
              'Simply copy and share this direct link to the program anywhere you want.',
            description_2:
              'Note: If you invite clients this way, you WILL have to wait for them to join the program before you can send them training.',
          },
          pending: "'Pending'",
        },
        copy_url_label: 'Copy This URL to Share',
        copy_url_btn: 'Copy link',
        modal_signup: {
          title: 'How would you like to sign up clients?',
          description:
            'Use one or more of the following options to sign up clients to your program:',
          unsaved_messages: {
            email: {
              title: 'Your email invite hasn’t been sent yet',
              text: 'If you’d like to send this invite please go back and select the ‘Send email invite’ button.',
            },
            manually_add: {
              title: 'Your client hasn’t been added yet',
              text: 'If you’d like to add this client please go back and select the ‘Add client’ button.',
            },
            current_client: {
              title: 'Your current clients haven’t been added yet',
              text: 'If you’d like to add these clients please go back and select the ‘Add clients’ button.',
            },
            continue: 'Do you want to continue?',
            ok_btn: 'Yes, continue',
            cancel_btn: 'Cancel',
          },
        },
      },
    },
    templates: {
      layout: {
        title: 'Templates',
        description:
          'Create templates of your work from scratch or save them directly from your programming to use again in the future. You can create templates from single parts, such as warm ups or exercises, a whole day’s training and even week-based templates such as training cycles.',
      },
      list: {
        title: 'Create week template',
        text: 'Create blocks of weeks that you can insert in any of your programs.',
      },
      sections: {
        list: {
          title: 'Create day template',
          cta: 'Create Template',
          action: 'Create day template',
          text: 'Create single part templates you can use time and again in your programs.',
        },
      },
      filter_no_results: {
        title: 'No templates match your search',
        text: 'Please adjust your search to refresh the page.',
      },
      copy_hyrox_template_modal: {
        title: 'Copy to my templates',
        label: 'Template name',
        close_btn: 'Cancel',
        save_btn: 'Save template',
        placeholder: 'E.g. warmup template',
        error_max: 'Template name must have at most {max_error} letters.',
        error_min: 'Template name must have at least {min_error} letters.',
        error_required: 'Template name is required.',
        copy: 'copy',
        success_notify: 'Template successfully copied',
      },

      messages: {
        saved: 'Template was succesfully saved.',
      },
    },
  },
  user: {
    benchmarks: {
      instructions: {
        title: 'Personal Records Introduction',
        close_btn: 'Close',
        text1:
          'Use Personal Records as a great way to track your progress over time. You can access the extensive library via your main navigation menu.',
        text2:
          'If you’re following programming from a coach, and they’ve linked a Personal Record into the part, we’ll show you your most current performance entry.',
        text3:
          'If you haven’t recorded a performance for a set Personal Record, add it there and then to see the prescribed programming.',
        text4:
          'If you can’t see a Personal Record you’d like to track, let us know <a href="mailto:info@fitr.training">info@fitr.training</a> and we’ll get it added into the system.',
      },
      page: {
        title: 'Personal Record tracker',
        text: 'Track your Personal Records over time. Select the ones you want from our extensive library.',
        show: {
          sure: "Are you sure you want to delete your Personal Record from {name}? Any information will be deleted and can't be restored.",
          table: {
            title: 'Whoops',
            text: 'Looks like you don`t have any data entries for the chosen range.',
          },
        },
      },
    },
    calendar: {
      title: 'No unallocated programs',
      text: 'You don’t have any programs to add to your calendar right now. As you finish fixed length programs they’ll appear here ready to use again.',
      grid: {
        delete:
          "Are you sure you want to delete the part you've created? You will lose any information you've entered.",
        unsave:
          'You have unsaved changes. Do you want to leave and discard your changes?',
      },
    },
    plans: {
      programs: {
        title: 'Your program library',
        text: "As you sign up to training programs they'll appear here. You'll need to be invited by a coach to a program, or find a link via a coach's public page.",
        info: "Accept or decline program invitations you've received.",
      },
      layout: {
        title: 'Your Program Library',
        text: "As you sign up to training programs they'll appear here. You'll need to be invited by a coach to a program, or find a link via a coach's public page.",
      },
    },
    profile: {
      billing: {
        title: 'Review and download your billing history.',
        billing_history: {
          title: 'Transaction history',
          text: 'Once you purchase a program, your history will appear here.',
        },
        transactions: {
          title: 'Billing history',
          text: 'Once you purchase training programs, you can download your billing history here.',
        },
        message:
          'Please add the relevant billing information to your account. This will appear on all invoices.',
        history: {
          info: 'We have not been able to take a required payment from the following program(s):',
        },
      },
      info: {
        accounts:
          'You can connect to Facebook for log in purposes. We do not push any content from FITR to your Facebook account.',
        logout: "We'll save all your data until you next log in.",
      },
      payment: {
        title: 'Add payment information',
        text: 'Link a card to your account so you can purchase training programs. Or, purchase using Apple Pay or Google Pay.',
      },
    },
    purchases: {
      title: 'Checkout',
      payment: 'Payment',
      add_new_method: 'Add new payment method',
      summary: 'Summary',
      free: 'Free',
      need_permission:
        'You do not have permission to access this program. Please contact the coaching provider.',
      privacy_setting:
        'It is likely due to a privacy setting on the program and they may need to grant you access. ',

      form: {
        question: {
          membership:
            'Choose your own start date and follow the programming from your calendar view. You’ll be able to always see a 7 days in advance.',
          fixed:
            'Choose your own start date after downloading the program and follow the programming from your calendar view.',
          bespoke:
            'Follow your programming from your calendar view. Future training visibility is set by your coach.',
          one_many:
            'Follow your programming from your calendar view. Future training visibility is set by your coach.',
        },
        info: 'Estimated conversion. Actual may differ.',
        trial_hint:
          'If you cancel before the end of your trial you will not be charged. If you don’t cancel we’ll automatically start a new billing cycle after your trial, rather than pro-rating the first payment.',
        success: 'Your coupon code has been successfully accepted.',

      },
    },
  },
  update_your_profile_info: {
    title: 'Update your profile info',
    client: {
      text: 'Why are we asking you this? With the introduction of features such as leaderboards, doing so will let you refine results in more detail.',
    },
    coach: {
      text: 'Why are we asking you this?',
    },
  },
};
