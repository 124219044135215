export default {
  header: {
    enter_promotional: 'Enter the promotional information about your program',
    exit_program: 'Exit Program Creation',
    save_as_draft: 'Save as draft',
    up_to_date: 'Up to Date!',
    info_and_pricing: 'Info & Pricing',
    sign_up_clients: 'Sign up clients',
    start_programming: 'Start programming',
    next: 'Next',
    skip: 'Skip',
    save_and_close: 'Save & Close',
    exit: 'Exit',
    program_creation: 'program creation',
    tooltip:
      'Every program has a step by step process to get you up and running and programming for your clients quickly. You can always move back and forth.',
  },

  attachment: {
    buttonText: 'Add Resource',
  },

  overview: {
    title: 'Overview',
  },

  description: {
    title: 'Description',

    text: 'Let prospective clients know all about your program and add any Resources to aid your sales pitch. These Resources will be available to all clients before they purchase the program and remain on this page only.',

    errors: {
      must_have: 'Description must have at most {maxLength} letters.',
    },

    placeholder: {
      fixed:
        '[Edit] Anyone who downloads this program will receive the program in full.',
      membership:
        '[Edit] Anyone who joins this program will start at session 1, day 1, week 1.',
      individual:
        "[Edit] You will be able to deliver 100% bespoke training to every client who's attached to this program. Create coupon codes to offer different clients different payment terms on the same program.",
      one_many:
        '[Edit] Anyone who joins this program will join the rest of the group and follow the same programming form that point on.',

      default: 'Tell the world what your program is all about here…',
    },
  },

  sessions: {
    label: 'Sessions per day',
    append: 'Sessions',
    invalid: 'Please Enter a Valid Sessions per day',
  },

  skip_sales_page: {
    label: 'Skip program sales page creation',
    description_1:
      "Skip completing the program sales page below if you'd like clients to be taken directly to the checkout page after clicking the program URL.",
    description_2:
      "This is useful if you'd like to embed this program URL into your own website sales page.",
    learn_more: 'Learn more',
  },

  tooltips: {
    program_type:
      "This can't be changed at this stage. Please create a new program if you need to.",

    currently_at: "This may be affected by a client's local timezone.",
  },

  welcome_message: {
    title: 'Welcome message',
    text: 'Clients will receive this message and any attached Resources directly to their FITR inbox area on the App. How about inlcuding a video as a welcome to the program?',
    restore: 'Restore default message.',
    customize:
      'Customise the message each new client will automatically receive to their inbox when they join this program.',
    default: 'Thanks for joining{programName}',
  },

  waiver: {
    title: 'Enable a pre-acceptance waiver?',

    options: {
      false: {
        label: 'No',
        text: "Clients won't need to sign a waiver.",
      },
      true: {
        label: 'Yes',
        text: "Clients will sign a waiver and we'll store a log of this in their profile for your records.",
      },
    },
  },

  visibility: {
    title: 'Client training visibility',

    options: {
      day: '{day} day in advance',
      days: '{days} days in advance',
      unlimited: 'Unlimited',
    },
  },

  accessibility: {
    title: 'Accessibility',

    options: {
      false: {
        label: 'Private',
        text: "Only those emails you place on the 'allowed' list will be able to access this program.",
      },
      true: {
        label: 'Public',
        text: 'Everyone will be able to access this program.',
      },
    },
  },

  storefront: {
    title: 'Storefront visibility',

    options: {
      false: {
        label: 'Hide from Storefront',
        text: 'Your program will be hidden from your storefront and the public will not be able to find it without a link.',
      },
      true: {
        label: 'Show on Storefront',
        text: 'Your program will be visible on your storefront for the public to find and join.',
      },
    },
  },

  notification: {
    title: 'Client performance notifications',

    options: {
      false: {
        label: 'Mute notifications',
        text: 'You will only receive notifications about sign\xa0ups/\u200Cdownloads and continued subscriptions and cancellations where applicable.',
      },
      true: {
        label: 'Show notifications',
        text: 'You will receive all client notifications as well as  sign\xa0ups/\u200Cdownloads and continued subscriptions and cancellations where applicable.',
      },
    },
  },

  copied_text: '✓ Copied to Clipboard',

  button: {
    programming: 'Programming',
    more: 'More',
  },

  list: {
    status: {
      empty: 'Program empty',
      finished: 'Program Finished',
      not_started: 'Not Started',
      pending: {
        label: 'Pending',
        tooltip:
          "Client's are 'Pending' when you manually add them, allowing you to prepare their program. Once ready, click 'Send program invite' to send a subscription link.",
      },
      all_good: 'All Good',
      days_left: '3 Days Left',
      deleted_user: 'Deleted user',
      is_invalid: {
        label: 'Invalid account',
        description: 'Email registered as a coach',
        tooltip:
          'User registered as a coach and as a result cannot access the program.',
      },
    },
  },
};
