import required from 'vuelidate/lib/validators/required';
import email from 'vuelidate/lib/validators/email';
import minLength from 'vuelidate/lib/validators/minLength';

// @vue/component
export default {
  name: 'SignInForm',

  data: () => ({
    isSubmited: false,
    isPasswordVisible: false,

    user: {
      email: '',
      password: '',
    },
  }),

  validations: {
    user: {
      email: {
        required,
        email: (val) => email(val.toLowerCase()),
      },

      password: {
        required,
        minLength: minLength(6),
      },
    },
  },

  methods: {
    signin() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSubmited = true;

        this.$store
          .dispatch('signin', {
            email: this.user.email.toLowerCase(),
            password: this.user.password,
          })
          .then(() => {
            this.$emit('loginDone');
          })
          .catch((e) => {
            this.isSubmited = false;

            if (e?.response?.data?.subdomain) {
              this.$notify({
                group: 'alerts',
                type: 'error',
                title: `You do not have access rights to that FITR subdomain. Please login <a href="${
                  import.meta.env.VITE_APP_BASE
                }">here</a> or contact support for more help.`,
                duration: -1,
              });
            }
          });
      }
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
