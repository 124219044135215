import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// @vue/component
export default {
  name: 'ModalUnsibscribe',

  props: {
    /**
     * Plan data
     * @type {object}
     */
    plan: {
      type: Object,
    },

    /**
     * Remove from
     * @type {string}
     * @default 'plan'
     */
    removeFrom: {
      type: String,
      default: 'plan',
    },

    /**
     * Show additional text
     * @type {boolean}
     */
    showAdditionalText: {
      type: Boolean,
    },
  },

  data() {
    return {
      isSubmited: false,
    };
  },

  methods: {
    showModal() {
      this.$refs.agreeUnSubscribeModal.show();
    },

    hideModal() {
      this.$refs.agreeUnSubscribeModal.hide();
    },

    unSubscribe() {
      this.isSubmited = true;

      let url = '';

      if (this.removeFrom == 'plan') {
        url = `stripe/plan/${this.plan.id}/unsubscribe`;
      } else if (this.removeFrom == 'purchases') {
        url = `athlete/plans/${this.plan.id}/unsubscribe`;
      }

      HTTP.delete(url)
        .then((response) => {
          this.$emit('unsubscribeData', response.data);

          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: 'Program successfully cancelled.',
          });

          this.hideModal();
        })
        .catch(HttpErrorsHandling)
        .then(() => {
          this.isSubmited = false;
        });
    },
  },
};
