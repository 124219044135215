// @vue/component
export default {
  namespaced: true,

  state() {
    return {
      counter: 0,
    };
  },

  mutations: {
    setCounter(state, payload) {
      state.counter = payload;
    },
  },

  getters: {
    notesCounter(state) {
      return state.counter;
    },
  },

  actions: {
    changeCounter({ commit }, counter) {
      commit('setCounter', counter);
    },
  },
};
