import header from './header';
import footer from './footer';
import cookies_policy from './cookies';
import home from './home';
import media from './media';
import modal from './modal';
import plan_card from './plan_card';
import plan_detail from './plan_detail';
import options from './options';
import role from './role';
import sign_up from './sign_up';
import chat from './chat';
import feed from './feed';
import landing from './landing';
import plan from './plan';
import profile from './profile';
import pricing from './pricing';
import hyrox from './hyrox';
import choose_your_plan from './choose_your_plan';
import onboarding_type from './onboarding_type';
import membership from './membership';
import plan_type from './plan_type';
import programs from './programs';
import cover from './cover';
import webAddress from './web_address';
import tables from './tables';
import notes from './notes';
import settings from './settings';
import validation_errors from './validation_errors';


export default {
  en: {
    header,
    footer,
    home,
    cookies_policy,
    media,
    modal,
    plan_card,
    plan_detail,
    auth: {
      options,
      role,
      sign_up,
      onboarding_type,
    },
    chat,
    feed,
    landing,
    plan,
    profile,
    pricing,
    hyrox,
    choose_your_plan,
    membership,

    plan_type,
    programs,
    cover,
    webAddress,
    tables,
    notes,
    settings,
    validation_errors,

    update_modal: {
      title: 'New System Update',
      text: "The update requires a site reload to work correctly. Please save anything you're working on before reloading the site. Unsaved changes will be lost.",
      ok: 'Reload now',
      cancel: 'Cancel',
    },
  },
};
