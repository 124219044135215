import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'GenderControl',

  props: {
    /**
     * The value
     * @type {string}
     * @default ''
     */
    value: {
      type: String,
      default: '',
    },

    /**
     * is auto-save functionality is enabled
     * @type {boolean}
     * @default true
     */
    autoSave: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['isUserCoach']),
  },

  methods: {
    choiceGender(type) {
      if (!type) return;

      this.$emit('input', type);

      if (this.autoSave) {
        let action = this.isUserCoach
          ? 'updatePrivateProfile'
          : 'updateProfile';
        this.$store.dispatch({
          type: action,
          gender: type,
        });
      }
    },
  },
};
