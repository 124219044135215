import { HTTP } from '@/utils/http-common';
import { mapGetters } from 'vuex';

import WarningItem from '@/shared/app-header/system-warning/warning-item/index.vue';

// @vue/component
export default {
  name: 'SystemWarning',

  components: {
    WarningItem,
  },

  data() {
    return {
      warnings: [],
      // {
      //   type: 'missed-payment',
      //   data: [{ plan_id: 430 }, { plan_id: 434 }]
      // }
    };
  },

  computed: {
    hasWarnings() {
      return this.warnings.lentgh > 0;
    },

    isShowConfigureAddressWarming() {
      return (
        this.isSubscriptionUnlimited ||
        (this.isSubscriptionPro && !this.isWLTrial) ||
        (this.isAccountFree && !this.isWLTrial)
      );
    },

    isHideWarning() {
      let routes = [
        'create_plan_main_info',
        'create_plan_schedule',
        'create_plan_review',
        'coach_program_edit',
      ];
      return routes.includes(this.$route.name);
    },

    ...mapGetters(['isUserCoach', 'isFinancialAviable', 'stripeAccount']),
    ...mapGetters('subscription', [
      'isWLActive',
      'isSubscriptionUnlimited',
      'isSubscriptionPro',
      'isWLTrial',
      'isAccountFree',
    ]),
    ...mapGetters('color_scheme', ['siteSubdomainName', 'hasAnyValue']),
  },

  mounted() {
    HTTP.get('users/warnings').then(({ data }) => {
      this.warnings = this.warnings.concat(data);
    });

    if (
      this.isUserCoach &&
      this.isFinancialAviable &&
      this.stripeAccount.account
    ) {
      this.$watch(
        function () {
          return [
            this.stripeAccount.charges_enabled,
            this.stripeAccount.payouts_enabled,
          ].join();
        },
        function () {
          this.checkStripeVerificationNeeded();
        },
        { immediate: true }
      );
    }

    // Configure a custom web address
    if (this.isUserCoach) {
      this.$watch(
        function () {
          return [
            this.isWLActive,
            this.hasAnyValue,
            this.siteSubdomainName,
            this.isShowConfigureAddressWarming,
          ].join();
        },
        function () {
          this.checkConfigureWebAddressNeeded();
        },
        { immediate: true }
      );
    }
  },

  methods: {
    checkStripeVerificationNeeded() {
      if (this.isUserCoach && this.isFinancialAviable) {
        const type = 'coach-stripe-verification-needed';

        const index = this.warnings.findIndex((w) => w.type == type);

        if (
          !this.stripeAccount.charges_enabled ||
          (this.stripeAccount.charges_enabled &&
            !this.stripeAccount.payouts_enabled)
        ) {
          if (index == -1) {
            this.warnings.push({
              type,
            });
          }
        } else {
          if (index != -1) {
            this.warnings.splice(index, 1);
          }
        }
      }
    },

    checkConfigureWebAddressNeeded() {
      if (this.isUserCoach) {
        const type = 'coach-configure-web-address';

        const index = this.warnings.findIndex((w) => w.type == type);

        if (
          this.isWLActive &&
          this.hasAnyValue &&
          !this.siteSubdomainName &&
          this.isShowConfigureAddressWarming
        ) {
          if (index == -1) {
            this.warnings.push({
              type,
            });
          }
        } else {
          if (index != -1) {
            this.warnings.splice(index, 1);
          }
        }
      }
    },
  },
};
