import integer from 'vuelidate/lib/validators/integer';
import minValue from 'vuelidate/lib/validators/minValue';
import maxValue from 'vuelidate/lib/validators/maxValue';

// @vue/component
export default {
  name: 'PageDaterangeFilter',

  props: {
    /**
     * Filter data
     * @type {object}
     */
    filter: Object,

    /**
     * Value of v-model
     * @type {string|object}
     */
    value: [String, Object],
  },

  data() {
    return {
      selected: 'all',
      from: null,
      to: null,
    };
  },

  validations: {
    from: {
      integer,
      minValue: minValue(13),
      maxValue: maxValue(99),
      // Maximum call stack size exceeded
      // maxValue: (value, model) => {
      //   if (model.$v.to.$model && !model.$v.to.$error) {
      //     return value < model.$v.to.$model;
      //   } else {
      //     return value < 99;
      //   }
      // }
    },
    to: {
      integer,
      // minValue: minValue(13),
      minValue: (value, model) => {
        if (value) {
          if (model.$v.from.$model && !model.$v.from.$error) {
            return value > model.$v.from.$model;
          } else {
            return value > 13;
          }
        } else {
          return true;
        }
      },
      maxValue: maxValue(99),
    },
  },

  watch: {
    from: function (value) {
      if (value != null) {
        this.triggerChange();
      }
    },

    to: function (value) {
      if (value != null) {
        this.triggerChange();
      }
    },

    selected: function (value) {
      if (value == 'all') {
        this.from = null;
        this.to = null;
        this.triggerChange();
      }
    },
  },

  mounted() {
    if (this.value != 'all') {
      this.selected = 'range';

      this.from = this.value.from;
      this.to = this.value.to;
    }
  },

  methods: {
    triggerChange() {
      if (this.from || this.to) {
        this.selected = 'range';
      } else {
        this.selected = 'all';
      }

      if (!this.$v.$invalid) {
        let res;

        if (this.selected == 'all') {
          res = 'all';
        } else {
          res = {
            from: this.from,
            to: this.to,
          };
        }

        this.$emit('input', res);
      }
    },
  },
};
