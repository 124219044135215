export default {
  title: {
    team: 'PROVIDER SET UP',
    invited: 'JOIN A TEAM',
    coach: 'PROVIDER SET UP',
    client: 'CLIENT SIGN UP',
  },

  subtitle: {
    team: 'Create your team account and start your 14 day free trial.',
    invited_1:
      'If you’ve received a team invite, create your account below and we’ll direct you to join the team. ',
    invited_2:
      'There’s no cost for contributing to an existing team account and you’ll have the option to upgrade if you want to create your own standalone programming in the future.',
    coach: 'Create your account and start your 14 day free trial.',
    client:
      'Create your free account to follow programming and track your progress.',
  },

  important: {
    team: 'We need to take some personal details of the first coach under this account. They will have the main admin rights to the account.',
  },

  error: 'Name field must contain less than 50 characters and can’t contain any of the following characters: / | \\ + = @ : < > ? ;',
};
