export default {
  main_title_add: 'Add new Resource',
  main_title_upload: 'Upload new Resource',
  item: {
    are_you_sure_part_one: 'Are you sure you want to delete ',
    are_you_sure_part_two:
      '? If deleted, it will be removed from all places where it was previously used. This action cannot be undone.',
    yes: 'Yes, delete',
    no: 'Cancel',
  },
  lib: {
    files: {
      title: 'Upload new Resource',
      text: 'Upload a Resource from your device or paste and add a YouTube link.',
    },
    cover: {
      title: 'Upload new cover',
    },
    fitr: {
      no_files: 'There are no files',
    },
    links: {
      youtube_label: 'Add YouTube link',
      youtube_modal: 'Paste the YouTube URL here',
      placeholder: {
        title: 'Link to a YouTube video',
        text: 'Paste the YouTube video URL above and press enter',
      },
      youtube_btn: 'Add link',
    },
  },
};
