export default {
  templates: {
    exclusive_content:
      'This exclusive content is reserved for affiliated HYROX Gyms and Trainers.',
    click_to_learn_more: 'Click below to learn more.',
    learn_more: 'Learn about HYROX affiliation and its benefits.',
    already_affiliated: 'Already affiliated? Let us know via',
    live_chat: 'live chat.',
  },
};
