export default {
  title: 'SELECT ACCOUNT',
  subtitle: 'Are you offering programming as a standalone coach, or as a team?',
  links: {
    standalone:
      'Deliver your programming as a standalone coach. You can also join other teams at no extra cost.',
    team:
      'Create a team account to invite an unlimited number of coaches to join you in delivering your programming under a single identity.',
  },
};
