import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'AppFooter',

  computed: {
    footerCssClass() {
      return this.$route.meta.footerCssClass;
    },

    ...mapGetters(['isUserCoach']),
    ...mapGetters('color_scheme', ['siteLogo']),
  },

  methods: {
    openCookiehubDialog() {
      if (window.cookiehub?.openSettings) {
        window.cookiehub.openSettings();
      }
    },
  },
};
