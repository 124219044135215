import Store from '@/store/store.js';

const CoachProfile = () => import('./Layout.vue');
const CoachProfileInfo = () => import('./info/index.vue');

const CoachProfileSettings = () => import('./settings/index.vue');
const CoachProfileCoaches = () => import('./coaches/index.vue');
const CoachProfileSubscription = () => import('./subscriptions/index.vue');
const CoachProfileBilling = () => import('./billing/index.vue');

const BlockedPage = () => import('./blocked-page/index.vue');

export default [
  {
    path: 'profile',
    component: CoachProfile,
    beforeEnter: function (to, from, next) {
      if (Store.getters.isFinancialAviable) {
        next();
      } else {
        next({ name: '404', replace: true });
      }
    },

    children: [
      {
        path: '',
        redirect: { name: 'coach_profile_info' },
      },
      {
        path: 'info',
        component: CoachProfileInfo,
        name: 'coach_profile_info',

        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },
      },

      {
        path: 'payouts',
        component: () => {
          if (Store.getters['isCustomStripeAccount']) {
            return import('./payment/index.vue');
          } else {
            return import('./standard-account/index.vue');
          }
        },

        name: 'coach_profile_payment',

        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },

        beforeEnter: function (to, from, next) {
          if (Store.getters['subscription/isAccountEmpty']) {
            next({ name: 'coach_profile_info' });
          } else {
            next();
          }
        },
      },
      {
        path: 'settings',
        component: CoachProfileSettings,
        name: 'coach_profile_settings',

        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },
      },
      {
        path: 'coaches',
        component: CoachProfileCoaches,
        name: 'coach_profile_coaches',
        meta: {
          disableScrollBehavior: true,
          footerCssClass: 'footer-with-fixed-btn',
        },
      },
      {
        path: 'subscription',
        component: CoachProfileSubscription,
        name: 'coach_profile_subscription',
        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },

        beforeEnter: (to, from, next) => {
          if (
            (Store.getters['subscription/isAccountFree'] &&
              Store.getters['subscription/isWLPaindAndActive']) ||
            Store.getters['subscription/hasSubscription']
          ) {
            next();
          } else {
            next({ name: 'choose_your_plan', replace: true });
          }
        },
      },
      {
        path: 'billing',
        component: CoachProfileBilling,
        name: 'coach_profile_billing',
        meta: {
          availableForBlocked: true,
        },

        // beforeEnter: (to, from, next) => {
        //   if (
        //     Store.getters['subscription/isAccountFree'] ||
        //     !Store.getters['subscription/hasSubscription'] ||
        //     !Store.getters.isFinancialAviable
        //   ) {
        //     next({ name: '404', replace: true });
        //   } else {
        //     next();
        //   }
        // },
      },
    ],
  },
  {
    path: '/upgrade-your-account',
    component: BlockedPage,
    name: 'coach_blocked',

    meta: {
      availableForBlocked: true,
    },

    beforeEnter: (to, from, next) => {
      if (Store.getters['subscription/isAccountFree']) {
        next({ name: '404', replace: true });
      } else {
        next();
      }
    },
  },
];
