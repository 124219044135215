import { subscribe, unsubscribe } from '@/utils/actioncable';
import { mapGetters, mapActions } from 'vuex';

// @vue/component
export default {
  name: 'ReleaseNotesCounter',

  data() {
    return {};
  },

  computed: {
    ...mapGetters('release_notes', ['notesCounter']),
  },

  created: function () {
    this.channel = subscribe('SystemNotificationChannel', (message) => {
      if (
        message.type == 'has_unread_release_notes' ||
        message.type == 'new_release_note'
      ) {
        if (message.data?.count_unread_notes) {
          this.changeCounter(message.data.count_unread_notes);
        }
      }
    });
  },

  beforeDestroy: function () {
    unsubscribe(this.channel);
  },

  methods: {
    ...mapActions('release_notes', ['changeCounter']),
  },
};
