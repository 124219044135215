import { debounce } from 'lodash-es';
import { subscribe, unsubscribe } from '@/utils/actioncable';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import { mapActions, mapGetters } from 'vuex';

import NoteItem from '@/components/release-notes/note-item/index.vue';

// @vue/component
export default {
  name: 'ModalReleseNotes',

  components: {
    NoteItem,
  },

  props: {
    /**
     * ID of notes
     * @type {number | string}
     * @default ''
     */
    notesId: {
      type: [Number, String],
      default: '',
    },
  },

  data() {
    return {
      note: {},
      isBusy: false,
    };
  },

  computed: {
    title() {
      return "What's New";
    },

    ...mapGetters('release_notes', ['notesCounter']),
  },

  created: function () {
    this.channel = subscribe('SystemNotificationChannel', (message) => {
      if (
        message.type == 'has_unread_release_notes' ||
        message.type == 'new_release_note'
      ) {
        if (message.data.release_note_id) {
          this.show(message.data.release_note_id);
        }

        if (message.data?.count_unread_notes) {
          this.changeCounter(message.data.count_unread_notes);
        } else {
          this.changeCounter(this.notesCounter + 1);
        }
      }
    });
  },

  beforeDestroy: function () {
    unsubscribe(this.channel);
  },

  methods: {
    hide() {
      this.$refs.releseNotesModal.hide();
    },

    show: debounce(function (id) {
      HTTP.get(`notes/${id}`)
        .then(({ data }) => {
          this.note = data;

          this.$refs.releseNotesModal.show();
        })
        .catch(HttpErrorsHandling);
    }, 1000),

    markRead() {
      this.isBusy = true;

      HTTP.post('notes/read', { id: this.note.id })
        .then(() => {
          this.note.read = true;
          this.changeCounter(this.notesCounter - 1);

          this.isBusy = false;

          if (this.$route.name == 'release-notes') {
            this.$root.$emit('mark-release-note-as-read', {
              id: this.note.id,
            });
          }

          this.hide();
        })
        .catch(HttpErrorsHandling);
    },

    handleModalHidden() {
      if (!this.note.read) {
        this.markRead();
      }
    },

    ...mapActions('release_notes', ['changeCounter']),
  },
};
