import DatePicker from 'vue2-datepicker';

const { CalendarPanel } = DatePicker;

/**
 * @param {Date | any} date - checking is it date instance
 * @returns {boolean} - result of checking
 */
function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

/**
 * @param {[Date] | any} date - date that would be checked
 * @returns {boolean} - result of checking
 */
function isValidRangeDate(date) {
  return (
    Array.isArray(date) &&
    date.length === 2 &&
    isValidDate(date[0]) &&
    isValidDate(date[1]) &&
    date[1].getTime() >= date[0].getTime()
  );
}

// @vue/component
export default {
  components: {
    DatePicker,
    CalendarPanel,
  },
  props: {
    // TODO: fix props type
    // eslint-disable-next-line
    value: {},
  },
  data() {
    return {
      innerValue: [new Date(NaN), new Date(NaN)],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (isValidRangeDate(val)) {
          this.innerValue = val;
        } else {
          this.innerValue = [new Date(NaN), new Date(NaN)];
        }
      },
    },
  },
  methods: {
    handleSelectStart(date) {
      this.innerValue = [date, this.innerValue[1]];

      if (isValidRangeDate(this.innerValue)) {
        this.$emit('select', this.innerValue, true);
      }
    },
    handleSelectEnd(date) {
      this.innerValue = [this.innerValue[0], date];

      if (isValidRangeDate(this.innerValue)) {
        this.$emit('select', this.innerValue, true);
      }
    },
    getClasses(cellDate, currentDates, classes) {
      if (
        !/disabled|active|not-current-month/.test(classes) &&
        cellDate >= this.innerValue[0] &&
        cellDate <= this.innerValue[1]
      ) {
        return 'in-range';
      }
      return '';
    },
  },
};
