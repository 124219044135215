import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

import { mapGetters, mapActions } from 'vuex';

// @vue/component
export default {
  name: 'ReleaseNoetsItem',

  props: {
    /**
     * Note Object
     * @type {object}
     */
    note: {
      type: Object,
    },

    /**
     * Is short version
     * @type {boolean}
     * @default false
     */
    short: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isBusy: false,
    };
  },

  computed: {
    releaseDate() {
      return this.$moment(this.note.release_date, 'DD/MM/YYYY').format(
        'DD MMMM, YYYY'
      );
    },

    canRead() {
      return this.note?.can_read;
    },

    ...mapGetters('release_notes', ['notesCounter']),
  },

  methods: {
    markRead() {
      this.isBusy = true;

      HTTP.post('notes/read', { id: this.note.id })
        .then(() => {
          // TODO: fix props mutation
          // eslint-disable-next-line vue/no-mutating-props
          this.note.read = true;

          this.$emit('changeUnreadCount');

          this.changeCounter(this.notesCounter - 1);

          this.isBusy = false;
        })
        .catch(HttpErrorsHandling);
    },

    hidePopup() {
      this.$emit('hidePopup');
    },

    ...mapActions('release_notes', ['changeCounter']),
  },

  components: {},
};
