<template>
  <div
    v-if="image"
    class="file-view"
    v-bind="$attrs"
    :class="{ 'file-view_loading': loading, disabled: disabled }"
  >
    <div v-if="loading" class="loading" />
    <img :src="image" />
    <div class="actions">
      <template v-if="!hideChangeButton">
        <div
          v-if="file"
          class="btn btn-primary btn-round btn-white-border btn-xs"
          :class="{ disabled: disabled }"
        >
          <input
            v-if="!disabled"
            key="change"
            type="file"
            :accept="uploadFormat"
            @change="onFileChange"
          />
          <SvgIcon name="editIcon" />
        </div>

        <div
          v-else
          class="btn btn-primary btn-round btn-white-border btn-xs"
          :class="{ disabled: disabled, 'mr-0': hideRemoveButton }"
          @click="edit"
        >
          <SvgIcon name="editIcon" />
        </div>
      </template>

      <template v-if="!hideRemoveButton">
        <button
          type="button"
          class="btn btn-danger btn-round btn-white-border btn-xs"
          :disabled="disabled"
          @click="remove"
        >
          <SvgIcon name="trashIcon" />
        </button>
      </template>
    </div>
  </div>
  <div
    v-else
    class="btn btn-success btn-file-input"
    :class="{ 'btn-file-input_loading': loading, disabled: disabled }"
    v-bind="$attrs"
    @click="onClick"
  >
    <input
      v-if="file && !disabled"
      key="create"
      type="file"
      :accept="uploadFormat"
      @change="onFileChange"
    />
    <span class="has-icon">
      <span class="has-icon__icon">
        <SvgIcon v-if="loading" name="loadingIcon"></SvgIcon>
        <SvgIcon v-else name="imageIcon"></SvgIcon>
      </span>
      <span class="has-icon__text"><slot>Upload</slot></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UploadButton',

  props: {
    /**
     * Uploaded image
     */
    image: {
      type: String,
      default: null,
    },

    /**
     * disabled state
     */
    disabled: {
      type: Boolean,

      dafaul: false,
    },

    /**
     * show loading state
     */
    loading: {
      type: Boolean,

      dafaul: false,
    },

    /**
     * button must have input file
     */
    file: {
      type: Boolean,

      dafaul: true,
    },

    /**
     * hide change image btn
     */
    hideChangeButton: {
      type: Boolean,

      default: false,
    },

    /**
     * hide delete image btn
     */
    hideRemoveButton: {
      type: Boolean,

      default: false,
    },

    /**
     * formats for download files
     */
    formats: {
      type: [String, Array],
      default: '*',
    },
  },

  computed: {
    uploadFormat() {
      let formats;

      if (Array.isArray(this.formats)) {
        formats = this.formats.map((format) => {
          return '.' + format;
        });
      } else if (this.formats == '*') {
        formats = this.formats;
      } else {
        formats = '.' + this.formats;
      }

      return formats;
    },
  },

  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      this.$emit('change', files[0]);
    },

    onClick() {
      if (!this.file) {
        this.$emit('change');
      }
    },

    remove() {
      this.$emit('remove');
    },

    edit() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn.btn-file-input {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.53rem;
  border-style: dashed;
  border-radius: $border-radius-lg;
  line-height: 1.2;

  .has-icon {
    display: block;
    text-align: center;

    .has-icon__icon {
      position: static;

      .svg-icon {
        width: 40px !important;
        height: 40px !important;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .has-icon__text {
      padding: 0;
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      line-height: 1;
    }
  }

  // Color themes
  &.btn-success {
    border-color: var(--secondary-text-color);

    &:not(:disabled, .disabled) {
      &:hover {
        border-color: var(--secondary-text-color);
      }

      &:focus,
      &.focus,
      &:focus-within {
        border-color: var(--secondary-text-color);
      }
    }

    &:disabled,
    &.disabled {
      border-color: $gray-700;
    }
  }

  // States
  &_loading {
    .svg-icon {
      animation: rotation 1s infinite linear;
    }
  }
}

.file-view {
  position: relative;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-400;
    border-radius: $border-radius-lg;
    object-fit: cover;
    object-position: center;
  }

  .actions {
    position: absolute;
    top: -10px;
    right: -9px;

    .btn.btn-primary {
      margin-right: 0.5rem;
    }
  }

  &_loading {
    pointer-events: none;

    img {
      opacity: 0.1;
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.file-view .btn.btn-primary,
.btn.btn-file-input {
  position: relative;

  &:not(.disabled) {
    cursor: pointer;

    svg {
      cursor: pointer;
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
