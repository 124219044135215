const Layout = () => import('./Layout.vue');
const List = () => import('./list/index.vue');
const AllPlans = () => import('./list/all-plans/index.vue');
const FormLayout = () => import('./form/Layout.vue');
const FormMainInfo = () => import('./form/main_info/index.vue');
const FormSignUpClients = () => import('./form/sign-up-clients/index.vue');
const FormSchedule = () => import('./form/schedule/index.vue');
const FormCalendar = () => import('./form/calendar/index.vue');
const ProgramEdit = () => import('./v2/edit/index.vue');
const ProgramView = () => import('./v2/view/index.vue');
const ProgramSchedule = () => import('./schedule/index.vue');
const ProgramViewDetails = () => import('./v2/view/details/index.vue');
const ProgramViewClients = () => import('./v2/view/clients/index.vue');
const ProgramViewSettings = () => import('./v2/view/settings/index.vue');
const ProgramViewWaiver = () => import('./v2/view/waiver/index.vue');

export default [
  {
    path: 'programs',
    component: Layout,

    children: [
      {
        path: '',
        component: List,
        name: 'my_plans',
        redirect: { name: 'my_plans_active' },
        meta: {
          myPlans: true,
          availableForBlocked: true,
        },

        children: [
          {
            path: '',
            redirect: { name: 'my_plans_active' },
          },

          {
            path: 'active',
            component: AllPlans,
            name: 'my_plans_active',
            meta: {
              plansType: 'active',
              availableForBlocked: true,
              footerCssClass: 'footer-with-fixed-btn',
            },
          },

          {
            path: 'active/ready/',
            component: AllPlans,
            name: 'my_plans_active_with_welcome_popup',
            meta: {
              plansType: 'active',
              showWelcomePopup: true,
              footerCssClass: 'footer-with-fixed-btn',
            },
          },

          {
            path: 'attention',
            component: AllPlans,
            name: 'my_plans_attention',
            meta: {
              plansType: 'attention',
              availableForBlocked: true,
              footerCssClass: 'footer-with-fixed-btn',
            },
          },
        ],
      },

      {
        path: 'create',
        component: FormLayout,

        children: [
          {
            path: '',
            name: 'create_plan',
            redirect: { name: 'create_plan_main_info' },
          },
          {
            path: 'choose-plan-type',
            name: 'choose_plan_type',
            redirect: {
              name: 'my_plans_active',
              params: { choose_program_type: true },
            },
          },
          {
            path: 'main',
            name: 'create_plan_main_info',
            component: FormMainInfo,
            meta: { hideFooter: true, knowledge_type: 'program_creation' },
          },
        ],
      },

      {
        path: 'edit/:plan_id',
        component: FormLayout,
        children: [
          {
            path: '',
            name: 'edit_plan',
            redirect: { name: 'edit_plan_main_info' },
            meta: {
              hideFooter: true,
              availableForBlocked: true,
            },
          },
          {
            path: 'main',
            name: 'edit_plan_main_info',
            component: FormMainInfo,
            meta: {
              hideFooter: true,
              availableForBlocked: true,
            },
          },

          {
            path: 'sign-up-clients',
            name: 'create_sign_up_clients',
            component: FormSignUpClients,
            meta: {
              hideFooter: true,
              availableForBlocked: true,
            },
          },

          {
            path: 'schedule',
            name: 'edit_plan_schedule',
            component: FormSchedule,
            meta: {
              hideFooter: true,
              availableForBlocked: true,
              bodyCssClass: 'theme_white',
            },
          },

          {
            path: 'calendar/:user_id',
            name: 'edit_plan_calendar',
            component: FormCalendar,
            meta: {
              hideFooter: true,
              availableForBlocked: true,
              bodyCssClass: 'theme_white',
            },
          },
        ],
      },

      {
        path: ':plan_id',
        component: ProgramView,
        props: true,

        children: [
          {
            path: '',
            name: 'coach_program_view',
            redirect: { name: 'coach_program_view_clients' },
          },
          {
            path: 'details',
            name: 'coach_program_view_details',
            component: ProgramViewDetails,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
          },
          {
            path: 'clients',
            name: 'coach_program_view_clients',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
            props: { listType: 'active' },
          },

          {
            path: 'in-trials',
            name: 'coach_program_view_in_trials',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
            props: { listType: 'in_trial' },
          },

          {
            path: 'scheduled',
            name: 'coach_program_view_scheduled',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
            props: { listType: 'scheduled' },
          },

          {
            path: 'invites',
            name: 'coach_program_view_invites',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
            props: { listType: 'pending' },
          },

          {
            path: 'past',
            name: 'coach_program_view_past',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
            props: { listType: 'past' },
          },

          {
            path: 'settings',
            name: 'coach_program_view_settings',
            component: ProgramViewSettings,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
          },

          {
            path: 'waiver',
            name: 'coach_program_view_waiver',
            component: ProgramViewWaiver,
            meta: {
              disableScrollBehavior: true,
              availableForBlocked: true,
            },
          },
        ],
      },
      {
        path: ':plan_id/edit',
        component: ProgramEdit,
        name: 'coach_program_edit',
        props: true,
        meta: {
          availableForBlocked: true,
        },
      },

      {
        path: ':plan_id/schedule',
        name: 'coach_program_schedule',
        component: ProgramSchedule,
        props: true,
        meta: {
          hideFooter: true,
          availableForBlocked: true,
          bodyCssClass: 'theme_white',
        },
      },
    ],
  },
];
