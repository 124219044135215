// @vue/component
export default {
  name: 'ModalResume',

  methods: {
    showModal() {
      this.$refs.resumeSubscribe.show();
    },

    hideModal() {
      this.$refs.resumeSubscribe.hide();
    },

    startFrom(resume_type) {
      this.$emit('startFrom', resume_type);
      this.hideModal();
    },
  },
};
