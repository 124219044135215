import { mapGetters } from 'vuex';
import LibraryFiles from '@/shared/media-library/library-files/index.vue';
import LibraryFitr from '@/shared/media-library/library-fitr/index.vue';

import PageSearch from '@/shared/page-search/index.vue';
import { PortalTarget } from 'portal-vue';
import PageFilter from '@/shared/page-filter/index.vue';

import vueDropzone from 'vue2-dropzone';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// @vue/component
export default {
  name: 'MediaLibrary',

  components: {
    LibraryFiles,
    LibraryFitr,
    PageSearch,
    vueDropzone,
    PortalTarget,
    PageFilter,
  },

  props: {
    /**
     * Attachments
     * @type {(Array|object)} - type array or object
     * @default [] - default empty array
     */
    attachments: {
      type: [Array, Object],
      default: function () {
        return [];
      },
    },

    /**
     * Multiple
     * @type {boolean} - type boolean
     * @default true - default true
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * Forbidden Types
     * @type {Array} - type array
     * @default [] - default empty array
     */
    forbiddenTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**
     * Only Upload
     * @type {boolean} - type boolean
     * @default false - default false
     */
    onlyUpload: {
      type: Boolean,
      default: false,
    },

    /**
     * In Hyrox Template Mode my files tab will be hidden
     * @type {boolean} - type boolean
     * @default false - default false
     */
    isHyroxTemplate: {
      type: Boolean,
      default: false,
    },

    /**
     * Limit to add resources
     * @type {(number|string)}
     * @default 50
     */
    resourcesLimit: {
      type: [Number, String],
      default: 50,
    },
  },

  data() {
    return {
      currentTab: 'files',
      isModalShowed: true,
      isChanged: false,
      searchString: '',
      selectedMedia: this.attachments,
      isTabBusy: false,
      hasAccessHyrox: true,

      isReady: false,

      isShowCurrentTab: false,
      isShowHyroxTab: false,
      isShowFitrTab: false,
      isLibraryUploaded: false,

      filesFilters: {
        type: {
          label: '',
          value: [],
          default: [],
          type: 'multiple',
          options: [
            {
              text: 'Video',
              value: 'video',
            },
            {
              text: 'YouTube',
              value: 'youtube',
            },
            {
              text: 'Image',
              value: 'image',
            },
            {
              text: 'Files',
              value: 'other',
            },
          ],
        },
      },
    };
  },

  computed: {
    isShowText() {
      return (
        this.currentTab === 'files' &&
        this.hasHyrox &&
        this.$route.name == 'challenges-library' &&
        this.isLibraryUploaded
      );
    },

    isSubmitAviable: function () {
      if (this.multiple) {
        return this.selectedMedia.length > 0;
      } else {
        return this.selectedMedia.id != undefined;
      }
    },

    currentOwner: function () {
      if (this.currentTab == 'fitr') {
        return 'fitr';
      } else if (this.currentTab == 'hyrox') {
        return 'hyrox';
      } else {
        return 'current';
      }
    },

    currentLibrary() {
      if (this.currentTab == 'fitr') {
        return 'fitr';
      } else {
        return 'files';
      }
    },

    resourcesLimitExceeded() {
      return (
        !this.onlyUpload && this.selectedMedia.length > this.resourcesLimit
      );
    },

    ...mapGetters([
      'isUserCoach',
      'isAccountOwner',
      'currentUserTitle',
      'hasHyrox',
    ]),
  },

  watch: {
    selectedMedia: function () {
      this.isChanged = true;
    },
    currentTab: function () {
      this.isLibraryUploaded = false;
      window.localStorage.setItem('addMediaActiveTab', this.currentTab);
    },
  },

  mounted() {
    if (this.onlyUpload) {
      this.isShowFitrTab = false;
      this.isShowCurrentTab = true;
      this.isShowHyroxTab = this.isAccountOwner && this.hasHyrox;

      if (window.localStorage.getItem('addMediaActiveTab')?.length) {
        let localActiveTab = window.localStorage.getItem('addMediaActiveTab');

        if (
          localActiveTab == 'fitr' ||
          (localActiveTab == 'hyrox' && !this.isShowHyroxTab)
        ) {
          this.currentTab = 'files';
          window.localStorage.setItem('addMediaActiveTab', this.currentTab);
        } else {
          this.currentTab = localActiveTab;
        }
      }

      this.isReady = true;
    } else {
      this.isShowCurrentTab = true;
      this.isShowFitrTab = this.isUserCoach;

      if (this.isUserCoach) {
        let params = {
          owner: 'hyrox',
          page: 1,
          per_page: 1,
        };

        HTTP.get('media/fetch', {
          params,
        })
          .then(({ data }) => {
            this.isShowHyroxTab = data.hyrox_access;

            if (this.isShowHyroxTab && this.isHyroxTemplate) {
              const activeTab =
                window.localStorage.getItem('addMediaActiveTab');

              this.isShowCurrentTab = false;
              if (activeTab == 'files' || !activeTab) {
                this.currentTab = 'hyrox';
              }
            }
          })
          .catch((error) => {
            HttpErrorsHandling(error);
          })
          .finally(() => {
            this.check();
          });
      } else {
        this.check();
      }
    }
  },

  methods: {
    check() {
      if (window.localStorage.getItem('addMediaActiveTab')?.length) {
        let localActiveTab = window.localStorage.getItem('addMediaActiveTab');

        if (
          (localActiveTab == 'fitr' && !this.isUserCoach) ||
          (localActiveTab == 'hyrox' && !this.isShowHyroxTab)
        ) {
          this.currentTab = 'files';
          window.localStorage.setItem('addMediaActiveTab', this.currentTab);
        } else {
          this.currentTab = localActiveTab;
        }
      }

      this.isReady = true;
    },

    checkHyroxAccess(hasAccess) {
      this.isLibraryUploaded = true;
      this.isShowHyroxTab = hasAccess;
    },

    hideModal() {
      this.$refs.filesModal.hide();
    },

    handleModalHide(e) {
      if (this.isChanged) {
        if (
          !window.confirm(
            'There are unsaved changes. Do you want to leave anyway?'
          )
        ) {
          e.preventDefault();
        } else {
          this.selectedMedia = this.attachments;
          this.isChanged = false;
        }
      }
    },

    handleModalHidden() {
      this.$emit('close');
    },

    handleModalShown() {
      this.$root.$emit('bv::hide::tooltip');
      this.$root.$emit('benchmarks::hide_ui');
    },

    setMedia() {
      if (!this.resourcesLimitExceeded) {
        this.isChanged = false;

        this.$emit('add', this.selectedMedia);

        this.isModalShowed = false;
      }
    },

    switchTab(tab) {
      this.currentTab = tab;
    },

    handleTabBusy(isBusy) {
      this.isTabBusy = isBusy;
    },
  },
};
