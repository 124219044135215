<template>
  <b-overlay :show="show" rounded="sm" no-center :bg-color="bgColor">
    <slot></slot>
    <template #overlay><div class="loading mt-5" /></template>
  </b-overlay>
</template>

<script>
export default {
  name: 'OverlayLoader',

  props: {
    /**
     * show loading state
     */
    show: {
      type: Boolean,

      dafaul: false,
    },

    /**
     * Background color of the component
     * @type {string}
     * @default '#fff'
     */
    bgColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
