const Settings = () => import('./layout/index.vue');
const SettingsDefaultUnits = () => import('./default-units/index.vue');
const SettingsPrivacy = () => import('./privacy/index.vue');

const SettingsEmails = () =>
  import('@/components/profile/coach/profile/settings/index.vue');

export default [
  {
    path: 'settings',
    component: Settings,

    children: [
      {
        path: '',
        redirect: { name: 'settings_default_units' },
      },

      {
        path: 'default-units',
        component: SettingsDefaultUnits,
        name: 'settings_default_units',
        meta: { disableScrollBehavior: true },
      },
      {
        path: 'emails',
        component: SettingsEmails,
        name: 'settings_emails',
        meta: { disableScrollBehavior: true },
      },
      {
        path: 'privacy',
        component: SettingsPrivacy,
        name: 'settings_privacy',
        meta: { disableScrollBehavior: true },
      },
    ],
  },
];
