<template>
  <!-- <transition name="fade"> -->
  <div
    v-if="swlTrialWarning"
    v-show="!isWarningHidden"
    class="alert alert-sm alert-danger rounded-0 m-0 pl-0 pr-0"
    role="alert"
  >
    <div class="container">
      <div class="alert-body justify-content-start">
        <div class="alert-body__icon">
          <SvgIcon name="alertStrokeIcon" />
        </div>
        <div class="alert-body__text">
          We can see you’re using the White Label features, that’s awesome!<br />
          Please remember to choose Unlimited plan when you upgrade if you want
          to continue using it after your trial.
          <router-link class="alert-link" :to="{ name: 'choose_your_plan' }"
            >Click here</router-link
          >
          to upgrade.
        </div>
        <div class="alert-body__close">
          <button
            type="button"
            class="btn btn-icon"
            data-dismiss="alert"
            aria-label="Close"
            @click="hideSwlTrialWarning"
          >
            <SvgIcon name="closeIcon" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const SwlTrialWarningName = 'swl-trial-warning';

export default {
  data() {
    return {
      // SWL trial warning
      swlTrialWarning: false,
    };
  },

  computed: {
    isTrial() {
      return (
        !this.isAccountFree &&
        this.isAccountActive &&
        this.subscriptionData?.trial &&
        !(this.isSubscriptionUnlimited || this.hasWhiteLabel)
      );
    },

    warningTrigger() {
      return [this.hasAnyValue, this.isTrial].join();
    },

    isWarningHidden() {
      return this.$route.name == 'coach_profile_my_accounts';
    },

    ...mapGetters('color_scheme', ['hasAnyValue']),
    ...mapGetters('subscription', [
      'isAccountFree',
      'isSubscriptionUnlimited',
      'isSubscriptionPro',
      'hasWhiteLabel',
      'subscriptionData',
      'isAccountActive',
      'isAccountFree',
    ]),
    ...mapGetters(['viewedPopups']),
  },

  created: function () {
    if (this.isTrial && !this.viewedPopups?.includes(SwlTrialWarningName)) {
      this.changesUnwatch = this.$watch(
        'warningTrigger',
        () => {
          this.showSwlTrialWarning();
        },
        { immediate: true }
      );
    }
  },

  methods: {
    showSwlTrialWarning() {
      if (this.hasAnyValue) {
        this.swlTrialWarning = this.isTrial;

        // if (this.changesUnwatch) {
        //   this.changesUnwatch();
        // }
      }
    },

    hideSwlTrialWarning() {
      this.swlTrialWarning = false;

      this.makePopupViewed({ name: SwlTrialWarningName });
    },

    ...mapActions(['makePopupViewed']),
  },
};
</script>
