export default {
  title: 'Create account or Log in',
  subtitle:
    "With a FITR client account you can follow programs from any coach that uses the platform. You don't need a new account per coach or program.",
  link: {
    create: {
      title: 'Create a new client account',
      text: "I've never followed programming sent by any coach on FITR.",
    },
    login: {
      title: 'Log in',
      text: 'I already have an account I can use to follow this program with.',
    },
  },
};
