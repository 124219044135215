const Layout = () => import('./layout/index.vue');
const UserPlans = () => import('./programs/index.vue');

export default [
  {
    path: 'my_programs',
    component: Layout,

    children: [
      {
        path: '',
        redirect: { name: 'user_my_plans' },
      },
      {
        path: 'active',
        component: UserPlans,
        name: 'user_my_plans',
        meta: {
          programsType: 'active',
        },
      },
      {
        path: 'past',
        component: UserPlans,
        name: 'user_my_past_plans',
        meta: {
          programsType: 'past',
        },
      },
      {
        path: 'invites',
        component: UserPlans,
        name: 'user_my_invites_plans',
        meta: {
          programsType: 'invites',
        },
      },
    ],
  },
];
