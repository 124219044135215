import Store from '@/store/store.js';

const CoachDashboard = () => import('./layout/index.vue');
const CoachDashboardTransactions = () => import('./transactions/index.vue');
const CoachDashboardPayouts = () => import('./payouts/index.vue');

export default [
  {
    path: 'dashboard',
    component: CoachDashboard,
    beforeEnter: function (to, from, next) {
      if (
        Store.getters.isFinancialAviable &&
        Store.getters.isCustomStripeAccount
      ) {
        next();
      } else {
        next({ name: '404', replace: true });
      }
    },

    children: [
      {
        path: '',
        name: 'coach_dashboard',
        redirect: { name: 'coach_dashboard_transactions' },
      },
      {
        path: 'transactions',
        component: CoachDashboardTransactions,
        name: 'coach_dashboard_transactions',
        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },
      },
      {
        path: 'payouts',
        component: CoachDashboardPayouts,
        name: 'coach_dashboard_payouts',
        meta: {
          disableScrollBehavior: true,
          availableForBlocked: true,
        },
      },
    ],
  },
];
