import { mapGetters, mapActions } from 'vuex';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// @vue/component
export default {
  name: 'SystemWarningItem',

  props: {
    /**
     * Warning data
     * @type {object}
     */
    warning: Object,
  },

  data() {
    return {
      isBusy: false,
      isShow: true,
    };
  },

  computed: {
    linkRoute() {
      if (this.warning.type == 'missed-payment') {
        if (this.warning.data && this.warning.data.length == 1) {
          return {
            name: 'public_plan',
            params: { plan_id: this.warning.data[0].plan_id.toString() },
          };
        } else {
          return { name: 'billing_history' };
        }
      }

      return null;
    },

    isWarningHidden() {
      if (this.$route.name == 'conversation') {
        return true;
      } else if (this.warning.type == 'missed-payment') {
        if (this.$route.name == 'billing_history') {
          return true;
        }

        if (this.warning.data) {
          return (
            this.$route.name == 'public_plan' &&
            (this.warning.data
              .map((d) => d.plan_id.toString())
              .includes(this.$route.params.plan_id.toString()) ||
              this.warning.data
                .map((d) => d.custom_url)
                .includes(this.$route.params.plan_id.toString()))
          );
        }
      } else if (
        this.warning.type == 'coach-app-icon-was-declined' &&
        this.$route.name == 'customisation-app-icon'
      ) {
        return true;
      } else if (
        this.warning.type == 'coach-configure-web-address' &&
        this.$route.name == 'zapier-integration'
      ) {
        return true;
      }

      return null;
    },

    isWarningClosable() {
      return this.warning.type == 'coach-configure-web-address';
    },

    ...mapGetters([
      'stripeAccount',
      'isFinancialAviable',
      'isStandardStripeAccount',
      'viewedPopups',
    ]),
  },

  created: function () {
    if (
      this.isWarningClosable &&
      this.viewedPopups?.includes(this.warning.type)
    ) {
      this.isShow = false;
    }
  },

  methods: {
    connect() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      HTTP.get('stripe/onboarding_url', {
        params: {
          source: 'settings',
        },
      })
        .then(({ data }) => {
          document.location.assign(data.url);
        })
        .catch((e) => {
          HttpErrorsHandling(e);

          this.isBusy = false;
        });
    },

    hideWarning() {
      if (this.isWarningClosable) {
        this.isShow = false;

        this.makePopupViewed({ name: this.warning.type });
      }
    },

    ...mapActions(['makePopupViewed']),
  },
};
