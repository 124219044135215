const ActivityTypes = {
  financial: 'financial',
  content_warnings: 'content_warnings',
  client_activity: 'client_activity',
  invite: 'invite',
  coach_membership: 'coach_membership',
  chat_mention: 'chat_mention',
};

const NotificationTypes = {
  // coach - free plans
  coach_new_free_one_many_client: 'coach_new_free_one_many_client',
  coach_new_free_bespoke_client: 'coach_new_free_bespoke_client',
  coach_new_free_automated_client: 'coach_new_free_automated_client',
  coach_new_free_fixed_client: 'coach_new_free_fixed_client',

  // coach - financial
  coach_new_one_many_client: 'coach_new_one_many_client',
  coach_new_bespoke_client: 'coach_new_bespoke_client',
  coach_new_automated_client: 'coach_new_automated_client',
  coach_new_fixed_client: 'coach_new_fixed_client',

  coach_new_one_many_client_with_coupon:
    'coach_new_one_many_client_with_coupon',
  coach_new_bespoke_client_with_coupon: 'coach_new_bespoke_client_with_coupon',
  coach_new_automated_client_with_coupon:
    'coach_new_automated_client_with_coupon',
  coach_downloaded_fixed_with_coupon: 'coach_downloaded_fixed_with_coupon',

  coach_client_start_trialing: 'coach_client_start_trialing',

  coach_client_new_subscribe_end_of_trial_wo_coupon:
    'coach_client_new_subscribe_end_of_trial_wo_coupon',
  coach_client_new_subscribe_end_of_trial_with_coupon:
    'coach_client_new_subscribe_end_of_trial_with_coupon',

  coach_client_canceled_subscription_per_trial:
    'coach_client_canceled_subscription_per_trial',

  coach_client_unsubscribed: 'coach_client_unsubscribed',
  coach_client_not_pay: 'coach_client_not_pay',
  coach_blocked_client: 'coach_blocked_client',

  coach_fitr_charge: 'coach_fitr_charge',
  coach_bank_payout: 'coach_bank_payout',

  coach_new_automated_payment: 'coach_new_automated_payment',
  coach_new_bespoke_payment: 'coach_new_bespoke_payment',
  coach_new_one_many_payment: 'coach_new_one_many_payment',

  coach_payment_method_declined: 'coach_payment_method_declined',
  coach_warning_stripe_account: 'coach_warning_stripe_account',

  coach_payment_refunded: 'coach_payment_refunded',

  coach_restored_subscription_notice: 'coach_restored_subscription_notice',

  coach_subscribe_wl: 'coach_subscribe_wl',
  coach_unsubscribe_wl: 'coach_unsubscribe_wl',

  coach_new_scheduled_grc: 'coach_new_scheduled_grc',
  coach_new_scheduled_s1d1: 'coach_new_scheduled_s1d1',

  // coach - content warnings
  coach_client_ending_fixed_plan: 'coach_client_ending_fixed_plan',
  coach_client_ended_fixed_plan: 'coach_client_ended_fixed_plan',

  coach_danger_automated_plan: 'coach_danger_automated_plan',

  coach_danger_bespoke_plan: 'coach_danger_bespoke_plan',

  coach_danger_one_many_plan: 'coach_danger_one_many_plan',
  coach_deleted_program_price_on_stripe: 'coach_deleted_program_price_on_stripe',

  // coach - client activity
  coach_new_performance: 'coach_new_performance',
  coach_new_performance_no_title: 'coach_new_performance_no_title',

  coach_new_comment: 'coach_new_comment',
  coach_new_benchmark_comment: 'coach_new_benchmark_comment',
  coach_new_body_metric_comment: 'coach_new_body_metric_comment',
  coach_new_challenge_comment: 'coach_new_challenge_comment',

  // coach - membership
  coach_subscribe_canceled: 'coach_subscribe_canceled',
  coach_subscribe_payment_fail: 'coach_subscribe_payment_fail',
  coach_end_trial_period: 'coach_end_trial_period',

  // coach - Mentions
  coach_leaderboard_chat_mention: 'coach_leaderboard_chat_mention',
  coach_community_chat_mention: 'coach_community_chat_mention',

  // client - free plans
  client_new_free_one_many: 'client_new_free_one_many',
  client_new_free_bespoke: 'client_new_free_bespoke',
  client_new_free_automated: 'client_new_free_automated',
  client_new_free_fixed: 'client_new_free_fixed',

  // client - financial
  client_new_one_many_payment: 'client_new_one_many_payment',
  client_new_bespoke_payment: 'client_new_bespoke_payment',
  client_new_automated_payment: 'client_new_automated_payment',
  client_new_subscription_payment: 'client_new_subscription_payment', // Deprecated
  client_new_fixed_plan_payment: 'client_new_fixed_plan_payment',

  client_new_subscribe_wo_coupon_and_trial:
    'client_new_subscribe_wo_coupon_and_trial',
  client_coach_start_trialing: 'client_coach_start_trialing',
  client_new_subscribe_end_of_trial_wo_coupon:
    'client_new_subscribe_end_of_trial_wo_coupon',
  client_new_subscribe_with_coupon: 'client_new_subscribe_with_coupon',
  client_new_subscribe_with_coupon_and_trial:
    'client_new_subscribe_with_coupon_and_trial',
  client_new_subscribe_end_of_trial_with_coupon:
    'client_new_subscribe_end_of_trial_with_coupon',

  client_canceled_subscription_per_trial:
    'client_canceled_subscription_per_trial',
  client_canceled_paid_subscription: 'client_canceled_paid_subscription',
  client_canceled_free_subscription: 'client_canceled_free_subscription',

  client_downloaded_fixed_with_coupon: 'client_downloaded_fixed_with_coupon',

  athlete_changed_plan_price: 'athlete_changed_plan_price',
  client_coach_scheduled_subscription: 'client_coach_scheduled_subscription',

  client_missed_payment: 'client_missed_payment',

  client_payment_refunded: 'client_payment_refunded',

  // client - content warnings

  // client - coach feedback
  client_new_comment: 'client_new_comment',
  client_new_benchmark_comment: 'client_new_benchmark_comment',
  client_new_body_metric_comment: 'client_new_body_metric_comment',
  client_new_challenge_comment: 'client_new_challenge_comment',

  // brand/gym - invites
  invite_brand_and_gym: 'invite_brand_and_gym',
  brand_and_gym_coach_declined_invite: 'brand_and_gym_coach_declined_invite',
  brand_and_gym_coach_accepted_invite: 'brand_and_gym_coach_accepted_invite',

  // past_due subscribe handle
  client_subscribe_set_past_due: 'client_subscribe_set_past_due',
  coach_have_past_due_client: 'coach_have_past_due_client',
  client_auto_cancel_subscribe: 'client_auto_cancel_subscribe',
  coach_have_auto_cancel_client: 'coach_have_auto_cancel_client',

  // client - Mentions
  client_leaderboard_chat_mention: 'client_leaderboard_chat_mention',
  client_leaderboard_new_comment: 'client_leaderboard_new_comment',
  client_community_chat_mention: 'client_community_chat_mention',

  attempt_to_buy_over_limit: 'attempt_to_buy_over_limit',
  attempt_to_buy_over_limit_pro: 'attempt_to_buy_over_limit_pro',
};

const NotificationActions = {
  // *************************************
  // COACH
  // *************************************

  // FREE Plans
  [NotificationTypes.coach_new_free_one_many_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_free_bespoke_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_free_automated_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_free_fixed_client]: {
    main: 'subscriber_list',
    sub: ['client_profile', 'message_to_client', 'plan_downloads'],
  },

  // Financial
  [NotificationTypes.coach_new_one_many_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_bespoke_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_automated_client]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_fixed_client]: {
    main: 'subscriber_list',
    sub: ['client_profile', 'message_to_client', 'plan_downloads'],
  },
  [NotificationTypes.coach_downloaded_fixed_with_coupon]: {
    main: 'subscriber_list',
    sub: ['client_profile', 'message_to_client', 'plan_downloads'],
  },
  [NotificationTypes.coach_new_one_many_client_with_coupon]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_bespoke_client_with_coupon]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_automated_client_with_coupon]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_client_start_trialing]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_client_new_subscribe_end_of_trial_wo_coupon]: {
    main: 'subscriber_list',
    sub: ['subscriber_list'],
  },
  [NotificationTypes.coach_client_new_subscribe_end_of_trial_with_coupon]: {
    main: 'subscriber_list',
    sub: ['subscriber_list'],
  },
  [NotificationTypes.coach_client_canceled_subscription_per_trial]: {
    main: 'subscriber_list',
    sub: ['subscriber_list'],
  },
  [NotificationTypes.coach_client_unsubscribed]: {
    main: 'client_activity',
    sub: [
      'training_calendar',
      'client_profile',
      'message_to_client',
      'subscriber_list',
    ],
  },
  [NotificationTypes.coach_client_not_pay]: {
    main: 'message_to_client',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_blocked_client]: {
    main: 'message_to_client',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_fitr_charge]: {
    main: 'dashboard',
    sub: [],
  },
  [NotificationTypes.coach_bank_payout]: {
    main: 'dashboard',
    sub: [],
  },
  [NotificationTypes.coach_new_automated_payment]: {
    main: 'plan_management',
    sub: [
      'client_activity',
      'training_calendar',
      'message_to_client',
      'subscriber_list',
    ],
  },
  [NotificationTypes.coach_new_bespoke_payment]: {
    main: 'plan_management',
    sub: [
      'client_activity',
      'training_calendar',
      'message_to_client',
      'subscriber_list',
    ],
  },
  [NotificationTypes.coach_new_one_many_payment]: {
    main: 'plan_management',
    sub: [
      'client_activity',
      'training_calendar',
      'message_to_client',
      'subscriber_list',
    ],
  },
  [NotificationTypes.coach_payment_method_declined]: {
    main: 'payment_settings',
    sub: [],
  },
  [NotificationTypes.coach_warning_stripe_account]: {
    main: 'payment_settings',
    sub: [],
  },
  [NotificationTypes.coach_payment_refunded]: {
    main: 'message_to_client',
    sub: [],
  },
  [NotificationTypes.coach_restored_subscription_notice]: {
    main: 'subscriber_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_subscribe_wl]: {
    main: 'coach_subscribe_wl',
    sub: [],
  },
  [NotificationTypes.coach_unsubscribe_wl]: {
    main: 'coach_unsubscribe_wl',
    sub: [],
  },
  [NotificationTypes.coach_new_scheduled_grc]: {
    main: 'scheduled_subscribers_list',
    sub: ['message_to_client', 'subscriber_list'],
  },
  [NotificationTypes.coach_new_scheduled_s1d1]: {
    main: 'scheduled_subscribers_list',
    sub: ['message_to_client', 'subscriber_list'],
  },

  // Content Warnings
  [NotificationTypes.coach_client_ending_fixed_plan]: {
    main: 'message_to_client',
    sub: ['client_profile'],
  },
  [NotificationTypes.coach_client_ended_fixed_plan]: {
    main: 'message_to_client',
    sub: ['client_profile'],
  },
  [NotificationTypes.coach_danger_automated_plan]: {
    main: 'plan_schedule',
    sub: ['plan_overview'],
  },
  [NotificationTypes.coach_danger_bespoke_plan]: {
    main: 'bespoke_schedule',
    sub: [
      'training_calendar',
      'client_profile',
      'message_to_client',
      'subscriber_list',
    ],
  },
  [NotificationTypes.coach_danger_one_many_plan]: {
    main: 'one_many_schedule',
    sub: ['subscriber_list'],
  },

  [NotificationTypes.coach_deleted_program_price_on_stripe]: {
    main: 'plan_overview',
    sub: [],
  },
  
  // Client Activity
  [NotificationTypes.coach_new_performance]: {
    main: 'open_sidebar',
    sub: ['client_profile', 'training_calendar', 'message_to_client'],
  },
  [NotificationTypes.coach_new_performance_no_title]: {
    main: 'open_sidebar',
    sub: ['client_profile', 'training_calendar', 'message_to_client'],
  },
  [NotificationTypes.coach_new_comment]: {
    main: 'open_sidebar',
    sub: ['client_profile', 'training_calendar', 'message_to_client'],
  },

  [NotificationTypes.coach_new_benchmark_comment]: {
    main: 'coach_new_benchmark_comment',
    sub: [],
  },

  [NotificationTypes.coach_new_body_metric_comment]: {
    main: 'coach_new_body_metric_comment',
    sub: [],
  },

  [NotificationTypes.coach_new_challenge_comment]: {
    main: 'coach_new_challenge_comment',
    sub: [],
  },

  // Coach Membership
  [NotificationTypes.coach_subscribe_canceled]: {
    main: 'coach_billing_page',
    sub: [],
  },
  [NotificationTypes.coach_subscribe_payment_fail]: {
    main: 'coach_billing_page',
    sub: [],
  },
  [NotificationTypes.coach_end_trial_period]: {
    main: 'coach_billing_page',
    sub: [],
  },
  [NotificationTypes.attempt_to_buy_over_limit]: {
    main: 'choose_your_plan',
    sub: [],
  },
  [NotificationTypes.attempt_to_buy_over_limit_pro]: {
    main: 'choose_your_plan',
    sub: [],
  },

  // Coach Mentions
  [NotificationTypes.coach_leaderboard_chat_mention]: {
    main: 'view_leaderbord',
    sub: [],
  },
  [NotificationTypes.coach_community_chat_mention]: {
    main: 'view_community',
    sub: [],
  },

  // *************************************
  // CLIENT
  // *************************************

  // FREE Plans
  [NotificationTypes.client_new_free_one_many]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_free_bespoke]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_free_automated]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_free_fixed]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },

  // Financial
  [NotificationTypes.client_new_one_many_payment]: {
    main: 'go_to_user_plans',
    sub: [],
  },
  [NotificationTypes.client_new_bespoke_payment]: {
    main: 'go_to_user_plans',
    sub: [],
  },
  [NotificationTypes.client_new_automated_payment]: {
    main: 'go_to_user_plans',
    sub: [],
  },

  [NotificationTypes.client_new_subscription_payment]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_fixed_plan_payment]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_subscribe_wo_coupon_and_trial]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_coach_start_trialing]: {
    main: 'client_own_calendar',
    sub: ['client_own_plans', 'message_to_coach'],
  },
  [NotificationTypes.client_new_subscribe_end_of_trial_wo_coupon]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_new_subscribe_end_of_trial_with_coupon]: {
    main: 'go_to_purchases',
    sub: [],
  },
  [NotificationTypes.client_new_subscribe_with_coupon]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_new_subscribe_with_coupon_and_trial]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_coach_scheduled_subscription]: {
    main: 'go_to_purchases',
    sub: [],
  },

  // Content Warnings
  [NotificationTypes.client_canceled_subscription_per_trial]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_canceled_paid_subscription]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_canceled_free_subscription]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_downloaded_fixed_with_coupon]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.client_missed_payment]: {
    main: 'payment_settings',
    sub: ['message_to_coach'],
  },
  [NotificationTypes.client_payment_refunded]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.athlete_changed_plan_price]: {
    main: 'view_public_plan',
    sub: [],
  },

  // Client Feedback
  [NotificationTypes.client_new_comment]: {
    main: 'open_sidebar',
    sub: ['coach_profile', 'coach_plans', 'message_to_coach'],
  },

  [NotificationTypes.client_new_benchmark_comment]: {
    main: 'client_new_benchmark_comment',
    sub: [],
  },

  [NotificationTypes.client_new_body_metric_comment]: {
    main: 'client_new_body_metric_comment',
    sub: [],
  },

  [NotificationTypes.client_new_challenge_comment]: {
    main: 'client_new_challenge_comment',
    sub: [],
  },

  // Client Mentions
  [NotificationTypes.client_community_chat_mention]: {
    main: 'view_community',
    sub: [],
  },
  [NotificationTypes.client_leaderboard_chat_mention]: {
    main: 'view_leaderbord',
    sub: [],
  },
  [NotificationTypes.client_leaderboard_new_comment]: {
    main: 'view_leaderbord',
    sub: [],
  },

  // *************************************
  // BRAND
  // *************************************

  // invites
  [NotificationTypes.invite_brand_and_gym]: {
    main: 'coach_profile',
    sub: [],
  },
  [NotificationTypes.brand_and_gym_coach_declined_invite]: {
    main: 'brand_invites',
    sub: [],
  },
  [NotificationTypes.brand_and_gym_coach_accepted_invite]: {
    main: 'brand_invites',
    sub: [],
  },
  [NotificationTypes.client_subscribe_set_past_due]: {
    main: 'client_billing_purchases',
    sub: [],
  },
  [NotificationTypes.coach_have_past_due_client]: {
    main: 'message_to_client',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.client_auto_cancel_subscribe]: {
    main: 'payment_settings',
    sub: ['message_to_coach'],
  },
  [NotificationTypes.coach_have_auto_cancel_client]: {
    main: 'message_to_client',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
};

const UnreadCounters = {
  financial: 'unread_financial',
  content_warnings: 'unread_warning',
  client_activity: 'unread_activity',
  invite: 'unread_invites',
  chat_mention: 'unread_leaderboards',
};

/**
 * @param {string} type - unread counter type
 * @returns {string} - returns the value of the unread counter
 */
function getUnreadCounterByType(type) {
  return UnreadCounters[type];
}

export {
  ActivityTypes,
  NotificationTypes,
  NotificationActions,
  UnreadCounters,
  getUnreadCounterByType,
};
