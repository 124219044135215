// @vue/component
export default {
  name: 'DocumentIcon',

  props: {
    /**
     * Document Object
     * @type {object}
     * @default {}
     */
    document: {
      type: Object,
      default: function () {
        return {};
      },
    },

    /**
     * Mode
     * @type {string}
     * @default 'icon'
     */
    mode: {
      type: String,
      default: 'icon', // icon / tag
    },

    /**
     * Size
     * @type {string}
     * @default '48'
     */
    size: {
      type: String,
      default: '48',
    },
  },
};
