import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import { CancelToken } from 'axios';
import MugenScroll from 'vue-mugen-scroll';
import DocumentIcon from '@/shared/document-icon/index.vue';
import { without, filter, cloneDeep } from 'lodash-es';

// @vue/component
export default {
  name: 'LibraryFitr',

  components: {
    MugenScroll,
    DocumentIcon,
  },

  props: {
    /**
     * value
     * @type {object} - type object
     * @default {} - default empty object
     */
    value: {},

    /**
     * Multiple
     * @type {boolean} - type boolean
     */
    multiple: Boolean,

    /**
     * Search String
     * @type {string} - type string
     * @default '' - default empty string
     */
    searchString: {
      type: String,
      default: '',
    },

    /**
     * Current Owner
     * @type {string} - type string
     * @default 'fitr' - default 'fitr'
     */
    currentOwner: {
      type: String,
      default: 'fitr',
    },

    /**
     * Forbidden Types
     * @type {Array} - type array
     * @default () => [] - default empty array
     */
    forbiddenTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**
     * Filters
     * @type {object} - type object
     * @default () => ({}) - default empty object
     */
    filters: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data: function () {
    return {
      isMobileLayout: false,
      files: [],
      isReady: false,
      isLoading: false,
      currentPage: 1,
      perPage: 15,
      pagination: {},
      sortType: 'created_at',
      sortDir: 'desc',
      localValue: cloneDeep(this.value),
    };
  },

  computed: {
    scrollContainer: function () {
      return this.isMobileLayout ? 'modal' : 'scrollWrap';
    },

    selectedMedia: {
      get() {
        // Files have different is_remove,position keys local and on server

        if (this.multiple) {
          const filteredValue = this.localValue.filter((f) => f != undefined);
          const filteredFiles = this.files.filter((f) => f != undefined);

          const selected = filteredValue.map((s) => {
            const exist = filteredFiles.find((f) => {
              return f.id === s.id;
            });

            return exist || s;
          });

          return selected;
        } else {
          const filteredFiles = this.files.filter((f) => f != undefined);

          const selected = filteredFiles.find((f) => {
            return f.id === this.localValue.id;
          });

          return Object.assign({}, selected);
        }
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },

    isScrollShouldHandle: function () {
      return this.currentPage < this.pagination.total_pages;
    },
  },

  watch: {
    value: function() {
      this.localValue = cloneDeep(this.value);
    },
    filters: function () {
      this.currentPage = 1;

      this.updateList();
    },
    currentOwner: function () {
      this.currentPage = 1;

      this.updateList();
    },
    searchString: function () {
      this.currentPage = 1;

      this.updateList();
    },
    sortType: function () {
      this.currentPage = 1;

      this.updateList();
    },
    sortDir: function () {
      this.currentPage = 1;

      this.updateList();
    },
  },

  mounted: function () {
    this.isReady = false;

    this.fetch()
      .then((items) => {
        this.files = filter(items, (f) => {
          return f.src != undefined;
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isReady = true;
      });
  },

  created() {
    this.mq = window.matchMedia('(max-width: 991.98px)');

    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    fetch() {
      this.isLoading = true;

      if (this.cancel) {
        this.cancel();
      }

      let params = {
        owner: this.currentOwner,
        page: this.currentPage,
        per_page: this.perPage,
      };

      // Filter
      if (this.currentOwner == 'fitr') {
        params['q[kind_in]'] = 'video';
      } else {
        let kinds = [];

        if (this.filters && this.filters.length > 0) {
          kinds = this.filters;
        } else {
          kinds = without(
            ['video', 'image', 'other', 'youtube'],
            ...this.forbiddenTypes
          );
        }

        params['q[kind_in]'] = kinds;
      }

      // Search
      params['q[title_cont_any]'] = this.searchString;

      // Sort
      params['q[s]'] = `${this.sortType} ${this.sortDir}`;

      return new Promise((resolve, reject) => {
        HTTP.get('media/fetch', {
          params,
          cancelToken: new CancelToken((c) => {
            this.cancel = c;
          }),
        })
          .then(({ data }) => {
            this.pagination = data.pagination;
            resolve(data.items);
            this.$emit('hyroxAccess', data.hyrox_access);
          })
          .catch((error) => {
            HttpErrorsHandling(error);

            reject(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },

    updateList() {
      this.fetch()
        .then((items) => {
          this.files = filter(items, (f) => {
            return f.src != undefined;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    nextPage() {
      this.currentPage += 1;

      this.fetch()
        .then((items) => {
          let files = filter(items, (f) => {
            return f.src != undefined;
          });

          this.files = this.files.concat(files);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sort(s) {
      if (s === this.sortType) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortType = s;
    },

    toggleSelected(file) {
      if (this.multiple) {
        let selected = this.localValue.find((f) => f.id === file.id);

        if (selected == undefined) {
          this.localValue.push(file);
        } else {
          this.localValue.splice(this.localValue.indexOf(selected), 1);
        }

        this.$emit('input', this.localValue);
      } else {
        this.$emit('input', file);
      }
    },
  },
};
