import axios from 'axios';
import { ROUTER } from '@/router';

export const HTTP = axios.create({
  baseURL: `${import.meta.env.VITE_API_BASE}/${import.meta.env.VITE_API_PATH}`,
  headers: {
    'content-type': 'application/json',
    //'Client-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'API-Version': 2,
  },
});

if (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) {
  HTTP.defaults.headers.common['Client-Timezone'] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
};

// .catch(HttpErrorsHandling)
// .catch(error => {
//   HttpErrorsHandling(error);
// })

/**
 * Handle http errors
 * @param {Error} error - error Object
 */
export function HttpErrorsHandling(error) {
  // console.log({ error });

  let text;
  let duration = 5000;

  if (axios.isCancel(error)) {
    console.log('Request canceled', error.message);
  } else if (error.response) {
    if (error.response.statusText?.length) {
      text = error.response.statusText;
    }

    if (error.response.status == 404) {
      text = 'Not Found';
    } else if (error.response.status == 403) {
      text = 'Forbidden: Access is denied';
    } else if (error.response.status == 401) {
      text = 'Access is denied. Try to reload the page.';
    } else if (error.response.status == 406) {
      text = 'Access is denied';
    } else {
      if (typeof error.response.data !== 'string') {
        try {
          text = Object.keys(error.response.data)
            .map((filed) => {
              return Object.keys(error.response.data[filed]).map((e) => {
                return error.response.data[filed][e];
              });
            })
            .toString();
        } catch (e) {
          console.dir(e);
        }
      }
    }

    // if (error.response.data && error.response.data.exception) {
    //   text = error.response.data.exception;
    // } else if (error.response.data && error.response.data.base && error.response.data.base.invalid) {
    //   text = error.response.data.base.invalid;
    // }
  } else if (error.request) {
    console.dir(error);

    text = error.message || 'Request failed';
  } else {
    text = error.message;
  }

  // Show the same popup as update popup when coach opens clients page with an old request
  if (error?.response?.data?.base?.deprecated) {
    const event = new Event('showUpdateModal');
    window.dispatchEvent(event);
  } else if (text != undefined) {
    window.FitrApp.$notify({
      group: 'alerts',
      type: 'error',
      title: text,
      duration,
    });
  }
}

HTTP.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        ROUTER.replace({ name: 'onboarding-signin' }).catch(() => {});
      } else if (error.response.status == 404) {
        ROUTER.replace({ name: '404' }).catch(() => {});
      } else if (error.response.status == 403) {
        ROUTER.replace({ path: '/' }).catch(() => {});
      } else if (error.response.status == 406) {
        ROUTER.replace({ name: 'coach_blocked' }).catch(() => {});
      } else if (error.response.status == 418) {
        if (ROUTER.currentRoute.name != 'coach_profile_my_accounts') {
          ROUTER.replace({ name: 'coach_profile_my_accounts' }).catch(() => {});
        }
      }
    }
    return Promise.reject(error);
  }
);
