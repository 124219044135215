// @vue/component
export default {
  name: 'NotificationsIndicatorLink',

  computed: {
    hasNew: function () {
      return this.$store.state.currentUser.has_new_notifications;
    },
  },
};
