<template>
  <div id="app" :class="bodyCssClass">
    <div class="app-wrap">
      <div v-if="isOffline" class="no-internet-connection system-noty danger">
        {{ $t('en.home.offline') }}
      </div>

      <div class="content">
        <router-view></router-view>
      </div>

      <app-footer v-if="!isFooterHidden"></app-footer>
    </div>

    <app-alerts></app-alerts>

    <b-modal
      ref="reloadPopup"
      :title="$t('en.home.multiple_title')"
      lazy
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <p class="mb-0">{{ $t('en.home.multiple_tabs') }}</p>
      <template slot="modal-footer">
        <button class="btn btn-primary" type="button" @click.prevent="reload">
          Reload
        </button>
      </template>
    </b-modal>

    <additional-profile-info-popup></additional-profile-info-popup>

    <template v-if="isBusinessAccount && !isBusinessOwner && isAccountBlocked">
      <b-modal
        ref="restrictedPopup"
        :title="$t('en.pricing.brand_gym_is_restricted_popup.title')"
        visible
      >
        <p class="mb-0">
          {{ $t('en.pricing.brand_gym_is_restricted_popup.text') }}
        </p>
        <template #modal-footer="{ close }">
          <button
            class="btn btn-outline-dark"
            type="button"
            style="min-width: 120px"
            @click="close"
          >
            Got It!
          </button>
        </template>
      </b-modal>
    </template>

    <portal-target name="benchmarks-preview-popup"></portal-target>

    <release-notes-modal
      v-if="!isDisabledReleaseNotes"
      ref="releaseNotesModal"
    ></release-notes-modal>

    <knowledge-notes-modal
      v-if="isUserCoach"
      ref="knowledgeNotesModal"
    ></knowledge-notes-modal>

    <ReloadPrompt></ReloadPrompt>

    <ModalDeclinedAppIcon ref="declinedAppIconModal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import {
  connect,
  disconnect,
  subscribe,
  unsubscribe,
} from '@/utils/actioncable';

import { HTTP } from '@/utils/http-common';

import { PortalTarget } from 'portal-vue';

import AppAlerts from '@/shared/app-alerts.vue';

import AppFooter from '@/shared/app-footer/short/index.vue';
import ReleaseNotesModal from '@/shared/modal-release-notes/index.vue';
import KnowledgeNotesModal from '@/shared/modal-knowledge-notes/index.vue';

import AdditionalProfileInfoPopup from '@/shared/additional-profile-info-popup/index.vue';

import ReloadPrompt from '@/shared/reload-prompt/index.vue';

import ModalDeclinedAppIcon from '@/shared/modal-declined-app-icon/index.vue';

export default {
  components: {
    AppAlerts,
    AppFooter,
    AdditionalProfileInfoPopup,
    PortalTarget,
    ReleaseNotesModal,
    KnowledgeNotesModal,
    ReloadPrompt,
    ModalDeclinedAppIcon,
  },

  data: () => ({
    isOffline: false,
    isDocHidden: false,

    alertsData: {
      title: null,
      text: null,
      type: null,
    },

    isFooterHidden: true,

    channel: null,
  }),

  computed: {
    bodyCssClass() {
      return this.$route.meta.bodyCssClass;
    },

    isDisabledReleaseNotes() {
      if (this.isUserCoach) {
        return this.isAccountBlocked;
      } else if (this.authenticated) {
        return false;
      } else {
        return true;
      }
    },

    themeCssVars() {
      return {
        ...this.primaryCssColors,
        ...this.secondaryCssColors,
        ...this.systemCssColors,
      };
    },

    isIntercomAvailable() {
      return !this.isCoachOverallAccount && this.isUserCoach;
    },

    ...mapState(['alerts', 'authenticated', 'currentUser']),
    ...mapGetters([
      'isBusinessAccount',
      'isBusinessOwner',
      'isUserCoach',
      'isCoachOverallAccount',
      'accountKind',
    ]),
    ...mapGetters('subscription', ['isAccountBlocked']),
    ...mapGetters('color_scheme', [
      'primaryCssColors',
      'secondaryCssColors',
      'systemCssColors',
    ]),
  },

  watch: {
    // TODO: have to be removed
    alerts() {
      const alert = this.alerts;
      let message = null;

      if (alert.type === null) {
        return;
      }

      switch (alert.type) {
        case 'warn':
          for (let msgObg in alert.info) {
            for (let msg in alert.info[msgObg]) {
              message = alert.info[msgObg][msg];

              this.$notify({
                group: 'alerts',
                type: alert.type,
                title: message ? message : 'Warning!',
              });
            }
          }

          break;

        case 'info':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Info message!',
          });

          break;

        case 'congrats':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Congrats!',
          });

          break;

        case 'error':
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Whoops!',
          });

          break;

        default:
          this.$notify({
            group: 'alerts',
            type: alert.type,
            title: alert.info.message ? alert.info.message : 'Info message!',
          });
      }

      this.$store.commit('Alerts', { type: null });
    },

    authenticated: {
      handler: function (val) {
        if (val) {
          this.connectSockets();
        } else {
          this.disconnectSockets();
        }
      },
      immediate: true,
    },

    '$route.meta': {
      handler: function (to) {
        this.isFooterHidden = !!to.hideFooter;
      },
      immediate: true,
    },

    themeCssVars: {
      handler: function (vars) {
        const root = document.documentElement;

        Object.entries(vars).forEach(([key, value]) => {
          root.style.setProperty(key, value);
        });
      },
      immediate: true,
    },

    'currentUser.stripe_coach_customer_id': {
      handler: function () {
        if (
          window.profitwell &&
          this.currentUser.role == 'coach' &&
          this.currentUser.stripe_coach_customer_id
        ) {
          window.profitwell('start', {
            user_id: this.currentUser.stripe_coach_customer_id,
          });
        }
      },
      immediate: true,
    },

    isIntercomAvailable: {
      handler: function (is_available) {
        if (window.Intercom) {
          if (is_available) {

            let params = {
              name: this.currentUser.full_name,
              user_id: this.currentUser.id,
              email:
                this.currentUser.brand_owner_email || this.currentUser.email,
              created_at: this.currentUser.created_at,
              'Account Type': 'coach',
              'Account owner name':
                this.currentUser.brand_owner_full_name ||
                this.currentUser.full_name,
              'Trial period start': this.currentUser.created_at,
            };

            if (window.localStorage.getItem('gclid')) {
              params.gclid = window.localStorage.getItem('gclid');
              window.localStorage.removeItem('gclid');
            };

            window.Intercom('update', params);
          } else {
            window.Intercom('shutdown');
          }
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on('show-new-user-info-popup', ({ role, invite }) => {
      this.showNewUserSuccessNotify({ role, invite });
    });

    if (window.localStorage.getItem('fitrShowNewUserInfoPopup')) {
      this.showNewUserSuccessNotify();

      window.localStorage.removeItem('fitrShowNewUserInfoPopup');
    }

    // this.$refs.knowledgeNotesModal.show();
  },

  created: function () {
    // console.log('created');

    window.addEventListener('online', this.updateOnlineIndicator);
    window.addEventListener('offline', this.updateOnlineIndicator);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    window.addEventListener('resize', this.calc100vh);
    this.calc100vh();
  },

  beforeDestroy: function () {
    window.removeEventListener('online', this.updateOnlineIndicator);
    window.removeEventListener('offline', this.updateOnlineIndicator);

    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );

    window.removeEventListener('resize', this.calc100vh);
  },

  methods: {
    showNewUserSuccessNotify({ role, invite } = {}) {
      let title = '';

      if (this.authenticated && this.isUserCoach) {
        if (this.isBusinessAccount) {
          title = `You're ready to go ${this.currentUser.title}!`;
        } else if (role == 'invited') {
          if (invite.token && invite.title) {
            title = `Congrats, we’ve added you into '${invite.title}'.`;
          } else {
            // title = 'How to join an existing team';
            title = `You're ready to go ${this.currentUser.full_name}!`;
          }
        } //else {
        //   title = `You're ready to go ${this.currentUser.full_name}!`;
        // }
      }

      if (title.length) {
        this.$notify({
          group: 'alerts',
          type: 'congrats',
          title: title,
        });
      }
    },

    calc100vh() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;

      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    handleVisibilityChange() {
      // console.log('visibilityState..', document.visibilityState);

      if (
        this.authenticated &&
        document.visibilityState != 'hidden' &&
        this.isDocHidden &&
        this.$refs.reloadPopup.isHidden
      ) {
        HTTP.get('users/me').then(({ data }) => {
          if (
            data.private.id == this.$store.state.privateUser.id &&
            data.current.id != this.currentUser.id
          ) {
            this.$refs.reloadPopup.show();
          }
        });
      }

      this.isDocHidden = document.visibilityState == 'hidden';
    },

    updateOnlineIndicator() {
      this.isOffline = !window.navigator.onLine;
    },

    connectSockets() {
      connect();

      this.channel = subscribe('SystemNotificationChannel', (message) => {
        if (message.type == 'stripe_status_changed' && message.data.user) {
          this.currentUser.stripe_connect_status =
            message.data.user.stripe_connect_status;
          this.currentUser.stripe_connect_payouts =
            message.data.user.stripe_connect_payouts;
        } else if (message.type == 'switched') {
          if (
            !document.hasFocus() &&
            message.data.private_account == this.$store.state.privateUser.id &&
            message.data.switched_to != this.currentUser.id
          ) {
            this.$refs.reloadPopup.show();
          }
        } else if (message.type == 'subscribe' && message.event == 'updated') {
          HTTP.get('subscribe').then(({ data }) => {
            this.$store.commit('setCurrentUserSubscription', data);
          });
        } else if (
          message.type == 'updated_coach_app_icon' &&
          message.data.state == 'declined'
        ) {
          HTTP.get('coach/coach_icon').then(({ data }) => {
            this.$refs.declinedAppIconModal.show(data.decline_reasons);
          });
        }
      });
    },

    disconnectSockets() {
      if (this.channel) {
        unsubscribe(this.channel);
        this.channel = null;
      }

      disconnect();
    },

    reload() {
      window.location.assign('/');
    },
  },

  metaInfo() {
    // console.log('metaInfo', import.meta.env.VITE_API_BASE);

    // const base = import.meta.env.VITE_API_BASE;

    // console.log('base', base);

    const image = new URL('Fitr.jpg', import.meta.env.VITE_APP_BASE)
      .href;

    return {
      title: 'FITR',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'FITR - Personal Trainer Software',
        },
        {
          vmid: 'og_title',
          property: 'og:title',
          content: 'FITR',
        },
        {
          vmid: 'og_description',
          property: 'og:description',
          content: 'FITR - Personal Trainer Software',
        },
        {
          vmid: 'og_image',
          property: 'og:image',
          content: image,
        },
        {
          vmid: 'twitter_card',
          name: 'twitter:card',
          content: 'summary',
        },
      ],
    };
  },
};
</script>

<style src="./app.scss" lang="scss"></style>
