const Layout = () => import('./layout/index.vue');
const ClientsWithPrograms = () => import('./all-clients/index.vue');
const ClientProfileLayout = () => import('./client-profile/layout/index.vue');
const ClientPrograms = () => import('./client-profile/programs/index.vue');

const ClientMetrics = () => import('./client-profile/metrics/index.vue');
const ClientMetric = () => import('./client-profile/metrics/metric/index.vue');

export default [
  {
    path: 'clients',
    component: Layout,

    children: [
      {
        path: '',
        redirect: { name: 'clients_with_programs' },
      },
      {
        path: 'active',
        component: ClientsWithPrograms,
        name: 'clients_with_programs',
        meta: {
          clientsType: 'active',
        },
      },
      {
        path: 'past',
        component: ClientsWithPrograms,
        name: 'past_clients',
        meta: {
          clientsType: 'past',
        },
      },
      {
        path: 'in-trials',
        component: ClientsWithPrograms,
        name: 'trial_clients',
        meta: {
          clientsType: 'in_trial',
        },
      },
      {
        path: 'pending',
        redirect: { name: 'clients_with_programs' },
      },
      {
        path: 'to_review',
        component: ClientsWithPrograms,
        name: 'to_review_clients',
        meta: {
          clientsType: 'activity',
        },
      },
    ],
  },

  {
    path: 'clients/:athlete_id',
    component: ClientProfileLayout,
    props: true,

    children: [
      {
        path: '',
        redirect: { name: 'show_client_programs' },
      },
      {
        path: 'programs',
        component: ClientPrograms,
        name: 'show_client_programs',
        props: true,
      },
      {
        path: 'benchmarks',
        component: ClientMetrics,
        name: 'show_client_benchmarks',
        props: true,
        meta: {
          metricType: 'benchmark',
        },
      },
      {
        path: 'benchmarks/:metric_id',
        component: ClientMetric,
        name: 'client-profile-benchmark',
        props: true,
        meta: {
          metricType: 'benchmark',
        },
      },
      {
        path: 'body-metrics',
        component: ClientMetrics,
        name: 'show_client_body_metrics',
        props: true,
        meta: {
          metricType: 'body-metric',
        },
      },
      {
        path: 'body-metrics/:metric_id',
        component: ClientMetric,
        name: 'client-profile-body-metric',
        props: true,
        meta: {
          metricType: 'body-metric',
        },
      },
      {
        path: 'challenges',
        component: ClientMetrics,
        name: 'show_client_challenges',
        props: true,
        meta: {
          metricType: 'challenge',
        },
      },
      {
        path: 'challenges/:metric_id',
        component: ClientMetric,
        name: 'client-profile-challenge',
        props: true,
        meta: {
          metricType: 'challenge',
        },
      },
    ],
  },
];
