import { HTTP, HttpErrorsHandling } from '@/utils/http-common';
import { CancelToken } from 'axios';
import { mapGetters } from 'vuex';
import vueDropzone from 'vue2-dropzone';
import MugenScroll from 'vue-mugen-scroll';
import { without, filter, includes, cloneDeep } from 'lodash-es';
import DocumentIcon from '@/shared/document-icon/index.vue';
import SvgIcon from '@/shared/svg-icon/index.vue';

const previewTemplate = function () {
  return `<div class='file-preview'>
    <div class='file-preview__status'>
      <svg
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :style="iconStyle"
        class="file-preview__icon file-preview__icon_loaded"
      >
        <g>
          <path d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z" fill="currentColor" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.673 9.046a1 1 0 0 1 .067 1.412l-4.647 5.112a1.5 1.5 0 0 1-2.17.052l-2.13-2.13a1 1 0 0 1 1.414-1.414l1.758 1.759 4.295-4.724a1 1 0 0 1 1.413-.067Z"
            fill="#fff"
          />
        </g>
      </svg>
    </div>

    <div class="file-preview__body">
      <span class="file-preview__loading">Uploading file:</span>
      <span class='file-preview__title' data-dz-name></span>
      <span class="file-preview__loaded">uploaded.</span>
      <span class="file-preview__error"><span data-dz-errormessage></span></span>
    </div>

    <div class='file-preview__progress'>
      <div class='progress'>
        <div class='progress-bar' style='width:0%;' data-dz-uploadprogress></div>
      </div>
    </div>

    <div class='file-preview__remove'></div>
  </div>`;
};

const dictDefaultMessage = function () {
  return `
      <span class="btn btn-primary d-block">
        Upload from device
      </span>
  `;
};

// const mq = window.matchMedia('(max-width: 991.98px)');

// @vue/component
export default {
  name: 'LibraryFiles',

  components: {
    vueDropzone,
    MugenScroll,
    DocumentIcon,
    SvgIcon,
  },

  props: {
    /**
     * Value
     * @type {(Array|object)} - type array or object
     */
    value: [Array, Object],

    /**
     * Multiple
     * @type {boolean} - type boolean
     */
    multiple: Boolean,

    /**
     * Only Upload
     * @type {boolean} - type boolean
     */
    onlyUpload: Boolean,

    /**
     * Forbidden Types
     * @type {Array} - type array
     * @default [] - default empty array
     */
    forbiddenTypes: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**
     * Search String
     * @type {string} - type string
     * @default '' - default empty string
     */
    searchString: {
      type: String,
      default: '',
    },

    /**
     * Filters
     * @type {Array} - type array
     * @default [] - type array
     */
    filters: {
      type: Object,
      default: function () {
        return {};
      },
    },

    /**
     * Current Owner
     * @type {string} - type string
     * @default 'current' - default current
     */
    currentOwner: {
      type: String,
      default: 'current',
    },
  },

  data: function () {
    return {
      localValue: cloneDeep(this.value),
      videoUrl: '',
      isVideoUrlUploadnig: false,

      isMobileLayout: false,

      currentPage: 1,
      perPage: 15,
      pagination: {},

      files: [],
      isReady: false,
      isLoading: false,
      deletingOneMedia: [],
      disabledFiles: {},

      dropzoneOptions: {
        url: `${import.meta.env.VITE_API_BASE}/${
          import.meta.env.VITE_API_PATH
        }media`,
        paramName: 'file',
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        maxFilesize: 500,
        dictFileTooBig:
          'File is too big ({{filesize}}Mb). Max filesize: {{maxFilesize}}Mb.',
        previewsContainer: '#dropzonePreviews',
        previewTemplate: previewTemplate(),
        dictDefaultMessage: dictDefaultMessage(),
        addRemoveLinks: false,

        timeout: 0,

        // handle error from amazon s3 in XML format
        // https://github.com/dropzone/dropzone/blob/v5.9.3/src/options.js#L677
        error(file, message) {
          if (file.previewElement) {
            file.previewElement.classList.add('dz-error');

            if (typeof message !== 'string' && message.error) {
              message = message.error;
            }

            // Check is message in xml format
            else if (message.indexOf('<?xml') === 0) {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(message, 'text/xml');
              message =
                xmlDoc.getElementsByTagName('Message')[0].childNodes[0]
                  .nodeValue;
            }

            for (let node of file.previewElement.querySelectorAll(
              '[data-dz-errormessage]'
            )) {
              node.textContent = message;
            }
          }
        },
      },

      awss3: {
        signingURL: `${import.meta.env.VITE_API_BASE}/api/media/direct_upload`,
        headers: {
          Authorization: `${localStorage.getItem(
            'token_type'
          )} ${localStorage.getItem('access_token')}`,
        },
        params: { scope: this.currentOwner },
        sendFileToServer: false,
        // withCredentials: false
      },

      sortType: 'created_at',
      sortDir: 'desc',
    };
  },

  computed: {
    isShowAddBlock() {
      if (this.currentOwner == 'hyrox') {
        return this.hasHyrox && this.isAccountOwner;
      } else {
        return true;
      }
    },

    scrollContainer: function () {
      return this.isMobileLayout ? 'modal' : 'scrollWrap';
    },

    selectedMedia: {
      get() {
        // Files have different is_remove,position keys local and on server

        if (this.multiple) {
          let filteredValue = this.localValue.filter((f) => f != undefined);
          let filteredFiles = this.files.filter((f) => f != undefined);

          let selected = filteredValue.map((s) => {
            let exist = filteredFiles.find((f) => {
              return f.id === s.id;
            });

            return exist || s;
          });

          return selected;
        } else {
          let filteredFiles = this.files.filter((f) => f != undefined);

          let selected = filteredFiles.find((f) => {
            return f.id === this.localValue.id;
          });

          return Object.assign({}, selected);
        }
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },

    isScrollShouldHandle: function () {
      return this.currentPage < this.pagination.total_pages;
    },

    filteredFiles() {
      if (this.files.length) {
        return this.files.filter((file) => {
          if (this.currentOwner == 'current' && file.scope == 'user') {
            return file;
          } else if (this.currentOwner == 'hyrox' && file.scope == 'hyrox') {
            return file;
          }
        });
      } else {
        return [];
      }
    },

    ...mapGetters(['isAccountOwner', 'hasHyrox']),
  },

  watch: {
    value: function () {
      this.localValue = cloneDeep(this.value);
    },
    'filters.type.value': function () {
      this.currentPage = 1;
      this.updateList();
    },
    searchString: function () {
      this.currentPage = 1;
      this.updateList();
    },
    sortType: function () {
      this.currentPage = 1;
      this.updateList();
    },
    sortDir: function () {
      this.currentPage = 1;
      this.updateList();
    },
    currentOwner: function () {
      this.awss3.params.scope = this.currentOwner;
      this.currentPage = 1;
      this.videoUrl = '';
      this.isReady = false;
      this.updateList();
    },
  },

  mounted: function () {
    if (this.onlyUpload) {
      this.isReady = true;

      this.files = filter(this.localValue, (f) => {
        return f.kind != 'youtube';
      });
    } else {
      this.isReady = false;

      this.fetch()
        .then((items) => {
          this.files = items;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isReady = true;
        });
    }
  },

  created() {
    document.addEventListener('keydown', this._keyListener);

    this.mq = window.matchMedia('(max-width: 991.98px)');

    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    _keyListener(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.submit();
      }
    },

    submit() {
      if (this.videoUrl.length && !this.isVideoUrlUploadnig) {
        this.isVideoUrlUploadnig = true;

        HTTP.request({
          url: 'media',
          method: 'POST',
          data: {
            media: {
              video_url: this.videoUrl,
            },
            scope: this.currentOwner,
          },
        })
          .then(({ data }) => {
            this.videoUrl = '';
            this.files.unshift(data);

            data.is_new = true;

            if (this.multiple) {
              this.localValue.unshift(data);
              this.$emit('input', this.localValue);
            } else {
              this.$emit('input', data);
            }
          })
          .catch(HttpErrorsHandling.bind(this))
          .then(() => {
            this.isVideoUrlUploadnig = false;
          });
      }
    },

    fetch() {
      if (!this.onlyUpload) {
        // it is not used when onlyUpload
        this.isLoading = true;

        if (this.cancel) {
          this.cancel();
        }

        let params = {
          owner: this.currentOwner,
          page: this.currentPage,
          per_page: this.perPage,
        };

        // Filter
        let kinds = [];

        if (this.filters?.type?.value && this.filters?.type?.value.length > 0) {
          kinds = this.filters?.type?.value;
        } else {
          kinds = without(
            ['video', 'image', 'other', 'youtube'],
            ...this.forbiddenTypes
          );
        }

        params['q[kind_in]'] = kinds;

        // Search
        params['q[title_cont_any]'] = this.searchString;

        // Sort
        params['q[s]'] = `${this.sortType} ${this.sortDir}`;

        return new Promise((resolve, reject) => {
          HTTP.get('media/fetch', {
            params,
            cancelToken: new CancelToken((c) => {
              this.cancel = c;
            }),
          })
            .then(({ data }) => {
              this.pagination = data.pagination;
              resolve(data.items);
              this.$emit('hyroxAccess', data.hyrox_access);
            })
            .catch((error) => {
              HttpErrorsHandling(error);

              reject(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
      }
    },

    removeNewMedia(file) {
      if (file.is_new) {
        this.$set(this.disabledFiles, file.id, true);

        HTTP.delete(`media/${file.id}`)
          .then(() => {
            let oneSelected = this.files.find((f) => f.id === file.id);

            this.files.splice(this.files.indexOf(oneSelected), 1);

            if (this.multiple) {
              this.localValue.splice(this.localValue.indexOf(oneSelected), 1);
              this.$emit('input', this.localValue);
            } else {
              if (file.id == this.selectedMedia.id) {
                this.selectedMedia = {};
              } else {
                /* eslint-disable */
                this.selectedMedia = this.selectedMedia;
                /* eslint-enable */
              }
            }
          })
          .catch((error) => {
            HttpErrorsHandling(error);
          })
          .finally(() => {
            this.$delete(this.disabledFiles, file.id);
          });
      }
    },

    updateList() {
      if (!this.onlyUpload) {
        this.fetch()
          .then((items) => {
            this.files = items;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isReady = true;
          });
      } else {
        this.isReady = true;
      }
    },

    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    fileUploaded(file, response) {
      if (!includes(this.forbiddenTypes, response.type)) {
        response.is_new = true;
        this.files.unshift(response);
        if (this.multiple) {
          this.localValue.unshift(response);
          this.$emit('input', this.localValue);
        } else {
          this.$emit('input', response);
        }
      }

      file.timer = window.setTimeout(() => {
        file.timer = null;
        this.$refs.filesDropzone.removeFile(file);
      }, 3000);
    },

    fileRemoved(file) {
      if (file.timer) {
        window.clearTimeout(file.timer);
      }
    },

    fileError(file) {
      if (file?.s3Signature?.key) {
        // Find id in s3Signature key
        const id = file.s3Signature.key.match(
          /uploads\/media\/file\/(\d+)\//
        )[1];

        if (id) {
          HTTP.delete(`media/${id}`);
        }
      }
    },

    fileSending(file, xhr) {
      xhr.ontimeout = () => {
        file.previewElement.classList.add('dz-error');
        file.previewElement.classList.remove('dz-processing');

        file.previewElement.querySelector('.file-preview__error').textContent =
          'Timeout error';
      };
    },

    fileAdded(file) {
      file.previewElement
        .querySelector('.file-preview__remove')
        .addEventListener('click', () => {
          this.$refs.filesDropzone.removeFile(file);
        });

      this.$emit('change');
    },

    nextPage() {
      this.currentPage += 1;

      this.fetch()
        .then((items) => {
          this.files = this.files.concat(items);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sort(s) {
      if (s === this.sortType) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sortType = s;
    },

    toggleSelected(file) {
      if (this.multiple) {
        let selected = this.localValue.find((f) => f.id === file.id);

        if (selected == undefined) {
          this.localValue.push(file);
        } else {
          this.localValue.splice(this.localValue.indexOf(selected), 1);
        }

        this.$emit('input', this.localValue);
      } else {
        this.$emit('input', file);
      }
    },
  },
};
