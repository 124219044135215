import { HTTP } from '@/utils/http-common';
import VideoPlayer from '@/shared/video-player/index.vue';

// @vue/component
export default {
  name: 'MyMediaModal',

  components: {
    VideoPlayer,
  },

  props: {
    /**
     * Is Mobile view
     * @type {boolean}
     * @default false
     */
    increaseBackdropIndex: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: {},

      hasMyMedia: false,
      loadingMyMedia: true,

      player: null,
    };
  },

  computed: {
    classList() {
      return 'media-modal ' + (this.increaseBackdropIndex ? 'increase-backdrop-z-index' : '');
    },

    youtubeSrc() {
      if (this.modal.type === 'youtube') {
        return `https://www.youtube.com/embed/${this.modal.video_id}?autoplay=1&controls=1`;
      }

      return null;
    },
  },
  methods: {
    /**
     * Open lightbox
     * @param {object} item - item
     */
    openLightbox(item) {
      this.modal = {
        id: item.id,
        src: item.src,
        stream_src: item.stream_src,
        state: item.state,
        thumb: item.thumb,
        alt: item.caption,
        type: item.type,
        video_id: item.video_id,
      };

      this.$refs.mediaModal.show();
    },

    /**
     * close lightbox
     */
    closeLightbox() {
      this.$refs.mediaModal.hide();
    },

    /**
     * Media modal closed
     */
    mediaModalClosed() {
      this.modal = {};

      this.$emit('hidden');
    },

    /**
     * Media modal opened
     */
    mediaModalOpened() {
      if (this.modal.type == 'video' && this.modal.state != 'published') {
        HTTP.get(`media/${this.modal.id}`).then(({ data }) => {
          // if (data.state == 'published') {
          this.modal.state = data.state;
          this.modal.stream_src = data.stream_src;
          // }
        });
      }

      this.$emit('shown');
    },
  },
};
