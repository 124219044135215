import { mapGetters, mapMutations } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

import { subscribe, unsubscribe } from '@/utils/actioncable.js';

import NotificationsPopup from '@/components/feed/popup/index.vue';

// @vue/component
export default {
  name: 'NotificationsIndicator',

  components: {
    NotificationsPopup,
  },

  mixins: [clickaway],

  computed: {
    hasNew: {
      get: function () {
        return this.$store.state.currentUser.has_new_notifications;
      },
      set: function (v) {
        this.$store.commit('changeHasNewNotifications', v);
      },
    },

    ...mapGetters(['isUserCoach', 'isFinancialAviable']),
    ...mapGetters('subscription', ['isAccountBlocked', 'isAccountActive']),
  },

  created: function () {
    this.channel = subscribe('NotificationChannel', (message) => {
      if (message.type == 'new') {
        const isFinancialInfo = [
          'coach_membership',
          'invite',
          'financial',
        ].includes(message.data.activity_type);

        if ((isFinancialInfo && this.isFinancialAviable) || !isFinancialInfo) {
          const availableForBlockedInfo = [
            'coach_membership',
            'invite',
          ].includes(message.data.activity_type);

          if (
            (this.isAccountBlocked && availableForBlockedInfo) ||
            this.isAccountActive ||
            !this.isUserCoach
          ) {
            this.receiveNotification(message.data);
            this.increaseUnreadCounter(message.data.id);
          }

          this.hasNew = true;
        }
      } else if (message.type == 'destroy') {
        this.decreaseUnreadCounter(message.data.id);
        this.destroyNotification(message.data.id);
      }
    });

    this.$root.$on('profile-menu-showed', this.hideNew);
    this.$root.$on('bv::dropdown::show', this.hideNew);
  },

  beforeDestroy: function () {
    unsubscribe(this.channel);

    this.$root.$off('profile-menu-showed', this.hideNew);
    this.$root.$off('bv::dropdown::show', this.hideNew);
  },

  methods: {
    clickaway() {
      this.$refs.feedPopup.hide();
    },

    showNew(e) {
      e.preventDefault();

      this.$root.$emit('hide-user-menu');

      this.$refs.feedPopup?.toggle();
    },

    hideNew() {
      this.$refs.feedPopup.hide();
    },

    ...mapMutations('feed', [
      'receiveNotification',
      'destroyNotification',
      'increaseUnreadCounter',
      'decreaseUnreadCounter',
    ]),
  },
};
