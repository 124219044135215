export default {
  stripe: {
    setup: {
      success: {
        title: 'Success!',
        text: "You're successfully connected to Stripe. You can now sell training programs through FITR.",
        link: 'Check / update your Stripe connection',
      },

      note: {
        title: 'Important note:',
        text: 'For any future payment, payout or financial queries, please contact Stripe directly.',
      },

      update: {
        title: 'Update in FITR, not Stripe.',
        text: 'Please refrain from updating any of the following in Stripe directly:',

        list: [
          'Program pricing & information',
          'Coupons',
          'Subscriptions',
          'Refunds',
          'Customers',
          'Products',
        ],

        changes: {
          not_auto:
            'Changes will not automatically update in your FITR account if you do!',
          make_changes:
            'Please make any changes in FITR where possible to avoid any confusion and errors. We advise to use Stripe itself for its reporting functionality and if you need to speak to Stripe support for any payment/payout issues.',
        },
      },

      connect: {
        title: 'Connect with Stripe.',
        text: 'Connect a Stripe account to FITR to start selling your programs through the platform.',
        icon_text: 'Connect with Stripe',
      },
    },

    tax: {
      setting_up: 'Automatic Tax Collection on Stripe',
      optional: '(Optional)',
      in_stripe: {
        text_1:
          'Stripe Tax will calculate, collect, and report tax on your clients payments. Automatically collect the right amount of tax and access the reports you need to file returns.',
        text_2: 'Here’s how to setup automatic tax collection on Stripe:',

        list: {
          dashboard: {
            text_1: 'Head to your',
            text_link: 'Stripe Dashboard ',
            text_2: 'to configure your tax settings.',
          },
          set_up: {
            text_1: 'Follow the instructions:',
            text_link: 'Set up Stripe Tax.',
          },
          activate:
            "Once you have completed the above steps, select the 'Activate Stripe tax' button below",
        },
      },

      buttons: {
        activate: {
          normal: 'Activate Stripe tax',
          in_progress: 'Activation in Progress',
          done: 'Stripe tax activated',
        },

        deactivate: 'Deactivate',
      },
    },
  },
};
