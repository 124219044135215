<template>
  <div id="app" class="wrap d-flex flex-column">
    <div
      class="content d-flex justify-content-center align-items-center flex-column m-auto"
    >
      <div class="thumb">
        <span>404</span>
        <img
          src="@/assets/images/not_found.svg"
          alt="Create and sell your first training plan"
        />
      </div>
      <h2>404 error</h2>
      <p>Sorry, we can't find this page.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrap {
  min-height: 100vh;
}

.thumb {
  position: relative;
  margin-bottom: 48px;

  span {
    position: absolute;
    top: 72px;
    left: 50%;
    color: #db2b44;
    font-size: 64px;
    transform: translate(-50%, -50%);
  }
}

h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: $font-weight-normal;
}

p {
  margin-bottom: 40px;
  color: #91939f;
}
</style>
