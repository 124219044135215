import { debounce } from 'lodash-es';

// @vue/component
export default {
  name: 'PageSearch',

  props: {
    /**
     * Placeholder text
     * @type {string}
     * @default 'Search…'
     */
    placeholder: {
      type: String,
      dafault: 'Search…',
    },

    /**
     * Value of v-model
     * @type {string}
     * @default ''
     */
    value: {
      type: String,
      dafault: '',
    },

    /**
     * Is input disabled
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      dafault: false,
    },
  },

  computed: {
    searchString: {
      get() {
        return this.value;
      },
      set(value) {
        this.inputedSearchString(value);
      },
    },
  },

  methods: {
    inputedSearchString: debounce(function (value) {
      this.$emit('input', value);
    }, 300),

    handleSearchSubmit(e) {
      e.target.blur();
    },
  },
};
