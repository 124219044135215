export default [
  {
    path: 'public/:id',
    redirect: to => {
      return { name: 'public-page-view', params: { user_id: to.params.id } };
    }
  },
  {
    path: 'public/:id/overview',
    name: 'coach_public_overview',
    redirect: to => {
      return { name: 'public-page-view', params: { user_id: to.params.id } };
    }
  },
  {
    path: 'public/:id/plans',
    name: 'coach_public_plans',
    redirect: to => {
      return { name: 'public-page-plans', params: { user_id: to.params.id } };
    }
  }
];
