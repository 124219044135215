export default {
  title: 'Inbox',
  main: {
    no_threads: {
      coach: {
        title: 'Your Inbox',
        text:
          'Keep in touch with your client base here. You can send DMs and start group chats called communities.',
      },
      else: {
        title: "You don't have any messages",
        text:
          "When you sign up to training program's, you'll be able to message the coach here.",
      },
    },
    has_threads: {
      v1: {
        title: 'Whoops.',
        text: "There's no results based on your search criteria.",
      },
      v2: {
        coach: {
          title: "You have't launched any communities yet",
          text:
            'Click <mark>Launch community</mark> from any subscription based program to launch a group chat amongst coache(s) & clients.',
        },
        else: {
          title: "You're not part of any communities yet",
          text:
            'Communities are group based chats linked to subscription based programs.',
        },
      },
      v3: {
        coach: {
          title: 'Your Inbox',
          text:
            'Keep in touch with your client base here. You can send DMs and start group chats called communities.',
        },
        else: {
          title: "You don't have any messages",
          text:
            "When you sign up to training program's, you'll be able to message the coach here.",
        },
      },
    },
  },
  remove: {
    title: 'Are you sure?',
    text: {
      line_1: 'This will delete the message history from your side only.',
      line_2: 'As an alternative you can archive this message instead.',
    },
    ok_title: 'Yes, Delete Chat',
  },
};
