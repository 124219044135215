// @vue/component
export default {
  name: 'PlanThumb',

  props: {
    /**
     * Covers list
     * @type {Array}
     */
    covers: Array,

    /**
     * Is square
     * @type {boolean}
     * @default false
     */
    square: {
      type: Boolean,
      default: false,
    },

    /**
     * Size
     * @type {number}
     * @default 48
     */
    size: {
      type: Number,
      default: 48,
    },
  },

  computed: {
    thumb() {
      let cover = this.covers?.find((c) => c.cover_type == 'table_view');

      return cover ? cover : null;
    },

    isEmpty() {
      return !this.thumb;
    },

    thumbStyle() {
      const style = {
        width: this.size + 'px',
        height: this.size + 'px',
      };

      return style;
    },
  },
};
