const Layout = () => import('./Layout.vue');

export default [
  {
    path: 'calendar/:user_id/:plan_id',
    component: Layout,
    name: 'coach_calendar',
    props: true,
    meta: {
      hideFooter: true,
      availableForBlocked: true,
      bodyCssClass: 'theme_white',
    },
  },
];
