export default {
  actions: {
    calendar: 'Go to calendar',
  },
  alert: {
    archived:
      'Sorry, this program has been archived by the coach. View available programs ',
    bought:
      "It's not currently possible to purchase this program due to restrictions on the coach's account. Please contact the coach to let them know.",
    permission:
      'You do not have permission to access this program. Please contact the coaching provider.',
    privacy_setting:
      'It is likely due to a privacy setting on the program and they may need to grant you access. ',
  },
  warning: {
    part1: 'Your last payment was declined.',
    part2: 'to get more information.',
  },
  modal: {
    what_happen: {
      fixed_s1d1: {
        title: 'Start your Program',
        text1: '<b>1.</b> Download the client app.',
        text2: '<b>2.</b> Use the same login credentials for the app.',
        text3:
          '<b>3.</b> Follow your programming and check the welcome message your coach has sent you in your app inbox.',
        text4:
          '<b>4.</b> Your program will commence on <b>%{date}</b>. Only on this date will programming appear in the app.',
        text4_launch:
          '<b>4.</b> Your program will commence on <b>%{date}</b>. You will not be charged until that date.',
      },
      individual: {
        text1: '<b>1.</b> Download the client app.',
        text2: '<b>2.</b> Use the same login credentials for the app.',
        text3:
          '<b>3.</b> Follow your programming and check the welcome message your coach has sent you in your app inbox.',
      },
    },
    payment: {
      text1:
        "We’ve stopped further training from being delivered to your calendar due to a failed payment. We'll automatically try and take payment over the next 7 days however you can manually retry now using the button below. Upon payment being confirmed, training will flow into your calendar so you're fully up to date.",
      text2:
        'If payment isn’t made within 7 days, we’ll automatically cancel your subscription. You can still resume it after this date and start the program where you left off by pressing the ‘resume subscription’ button that will appear.',
      link: 'Set up a new card and pay',
      button: 'Try my payment again',
    },

    incomplete: {
      text1:
        'We couldn’t complete a successful charge from you. Your subscription has an incomplete status now and will be automatically cancelled.',
      text2:
        'We recommend you to try again or set up a new credit card to pay.',
    },

    cancel: {
      text: 'Are you sure you want to cancel your subscription to this program?',
      textSecond:
        'Your access to the program will continue until the natural end of the current billing cycle. At that point no further payment will be taken and access to program content will stop.',
      button: {
        no: 'No, keep my subscription',
        yes: 'Yes, cancel my subscription',
      },
    },
    resume: {
      text: 'Hey! Nice to see you again. Please choose how you want to start the program again.',
      restart: 'Start from the very beginning',
      continue: 'Resume from where I left off',
    },
  },
};
