import { HTTP } from '@/utils/http-common';
import {
  primaryCssTheme,
  secondaryCssTheme,
  systemCssTheme,
} from '@/utils/theme-colors';

export default {
  namespaced: true,

  state() {
    return {
      // {
      //   "web_theme": {
      //     "primary_color": "#F2453D",
      //     "secondary_color": "#3A1740",
      //     "system_text_color": "#2C98F0",
      //     "primary_contrast_color": "#FFFFFF",
      //     "secondary_contrast_color": "#FFFFFF"
      //   },
      //   "mobile_light_theme": {
      //     "primary_color": "#389840",
      //     "primary_background_color"
      //     "primary_text_color"
      //     "secondary_color": "#f5fbf5",
      //     "secondary_background_color"
      //     "system_text_color": "#389840",
      //     "primary_contrast_color": "#FFFFFF",
      //     "secondary_contrast_color": "#FFFFFF"
      //   },
      //   "mobile_dark_theme": {
      //     "primary_color": "#389840",
      //     "primary_background_color"
      //     "primary_text_color"
      //     "secondary_color": "#111111",
      //     "secondary_background_color"
      //     "system_text_color": "#389840",
      //     "primary_contrast_color": "#FFFFFF",
      //     "secondary_contrast_color": "#FFFFFF"
      //   },
      //   "updated_at": "2022-12-17 08:37:46 +0000",
      //   "user": { "id": 23090, "full_name": "🤷‍♂️ WhiteLabel" },
      //   "subdomain": null,
      //   "logo": {
      //     "original": "https://cdn-dev.fitr.training/uploads/theme/logo/7/file.jpg",
      //     "hd": "https://cdn-dev.fitr.training/uploads/theme/logo/7/logo_hd_file.jpg",
      //     "logo": "https://cdn-dev.fitr.training/uploads/theme/logo/7/logo_file.jpg",
      //     "small": "https://cdn-dev.fitr.training/uploads/theme/logo/7/logo_small_file.jpg"
      //   }
      // }

      originalData: null,
    };
  },

  mutations: {
    changeOriginalData(state, payload) {
      state.originalData = payload;
    },

    changePrimary(state, payload) {
      state.originalData.web_theme.primary_color = payload;
    },
    changePrimaryContrast(state, payload) {
      state.originalData.web_theme.primary_contrast_color = payload;
    },

    changeSecondary(state, payload) {
      state.originalData.web_theme.secondary_color = payload;
    },
    changeSecondaryContrast(state, payload) {
      state.originalData.web_theme.secondary_contrast_color = payload;
    },

    changeSystem(state, payload) {
      state.originalData.web_theme.system_text_color = payload;
    },
    changeLogo(state, payload) {
      state.originalData.logo = payload;
    },
    changeSubdomain(state, payload) {
      state.originalData.subdomain = payload;
    },
    changeSubdomainOwner(state, payload) {
      state.originalData.user = payload;
    },
  },

  getters: {
    webTheme(state) {
      return state.originalData?.web_theme;
    },

    primaryColor(_, getters) {
      return getters.webTheme?.primary_color || '#389840';
    },
    primaryContrastColor(_, getters) {
      return getters.webTheme?.primary_contrast_color || '#ffffff';
    },
    primaryCssColors(_, getters) {
      return primaryCssTheme(
        getters.primaryColor,
        getters.primaryContrastColor
      );
    },

    secondaryColor(_, getters) {
      return getters.webTheme?.secondary_color || '#f5fbf5';
    },
    secondaryContrastColor(_, getters) {
      return getters.webTheme?.secondary_contrast_color || '#22242f';
    },
    secondaryCssColors(_, getters) {
      return secondaryCssTheme(
        getters.secondaryColor,
        getters.secondaryContrastColor
      );
    },

    systemColor(_, getters) {
      return getters.webTheme?.system_text_color || '#389840';
    },
    systemCssColors(_, getters) {
      return systemCssTheme(getters.systemColor);
    },

    siteLogo(state) {
      return state.originalData?.logo;
    },
    siteSubdomainName(state) {
      return state.originalData?.subdomain?.sub_domain_name;
    },
    siteSubdomainOwner(state) {
      return state.originalData?.user;
    },

    hasAnyValue(state) {
      return !!(
        state.originalData?.web_theme?.primary_color ||
        state.originalData?.web_theme?.secondary_color ||
        state.originalData?.web_theme?.system_text_color ||
        state.originalData?.mobile_light_theme?.primary_color ||
        state.originalData?.mobile_light_theme?.primary_background_color ||
        state.originalData?.mobile_light_theme?.primary_text_color ||
        state.originalData?.mobile_light_theme?.secondary_color ||
        state.originalData?.mobile_light_theme?.secondary_background_color ||
        state.originalData?.mobile_light_theme?.system_text_color ||
        state.originalData?.mobile_dark_theme?.primary_color ||
        state.originalData?.mobile_dark_theme?.primary_background_color ||
        state.originalData?.mobile_dark_theme?.primary_text_color ||
        state.originalData?.mobile_dark_theme?.secondary_color ||
        state.originalData?.mobile_dark_theme?.secondary_background_color ||
        state.originalData?.mobile_dark_theme?.system_text_color ||
        state.originalData?.logo?.original ||
        state.originalData?.subdomain?.sub_domain_name
      );
    },
  },

  actions: {
    fetchAppTheme({ dispatch }) {
      return HTTP.get('themes').then(({ data }) => {
        dispatch('setTheme', data);
      });
    },

    setTheme({ commit }, data) {
      if (!data) {
        data = {
          web_theme: {
            primary_color: null,
            secondary_color: null,
            system_text_color: null,
            primary_contrast_color: null,
            secondary_contrast_color: null,
          },
          mobile_light_theme: {
            primary_color: null,
            primary_background_color: null,
            primary_text_color: null,
            secondary_color: null,
            secondary_background_color: null,
            system_text_color: null,
            primary_contrast_color: null,
            secondary_contrast_color: null,
          },
          mobile_dark_theme: {
            primary_color: null,
            primary_background_color: null,
            primary_text_color: null,
            secondary_color: null,
            secondary_background_color: null,
            system_text_color: null,
            primary_contrast_color: null,
            secondary_contrast_color: null,
          },
          user: null,
          subdomain: null,
          logo: { original: null, hd: null, logo: null, small: null },
        };
      } else {
        if (data.web_theme?.primary_color) {
          document
            .querySelector('meta[name="theme-color"]')
            .setAttribute('content', data.web_theme?.primary_color);
        }
      }

      commit('changeOriginalData', data);
    },
  },
};
