const colors = [
  '#23a9fc',
  '#6610f2',
  '#8c56e3',
  '#e83e8c',
  '#db2b44',
  '#ff9209',
  '#ffcb09',
  '#389840',
  '#20c997',
  '#17a2b8',
];

// @vue/component
export default {
  name: 'ColorPicker',

  props: {
    /**
     * Value of v-model
     * @type {string}
     */
    value: {
      type: String,
    },
  },

  data() {
    return {
      colors,
    };
  },

  mounted: function () {
    // set default value if empty

    if (!this.value) {
      this.$nextTick(() => {
        let color = colors[Math.floor(Math.random() * colors.length)];

        this.$emit('input', color);
      });
    }
  },
};
