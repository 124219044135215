import ActionCable from 'actioncable';

// ActionCable.startDebugging();

/**
 * @type {ActionCable.Cable}
 */
let cable;

/**
 * connect to cable
 */
function connect() {
  // console.log('connect');

  let token = window.localStorage.getItem('access_token');

  cable = ActionCable.createConsumer(
    `${import.meta.env.VITE_WS_BASE}?token=${token}`
  );
}

/**
 *
 */
function disconnect() {
  // console.log('disconnect');

  if (cable) {
    cable.disconnect();
  }
}

/**
 *
 * @param {string} name - Name of the channel
 * @param {Function} received - callback function
 * @param {object} params - optional params
 * @returns {ActionCable.Channel} - returns channel
 */
function subscribe(name, received, params) {
  // console.log('subscribe');

  let channel = {
    channel: name,
  };

  if (params) {
    channel = { ...channel, ...params };
  }

  return cable.subscriptions.create(channel, { received });
}

/**
 *
 * @param {ActionCable.Channel} channel - channel that will be unsubscribed
 */
function unsubscribe(channel) {
  // console.log('unsubscribe');

  channel.unsubscribe();
}

export { connect, disconnect, subscribe, unsubscribe };
