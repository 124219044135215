export default {
  info: {
    text_1: 'A client is coming to the end of their program',
    text_2: 'A client needs their schedule updated soon',
    text_3: 'A Client needs their schedule updated ASAP'
  },
  questions: {
    user: {
      fixed: 'Choose your own start date after downloading the program and you can follow the programming from your calendar view.',
      membership: 'Choose your own start date and follow the programming from your calendar view. You’ll always see 7 days in advance.',
      individual: 'Follow your programming from your calendar view. Future training visibility is set by your coach.',
      one_many: 'Follow your programming from your calendar view. Future training visibility is set by your coach.'
    },
    coach: {
      fixed: 'Clients choose their own start date after downloading the program.',
      membership: 'Make sure you keep updating the training schedule over time.',
      individual: 'Make sure you keep updating the client\'s training schedule over time.',
      one_many: 'Make sure you keep updating the training schedule over time.'
    }
  }
}