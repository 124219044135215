import Store from '@/store/store.js';

const PlansPage = () => import('./plans/index.vue');

export default [
  {
    path: 'choose_your_plan',
    component: PlansPage,
    name: 'choose_your_plan',
    meta: {
      availableForBlocked: true,
    },

    beforeEnter: (to, from, next) => {
      if (!Store.getters.isFinancialAviable) {
        next({ name: 'coach_profile_subscription', replace: true });
      } else {
        next();
      }
    },
  },
];
