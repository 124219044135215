import { mapGetters } from 'vuex';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common.js';

import UserAvatar from '@/shared/user-avatar/index.vue';

import TrialStatus from '@/shared/app-header/accounts-list/trial-status/index.vue';

// @vue/component
export default {
  name: 'AccountsList',

  components: {
    UserAvatar,
    TrialStatus,
  },

  computed: {
    ...mapGetters(['filteredAccounts', 'hasCoachAnotherAccounts']),
  },

  methods: {
    accountTitle(account) {
      if (account.account_kind == 'private') {
        return account.full_name;
      } else {
        return account.title;
      }
    },

    accountSubTitle(account) {
      if (account.account_kind == 'private') {
        return 'Coach';
      } else {
        return account.account_kind;
      }
    },

    show() {
      this.$refs.modal.show();
    },

    changeAccount(id, path = '/') {
      HTTP.put('accounts/switch', { id })
        .then(() => {
          window.localStorage.removeItem('fitrProgramsFilters');

          window.location.assign(path);
        })
        .catch(HttpErrorsHandling);
    },

    goToBilling(account) {
      let route;

      if (account.subscriptions?.plan?.id) {
        route = this.$router.resolve({ name: 'coach_profile_billing' });
      } else {
        route = this.$router.resolve({ name: 'coach_profile_subscription' });
      }

      this.changeAccount(account.id, route.href);
    },
  },
};
