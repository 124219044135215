import StatusWithIcon from '@/shared/status-with-icon/index.vue';

// @vue/component
export default {
  name: 'ClientStatusBage',

  components: {
    StatusWithIcon,
  },

  props: {
    /**
     * Program Object
     * @type {object}
     * @default {}
     */
    program: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    isProgramEmpty() {
      return (
        (this.program.start_day === null && this.program.end_day === null) ||
        (this.program.schedule_started_at === null &&
          this.program.schedule_ended_at === null)
      );
    },
  },
};
