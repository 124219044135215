import tinycolor from 'tinycolor2';

/**
 *
 * @param {string} primaryColor - primary color string
 * @param {string} contrastColor - contrast color string
 * @returns {{[key: string]: string}} - custom properties variables object
 */
export function primaryCssTheme(primaryColor, contrastColor) {
  const color = tinycolor(primaryColor);

  const primary_color = color.toHexString();
  const primary_dark_color = color.darken(7.5).toHexString();
  const primary_shadow_color = color.lighten(60).toHexString();

  return {
    '--primary-color': primary_color,
    '--primary-dark-color': primary_dark_color,
    '--primary-shadow-color': primary_shadow_color,
    '--primary-text-color': contrastColor,
    '--primary-contrast-color': contrastColor,
  };
}

/**
 *
 * @param {string} secondaryColor - secondary color string
 * @param {string} contrastColor - contrast color string
 * @returns {{[key: string]: string}} - custom properties variables object
 */
export function secondaryCssTheme(secondaryColor, contrastColor) {
  const color = tinycolor(secondaryColor);
  const luminance = color.getLuminance();

  const secondary_color = color.toHexString();
  const secondary_dark_color = color.darken(7.5).toHexString();
  const secondary_shadow_color = color.lighten(7.5).toHexString();
  const secondary_text_color =
    luminance < 0.5
      ? color.lighten(50).toHexString()
      : color.darken(50).toHexString();

  return {
    '--secondary-color': secondary_color,
    '--secondary-dark-color': secondary_dark_color,
    '--secondary-shadow-color': secondary_shadow_color,
    '--secondary-text-color': secondary_text_color,
    '--secondary-contrast-color': contrastColor,
  };
}

/**
 *
 * @param {string} systemColor - system color string
 * @returns {{[key: string]: string}} - custom properties variables object
 */
export function systemCssTheme(systemColor) {
  const color = tinycolor(systemColor);

  const system_color = color.toHexString();
  const system_dark_color = color.darken(7.5).toHexString();
  const system_shadow_color = color.lighten(50).toHexString();

  return {
    '--system-text-color': system_color,
    '--system-text-dark-color': system_dark_color,
    '--system-text-shadow-color': system_shadow_color,
  };
}
