// @vue/component
export default {
  name: 'ChecklistWrapper',


  props: {
    /**
     * Is Mobile view
     * @type {boolean}
     * @default false
     */
    isMobile: {
      type: Boolean,
      default: true,
    },

    /**
     * Is Shown
     * @type {boolean}
     * @default false
     */
    isShown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isShownContent: {
      get() {
        return this.isShown;
      },

      set(selected) {
        this.$emit('changeShown', selected);
      },
    },
  },
};
