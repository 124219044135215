import ReleaseNotesCounter from '@/components/release-notes/notes-counter/index.vue';

import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'HelpDropdown',

  components: {
    ReleaseNotesCounter,
  },

  props: {
    /**
     * indicating is header empty
     * @type {boolean}
     * @default false
     */
    isEmptyHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDisabledNotes() {
      return this.isAccountBlocked && this.isUserCoach;
    },
    ...mapGetters('subscription', ['isAccountBlocked']),
    ...mapGetters(['isUserCoach', 'isCoachOverallAccount']),
  },

  methods: {
    openLiveChat() {
      if (window.cookiehub?.hasConsented('preferences')) {
        window.Intercom && window.Intercom('show');
      } else {
        window.cookiehub?.openSettings && window.cookiehub.openSettings();
      }
    },
  },
};
