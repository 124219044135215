import Store from '@/store/store.js';

const Layout = () => import('./layout/index.vue');
const List = () => import('./list/index.vue');

export default [
  {
    path: '/feed',
    component: Layout,

    children: [
      {
        path: '',
        redirect: { name: 'feed_overview' },
      },
      {
        path: 'overview',
        name: 'feed_overview',
        component: List,
        props: { type: 'overview' },

        meta: {
          availableForBlocked: true,
        },

        // redirect: () => {
        //   if (Store.state.currentUser.role === 'coach' && Store.getters["subscription/isAccountBlocked"]) {
        //     return { name: 'feed_invites' };
        //   }
        //   // в функцию в качестве аргумента передаётся маршрут
        //   // возвращаемым значением должна быть строка или объект пути
        // }
      },
      {
        path: 'warnings',
        name: 'feed_warnings',
        component: List,
        props: { type: 'content_warnings' },
      },
      {
        path: 'activity',
        name: 'feed_activity',
        component: List,
        props: { type: 'client_activity' },
      },
      {
        path: 'financial',
        name: 'feed_financial',
        component: List,
        props: { type: 'financial' },

        beforeEnter: function (to, from, next) {
          if (
            Store.getters.isBusinessAccount &&
            !Store.getters.isBusinessOwner
          ) {
            next({ name: 'feed_overview', replace: true });
          } else {
            next();
          }
        },
      },
      {
        path: 'invites',
        name: 'feed_invites',
        component: List,
        props: { type: 'invite' },
      },

      {
        path: 'leaderboards',
        name: 'feed_leaderboards',
        component: List,
        props: { type: 'chat_mention' },
      },
    ],
  },
];
