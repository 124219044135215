// @vue/component
export default {
  name: 'TrialStatus',

  props: {
    /**
     * Subscription data
     * @type {object}
     */
    subscription: Object,
  },

  computed: {
    isSubsPastDue() {
      return this.subscription.active && this.subscription.state == 'past_due';
    },

    isSubsActive() {
      return (
        (this.subscription.active && this.subscription.state) ||
        this.subscription.free_usage
      );
    },

    isCancelAtPeriodEnd() {
      return this.isSubsActive && this.subscription?.cancel_at_period_end;
    },

    isTrialActive() {
      return (
        !this.subscription.free_usage &&
        this.subscription.trial &&
        !!this.subscription.trial_end_date
      );
    },

    isTrialEnded() {
      return !this.subscription.active;
    },

    trialCounter() {
      if (this.isTrialActive) {
        let ended_at = this.$moment(
          this.subscription.trial_end_date,
          'YYYY-MM-DD HH:mm:ss Z'
        );
        let current = this.$moment();

        let diff = ended_at.diff(current);
        let duration = this.$moment.duration(diff);

        return parseInt(duration.asDays(), 10);
      }

      return null;
    },
  },
};
