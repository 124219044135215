import { mapState } from 'vuex';

import AttachmentsList from '@/shared/attachments-list/index.vue';
import UserAvatar from '@/shared/user-avatar/index.vue';

// @vue/component
export default {
  name: 'PlanDetails',

  components: {
    AttachmentsList,
    UserAvatar,
  },

  props: {
    /**
     * Attachments list
     * @type {Array}
     * @default []
     */
    attachments: {
      type: Array,
      default: function () {
        return [];
      },
    },

    /**
     * Description text
     * @type {string}
     */
    description: String,

    /**
     * Is skip sales page
     * @type {boolean}
     * @default false
     */
    skipSalesPage: {
      type: Boolean,
      default: false,
    },

    /**
     * Is owner
     * @type {object}
     * @default {}
     */
    owner: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  computed: {
    isDescriptionExist() {
      return (
        !this.skipSalesPage &&
        this.description != undefined &&
        this.description.length > 0
      );
    },
    isAttachmentsExist() {
      return (
        !this.skipSalesPage &&
        this.attachments != undefined &&
        this.attachments.length > 0
      );
    },
    isMainInfoExist() {
      return this.isDescriptionExist || this.isAttachmentsExist;
    },

    isOwner() {
      return this.currentUser && this.owner.id == this.currentUser.id;
    },

    ownerUserType() {
      return this.owner.type_account.charAt(0).toLowerCase();
    },

    ...mapState(['currentUser']),
  },
};
