import { debounce } from 'lodash-es';
import { mapGetters, mapActions, mapState } from 'vuex';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// @vue/component
export default {
  name: 'ModalKnowledgeNotes',

  data() {
    return {
      note: {},
      // isBusy: false,
      dontShow: false,
      popupName: '',
    };
  },

  computed: {
    title() {
      return this.note?.title;
    },

    isFixedPlan() {
      return this.planType == 'fixed';
    },
    isMembershipPlan() {
      return this.planType == 'membership';
    },
    isBespokePlan() {
      return this.planType == 'individual';
    },
    isOneManyPlan() {
      return this.planType == 'one_many';
    },

    ...mapState('plan', ['planType']),

    ...mapGetters(['viewedPopups']),
    ...mapGetters(['isBusinessOwner']),
  },

  watch: {
    $route(to) {
      if (
        this.popupName != 'open_individual_schedule' &&
        this.isBusinessOwner
      ) {
        this.popupName = to.meta?.knowledge_type;
      }

      // Step 2 - Program Schedule (FL)
      // Step 2 - Program Schedule (S1D1)
      if (this.popupName == 'program_schedule') {
        if (this.isFixedPlan) {
          this.popupName = `${this.popupName}_fl`;
        } else if (this.isMembershipPlan) {
          this.popupName = `${this.popupName}_s1d1`;
        }
      }

      if (
        this.popupName &&
        this.viewedPopups &&
        !this.viewedPopups.includes(this.popupName)
      ) {
        // Step 2 - Review & Publish (1-2-1 + GRC)
        if (
          this.popupName == 'review_and_publish_custom_grc' &&
          (this.isFixedPlan || this.isMembershipPlan)
        ) {
          return false;
        }

        // Overview step after publishing - (1-2-1 Program)
        // if (
        //   this.popupName == 'schedule_step_after_publishing_custom' &&
        //   !to.params.justPublished &&
        //   !this.isBespokePlan
        // ) {
        //   return false;
        // }

        this.show(this.popupName);
      }
    },
  },

  mounted() {
    // The time you go into a client’s 1-2-1 program - (1-2-1 Program)
    this.$root.$on('show-knowledge-custom-plan-popup', () => {
      this.popupName = 'open_individual_schedule';

      if (this.viewedPopups && !this.viewedPopups.includes(this.popupName)) {
        this.show(this.popupName);
      }
    });
  },

  methods: {
    /**
     * Hide modal
     */
    hide() {
      this.$refs.knowledgeNotesModal.hide();
    },

    show: debounce(function (id) {
      HTTP.get(`notes/knowledge?slug=${id}`)
        .then(({ data }) => {
          this.note = data;

          this.$refs.knowledgeNotesModal.show();
        })
        .catch(HttpErrorsHandling);
    }, 600),

    /**
     * On modal hidden
     */
    onModalHidden() {
      if (this.dontShow) {
        this.makePopupViewed({ name: this.popupName });
      }

      if (
        ['program_creation',].includes(this.popupName)
      ) {
        this.$root.$emit('hide-knowledge-notes');
      }

      this.popupName = '';
      this.note = {};
      this.dontShow = false;    },

    ...mapActions(['makePopupViewed']),
  },
};
