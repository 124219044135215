export default {
  layout: {
    title: 'My Feed',
    is_coach: {
      title: 'Your Feed',
      text: "Once you're up and running activity about your coaching business will show here.",
      text_for_blocked_coach: 'Invites from other teams will appear here.',
      text_for_blocked_bg:
        "Once you're up and running activity about your coaching business will show here.",
      link: 'Create a Training Program',
    },
    not_coach: {
      title: 'Oops.',
      text: "It seems like you don't have any notifications yet.",
    },
  },
  list: {
    title: 'Oops.',
    alt_title: 'Hmmm.',
    leaderboards:
      'Updates such as comments on leaderboards will appear here over time.',
    is_coach: {
      invite:
        'Invitations to and from other teams will appear here when applicable. Learn about managing multiple teams <a href="https://intercom.help/fitr/en/articles/3719199-invite-add-coaches-to-my-coaching-account" target="_blank">here</a>.',
      not_invite:
        'Once you start coaching clients on training programs, updates will feed here over time.',
    },
    not_coach: {
      text: "Seems like you don't have any notifications yet.",
    },
  },
};
