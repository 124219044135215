<template>
  <div class="container static-page">
    <h1>Cookie Policy</h1>

    <h2>What are cookies?</h2>
    <p>
      Cookies are tiny text files which are stored on Your computer when You
      visit certain web pages. We (and almost all other websites) use these to
      keep track of certain actions You take, for example to remember You when
      You return to Our website site.
    </p>
    <p>
      It’s important to note that the cookies We use do not harm Your computer
      in any way and We do not store personally identifiable information in the
      cookies We create.
    </p>
    <p>
      If You’d like to read more information about what cookies are and how
      they’re used please visit: <a
        href="http://www.aboutcookies.org.uk"
        target="_blank"
        >http://www.aboutcookies.org.uk</a
      >
    </p>
    <p>
      In some cases, You may find cookies stored by selected partners We work
      with for remarketing purposes. You may manage these cookies by visiting
      the <a
        href="https://networkadvertising.org/managing/opt_out.asp"
        target="_blank"
        >Network Advertising Initiative Website</a
      > where You can choose to opt out of specific cookies.
    </p>
    <p>
      Some browsers such as Google Chrome also offer a feature called Do Not
      Track (DNT), which tells websites not to send cookie information to Your
      browser so that that You cannot by remarketed to. To Manage Your DNT
      settings please refer to Your browser’s cookie and privacy settings.
    </p>

    <h3>Chrome</h3>
    <p>Settings > Advanced > Privacy and security</p>
    <h3>Safari</h3>
    <p>Settings > Safari > Privacy & Policy</p>
    <h3>Android Browsers</h3>
    <p>Settings > Device > Applications > Internet > Privacy</p>
    <p>For all other browsers, please refer to online help guides.</p>

    <h2>More information on Our cookies</h2>
    <p>
      We store cookies on Your computer so We can do things like remember Your
      product interests and know when You last visited the website. These
      cookies do not hold any personally identifying information and We only
      store them for as long as is needed.
    </p>
    <p>
      Please note that Our cookies are designed to help provide a functional and
      tailored experience. You may opt-out from this service by updating Your
      browser settings. Please refer to Do Not Track browser settings detailed
      above.
    </p>

    <h2>Third party cookies</h2>
    <p>
      In some cases, You will find third party cookies stored on Your computer
      from one or more of Our trusted partners below.
    </p>

    <h2>Targeted advertising</h2>
    <p>
      We work with several advertising platforms that provide targeted
      advertising solutions. You can Your privacy settings for individual
      partners below.
    </p>

    <h3>Facebook</h3>
    <p>
      We use Facebook to deliver targeted advertisement based on Your interests
      shared with the platform. You can manage all Your privacy setting for the
      platform at the link below.
    </p>
    <p>
      <a href="https://www.facebook.com/ads/preferences/edit/" target="_blank"
        >https://www.facebook.com/ads/preferences/edit/</a
      >
    </p>
    <h3>Google</h3>
    <p>
      Manage Your targeted advertising settings for the entire google product
      suite including YouTube and Chrome browser at the link below.
    </p>
    <p>
      <a
        href="https://adssettings.google.com/authenticated#fyRr4c"
        target="_blank"
        >https://adssettings.google.com/authenticated#fyRr4c</a
      >
    </p>

    <h2>Analytical cookies</h2>
    <p>
      You may also find cookies stored on Your computer which allows Us to track
      Your engagement with various campaigns provided by one of Our trusted
      partners or affiliates programmes. These cookies are used to track success
      campaigns and do not store any personally identifiable information.  If
      You would not like to be tracked for analytical purposes, You can disable
      sending of cookie data within Your browser.
    </p>
    <p>
      You may also wish to read Our <router-link
        to="/pages/privacy_policy"
        target="_blank"
        >Privacy Policy</router-link
      > to view  a list of out trusted of third party partners that may store
      cookies to help use gauge performance of marketing campaigns.
    </p>
    <p>
      We will update Our Cookie Policy from time to time and We suggest You
      check back as We may not always notify You of changes.
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style src="@/assets/styles/_static-pages.scss" lang="scss" scoped></style>
