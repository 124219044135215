<template>
  <div class="profile wrap d-flex flex-column">
    <app-header />

    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/shared/app-header/index.vue';

export default {
  components: {
    AppHeader,
  },
  data: () => ({}),
};
</script>

<style src="../profile.scss" lang="scss" scoped></style>
