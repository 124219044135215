export default {
  cover_types: {
    table_view: {
      title: 'Desktop / Table / Client App View',
      descr:
        'This is how your program cover media will look on a laptop, your table view and in the client app on the main calendar screen.',
      hint: "The optimum ratio for this area is <span class='text-nowrap'>'1 : 1'</span> <span class='text-nowrap'>(500px : 500px)</span>",
    },
    tiled_view: {
      title: 'Tiled / Mobile view',
      descr:
        'This is how your cover media will appear on mobile and when we use the tiled view on your public page.',
      hint: "The optimum ratio for this area is <span class='text-nowrap'>'1.94 : 1'</span> <span class='text-nowrap'>(360px : 186px)</span>",
    },
  },
};
