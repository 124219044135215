import UploadButton from '@/shared/buttons/upload.vue';

// @vue/component
export default {
  name: 'ModalPlanCover',

  components: {
    UploadButton,
  },

  props: {
    /**
     * @type {object} cover
     * @description cover object
     */
    cover: Object,

    /**
     * @type {Array} covers
     * @description Array of covers
     */
    covers: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  computed: {
    coverPreview() {
      return this.covers.length > 0
        ? this.covers.find((c) => c.cover_type == this.cover.cover_type)
        : null;
    },

    coverStyle() {
      return `max-width: ${this.cover.width}px; max-height: ${this.cover.height}px`;
    },

    coverSrc() {
      if (this.coverPreview?.blob) {
        return this.coverPreview.blob;
      } else if (this.coverPreview?.media?.type == 'youtube') {
        return this.coverPreview.media.cover_hd;
      } else if (this.coverPreview?.media?.type == 'video') {
        return this.coverPreview.media.cover_hd;
      } else if (this.coverPreview?.large) {
        return this.coverPreview.large;
      }
      return null;
    },
  },

  methods: {
    editCover() {
      // for legacy covers where is no media.src
      if (
        this.coverSrc &&
        this.coverPreview.media &&
        !this.coverPreview.media.src
      ) {
        this.coverPreview.media.src = this.coverSrc;
      }

      this.$emit('editCover', {
        type: this.cover.cover_type,
        media: this.coverPreview?.media,
      });
    },

    removeCover() {
      this.$emit('removeCover', {
        type: this.cover.cover_type,
      });
    },
  },
};
