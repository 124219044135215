<template>
  <!-- <div v-if="offlineReady || needRefresh" class="pwa-toast" role="alert">
    <div class="message">
      <span v-if="offlineReady"> App ready to work offline </span>
      <span v-else>
        New content available, click on reload button to update.
      </span>
    </div>
    <button v-if="needRefresh" @click="updateServiceWorker()">Reload</button>
    <button @click="close">Close</button>
  </div> -->

  <b-modal
    v-model="needRefresh"
    :title="$t('en.update_modal.title')"
    hide-header-close
    no-close-on-backdrop
    size="572"
    lazy
  >
    <p class="mb-0">{{ $t('en.update_modal.text') }}</p>
    <template #modal-footer="{ close }">
      <button class="btn btn-outline-dark" type="button" @click.prevent="close">
        {{ $t('en.update_modal.cancel') }}
      </button>
      <button class="btn btn-primary" type="button" @click.prevent="reloadNow">
        {{ $t('en.update_modal.ok') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
// import useRegisterSW from '@/mixins/useRegisterSW';

// const intervalMS = 60 * 60 * 1000;

export default {
  name: 'ReloadPrompt',
  // mixins: [useRegisterSW],

  data() {
    return {
      needRefresh: false,
    };
  },

  created() {
    window.addEventListener('showUpdateModal', this.showUpdateModal);
  },

  beforeDestroy() {
    window.removeEventListener('showUpdateModal', this.showUpdateModal);
  },

  methods: {
    showUpdateModal() {
      this.needRefresh = true;
    },
    reloadNow() {
      window.location.reload();
    },

    //   handleSWManualUpdates(r) {
    //     r &&
    //       setInterval(() => {
    //         r.update();
    //       }, intervalMS);
    //   },

    //   reloadNow() {
    //     if (this.offlineReady) {
    //       window.location.reload();
    //     } else {
    //       this.updateServiceWorker();
    //     }
    //   },
  },
};
</script>
