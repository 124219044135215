const Layout = () => import('./layout/index.vue');
const Library = () => import('./my-library/index.vue');

export default [
  {
    path: 'challenges',
    component: Layout,

    children: [
      {
        path: '',
        redirect: { name: 'challenges-library' },
      },

      {
        path: 'library',
        component: Library,
        name: 'challenges-library',
        props: {
          libraryKind: 'challenge',
        },
        meta: {
          knowledge_type: 'challenge_note',
          footerCssClass: 'footer-with-fixed-btn',
        },
      },

      {
        path: 'fitr-library',
        component: Library,
        name: 'challenges-fitr-library',
        props: {
          libraryKind: 'fitr_challenge',
        },
      },
    ],
  },
];
