import { Portal } from 'portal-vue';

import { cloneDeep, isEqual, sortBy } from 'lodash-es';

import { mixin as clickaway } from 'vue-clickaway';

import MultiselectField from '@/shared/multiselect-field/index.vue';

import DaterangeFilter from '@/shared/page-filter/daterange/index.vue';
import DateRange from '@/shared/date-range/index.vue';

const daterangeResultText = function (value) {
  if (value?.from && value?.to) {
    return `${value.from}—${value?.to}`;
  } else if (value?.from) {
    return `from ${value.from}`;
  } else if (value?.to) {
    return `to ${value.to}`;
  } else {
    return value;
  }
};

const dateResultText = function (date, moment) {
  return moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
};

// @vue/component
export default {
  name: 'PageFilter',

  components: {
    Portal,
    MultiselectField,
    DaterangeFilter,
    DateRange,
  },

  mixins: [clickaway],

  props: {
    /**
     * Value
     * @type {object} - type object 
     * @default {} - default value = empty object
     */
    value: {
      type: Object,
      dafault: {},
    },

    /**
     * Multiple
     * @type {boolean} - type booleand 
     * @default false - default value = false
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * Portal Name
     * @type {string} - type String 
     * @default 'filter-results' - default value = 'filter-results'
     */
    portalName: {
      type: String,
      default: 'filter-results',
    },

    /**
     * Dropdown Size
     * @type {string} - type String 
     * @default 'md' - default value = 'md'
     */
    dropdownSize: {
      type: String,
      default: 'md',
    },

    /**
     * Is Show Badges
     * @type {boolean} - type Boolean 
     * @default true - default value = true
     */
    isShowBadges: {
      type: Boolean,
      default: true,
    },

    /**
     * Additional Style Class
     * @type {string} - type String
     * @default '' - default value = empty string
     */
    additionalStyleClass: {
      type: String,
      default: '',
      validator: function (value) {
        return ['hyrox-library', ''].indexOf(value) !== -1;
      },
    },
  },

  data: function () {
    return {
      isMobileLayout: false,

      isModalShow: false,

      localFilters: {},

      tmp: [],
    };
  },

  computed: {
    sortedLocalFilters() {
      let sorted = sortBy(Object.entries(this.localFilters), [
        function (e) {
          return e[1].position;
        },
      ]);

      return sorted.reduce((a, v) => {
        a[v[0]] = this.localFilters[v[0]];
        return a;
      }, {});
    },

    filterResults() {
      if (!this.isShowBadges) {
        return false;
      }
      let filtered = Object.keys(this.value)
        .filter((key) => {
          if (this.value[key].type == 'multiple') {
            // for Array
            return !isEqual(
              sortBy(this.value[key].value),
              sortBy(this.value[key].default)
            );
          } else {
            return this.value[key].value != this.value[key].default;
          }
        })
        .reduce((obj, key) => {
          if (this.value[key].type == 'daterange') {
            obj[key] = {
              name: key,
              value: daterangeResultText(this.value[key].value),
            };
          } else if (this.value[key].type == 'daterange-picker') {
            if (this.value[key].value?.length > 0) {
              obj[key] = {
                name: key,
                value: `${dateResultText(
                  this.value[key].value[0],
                  this.$moment
                )} - ${dateResultText(this.value[key].value[1], this.$moment)}`,
              };
            }
          } else if (Array.isArray(this.value[key].value)) {
            this.value[key].value.forEach((v) => {
              if (typeof v === 'string') {
                let selected = this.value[key].options.find(
                  (o) => o.value == v
                );

                obj[v] = {
                  key: key,
                  name: v,
                  value: selected?.text,
                };
              } else {
                // for Array of counries
                obj[v.value] = {
                  key: key,
                  name: v.value,
                  value: v.title,
                };
              }
            });
          } else {
            let selected = this.value[key].options.find(
              (v) => v.value == this.value[key].value
            );

            obj[key] = {
              name: key,
              value: selected.text,
            };
          }

          return obj;
        }, {});

      return filtered;
    },

    hasFilterResults() {
      return Object.keys(this.filterResults).length > 0;
    },
  },

  watch: {
    value: {
      handler: function (value) {
        if (!isEqual(this.localFilters, value)) {
          this.localFilters = cloneDeep(value);
        }
      },
      immediate: true,
    },
  },

  created: function () {
    this.mq = window.matchMedia('(max-width: 991.98px)');

    this.updateMatches();
    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy() {
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    filterChanged() {
      if (!this.isMobileLayout) {
        this.$emit('input', this.localFilters);
      }
    },

    apply() {
      this.$emit('input', this.localFilters);

      this.hide();
    },

    clear() {
      for (let key in this.localFilters) {
        if (Array.isArray(this.localFilters[key].default)) {
          // for Array of counries
          this.localFilters[key].value = cloneDeep(
            this.localFilters[key].default
          );
        } else {
          this.localFilters[key].value = this.localFilters[key].default;
        }
      }

      this.$emit('input', this.localFilters);
      this.hide();
    },

    unselect(filter) {
      if (filter.key) {
        let index = this.localFilters[filter.key].value.findIndex((v) => {
          if (typeof v === 'string') {
            return v == filter.name;
          } else {
            // for Array of counries
            return v.id == filter.name;
          }
        });

        if (index != -1) {
          this.localFilters[filter.key].value.splice(index, 1);
        }
      } else {
        this.localFilters[filter.name].value =
          this.localFilters[filter.name].default;
      }

      this.$emit('input', this.localFilters);
    },

    toggle() {
      this.isModalShow = !this.isModalShow;
    },

    show() {
      this.isModalShow = true;
    },

    hide() {
      this.isModalShow = false;
    },

    away(e) {
      if (!e.target.closest('.mx-datepicker-popup')) {
        this.hide();
      }
    },

    onModalHidden() {
      this.localFilters = cloneDeep(this.value);
    },
  },
};
