import UserAvatar from '@/shared/user-avatar/index.vue';
import PlanThumb from '@/shared/plan-thumb/index.vue';

// @vue/component
export default {
  name: 'UserCard',

  components: {
    UserAvatar,
    PlanThumb,
  },

  props: {
    /**
     * Title text
     * @type {string}
     */
    title: String,

    /**
     * Thumb link
     * @type {string}
     */
    thumb: String,

    /**
     * Prefix text
     * @type {string}
     */
    prefix: String,

    /**
     * Is square
     * @type {boolean}
     * @default false
     */
    square: {
      type: Boolean,
      default: true,
    },

    /**
     * Is plan
     * @type {boolean}
     * @default false
     */
    plan: {
      type: Boolean,
      default: false,
    },

    /**
     * Covers list
     * @type {Array}
     */
    covers: {
      type: Array,
    },

    /**
     * Size of image
     * @type {number}
     */
    size: Number,

    /**
     * Show dor indicator above plan thumb
     * @type {boolean}
     * @default false
     */
    indicator: {
      type: Boolean,
      default: false,
    },

    /**
     * Unique id for tooltip - if filled, show tooltip
     * @type {string|number}
     * @default null
     */
    uniqueIdForTooltip: {
      type: [String, Number],
      default: null,
    },
  },

  computed: {
    avatar() {
      return {
        full_name: this.title,
        avatar_url: this.thumb,
      };
    },

    isPlan() {
      return this.plan;
    },
  },
};
// comment)
