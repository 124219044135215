export default {
  header: "Upgrade your '{type}' account",
  sub_header:
    'For continued access to all our features, please upgrade your account below',
  annual_compared_to_monthly:
    'Charged on an annual basis. Saving when compared to monthly plan.',
  charged_at_end_of_trial: 'First payment date will be',

  purchase_plan: 'Complete purchase',

  blocked_page: {
    expired: {
      title: 'Oops! Please upgrade your account',
      text: 'To get unlimited access to FITR, upgrade your account',
    },
    start_trial: {
      block_1: {
        title: 'What is your personal account?',
        text: 'Every coach on FITR has a ‘personal account’. It allows you to create and link into multiple teams if you need to.',
        list: {
          title: 'At no extra cost you can:',
          point1: 'Edit general settings;',
          point2: 'Manage invitations from other teams;',
          point3: 'Switch between teams you’re a part of.',
        },
        info: {
          line_1:
            'You <b>DON’T</b> need to activate your personal account trial to create or work in other teams.',
          line_2:
            'Switch accounts using the dropdown option from your profile picture.',
          line_3:
            'Need more help? <a href="https://intercom.help/fitr/en/articles/4781845-what-is-my-personal-account" target="_blank">Click here</a>.',
        },
      },
      block_2: {
        title: 'When do I need to activate this trial?',
        text: 'Activating the trial will let you:',
        list: {
          point1: 'Create your own training programs;',
          point2: 'Manage an independent client list by yourself; and',
          point3: 'Link a new bank account.',
        },

        info: {
          line_1:
            "Need to work with other coaches? Create a new team using the 'create new account' dropdown option from your profile picture.",
          line_2:
            'Remember, you <b>DON’T</b> need to activate the trial to create or work in other teams.',
        },
      },
      alert: {
        price: '£49.99 / Month',
        text: 'Transaction fees apply on program sales',
        button: 'Start your 14 day trial',
      },
    },
    past_due: {
      title: 'Your account is now restricted.',
      text: 'Please upgrade to a paid plan to continue coaching on FITR.',
    },
  },

  warnings: {
    clients_before_limit: "You're approaching the client limit of your plan. Please upgrade to increase your limit.",
    clients_limit: 'You’ve reached the client limit of your plan. Please upgrade to increase your limit.',
    trial_has_expired:
      'Your trial period has expired, please upgrade your account to remove the restrictions.',
    trial_with_clients:
      'You have active clients and {left} left in your trial. Upgrade today and you’ll only be charged when your trial ends.',
    trial_without_clients:
      'You have {left} left in your trial. Upgrade today and you’ll only be charged when your trial ends.',

    renew_with_clients:
      'You will only have basic access to your clients from {date}. Renew now to avoid disruption.',
    renew_without_clients: 'You have full access until {date}.',

    payment_failed:
      'A recent payment failed. Please check your ‘Billing’ page for information.',

    canceled:
      'You only have access to basic features. Upgrade today for unrestricted access!',

    for_invited:
      "'{name}' is currently restricted. Please contact the owner for information.",
  },

  notify: {
    successfully_subscribed: 'You have been successfully subscribed!',
    successfully_switched: 'You have been successfully switched!',
  },

  tooltips: {
    transaction_fee:
      'A transaction fee of 5% will be applied when charging for your programs. This includes the payment processing fee paid directly to Stripe, and covers client billing and automatic bank transfers on your behalf.',
  },

  payment_cant_be_completed:
    'Payment failed due to a card error, please retry.',
  plan_cant_be_completed:
    'Subscription failed due to a card error, please retry.',

  cancel_subscription: {
    title: 'Cancel your plan?',
    with_clients: {
      text_1:
        'Your {plan} subscription will end on <b>{date}</b> and you still have active clients.',
      text_2:
        "After this date you can't create or sell programs (or deliver any for free) and you'll only have access to basic features to manage your current clients.",
    },
    without_clients: {
      text_1: 'Your {plan} subscription will end on <b>{date}</b>.',
      text_2:
        "After this date you can't create or sell programs (or deliver any for free) and you'll only have access to basic features to manage your current clients.",
    },
    white_label:
      "{text} to customise the web platform, coach and client apps. All branding will return to FITR's default green",
    must_upgrade_to_the_unlimited:
      'If you wish to add White label in the future you must upgrade to the Unlimited plan.',
    skip_program_sales_page_creation:
      'The option to “Skip program sales page creation” is enabled for {count} of your programs. This option is only available with an active White label bolt-on subscription. If you unsubscribe, program links open the program sales page by default. Ensure that all of those pages are prepared - {link}.',
  },

  brand_gym_is_restricted_popup: {
    title: 'Your brand/gym/team is restricted',
    text: 'This means you currently have limited access to the system as a connected coach. Please contact the brand/gym owner for further information.',
  },

  manage: {
    header: "Manage your '{type}' account",
    sub_header:
      'Manage whether you want to pay monthly or get 2 months free when you pay annually.',
  },

  switch_to_monthly_payments: {
    title: 'Switch to monthly payments',
    text: "Are you sure? Your annual plan will be active until <b>{date}</b>. After this date we'll charge you based on the new pricing plan.",
  },
};
