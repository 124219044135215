export default {
  payment: {
    new_method: 'Add payment information',
    update_method: 'Update payment method',
    add_method: 'Add payment method',

    title: {
      new: 'Add Payment information',
      edit: 'Update payment information',
      rewrite: 'Add new payment information',

    },
    button: {
      new: 'Add Payment Information',
      update: 'Save',
    },
    text: 'Required fields. This information is stored directly and securely in Stripe.  Address information will appear on your invoices and receipts.',
    short_text: 'Required fields.',
    rewrite_text: 'Please note this will replace the current card on file. Your information is securely stored with our financial partner Stripe.',
  },
  calendar: {
    title: 'Choose when to start your program',
    start_date: 'Start date',
    color_select: 'Select program colour',
    info_sure: 'Are you sure you want to start your program on:',
    info_warn: 'NOTE: This can’t be changed afterwards.',
    info_hint:
      'You can reschedule the program via the main program area if required.',
    yes: 'Start program',
    yes_paid: 'Proceed to checkout',
  },
  dashboard: {
    send: 'Send message to all clients',
  },
  perfomance: {
    title_empty: 'Session notes',
    title: 'Session notes',
    about: {
      title: 'Review your client’s session notes',
      text_1:
        'If your client has added session notes, you’ll see something like this:',
      text_2:
        'If you can’t see any session notes, the client has left this day blank.',
      text_3:
        'To leave feedback, click into the desired day and scroll to the comment section.',
      dont_show: 'Don’t show this pop-up again',
    },
  },
  plan_types: {
    fixed: {
      point_1:
        'Upon download, every client will receive the full training schedule.',
      point_2:
        "You will need to pre-load the program's schedule for the duration of the program before you can sell it to clients.",
      point_3:
        'Each client will be able to choose when to start the program - e.g. download on a Friday, start the following Monday.',
      point_4:
        'Manage one central training schedule safe in the knowledge training will be delivered automatically to your clients on download.',
      point_5:
        'Ideal for programs where there is a definitive start and end goal.',
    },
    membership: {
      point_1:
        "Regardless of when any client subscribes, they'll start at the very first session you ever built. This could mean you have clients at different stages of the program at any one time.",
      point_2:
        'You don’t need to load all the training to start selling this program, just the first week or so. Add future programming as time progresses.',
      point_3:
        "Clients will always have 7 days visibulity into the future. Build in advance and we'll let them see it when it's time.",
      point_4:
        'Ideal for training cylces or rehabilitation programs where you want clients to begin at the very start of what you’ve built, not at today’s date.',
    },
    individual: {
      point_1:
        'This program is for clients who need to receive 100% bespoke training.',
      point_2: 'You do NOT need a bepsoke program per bespoke client.',
      point_3:
        "Why? As each client joins, you'll have the ability to add bespoke training to their individual training schedule.",
      point_4:
        'Create one bespoke program and invite all your bespoke clients to it. You can even create coupon codes to offer different clients different payment terms on the same program.',
    },
    one_many: {
      point_1:
        'Every client will get the same programming on the same calendar date. One training schedule to update, unlimited clients.',
      point_2:
        'Utilise our personal record feature to allow you to customise the workouts to each individual’s ability.',
      point_3:
        'Save time by managing one central training schedule safe in the knowledge training will be delivered to your clients when they need it.',
      point_4:
        'Ideal for where you want a whole group of people to receive the same core programming, in a calendar format, such as gym wide programming.',
    },
  },
  schedule_hint: {
    title: 'Helpful hint',
    fixed:
      "Now to build your program's content. Take your time, you can save programs as draft and come back to them. The perfect program takes time.",
    fixed_2:
      "Once you're ready to unleash it to the world, head to Step 3 - Review & Publish.",
    membership:
      'You only need to create the first week of the training schedule before you can publish the program.',
    membership_2:
      "You can add more weeks over time once you've published the program and clients are onboard.",
    else_1:
      "After publishing this program you'll be able to create the training your clients will follow.",
    else_2:
      "After publishing, we'll show you how to sign up clients to the program too.",
    dont_show: 'Don’t show me this again',
  },
  paste_sections_hint: {
    title: "'Paste to' options",
    text: "You now have two options on how to paste the programming you've just copied:",
    day: '- сlick this icon to paste everything into the same day',
    structure:
      '- click this icon to paste the training whilst keeping its copied structure',
    dont_show: 'Don’t show me this message again',
  },
  user_info: {
    buisness_1:
      'If you need any help getting started, please contact us via live chat.',
    buisness_2:
      'Remember you can add more coaches to your {accountKind} in settings.',
    thanks: {
      title: 'Thanks!',
      text: 'The FITR Team.',
    },
    invited: {
      with_token:
        'We’ll now link you into the training programs area so you can get started.',
      else: {
        text_1:
          "We'll take you to your feed now where, if you've been invited to join an existing team, you'll see an invite ready for you to accept.",
        text_2: "Don't see an invite in your feed?",
        text_3:
          "Make sure the person inviting you is using the same email as you've signed up with. If they haven't, they'll need to re-invite you to the team.",
      },
    },
    standalone:
      "Now you're set up, have a play around creating training programs and signing up clients. We're always here to help in live chat if you need.",
    start_button: 'Start Using FITR',
  },
  custom_program: {
    title: 'Do you need another 1-2-1 program?',
    text: 'Remember you can add multiple clients to a single 1-2-1 program and give each totally independent training to follow; saving you from creating a new program for every client!',
    cancel: 'Cancel',
    continue: 'Yes, continue',
    dont_show: "Don't show me this warning again.",
  },

  reschedule_client: {
    week: 'Week',
    day: 'Day',
    title: 'Reschedule your client',
    text: '{clientName} is currently on <span>Week {weekNumber}</span> <span>Day {dayNumber}</span>, what Week/Day would you like to move them to?',
    checkbox: {
      desktop: {
        text: 'Clear future programming?',
        tooltip:
          "Rescheduling may result in the client's calendar having extended future training visibility. E.g. If you moved a client to week 20, instead of week 2, and then corrected this mistake, they would have 18 weeks of programming ahead of time. Select this box to remove any future programming longer than 7 days.",
      },
    },
    error: 'Please enter a value between',
    btn_cancel: 'Cancel',
    btn_ok: 'Confirm',
    notify_success:
      '{clientName} has been moved to Week {weekNumber} Day {dayNumber}',
  },

  resetAddress: {
    title: 'Reset web address',
    text: 'Please note, after reverting to the default URL, any custom links you had previously shared with clients will no longer work.',
    cancel: 'Cancel',
    reset: 'Reset',
  },

  deactivate_tax: {
    title: 'Deactivate stripe tax?',
    text: 'Please note you will also need to deactivate this in your Stripe account. You can access this',
    link_text: 'here.',

    buttons: {
      yes: 'Yes, deactivate',
      cancel: 'Cancel',
    },
  },

  choose_client: {
    title: 'Which client do you want to start programming for?',
    description:
      "Select who you'd like to start programming for first. Don't worry, you can switch between clients easily later too.",
    no_result_title: 'No results',
    no_result_text: 'You don’t have any clients matching your search.',
    search_placeholder: 'Search…',
    btn_close: 'Close',
    btn_skip: 'Skip',
    btn_next: 'Next',
  },
};
