<template>
  <div class="wrap d-flex flex-column">
    <AppHeader></AppHeader>

    <div
      class="content d-flex justify-content-center align-items-center flex-column m-auto"
    >
      <div class="thumb">
        <span>404</span>
        <img
          src="@/assets/images/not_found.svg"
          alt="Create and sell your first training plan"
        />
      </div>
      <h2>404 error</h2>
      <p>Sorry, we can't find this page.</p>
      <div class="actions text-center">
        <router-link class="btn btn-primary" :to="{ name: 'main' }"
          >Go to main page</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/shared/app-header/index.vue';

export default {
  components: {
    AppHeader,
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  margin-bottom: 3rem;

  span {
    position: absolute;
    top: 4.5rem;
    left: 50%;
    color: #db2b44;
    font-size: 4rem;
    transform: translate(-50%, -50%);
  }
}

h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: $font-weight-normal;
}

p {
  margin-bottom: 2.5rem;
  color: #91939f;
}
</style>
