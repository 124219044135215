import { mapGetters } from 'vuex';

// @vue/component
export default {
  name: 'StateControl',

  props: {
    /**
     * Value of v-model
     * @type {string}
     */
    value: String,

    /**
     * Error message
     * @type {string}
     */
    errors: String,

    /**
     * Is disabled
     * @type {boolean}
     */
    disabled: Boolean,

    /**
     * Input label
     * @type {string}
     * @default 'State / Province'
     */
    label: {
      type: String,
      default: 'State / Province',
    },

    /**
     * Input placeholder
     * @type {string}
     * @default 'Select'
     */
    placeholder: {
      type: String,
      default: 'Select',
    },

    /**
     * Code of country
     * @type {string}
     * @default ''
     */
    countryCode: {
      type: String,
      default: '',
    },

    /**
     * Is required marker visible
     * @type {boolean}
     * @default false
     */
    showRequiredMarker: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    state: {
      get() {
        return this.value;
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },

    statesForSelect() {
      let statesOfCurrentCountry = this.states.find((statesOfCountry) => {
        return statesOfCountry[this.countryCode];
      });

      if (statesOfCurrentCountry) {
        return statesOfCurrentCountry[this.countryCode].map((state) => {
          return {
            text: state.name,
            value: state.code,
          };
        });
      } else {
        return [];
      }
    },

    ...mapGetters('settings', ['states']),
  },
};
