const MyMedia = () => import('./Layout.vue');
const MyMediaIndex = () => import('./index/index.vue');
const MyMediaShow = () => import('./show/index.vue');

export default [
  {
    path: 'resources',
    component: MyMedia,

    children: [
      {
        path: '',
        component: MyMediaIndex,
        name: 'media_library',
        meta: {
          footerCssClass: 'footer-with-fixed-btn',
        },
      },
      {
        path: ':owner',
        component: MyMediaShow,
        name: 'owner_media_library',
        props: true,
        meta: {
          footerCssClass: 'footer-with-fixed-btn',
        },
      },
    ],
  },
  // For old urls
  {
    path: 'media',
    redirect: 'resources', // redirect from /media to /resources

    children: [
      {
        path: '',
        redirect: { name: 'media_library' }, // redirect from /media to /resources
      },
      {
        path: ':owner',
        redirect: { name: 'owner_media_library' }, // redirect from /media/:owner to /resources/:owner
      },
    ],
  },
];
