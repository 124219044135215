<template>
  <div class="container static-page">
    <h1>INTRODUCTION TO PRIVACY POLICY</h1>

    <p>The aim of Our Privacy Policy is to clearly outline to You:</p>
    <ul>
      <li>
        what data We/Us/Our (Libertatum Applications Limited – trading as FITR)
        will collect at different times when You use Our service;
      </li>
      <li>how We may use that data;</li>
      <li>how long We may store that data;</li>
      <li>
        how You/Your (the human user of the website or service) may opt-out of
        Us using or storing that data;
      </li>
      <li>
        and where We may send that data (outside of Flubit) for Us to operate
        Our service (both critical use and optional use).
      </li>
    </ul>

    <p>
      Inside this Privacy Policy We also identify which information is optional
      to provide to use Our service, and which information is critical.
    </p>
    <p>
      To use FITR service We have made it easy for You to view which information
      We will store, and You can change Your preferences at any time at within
      your account settings. You can delete your account at any time and you can
      unsubscribe from email communication via the ‘unsubscribe’ link at the
      bottom of each email.
    </p>
    <p>
      We use industry standard efforts to safeguard the confidentiality of Your
      personal identifiable information, such as firewalls and Secure Socket
      Layers where appropriate.
    </p>
    <p>
      We have separated the remainder of the Policy into the following sections:
    </p>
    <ol>
      <li>The data We collect for Our core (critical) service</li>
      <li>The data We share with 3rd parties</li>
      <li>
        How We may use Your data for marketing purposes and additional data We
        may request permission for
      </li>
      <li>Information about storing Your information</li>
      <li>Your rights over Your data</li>
      <li>Future changes to Our privacy policy</li>
    </ol>

    <h2>1. THE DATA WE COLLECT FOR OUR CORE (CRITICAL) SERVICE</h2>

    <p>
      FITR is an online health and fitness management tool where professionals
      can manage training plans sent to their clients. It is also a marketplace,
      where users can find and purchase training plans to follow. The full
      feature set for both parties can be see at www.fitr.training.
    </p>
    <p>
      We require certain information from You in order to run FITR service. The
      following information in the table below is deemed as critical to Our
      business, depending on how much You interact with Us (for example: We need
      more information from You if You purchase from Us, compared to if You
      simply sign up for an account). For avoidance of doubt, this information
      does not include instances where FITR may need to use Your data to comply
      with legal and/or regulatory purposes
    </p>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Information We Use</th>
          <th scope="col">How & Why We may use the data provided</th>
          <th scope="col">When does this become critical?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Email</td>
          <td>
            <p>To be able to log into FITR</p>
            <p>
              To contact You if there if there are notifications on your
              account, for example if you have a new client.
            </p>
            <p>
              Customer service may use this to verify Your identity and assist
              You
            </p>
            <p>Fraud detection and prevention against You or Us.</p>
            <p>Account verification purposes</p>
          </td>
          <td>When You sign up to the service.</td>
        </tr>
        <tr>
          <td>Your Full Name</td>
          <td>
            <p>
              To allow You to be found by other users on the platform, for
              example to find your account and see the plans you have for sale.
            </p>
            <p>
              Customer service may use this to verify Your identity and assist
              You
            </p>
            <p>Fraud detection and prevention against You or Us.</p>
          </td>
          <td>When You sign up to the service.</td>
        </tr>
        <tr>
          <td>Your Date of Birth</td>
          <td>
            <p>To ensure you are of legal age to use FITR (13+).</p>
            <p>Fraud detection and prevention against You or Us.</p>
          </td>
          <td>When You sign up to the service.</td>
        </tr>
        <tr>
          <td>Product purchase details and history</td>
          <td>
            <p>
              To allow coach accounts on FITR see their trading history via
              their ‘Statistics’ tab, e.g. to see how many people are signed up
              to one of their training plans.
            </p>
            <p>For accounts and regulation reasons.</p>
          </td>
          <td>
            When You make a purchase, We will need to record this data for
            future actions relating to customer service and accountancy.
          </td>
        </tr>
        <tr>
          <td>Credit card information</td>
          <td>
            <p>
              We do not keep a record of Your credit card information, We use a
              secure 3rd-party service (Stripe) to process Your payment for Your
              order on Our service.
            </p>
            <p>
              Your payment information is tokenised by this 3rd party service
              which allows Us to issue You a refund should We need to and allow
              You check out faster if You are logged in to flubit.
            </p>
          </td>
          <td>
            <p>
              When You make an order, so we can take payment and make actions in
              the future (for example: refunds).
            </p>
            <p>When You receive funds from selling Your training plans.</p>
          </td>
        </tr>
        <tr>
          <td>Anonymous Aggregate data</td>
          <td>
            <p>
              To create internal reports, test Our IT systems, research, data
              analysis, improving Our site, building &amp; developing Our
              Website or developing new products or services.
            </p>
            <p>
              We only use and share this information with third parties when it
              is anonymous i.e. without identifying information
            </p>
          </td>
          <td>We use anonymous aggregated data at all times on Our service.</td>
        </tr>
      </tbody>
    </table>

    <h3>Other data we may collect</h3>
    <p>
      Technical information, including the Internet protocol (IP) address used
      to connect your computer to the Internet, your login information, browser
      type and version, time zone setting, GPS location, device, browser plug-in
      types and versions, operating system and platform.
    </p>
    <p>
      Information about your visit, including the full Uniform Resource Locators
      (URL) clickstream to, through and from our site (including date and time);
      products you viewed or searched for; page response times, download errors,
      length of visits to certain pages, page interaction information (such as
      scrolling, clicks, and mouse-overs), and methods used to browse away from
      the page.
    </p>

    <p>
      We may combine this information with information you give to us and
      information we collect about you. We may us this information and the
      combined information for the purposes set out above (depending on the
      types of information we receive).
    </p>

    <h2>2. THE DATA WE SHARE WITH 3RD PARTIES</h2>

    <p>
      We do not sell or intend to share any of Your personal details to third
      parties, excluding instances and purposes listed in this section.
    </p>

    <p>
      We share some data with the following third parties in order for Us to
      carry out business, deliver a great customer service experience to You and
      improve Our business. As the service evolves We may add services to this
      list.
    </p>
    <p>
      Further information about 3rd parties that may store data about You
      (either anonymous or identifiable) about You can be found in Our Cookie
      Policy.
    </p>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">3rd Party</th>
          <th scope="col">URL</th>
          <th scope="col">Why we use this service</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Google Analytics</td>
          <td>
            <a href="https://analytics.google.com/"
              >https://analytics.google.com</a
            >
          </td>
          <td>
            We use Google Analytics to track anonymous data about Our website
            and service usage. Google collects data via a cookie on Our website.
          </td>
        </tr>
        <tr>
          <td>Stripe</td>
          <td><a href="https://stripe.com/">https://stripe.com/</a></td>
          <td>
            Stripe is a payment processor who stores and manages Our online
            transactions. We will send them all information relating to payment
            and order details You provide.
          </td>
        </tr>
        <tr>
          <td>Mailchimp</td>
          <td><a href="https://mailchimp.com">https://mailchimp.com</a></td>
          <td>
            Instiller acts as Our newsletter/mailing list provider. We send
            Instiller all the information we need to process Our email
            communication on Flubit.com
          </td>
        </tr>
        <tr>
          <td>
            Facebook<br />
            Instagram<br />
            Twitter
          </td>
          <td>
            <a href="https://facebook.com">https://facebook.com</a><br />
            <a href="https://instagram.com">https://instagram.com</a><br />
            <a href="https://twitter.com">https://twitter.com</a><br />
          </td>
          <td>
            We use Our social channels to feed organic information about FITR
            Training and also, where permission is granted (see section 3) for
            multiple format targeting and re-targeting campaigns. Facebook
            targets its own users, we also send cookie data for the remarketing
            and basket abandonment where permission is granted.
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      We may provide further data to third parties not listed above, but before
      We do so We ensure that all data is anonymous meaning it cannot be
      identified as You.
    </p>
    <p>
      There are three reasons where we may share Your data with 3rd parties that
      we have not listed here:
    </p>

    <ul>
      <li>
        Professional Advisers and Investors: We may also share Your data with
        professional advisers such as Our lawyers and insurers to manage risks
        and legal claims, and/or as part of Our relationship and obligations to
        Our investor organisations. This is in Our legitimate interests.
      </li>
      <li>
        Group: It is possible we could sell Our business to a third party or
        re-organise Our business or become insolvent. In that scenario, Our
        database of customers is one of the biggest parts of that business and
        so we would need to share it with the third-party buyer and their
        advisers. This is in the legitimate interests of selling Our business.
      </li>
      <li>
        Law Enforcement/Legal Compliance: We will cooperate with all third
        parties to enforce their intellectual property or other rights. We will
        also cooperate with law enforcement requests from within or outside Your
        country of residence. This may include disclosing Your personal
        information to government or law enforcement agencies, or private
        parties, when we have a good faith belief that disclosure is required by
        law or when we, in Our discretion, believe that disclosure is necessary
        to protect Our legal rights, or those of third parties and/or to comply
        with a judicial proceeding, court order, fraud reduction or legal
        process served on Us. In such cases, we may raise or waive any legal
        objection or right available to Us. These uses of Your data are in Our
        legitimate interests of protecting Our business security. We may also
        use Your data and share it with the recipients listed in this Privacy
        Policy for the purpose of complying with Our legal obligations.
      </li>
    </ul>

    <h2>
      3. HOW WE MAY USE YOUR DATA FOR MARKETING PURPOSES AND ADDITIONAL DATA WE
      MAY REQUEST PERMISSION FOR.
    </h2>

    <p>
      Part of the role of FITR team is to market Our products and services to
      enable more people to register with Our service. To do so We use a number
      of different 3rd parties to help Us with these initiatives. Those 3rd
      parties are listed in Section 2, and unless stated otherwise anonymised
      information about Your interactions with FITR is sent to these services.
      We see these anonymised transfers of data as critical to the running and
      future success of Our business and You cannot opt-out of them if You use
      FITR.
    </p>
    <p>
      In addition to the anonymised data we send to 3rd parties, We may ask
      permission from You to use extended data to help give You a better
      service. A better service may include (but is not limited to),
    </p>

    <ul>
      <li>
        the ability for Us to personalise Our email communication to You based
        on identifiable information (e.g. name, DOB);
      </li>
      <li>
        whilst browsing FITR, having Us push relevant deals and information to
        You based on Your specific interactions with the website.
      </li>
    </ul>

    <p>
      For this We will ask You to opt-in to Our marketing initiatives; You will
      automatically be opted-out of these services when You join FITR. If at any
      stage You would like to opt in or out You can do so in Your account
      settings.
    </p>
    <p>
      The additional data We will request permission to use to send to 3rd
      parties is as follows:
    </p>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Data provided by You</th>
          <th scope="col">Use</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Email address</td>
          <td>To identify You on 3rd party services and internally.</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>
            To improve user experience with email and advertising
            personalisation and re-targeting.
          </td>
        </tr>
        <tr>
          <td>Date of birth</td>
          <td>
            Helps Us identify product deals more relevant to You based on
            purchases of other people in Your age demographic.
          </td>
        </tr>
        <tr>
          <td>Gender</td>
          <td>
            Helps Us identify product deals more relevant to You, and improve
            service based on learnings of others similar to You.
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Other data we use</th>
          <th scope="col">Use</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Purchase data</td>
          <td>Helps Us identify product deals more relevant to You.</td>
        </tr>
        <tr>
          <td>Email engagement data</td>
          <td>For engagement analysis and optimisation.</td>
        </tr>
      </tbody>
    </table>

    <h2>4. INFORMATION ABOUT KEEPING YOUR INFORMATION</h2>

    <p>
      We keep Your information for as long as it is seen necessary to uphold Our
      obligations to: deliver Your training plan order, keep Your historic
      training data, meet government regulations (for example: relating to
      accountancy) improve Our service to You, prevent fraud and for law
      enforcement purposes.
    </p>
    <p>
      If reasonably necessary or required to meet legal or regulatory
      requirements, resolve disputes, prevent fraud and abuse, or enforce Our
      terms and conditions, We may also keep hold of some of Your information as
      required, even after You have closed Your account, or it is no longer
      needed to provide the services to You.
    </p>
    <p>
      To give You a better understanding, we usually keep financial records
      (including information about orders &amp; transactions) for 7 years. We
      will keep top-level information (for example relating to the total
      quantity of sales transacted in a year) for as long as We as a business
      deem it useful.
    </p>

    <h2>5.YOUR RIGHTS OVER YOUR DATA</h2>

    <p>
      You have the following rights when it comes to Your personal information
    </p>

    <ul>
      <li>
        The right to be informed about how We use and store Your information
      </li>
      <li>The right to access the personal information We store about You</li>
      <li>
        The right to request the correction of inaccurate information, such as
        through the My Account section on Our Website
      </li>
      <li>
        The right to opt-out of non-critical marketing activities on Flubit
      </li>
      <li>The right to complain to Your data protection regulator</li>
    </ul>
    <p>
      If You want to exercise Your rights, or have any questions or concerns
      please contact Us at
      <a href="mailto:support@fitr.training">support@fitr.training</a>.
    </p>

    <h4>RIGHT TO BE FORGOTTEN</h4>

    <p>
      Users also have the legal right to request deletion of any personally
      identifiable information. We will adhere to this request where the
      deletion of information does not impact Our commitment to uphold any
      financial or legal requirements We must undertake to operate.
    </p>
    <p>
      We may require to keep anonymous training plan detail, such as the
      schedule of all a coach’s plans that have been purchased by other users.
      We do so, so those users that have purchased this information do not lose
      access to their historic data when You close your account. Your name, DOB
      and email will not be linked to this stored data.
    </p>
    <p>
      Requests must be responded to within 30 days, however requests which are
      overly complicated may take longer to process. To make any of the above
      requests, please contact Our dedicated customer support team at
      <a href="mailto:support@fitr.training">support@fitr.training</a>
    </p>

    <h4>NO FEE USUALLY REQUIRED</h4>
    <p>
      We do not anticipate that You will have to pay a fee to access Your
      personal data (or to exercise any of the other rights). However, we may
      charge a reasonable fee if Your request is clearly unfounded, repetitive
      or excessive. Alternatively, we may refuse to comply with Your request in
      these circumstances.
    </p>
    <p><i>We may need further data from You</i></p>
    <p>
      We may need to request specific information from You to help Us confirm
      Your identity and ensure Your right to access Your personal data (or to
      exercise any of Your other rights). This is a security measure to ensure
      that personal data is not disclosed to any person who has no right to
      receive it.
    </p>

    <h4>WHERE WE STORE YOUR DATA</h4>
    <p>
      The data that we collect from you may be transferred to, and stored at, a
      destination outside the European Economic Area ("EEA"). It may also be
      processed by staff operating outside the EEA who work for us or for one of
      our suppliers. Such staff maybe engaged in, among other things, the
      fulfilment of your order, the processing of your payment details and the
      provision of support services. By submitting your personal data, you agree
      to this transfer, storing or processing. We will take all steps reasonably
      necessary to ensure that your data is treated securely and in accordance
      with this privacy policy.
    </p>
    <p>
      We always ensure that your data is only transferred in full accordance
      with UK data protection laws. In particular that means your data will only
      be transferred to a country that the European Commission has determined
      provides an adequate level of protection, or to service providers who have
      an agreement with us committing to the Model Contract Clauses, which are
      defined by the European Commission.
    </p>
    <p>
      Libertatum Applications Ltd and your data is hosted in the United States
      on Amazon Web Services (AWS). Amazon take physical and network security
      seriously. You can read more about the specifics of their approach at
      <a href="https://aws.amazon.com/security/"
        >https://aws.amazon.com/security</a
      >. We have signed an agreement with AWS that enables transfer of personal
      data to the US and commits to the Model Contract Clauses, which are
      defined by the European Commission in accordance with UK data protection
      laws.
    </p>
    <p>
      All information you provide to us is stored on our secure servers. Where
      we have given you (or where you have chosen) a password which enables you
      to access certain parts of our site, you are responsible for keeping this
      password confidential. We ask you not to share a password with anyone.
    </p>
    <p>
      Unfortunately, the transmission of information via the internet is not
      completely secure. Although we will do our best to protect your personal
      data, we cannot guarantee the security of your data transmitted to our
      site; any transmission is at your own risk. Once we have received your
      information, we will use strict procedures and security features to try to
      prevent unauthorised access.
    </p>

    <h2>6. Change to Our privacy policy</h2>
    <p>
      We will make changes to Our Privacy Policy from time to time. You will be
      notified about these changes when they occur, and we will let You know a
      summary of the key changes. We do suggest however You always take time to
      read any changes before continuing to use Our service.
    </p>
    <h2>7. Cookies</h2>
    <p>
      We have a separate Cookie policy, this can be found
      <router-link to="/pages/cookies_policy"> here </router-link>.
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style src="@/assets/styles/_static-pages.scss" lang="scss" scoped></style>
