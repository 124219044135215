const CoachMyAccountsPage = () => import('./index.vue');

export default [
  {
    path: 'my_accounts',
    name: 'coach_profile_my_accounts',
    component: CoachMyAccountsPage,
    meta: {
      coachOwnProfile: true,
      disableScrollBehavior: true,
      availableForBlocked: true,
    },
  },
];
