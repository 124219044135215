import { mapState, mapGetters, mapActions } from 'vuex';

import {
  ActivityTypes,
  NotificationTypes,
  NotificationActions,
} from '@/components/feed/helpers.js';

import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

import UserAvatar from '@/shared/user-avatar/index.vue';
import SubActionsDropdown from '@/shared/sub-actions-dropdown/index.vue';
import LogoImage from '@/assets/images/powered-by-fitr-training.svg';

const AvailableActions = {
  // COACH
  one_many_schedule: {
    title: 'Program schedule',
    action: 'goToOneManySchedule',
  },
  bespoke_schedule: {
    title: 'Program schedule',
    action: 'goToBespokeSchedule',
  },
  schedule_depend_on_type: {
    title: 'Program schedule',
    action: 'goToProgramSchedule',
  },
  message_to_client: {
    title: 'Direct message client',
    action: 'directMessage',
  },
  subscriber_list: {
    title: 'View full subscriber list',
    action: 'goToPlanAthletes',
  },
  scheduled_subscribers_list: {
    title: 'Go to Scheduled clients',
    action: 'goToPlanScheduledAthletes',
  },
  plan_management: {
    title: 'Go to Programming', // Plan management
    action: 'goToPlanManagement',
  },
  client_activity: {
    title: 'Client Activity',
    action: 'goToClientActivity',
  },
  client_profile: {
    title: 'View client profile',
    action: 'goToClientProfile',
  },
  plan_downloads: {
    title: 'View all program downloads',
    action: 'goToPlanAthletes',
  },
  training_calendar: {
    title: 'View training calendar',
    action: 'goToAthleteCalendar',
  },
  payment_settings: {
    title: 'Payment Settings',
    action: 'goToPaymentSettings',
  },
  dashboard: {
    title: 'Dashboard',
    action: 'goToDashboard',
  },
  plan_schedule: {
    title: 'Program schedule',
    action: 'goToPlanSchedule',
  },
  plan_overview: {
    title: 'Program overview',
    action: 'goToPlanOverview',
  },
  open_sidebar: {
    title: 'Opens up the sidebar',
    action: 'openSidebar',
  },
  coach_billing_page: {
    title: 'Plan & Billing',
    action: 'goToBillingPage',
  },
  choose_your_plan: {
    title: 'Choose your plan',
    action: 'goToPlansPage',
  },
  coach_new_benchmark_comment: {
    title: 'Client has added a new comment',
    action: 'goToBenchmark',
  },
  coach_new_body_metric_comment: {
    title: 'Client has added a new comment',
    action: 'goToBodyMetric',
  },
  coach_new_challenge_comment: {
    title: 'Client has added a new comment',
    action: 'goToChallenge',
  },
  coach_subscribe_wl: {
    title: 'Subscribed white label',
    action: 'goToWhiteLabelCustome',
  },

  // CLIENT
  client_own_calendar: {
    title: 'Open Calendar',
    action: 'goToClientOwnCalendar',
  },
  message_to_coach: {
    title: 'Direct message your coach',
    action: 'directMessage',
  },
  client_billing_purchases: {
    title: 'Open My Purchases',
    action: 'goToClientPurchases',
  },
  coach_profile: {
    title: 'View coach profile',
    action: 'goToCoachProfile',
  },
  coach_plans: {
    title: 'View coach program library',
    action: 'goToCoachPlans',
  },
  view_public_plan: {
    title: 'View Program',
    action: 'goToPublicPlan',
  },
  go_to_purchases: {
    title: 'View my Purchases',
    action: 'goToPurchases',
  },
  go_to_user_plans: {
    title: 'View my Programs',
    action: 'goToClientOwnPlans',
  },

  // BRAND
  brand_invites: {
    title: 'View all invites',
    action: 'goToBrandInvites',
  },

  // Mentions
  view_leaderbord: {
    title: 'Open Leaderbord',
    action: 'goToLeaderbord',
  },

  view_community: {
    title: 'Open Community',
    action: 'goToCommunity',
  },

  client_new_benchmark_comment: {
    title: 'Coach has added a new comment',
    action: '',
  },

  client_new_body_metric_comment: {
    title: 'Coach has added a new comment',
    action: '',
  },

  client_new_challenge_comment: {
    title: 'Coach has added a new comment',
    action: '',
  },
};

// @vue/component
export default {
  name: 'NotificationView',

  components: {
    UserAvatar,
    SubActionsDropdown,
  },

  props: {
    /**
     * The notification object to be displayed.
     * @type {object}
     * @default null
     */
    notification: {
      type: Object,
      default: null,
    },

    /**
     * The mode of the notification view.
     * @type {string}
     * @default 'full'
     */
    mode: {
      type: String,
      default: 'full',
    },
  },

  data() {
    return {
      calendarOptions: {
        sameDay: 'LT',
        lastDay: '[Yesterday], h:mm a',
        // lastWeek: function(now) {
        //   let diff = this.diff(now, 'days') * -1;
        //   return '[' + diff + ' days ago]';
        // },
        sameElse: 'D MMMM, YYYY [at] h:mm a',
      },
      avatarTheme: {
        circle: true,
        fit: 'cover',
      },
    };
  },

  computed: {
    isFullMode() {
      return this.mode == 'full';
    },
    isFinancial() {
      return this.notification.activity_type == ActivityTypes.financial;
    },
    isWarning() {
      return this.notification.activity_type == ActivityTypes.content_warnings;
    },
    isActivity() {
      return this.notification.activity_type == ActivityTypes.client_activity;
    },
    isInvite() {
      return this.notification.activity_type == ActivityTypes.invite;
    },
    isInviteFromBrand() {
      return (
        this.isInvite &&
        this.notification.notification_type ==
          NotificationTypes.invite_brand_and_gym
      );
    },
    isCoachMembership() {
      return this.notification.activity_type == ActivityTypes.coach_membership;
    },

    isWarningStatus() {
      return (
        this.isWarning &&
        [
          NotificationTypes.coach_client_ending_fixed_plan,
          NotificationTypes.coach_client_ended_fixed_plan,
        ].includes(this.notification.notification_type)
      );
    },
    isDangerStatus() {
      return (
        this.isWarning &&
        [
          NotificationTypes.coach_danger_automated_plan,
          NotificationTypes.coach_danger_bespoke_plan,
          NotificationTypes.coach_danger_one_many_plan,
        ].includes(this.notification.notification_type)
      );
    },

    thumbData() {
      if (
        (this.isUserCoach && this.isWarning) ||
        (!this.isUserCoach &&
          this.isFinancial &&
          this.notification.notification_type !=
            NotificationTypes.client_payment_refunded)
      ) {
        let cover = this.notification.plan.covers.find(
          (c) => c.cover_type == 'table_view'
        );

        return {
          full_name: this.notification.plan.title,
          avatar_url: cover ? cover.normal : null,
        };
      } else if (
        (this.isUserCoach &&
          this.isFinancial &&
          [
            NotificationTypes.coach_fitr_charge,
            NotificationTypes.coach_bank_payout,
            NotificationTypes.coach_payment_method_declined,
            NotificationTypes.coach_warning_stripe_account,
            NotificationTypes.coach_payment_refunded,
            NotificationTypes.coach_bank_payout_fail,
            NotificationTypes.coach_subscribe_wl,
            NotificationTypes.coach_unsubscribe_wl,
          ].includes(this.notification.notification_type)) ||
        (!this.isUserCoach &&
          this.notification.notification_type ==
            NotificationTypes.client_payment_refunded) ||
        (this.isUserCoach && this.isCoachMembership)
      ) {
        /* eslint-disable */
        // TODO fix Unexpected side effect in "thumbData" computed property
        this.avatarTheme = {
          circle: false,
          fit: 'contain',
        };
        /* eslint-enable */

        let avatar_url = LogoImage;

        if (this.siteLogo?.logo) {
          avatar_url = this.siteLogo.logo;
        }

        return {
          full_name: 'FITR',
          avatar_url,
        };
      } else {
        return this.notification.user;
      }
    },

    notificationActions() {
      return NotificationActions[
        NotificationTypes[this.notification.notification_type]
      ];
    },

    notyMainAction() {
      let result = '';

      if (this.notificationActions != undefined) {
        let action = AvailableActions[this.notificationActions.main];

        if (action != undefined) {
          result = action;
        }
      }

      return result;
    },

    notySubActions() {
      let result = [];

      if (this.notificationActions != undefined) {
        this.notificationActions.sub.map((act) => {
          if (AvailableActions[act] != undefined) {
            result.push(AvailableActions[act]);
          }
        });
      }

      return result;
    },

    ...mapState(['currentUser']),
    ...mapGetters(['isUserCoach', 'isBusinessOwner', 'isFinancialAviable']),
    ...mapGetters('subscription', ['isAccountActive', 'hasSubscription']),
    ...mapGetters('color_scheme', ['siteLogo']),
  },

  methods: {
    /**
     * Main action
     */
    mainAction() {
      if (!this.notification.read) {
        this.toggleReadState(this.notification.id);
      }

      if (this[this.notyMainAction.action] != undefined) {
        this[this.notyMainAction.action]();
      }
    },

    /**
     * Sub action
     * @param {string} action - action name
     */
    subAction(action) {
      if (this[action] != undefined) {
        this[action]();
      }
    },

    //
    // Actions
    //

    /**
     * Leads coach to the 1-many plan schedule
     */
    goToOneManySchedule() {
      this.$router.push({
        name: 'coach_calendar',
        params: {
          plan_id: this.notification.plan.id.toString(),
          user_id: this.currentUser.id.toString(),
        },
      });
    },

    /**
     * Leads coach to the bespoke plan schedule of that client
     */
    goToBespokeSchedule() {
      this.$router.push({
        name: 'coach_calendar',
        params: {
          plan_id: this.notification.plan.id.toString(),
          user_id: this.notification.user.id.toString(),
        },
      });
    },

    /**
     * Leads coach to the plan schedule depend on plan type
     */
    goToProgramSchedule() {
      if (this.notification.plan?.plan_type == 'individual') {
        this.goToBespokeSchedule();
      } else if (this.notification.plan?.plan_type == 'one_many') {
        this.goToOneManySchedule();
      } else {
        this.goToPlanManagement();
      }
    },

    /**
     * Direct message client
     */
    directMessage() {
      if (this.notification.chat_room_id != undefined) {
        if (this.isFullMode) {
          this.$emit('openSendMessageModal', {
            id: this.notification.user.id,
            user_name: this.notification.user.full_name,
            chat_room_id: this.notification.chat_room_id,
          });
        } else {
          this.$router.push({
            name: 'conversation',
            params: {
              conversation_id: this.notification.chat_room_id.toString(),
            },
          });
        }
      }
    },

    /**
     * View full subscriber list
     */
    goToPlanAthletes() {
      this.$router.push({
        name: 'coach_program_view_clients',
        params: { plan_id: this.notification.plan.id.toString() },
      });
    },

    /**
     * Go to Scheduled clients tab
     */
    goToPlanScheduledAthletes() {
      this.$router.push({
        name: 'coach_program_view_scheduled',
        params: { plan_id: this.notification.plan.id.toString() },
      });
    },

    /**
     * Go to plan management
     */
    goToPlanManagement() {
      this.$router.push({
        name: 'my_plans',
      });
    },

    /**
     * Go to Client Activity
     */
    goToClientActivity() {
      this.$router.push({
        name: 'clients_with_programs',
      });
    },

    /**
     * View client profile
     */
    goToClientProfile() {
      this.$router.push({
        name: 'show_client_programs',
        params: { athlete_id: this.notification.user.id.toString() },
      });
    },

    /**
     * View training calendar
     */
    goToAthleteCalendar() {
      this.$router.push({
        name: 'athlete_calendar',
        params: { user_id: this.notification.user.id.toString() },
      });
    },

    /**
     * Head to Payment Settings
     */
    goToPaymentSettings() {
      if (this.isUserCoach) {
        this.$router.push({
          name: 'coach_profile_payment',
        });
      } else {
        this.$router.push({
          name: 'billing_payment',
        });
      }
    },

    /**
     * Head to Dashboard
     */
    goToDashboard() {
      this.$router.push({
        name: 'coach_dashboard',
      });
    },

    /**
     * Go to edit plan's schedule
     */
    goToPlanSchedule() {
      this.$router.push({
        name: 'coach_program_schedule',
        params: { plan_id: this.notification.plan.id.toString() },
      });
    },

    /**
     * Go to plan page overview tab
     */
    goToPlanOverview() {
      const currentRoute = this.$route;

      const targetRoute = {
        name: 'coach_program_view_details',
        params: { plan_id: this.notification.plan.id.toString() }
      };

      if (
        currentRoute.name !== targetRoute.name &&
        currentRoute.params.plan_id !== targetRoute.params.plan_id
      ) {
        this.$router.push(targetRoute);
      }
    },

    /**
     * Opens up the sidebar
     */
    openSidebar() {
      if (this.notification.schedule_id != undefined) {
        if (this.isFullMode) {
          this.$emit('openScheduleModal', {
            plan: this.notification.plan,
            user_id: this.notification.user.id,
            schedule_id: this.notification.schedule_id,
            section_id: this.notification.section_id,
          });
        } else {
          let date = this.$moment(
            this.notification.schedule_date,
            'DD/MM/YYYY'
          );

          let options = {
            name: this.isUserCoach ? 'athlete_calendar' : 'calendar',
            params: { user_id: this.notification.user.id },
            query: {
              year: date.year(),
              month: date.month() + 1,
              day: date.date(),
              schedule_id: this.notification.schedule_id,
              section_id: this.notification.section_id,
            },
          };

          this.$router.push(options);
        }
      }
    },

    /**
     * Go to client own calendar
     */
    goToClientOwnCalendar() {
      this.$router.push({ name: 'calendar' });
    },

    /**
     * View coach profile
     */
    goToCoachProfile() {
      if (
        this.notification.user?.custom_url &&
        this.notification.user?.type_account
      ) {
        let user_type = this.notification.user.type_account
          .charAt(0)
          .toLowerCase();
        this.$router.push({
          name: 'public-page-short-link',
          params: { user_id: this.notification.user.custom_url, user_type },
        });
      } else {
        this.$router.push({
          name: 'public-page-view',
          params: { user_id: this.notification.user.id.toString() },
        });
      }
    },

    /**
     * View coach plan library
     */
    goToCoachPlans() {
      if (
        this.notification.user?.custom_url &&
        this.notification.user?.type_account
      ) {
        let user_type = this.notification.user.type_account
          .charAt(0)
          .toLowerCase();
        this.$router.push({
          name: 'public-page-short-link-plans',
          params: { user_id: this.notification.user.custom_url, user_type },
        });
      } else {
        this.$router.push({
          name: 'public-page-plans',
          params: { user_id: this.notification.user.id.toString() },
        });
      }
    },

    /**
     * Go to public plan
     */
    goToPublicPlan() {
      let plan_id =
        this.notification.plan.custom_url ||
        this.notification.plan.id.toString();

      this.$router.push({
        name: 'public_plan',
        params: { plan_id },
      });
    },

    /**
     * Go to purchaces
     */
    goToPurchases() {
      this.$router.push({ name: 'billing_purchases' });
    },

    /**
     * Go to client own plans
     */
    goToClientOwnPlans() {
      this.$router.push({ name: 'user_my_plans' });
    },

    /**
     * Go to client purchases
     */
    goToClientPurchases() {
      this.$router.push({ name: 'billing_purchases' });
    },

    /**
     * Head to Plan & Billing
     */
    goToBillingPage() {
      if (this.isFinancialAviable) {
        if (this.hasSubscription) {
          this.$router.push({ name: 'coach_profile_billing' });
        } else {
          this.$router.push({ name: 'coach_profile_subscription' });
        }
      }
    },

    /**
     * Go to plans page
     */
    goToPlansPage() {
      this.$router.push({ name: 'choose_your_plan' });
    },

    /**
     * Change read state
     * @param {string|number} id - id
     * @param {string} event - event
     */
    changeReadState(id, event) {
      event.target.blur();

      this.toggleReadState(id);
    },

    /**
     * View all invites
     */
    goToBrandInvites() {
      if (this.isBusinessOwner) {
        this.$router.push({ name: 'coach_profile_coaches' });
      }
    },

    /**
     * Accept invite by coach
     */
    acceptInvite() {
      // TODO: fix mutating props
      /* eslint-disable */
      // TODO fix Unexpected mutation of "notification" prop
      this.notification.invite.status = 'accepted';
      /* eslint-enable */

      HTTP.put(`invites/${this.notification.user.id}/accept`)
        .then(() => {
          if (!this.notification.read) {
            this.toggleReadState(this.notification.id);
          }

          this.getCoachAccounts();
        })
        .catch((error) => {
          HttpErrorsHandling(error);
          /* eslint-disable */
          // TODO fix Unexpected mutation of "notification" prop
          this.notification.invite.status = 'pending';
          /* eslint-enable */
        });
    },

    /**
     * Decline invite by coach
     */
    declineInvite() {
      /* eslint-disable */
      // TODO fix Unexpected mutation of "notification" prop
      this.notification.invite.status = 'rejected';
      /* eslint-enable */

      HTTP.put(`invites/${this.notification.user.id}/reject`)
        .then(() => {
          if (!this.notification.read) {
            this.toggleReadState(this.notification.id);
          }
        })
        .catch((error) => {
          HttpErrorsHandling(error);
          /* eslint-disable */
          // TODO fix Unexpected mutation of "notification" prop
          this.notification.invite.status = 'pending';
          /* eslint-enable */
        });
    },

    /**
     * View Leaderbord
     */
    goToLeaderbord() {
      let routeName = 'challenges-leaderboard-comments';

      if (this.notification.score_comment.info.kind == 'leaderboard') {
        routeName = 'daily-leaderboard-comments';
      }

      if (this.notification.score_comment) {
        this.$router.push({
          name: routeName,
          params: {
            id: this.notification.score_comment.info.score_score_id,
            result_id: this.notification.score_comment.info.score_value_id,
            comment_id: this.notification.score_comment.id,
          },
        });
      }
    },

    /**
     * View Community
     */
    goToCommunity() {
      if (this.notification.chat_message?.chat_room_id) {
        this.$router.push({
          name: 'conversation',
          params: {
            conversation_id:
              this.notification.chat_message.chat_room_id.toString(),
          },
        });
      }
    },

    /**
     * Go to benchmark page with new comment from athlete
     */
    goToBenchmark() {
      if (
        this.$router.history.current.name !== 'client-profile-benchmark' &&
        this.notification.benchmark.id &&
        this.notification.user.id
      ) {
        this.$router.push({
          name: 'client-profile-benchmark',
          params: {
            athlete_id: this.notification.user.id,
            metric_id: this.notification.benchmark.id,
          },
        });
      }
    },

    /**
     * Go to body metric page
     */
    goToBodyMetric() {
      if (
        this.$router.history.current.name !== 'client-profile-body-metric' &&
        this.notification.body_metric.id &&
        this.notification.user.id
      ) {
        this.$router.push({
          name: 'client-profile-body-metric',
          params: {
            athlete_id: this.notification.user.id,
            metric_id: this.notification.body_metric.id,
          },
        });
      }
    },

    /**
     * Go to challenge page
     */
    goToChallenge() {
      if (
        this.$router.history.current.name !== 'client-profile-challenge' &&
        this.notification.body_metric.id &&
        this.notification.user.id
      ) {
        this.$router.push({
          name: 'client-profile-challenge',
          params: {
            athlete_id: this.notification.user.id,
            metric_id: this.notification.challenge.id,
          },
        });
      }
    },

    /**
     * Go to white label custome
     */
    goToWhiteLabelCustome() {
      if (this.$router.history.current.name !== 'customisation') {
        this.$router.push({
          name: 'customisation',
        });
      }
    },

    ...mapActions(['getCoachAccounts']),
    ...mapActions('feed', ['toggleReadState']),
  },
};
