import ColorPicker from '@/shared/color-picker/View.vue';

// TODO: use vue2-datepicker
import Datepicker from 'vuejs-datepicker';

// @vue/component
export default {
  name: 'ModalAddToCalendar',

  components: {
    Datepicker,
    ColorPicker,
  },

  props: {
    /**
     * Value
     * @type {object} - type Object
     */
    value: {
      type: Object,
    },
  },

  data() {
    return {
      disabled: [],
      format: 'dd/MM/yyyy',
      clear: true,
      datepickerDate: new Date(),
      busy: false,
    };
  },

  computed: {
    okButtonTitle() {
      if (
        ['membership', 'fixed'].includes(this.value.plan_type) &&
        this.value.plan_pricing == 'paid'
      ) {
        return this.$t('en.modal.calendar.yes_paid');
      } else {
        return this.$t('en.modal.calendar.yes');
      }
    },

    startDate() {
      return this.$moment(this.datepickerDate, 'DD/MM/YYYY').format(
        'dddd, DD MMMM YYYY'
      );
    },

    disabledDates() {
      const yesterday = new Date();
      yesterday.setDate(new Date().getDate() - 1);

      const dates = {
        to: yesterday,
      };

      if (this.value.plan_type == 'membership' && this.value.launch_date) {
        const isPast = this.$moment(
          this.value.launch_date,
          'YYYY-MM-DD'
        ).isSameOrBefore(this.$moment(), 'day');

        if (isPast) {
          const from = new Date();
          from.setDate(new Date().getDate() + 7);

          dates.from = from;
        }
      }

      return dates;
    },

    firstPaymentDate() {
      if (this.value.trial_days) {
        let startDate = new Date(this.datepickerDate);
        let day = startDate.getDate();

        startDate.setDate(day + this.value.trial_days);

        return this.$moment(startDate, 'DD/MM/YYYY').format('DD MMMM YYYY');
      } else {
        return this.$moment(this.datepickerDate, 'DD/MM/YYYY').format(
          'DD MMMM YYYY'
        );
      }
    },
  },

  methods: {
    /**
     * Show modal
     */
    show() {
      this.$refs.addToCalendarModal.show();
    },

    /**
     * Hida modal
     */
    hide() {
      this.$refs.addToCalendarModal.hide();
    },

    /**
     * Reset start date
     */
    resetStartDate() {
      this.datepickerDate = new Date();
    },

    /**
     * Add plans
     */
    addPlans() {
      this.busy = true;
      this.$emit(
        'setStartDate',
        this.$moment(this.datepickerDate).format('DD/MM/YYYY')
      );
    },

    /**
     * Stop loading
     */
    stopLoading() {
      this.busy = false;
    },
  },
};
