// @vue/component
export default {
  name: 'StarRate',

  props: {
    /**
     * Value of v-model
     * @type {number|string}
     */
    value: { type: [Number, String] },

    /**
     * Name of the input
     * @type {string}
     * @default 'rate'
     */
    name: { type: String, default: 'rate' },

    /**
     * Length of stars
     * @type {number}
     */
    length: { type: Number },

    /**
     * Is show count
     * @type {boolean}
     */
    showcount: { type: Boolean },

    /**
     * Is required
     * @type {boolean}
     */
    required: { type: Boolean },

    /**
     * Rate description
     * @type {Array}
     * @default []
     */
    ratedesc: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Is disabled
     * @type {boolean}
     * @default false
     */
    disabled: { type: Boolean, default: false },

    /**
     * Is readonly
     * @type {boolean}
     * @default false
     */
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      over: 0,
      rate: 0,
    };
  },

  watch: {
    value: function (newValue) {
      this.rate = newValue;
    },
  },

  created() {
    // TODO: fix props mutation
    /* eslint-disable vue/no-mutating-props */
    if (this.value >= this.length) {
      this.value = this.length;
    } else if (this.value < 0) {
      this.value = 0;
    }
    /* eslint-enable vue/no-mutating-props */

    this.rate = this.value;
    this.over = this.value;
  },

  methods: {
    onOver(index) {
      if (!this.readonly) {
        this.over = index;
      }
    },
    onOut() {
      if (!this.readonly) {
        this.over = this.rate;
      }
    },
    setRate(index) {
      if (this.readonly) {
        return false;
      }
      this.$emit('before-rate', this.rate);
      this.rate = this.rate === index ? null : index;
      this.$emit('input', this.rate);
      this.$emit('after-rate', this.rate);
    },
    isFilled(index) {
      return index <= this.over;
    },
    isEmpty(index) {
      return index > this.over || (!this.value && !this.over);
    },
  },
};
