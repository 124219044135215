import Multiselect from 'vue-multiselect';
import UserCard from '@/shared/user-card/index.vue';

// @vue/component
export default {
  name: 'MultiselectField',

  components: {
    Multiselect,
    UserCard,
  },

  props: {
    /**
     * The options available for selection.
     * @type {Array}
     */
    options: {
      type: Array,
    },

    /**
     * The value(s) selected.
     * @type {Array|object}
     */
    value: {
      type: [Array, Object],
    },

    /**
     * The placeholder text displayed when no option is selected.
     * @type {string}
     * @default 'Select Program(s)'
     */
    placeholder: {
      type: String,
      default: 'Select Program(s)',
    },

    /**
     * The type of options rendered.
     * @type {string}
     * @default 'userCard'
     */
    optionType: {
      type: String,
      default: 'userCard',
    },

    /**
     * The custom theme applied to the component.
     * @type {string}
     * @default ''
     */
    customTheme: {
      type: String,
      default: '',
    },

    /**
     * The direction in which the options menu opens.
     * @type {string}
     * @default ''
     */
    openDirection: {
      type: String,
      default: '',
    },

    /**
     * is multiple
     * @type {boolean}
     * @default true
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    /**
     * is disabled
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * is hide selected
     * @type {boolean}
     * @default false
     */
    hideSelected: {
      type: Boolean,
      default: false,
    },

    /**
     * is has pagination and search
     * @type {boolean}
     * @default false
     */
    hasPaginationAndSearch: {
      type: Boolean,
      default: false,
    },

    /**
     * The placeholder text displayed when no results are found during search.
     * @type {string}
     * @default 'No elements found'
     */
    noResultsPlaceholder: {
      type: String,
      default: 'No elements found',
    },
  },

  computed: {
    selected: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    searchHandler(value) {
      this.$emit('searchHandler', value);
    },
  },
};
