import { intersectionWith } from 'lodash-es';
import { HTTP } from '@/utils/http-common';

// {
//   "countries": [
//     {
//       "code": "US",
//       "name": "United States",
//       "local_currencies": ["USD"],
//       "bank_fields": []
//     },
//     {
//       "code": "DE",
//       "name": "Germany",
//       "local_currencies": ["EUR"],
//       "bank_fields": []
//     },
//     {
//       "code": "DK",
//       "name": "Denmark",
//       "local_currencies": ["EUR", "DKK"],
//       "bank_fields": []
//     },
//   ],
//   "currencies": [
//     {
//       "code": "USD",
//       "symbol": "$",
//       "subunit_to_unit": 100
//     },
//     {
//       "code": "EUR",
//       "symbol": "€",
//       "subunit_to_unit": 100
//     },
//     {
//       "code": "DKK",
//       "symbol": "kr.",
//       "subunit_to_unit": 100
//     }
//   ],
//   "payout_countries": ["US", "DE", "DK"]
// }

export default {
  namespaced: true,

  state() {
    return {
      countries: [],
      currencies: [],
      payout_countries: [],
      tax_types: [],
      states: [],
    };
  },

  mutations: {
    setCountries(state, payload) {
      state.countries = payload;
    },
    setStates(state, payload) {
      state.states = payload;
    },
    setCurrencies(state, payload) {
      state.currencies = payload;
    },
    setPayoutCountries(state, payload) {
      state.payout_countries = payload;
    },
    setTaxTypes(state, payload) {
      state.tax_types = payload;
    },
  },

  actions: {
    fetch({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (
          state.countries.length &&
          state.currencies.length &&
          state.payout_countries.length
        ) {
          resolve();
        } else {
          HTTP.get('settings')
            .then(({ data }) => {
              commit('setCountries', data.countries);
              commit('setStates', data.states);
              commit('setCurrencies', data.currencies);
              commit('setPayoutCountries', data.payout_countries);
              commit('setTaxTypes', data.tax_types);

              resolve();
            })
            .catch(({ response }) => {
              console.log('countries: catch', response);

              reject();
            });
        }
      });
    },
  },

  getters: {
    currentCountry(state, getters, rootState) {
      if (rootState.currentUser.country) {
        return state.countries.find(
          (c) => c.code == rootState.currentUser.country
        );
      }
    },

    payoutCountries(state) {
      return intersectionWith(
        state.countries,
        state.payout_countries,
        (country, payout_country) => {
          return country.code == payout_country;
        }
      );
    },

    availableCountries: (state, getters, rootState) => (role) => {
      let result = state.countries;

      if (
        (role == 'coach' || rootState.currentUser.role == 'coach') &&
        role !== 'user'
      ) {
        result = getters.payoutCountries;
      }

      return result.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },

    states(state) {
      return state.states;
    },

    localCurrencies(state, getters) {
      if (getters.currentCountry) {
        return intersectionWith(
          state.currencies,
          [...getters.currentCountry.local_currencies],
          (c1, c2) => {
            return c1.code == c2;
          }
        );
      }
    },

    availableCurrencies(state, getters, rootState) {
      let currencies = [];

      if (rootState.currentUser.role == 'user') {
        currencies = ['USD', 'EUR'];
      }

      if (getters.currentCountry) {
        currencies = new Set([
          ...currencies,
          ...getters.currentCountry.local_currencies,
        ]);

        return intersectionWith(state.currencies, [...currencies], (c1, c2) => {
          return c1.code == c2;
        });
      }
    },

    availableCurrenciesForCountry: (state) => (country) => {
      if (country) {
        let countryObj = state.countries.find((c) => c.code == country);

        if (countryObj) {
          return intersectionWith(
            state.currencies,
            [...countryObj.local_currencies],
            (c1, c2) => {
              return c1.code == c2;
            }
          );
        }
      }
    },

    countryNameByCode: (state) => (code) => {
      let countryObj = state.countries.find((c) => c.code == code);

      if (countryObj) {
        return countryObj.name;
      }
    },

    stateNameByCode: (state) => (countryCode, stateCode) => {
      let currentStateList = state.states.find(
        (stateList) => stateList[countryCode]
      );

      if (currentStateList) {
        let currentState = currentStateList[countryCode].find(
          (state) => state.code == stateCode
        );

        return currentState ? currentState.name : '';
      } else {
        return '';
      }
    },

    countryPhoneByCode: (state) => (code) => {
      let countryObj = state.countries.find((c) => c.code == code);

      if (countryObj) {
        return countryObj.country_phone_code;
      }
    },

    taxTypes(state) {
      return state.tax_types;
    },
  },
};
