export default {
  nav: {
    programs: 'Programs',
    clients: 'Clients',
    templates: 'Templates',
    benchmarks: 'Personal Records',
    leaderboards: 'Leaderboards',
    challenges: 'Challenges',
    my_accounts: 'My accounts',
    my_profile: 'My profile',
    media: 'Resources',
    coupons: 'Coupons',
    calendar: 'Calendar',
    my_programs: 'My Programs',
    my_calendar: 'My Calendar',
    support: 'Technical Support',
    to_dashboard: 'Go to Dashboard',
    finance_billing: 'Finance & billing',
    dashboard: 'Dashboard',
    white_label: 'White Label',
    programming: 'Programming',
    create_brand: 'Create new account',
    logout_message: 'You will be logged out from all accounts.',
    public_page: 'Account page',
    your_storefront: 'Your Storefront',
    storefront: 'Marketing',
    help: 'HELP',
    help_centre: 'Help Centre',
    demo_videos: 'Demo Videos',
    whats_new: 'What’s New',
    talk_to_support: 'Talk to system support',
    settings: 'Settings',
    account_settings: 'Account settings',
    switch_account: 'Switch account',
    switch_to_personal: 'Switch to personal',
    plan_and_billing: 'Plan & Billing',
    platform_subscription: 'Platform subscription',
    activate_account: 'Activate account',
    profile: 'Profile',
    billing: 'Billing',
    zapier_integration: 'Zapier',
  },
};
