// @vue/component
export default {
  name: 'ModalDeclinedAppIcon',

  data() {
    return {
      reasons: [],
    };
  },

  methods: {
    show(reasons) {
      this.reasons = reasons;
      this.$refs.declinedAppIconModal.show();
    },

    hide() {
      this.$refs.declinedAppIconModal.hide();
    },

    goToAppIconPage() {
      if (this.$route.name !== 'customisation-app-icon') {
        this.$router.push({
          name: 'customisation-app-icon',
        });
      } else {
        this.$root.$emit('declined-app-icon-view-details');
      }

      this.hide();
    },
  },
};
