import DatePicker from 'vue2-datepicker';
import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// @vue/component
export default {
  name: 'ModalReschedule',

  components: {
    DatePicker,
  },

  props: {
    /**
     * Plan ID
     * @type {number|string}
     */
    plan_id: {
      type: [Number, String],
    },

    /**
     * Plan title
     * @type {string}
     */
    plan_title: {
      type: String,
    },

    /**
     * Return data after update
     * @type {boolean}
     * @default false
     */
    return_data_after_update: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rescheduleDate: null,
      rescheduleMinDate: '',
      rescheduleStartDate: '',
      loadingRescheduleInfo: false,
      isRescheduleSubmited: false,
    };
  },

  methods: {
    // show modal and set dates
    showModal(data) {
      this.rescheduleStartDate = data.start_current_schedule;
      this.rescheduleMinDate = data.min_available_date;

      this.$refs.rescheduleModal.show();
    },

    // hide modal and clear reschedule date
    hideModal() {
      this.$refs.rescheduleModal.hide();
      this.rescheduleDate =
        this.rescheduleStartDate =
        this.rescheduleMinDate =
          null;
    },

    // check disabled dates for date picker
    disabledDate(date) {
      let after = new Date(
        this.$moment(this.rescheduleMinDate).format('YYYY-MM-DD')
      );

      after.setDate(after.getDate() - 1);

      return date.getTime() < after.getTime();
    },

    // reschedule start of program and show notice or errors. Return start date after update (optional)
    reschedule() {
      this.isRescheduleSubmited = true;

      HTTP.request({
        url: `plans/${this.plan_id}/re_add_schedule`,
        method: 'POST',
        data: {
          date: this.$moment(this.rescheduleDate).format('YYYY-MM-DD'),
        },
      })
        .then(() => {
          this.$notify({
            group: 'alerts',
            type: 'congrats',
            title: `${this.plan_title} has been successfully rescheduled.`,
          });

          if (this.return_data_after_update) {
            this.$emit(
              'updateStartDate',
              this.$moment(this.rescheduleDate).format('YYYY-MM-DD')
            );
          }

          this.hideModal();
        })
        .catch(HttpErrorsHandling)
        .finally(() => {
          this.isRescheduleSubmited = false;
        });
    },
  },
};
