import { HttpErrorsHandling } from '@/utils/http-common.js';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { PortalTarget } from 'portal-vue';

import UserAvatar from '@/shared/user-avatar/index.vue';
import NotificationsIndicator from '@/components/feed/indicator/index.vue';
import NotificationsIndicatorLink from '@/components/feed/indicator-link/index.vue';
import ChatIndicator from '@/components/chat/indicator/index.vue';
import ChatIndicatorLink from '@/components/chat/indicator-link/index.vue';
import OnboardingChecklist from '@/components/onboarding-checklist/index.vue';
// import CookiesPolicy from '@/shared/cookies-policy/index.vue';

import AccountsList from '@/shared/app-header/accounts-list/index.vue';
import SystemWarning from '@/shared/app-header/system-warning/index.vue';
import SubscriptionWarning from '@/shared/app-header/subscription-warning/index.vue';
import HelpDropdown from '@/shared/app-header/help-dropdown/index.vue';
import ReleaseNotesCounter from '@/components/release-notes/notes-counter/index.vue';
import SwlTrialWarning from '@/components/profile/coach/swl-customisation/swl-trial-warning.vue';
import TrialStatus from '@/shared/app-header/accounts-list/trial-status/index.vue';
const ClickedByHighlightedName = 'clicked-by-highlighted-coach-empty-account';

const checkSubstring = function (str, subStrings) {
  if (
    subStrings.some(function (v) {
      return str.indexOf(v) >= 0;
    })
  ) {
    return true;
  } else {
    return false;
  }
};

// @vue/component
export default {
  name: 'AppHeader',

  components: {
    PortalTarget,
    UserAvatar,
    NotificationsIndicator,
    NotificationsIndicatorLink,
    ChatIndicator,
    ChatIndicatorLink,
    OnboardingChecklist,
    // CookiesPolicy,
    AccountsList,
    SystemWarning,
    SubscriptionWarning,
    TrialStatus,
    HelpDropdown,
    ReleaseNotesCounter,
    SwlTrialWarning,
  },

  data() {
    return {
      isShowMenu: false,
      isMobileLayout: false,
      dropdownShowList: 0,
    };
  },

  computed: {
    isCoachOwnProfile() {
      return !!this.$route.meta.coachOwnProfile;
    },

    activeAccount() {
      return this.isCoachOwnProfile ? this.privateUser : this.currentUser;
    },

    isCurrentUser() {
      return this.authenticated;
    },

    hasNew() {
      return this.activeAccount.has_new_notifications;
    },

    currentUserName() {
      return this.isCoachOwnProfile
        ? this.activeAccount.full_name
        : this.activeAccount.title;
    },

    defaultAvatar() {
      return this.currentUserName.charAt(0).toUpperCase();
    },

    publicPageRoute() {
      return this.accountPublicRoute();
    },

    // Highlight empty account for invited coaches and B/G owners
    // (begin)
    coachEmptyAccount() {
      if (this.hasCoachAnotherAccounts) {
        return this.filteredAccounts.find((a) => {
          return (
            a.account_kind == 'private' &&
            !a.subscriptions.active &&
            !a.subscriptions.trial &&
            !a.subscriptions.trial_end_date
          );
        });
      } else {
        return null;
      }
    },

    hasCoachEmptyAccount() {
      return !!this.coachEmptyAccount?.id;
    },

    highlightCoachEmptyAccount() {
      let viewed = this.viewedPopups?.includes(ClickedByHighlightedName);

      return (
        this.hasCoachEmptyAccount &&
        this.filteredAccounts.length == 1 &&
        !viewed
      );
    },
    // (end)

    isBillingAviable() {
      return !this.isAccountFree && this.hasSubscription;
    },

    isUpgradeAccountAviable() {
      return this.isTrialActive || this.isTrialExpired;
    },

    isProgrammingLinkActive() {
      let routes = [
        'coach/programs',
        'challenges',
        'leaderboards',
        'templates',
        'media',
      ];

      return checkSubstring(this.$route.path, routes);
    },

    isStorefrontLinkActive() {
      // coupons
      let routes = ['coupons'];

      if (this.$route.name == 'public-page-short-link') {
        return true;
      } else {
        return checkSubstring(this.$route.path, routes);
      }
    },

    // Checklist
    onboardingChecklistAviable() {
      return (
        this.isCurrentUser &&
        this.isUserCoach &&
        !this.isAccountBlocked &&
        this.isFinancialAviable &&
        !this.isOnboardingChecklistHidden
      );
    },

    isDisabledNotes() {
      return this.isAccountBlocked && this.isUserCoach;
    },

    ...mapState(['currentUser', 'privateUser', 'authenticated']),
    ...mapGetters([
      'isUserCoach',
      'isBusinessAccount',
      'isBusinessOwner',
      'isFinancialAviable',
      'accountPublicRoute',
      'hasAccountsNotifications',
      'filteredAccounts',
      'hasCoachAnotherAccounts',
      'viewedPopups',
      'stripeAccount',
      'isOnboardingChecklistHidden',
      'isCustomStripeAccount',
    ]),
    ...mapGetters('subscription', [
      'isAccountBlocked',
      'isAccountFree',
      'hasSubscription',
      'isAccountEmpty',
      'isTrialActive',
      'isTrialExpired',
    ]),
    ...mapGetters('color_scheme', ['siteLogo']),
  },

  mounted: function () {
    this.$root.$on('hide-user-menu', this.close);

    this.mq = window.matchMedia('(max-width: 991.98px)');

    this.updateMatches();

    this.mq.addListener(this.updateMatches);

    if (this.isUserCoach && this.$route.name != 'coach_profile_my_accounts') {
      this.getCoachAccounts().catch(HttpErrorsHandling);
    }
  },

  beforeDestroy: function () {
    this.$root.$off('hide-user-menu', this.close);

    if (this.isShowMenu) {
      this.close();
    }

    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    /**
     * Handle dropdown shown
     */
    handleDropdownShown() {
      this.dropdownShowList = this.dropdownShowList + 1;
      this.setHeaderDropdownStatus(this.dropdownShowList != 0);
    },

    /**
     * Handle dropdown hide
     */
    handleDropdownHide() {
      this.dropdownShowList = this.dropdownShowList - 1;
      this.setHeaderDropdownStatus(this.dropdownShowList != 0);
    },

    /**
     * Update matches
     */
    updateMatches() {
      this.isMobileLayout = this.mq.matches;

      // if (window.Intercom) {
      //   if (this.mq.matches) {
      //     window.Intercom('update', {
      //       custom_launcher_selector: '#custom-intercom-launcher',
      //       hide_default_launcher: true,
      //       vertical_padding: 20,
      //     });
      //   } else {
      //     window.Intercom('update', {
      //       custom_launcher_selector: '',
      //       hide_default_launcher: false,
      //       vertical_padding: 100,
      //     });
      //   }
      // }
    },

    /**
     * Close
     */
    close: function () {
      this.toggleBodyClass('removeClass', 'stop-scrolling');
      this.isShowMenu = false;
    },

    /**
     * Toggle menu
     */
    toggleMenu() {
      if (this.isShowMenu) {
        this.toggleBodyClass('removeClass', 'stop-scrolling');
      } else {
        this.toggleBodyClass('addClass', 'stop-scrolling');
      }

      this.isShowMenu = !this.isShowMenu;
    },

    /**
     * Profile Menu showed
     */
    profileMenuShowed() {
      this.$root.$emit('profile-menu-showed');

      if (this.isShowMenu) {
        this.close();
      }
    },

    /**
     * Sing out
     */
    singOut() {
      this.toggleBodyClass('removeClass', 'stop-scrolling');

      this.$store.dispatch({
        type: 'signout',
      });
    },

    /**
     * Toggle body class
     * @param {string} addRemoveClass - Indicates whether to add or remove the class
     * @param {string} className - The class name to be added or removed.
     */
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    /**
     * Hide coach dropdown menu
     */
    hideCoachDropdownMenu() {
      if (this.$refs.coachDropdownMenu) {
        this.$refs.coachDropdownMenu.hide(true);
      }
    },

    /**
     * Click by highlighted hoach empty account
     */
    clickByHighlightedHoachEmptyAccount() {
      this.$refs.accountsList.changeAccount(this.coachEmptyAccount.id);
      this.makePopupViewed({ name: ClickedByHighlightedName });
    },

    /**
     * Open live chat
     */
    openLiveChat() {
      if (window.cookiehub?.hasConsented('preferences')) {
        window.Intercom && window.Intercom('show');
      } else {
        window.cookiehub?.openSettings && window.cookiehub.openSettings();
      }
    },

    ...mapMutations(['setAccounts', 'setHeaderDropdownStatus']),
    ...mapActions(['makePopupViewed', 'getCoachAccounts']),
  },
};
