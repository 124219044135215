import Store from '@/store/store.js';

const Layout = () => import('./layout/index.vue');
const Edit = () => import('./edit/index.vue');
const View = () => import('./view/info/index.vue');
const Plans = () => import('./view/plans/index.vue');
const Coaches = () => import('./view/coaches/index.vue');

export default [
  // create --
  {
    path: '/create-account',
    component: Layout,

    children: [
      {
        path: '',
        component: Edit,
        name: 'create-account',
        props: { isCreation: true },

        meta: {
          availableForBlocked: true,
        },
      },

      // Deprecated
      {
        path: 'brand',
        name: 'create-brand-account',
        redirect: {
          name: 'onboarding-business-setup',
          params: { type: 'brand' },
        },
      },

      // Deprecated
      {
        path: 'gym',
        name: 'create-gym-account',
        redirect: {
          name: 'onboarding-business-setup',
          params: { type: 'gym' },
        },
      },
    ],
  },

  // for owner
  {
    path: '/public-page/',
    component: Layout,

    children: [
      {
        path: '',
        name: 'own-public-page-preview',
        redirect: () => {
          return {
            name: 'public-page-view',
            params: { user_id: Store.state.currentUser.id },
          };
        },
      },
      {
        path: 'plans',
        name: 'own-public-page-plans',
        redirect: () => {
          return {
            name: 'public-page-plans',
            params: { user_id: Store.state.currentUser.id },
          };
        },
      },
      {
        path: 'coaches',
        name: 'own-public-page-coaches',
        redirect: () => {
          return {
            name: 'public-page-coaches',
            params: { user_id: Store.state.currentUser.id },
          };
        },
      },
      {
        path: 'edit',
        component: Edit,
        name: 'own-public-page-edit',

        beforeEnter: function (to, from, next) {
          if (
            Store.getters.isBusinessAccount &&
            !Store.getters.isBusinessOwner
          ) {
            next({
              name: 'public-page-view',
              params: { user_id: Store.state.currentUser.id },
              replace: true,
            });
          } else if (!Store.getters.isUserCoach) {
            next({ name: 'main' });
          } else {
            next();
          }
        },
      },
    ],
  },

  // public
  // Deprecated
  {
    path: '/public-page/:user_id/',
    component: Layout,
    props: true,

    children: [
      {
        path: '',
        component: View,
        name: 'public-page-view',
        meta: { publicPage: true },
      },
      {
        path: 'plans',
        component: Plans,
        name: 'public-page-plans',
        meta: { publicPage: true },
      },
      {
        path: 'coaches',
        component: Coaches,
        name: 'public-page-coaches',
        meta: { publicPage: true },
      },
    ],
  },

  {
    path: '/:user_type(c|b|g|t)/:user_id',
    component: Layout,
    props: true,

    children: [
      {
        path: '',
        component: View,
        name: 'public-page-short-link',
        meta: { publicPage: true, knowledge_type: 'storefront_note' },
      },
      {
        path: 'programs',
        component: Plans,
        name: 'public-page-short-link-plans',
        meta: { publicPage: true },
      },
      {
        path: 'coaches',
        component: Coaches,
        name: 'public-page-short-link-coaches',
        meta: { publicPage: true },
      },
    ],
  },
];
