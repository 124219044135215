<template>
  <div class="container static-page">
    <h1>Data security information</h1>

    <p>Coming soon</p>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style src="@/assets/styles/_static-pages.scss" lang="scss" scoped></style>
