export default {
  common: {
    more: 'More',
    edit: 'Edit',
    message: 'Message',
    programming: 'Programming',
    view_progress: 'View progress',
  },

  program_list: {
    col_1: 'Program',
    col_2: 'Price',
    col_3: 'Clients',
    col_4: 'Status',
    col_5: 'Actions',

    restore: 'Restore',

    sub_actions: {
      edit: 'Edit program',
      sign_up: 'Sign up new clients',
      message: 'Message the community',
      bulk: 'Send bulk message',
      preview: "Client's preview",
      clients: 'Clients',
      clone: 'Clone',
      copy: 'Copy to',
      archive: 'Archive',
      delete: 'Delete',

      unmute: 'Unmute notifications',
      mute: 'Mute notifications',

      block_downloads: 'Block new downloads',
      block_subscriptions: 'Block new subscriptions',
      available: 'Make available',
    },
  },

  template_list: {
    col_1: 'Name',
    col_2: {
      days: 'No. of blocks',
      weeks: 'No. of weeks',
    },
    col_3: 'Last updated',
    col_4: 'Actions',

    rename: 'Rename',
    copy: 'Copy to templates',
  },

  program_clients_list: {
    col_1: 'Name',
    col_2: 'Start date',
    col_3: 'Currently at',
    col_4: 'Actions',

    col_status: {
      default: 'Status',
      past: 'Client status',
      training: 'Training status',
      invite: 'Invite Status',
    },

    dates: {
      end_date: 'End date',
      days_left_in_trial: 'Days left in trial',
      dates_end_in_trial: 'Days left in trial',
      projected_end_date: 'Projected end date',
      training_programmed_to: 'Training programmed to',
    },

    remove: 'Remove Client From Program',
    cancel: 'Cancel subscription',

    sub_actions: {
      remove: 'Remove Client',
      training: 'Add Training',
      reschedule: 'Reschedule',
      edit: 'Edit Client Info',
      message: 'Send message',
    },
  },

  client_list: {
    col_1: 'Name',

    col_2: 'Mood',
    col_3: 'Energy',
    col_4: 'Completion %',
    col_5: 'CLTV',
    col_6: 'LT days',
    col_7: 'Programs',
    col_8: 'Days left',
    col_9: 'Client status',
    col_10: 'Program LTV',
    col_11: 'Actions',

    review: 'Review activity',

    sub_actions: {
      message: 'Send Message',
      activity: 'View Activity',
      resend: 'Resend Invite',
      edit: 'Edit Client Info',
      invite: 'Invite to a Program',
      remove: 'Remove Client',
      clear: 'Clear all notifications',
    },
  },

  coaches_list: {
    col_1: 'Coach',
    col_2: 'Status',
    col_3: 'Actions',

    status: {
      active: 'Active',
      rejected: 'Rejected',
      cancelled: 'Cancelled',
      invited: 'Invited',
    },

    resend: 'Resend',
  },

  conversation_table: {
    mute: 'Mute',
    unmute: 'Unmute',

    sub_actions: {
      mark_as_read: 'Mark as read',
      mark_as_unread: 'Mark as unread',

      restore: 'Restore',
      archive: 'Archive',
      delete: 'Delete',
    },
  },

  client_profile_table: {
    col_1: 'Name',
    col_2: 'Price',
    col_3: 'Start date',
    col_4: 'End date',
    col_5: 'Schedule Status',
    col_6: 'Actions',

    sub_actions: {
      open: 'Open program',
      reschedule: 'Reschedule',
      waiver: 'Waiver acceptance',
      cancel: 'Cancel Subscription',
    },
  },

  client_benchmark_table: {
    col_1: 'Name',
    col_2: {
      score: 'Latest Score',
      entry: 'Latest Entry',
    },
    col_3: 'Gain / Loss',
    col_4: 'Media',
    col_5: 'Notes',
    col_6: 'Date',
    col_7: 'Actions',

    col_des: 'Description',
  },

  coupons_table: {
    col_1: 'Coupon',
    col_2: 'Duration',
    col_3: 'Start / End Dates',
    col_4: 'Times Used',
    col_5: 'Program(s)',
    col_6: 'Status',
    col_7: 'Actions',

    copy: 'Copy',
    deactivate: 'Deactivate',
  },

  challenges_table: {
    col_1: 'Challenge',
    col_2: 'Score type',
    col_3: 'Description',
    col_4: 'Scores',
    col_5: 'Actions',
    col_created: 'Created by',

    owner: 'Owner',
  },

  accounts_table: {
    col_1: 'Account name',
    col_2: 'Launch date',
    col_3: 'Account status',
    col_4: 'My role',
    col_5: 'Actions',

    integration: 'Integration',

    accept: 'Accept',
    decline: 'Decline',
    go_to_account: 'Go to account',
  },
};
